import React, {useMemo} from 'react'
import {
  useCollectionAdd,
  useCollectionClone,
  useCollectionDelete,
  useCollectionGet, useCollectionMerge, useCollectionUpdateQuery
} from 'services/collection/collection.hooks';
import {useParams} from 'react-router-dom';
import ProfileProvider from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {
  useCollectionGroups,
  useCollectionPatch, useCollectionSections,
  useCollectionToggleFavorite, useCollectionToggleLabel,
  useCollectionToggleVisibility
} from 'services/collection/collection.utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useCollectionTagGroupPatch} from 'services/collection/tagGroup/tagGroup.utils';
import {
  useCollectionTagGroupAdd,
  useCollectionTagGroupDelete,
  useCollectionTagGroupMove
} from 'services/collection/tagGroup/tagGroup.hooks';
import {useProviderDataState, useProviderView} from 'helpers/hooks/utils';
import {useClientGet} from 'services/client/client.hooks';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useSourceDelete, useSourceUpdate} from 'services/source/source.hooks';
import {
  useCollectionCustomFieldAdd,
  useCollectionCustomFieldCache,
  useCollectionCustomFieldDelete
} from 'services/collection/customField/customField.hooks';
import {useCollectionCustomFieldPatch} from 'services/collection/customField/customField.utils';

const CollectionProfileProvider = (props) => {
  const params = useParams();
  const tableProvider = useTable();
  const { collectionId } = {...utils.cleanObject({
    collectionId: tableProvider?.selected()?.collectionId
  }), ...params, ...props};

  const clientId = useAuthClientId();

  const dataKey = 'collectionId';
  const view = useProviderView('collection', props.skipView);

  const client = useClientGet({clientId}, {enabled: clientId >= 0});
  const collection = useCollectionGet({collectionId}, {enabled: +collectionId > 0});

  const [context, onContext] = useProviderDataState(client);
  const [data, onData] = useProviderDataState(collection);

  const customFields = useCollectionCustomFieldCache({clientId: clientId, collectionId: collection.data?.collectionId, addLinks: false},
    {enabled: clientId >= 0 && collection.data?.collectionId > 0});

  const groups = useCollectionGroups(view, data?.data, (customFields.data?.data ?? customFields.data));
  const sections = useCollectionSections(view);

  // isLoading anything, is loading something definition related
  const isLoading = props?.isLoading || customFields.status?.isLoading || client.status?.isLoading || collection.status?.isLoading;
  const isDefinitionsLoading = props?.isDefinitionsLoading || !customFields.status?.isSettled;

  const cardDefinitions = useMemo(() => {
    return groups?.length > 0 ? groups : null;
  }, [groups]);

  const sectionDefinitions = useMemo(() => {
    return sections?.length > 0 ? sections : null;
  }, [sections]);

  const options = useMemo(() => ({
    cardState: {
      options: {
        name: 'collection',
        type: constants.appState.type.local
      }
    },
    cardDefinitions,
    sectionDefinitions
  }), [cardDefinitions, sectionDefinitions]);

  const fieldData = useMemo(() => {
    return {
      customFields: (customFields.data?.data ?? customFields.data),
    };
  }, [customFields.data]);

  const updateData = useCollectionPatch();
  const createData = useCollectionAdd();
  const cloneData = useCollectionClone();
  const mergeData = useCollectionMerge();
  const deleteData = useCollectionDelete();
  const updateQuery = useCollectionUpdateQuery();
  const updateTagGroup = useCollectionTagGroupPatch();
  const createTagGroup = useCollectionTagGroupAdd();
  const deleteTagGroup = useCollectionTagGroupDelete();
  const moveTagGroup = useCollectionTagGroupMove();
  const updateCustomField = useCollectionCustomFieldPatch();
  const createCustomField = useCollectionCustomFieldAdd();
  const deleteCustomField = useCollectionCustomFieldDelete();
  const toggleLabel = useCollectionToggleLabel();
  const toggleFavorite = useCollectionToggleFavorite();
  const toggleVisibility = useCollectionToggleVisibility();
  const updateSource = useSourceUpdate();
  const deleteSource = useSourceDelete();
  const updaters = useMemo(() => {
    return {
      createData: (data) => {
        return createData.mutation.mutateAsync(data);
      },
      cloneData: () => {
        return cloneData.mutation.mutateAsync({collectionId: collection.data?.collectionId});
      },
      mergeData: (data) => {
        return mergeData.mutation.mutateAsync({collectionId: collection.data?.collectionId, ...data});
      },
      deleteData: (data) => {
        return deleteData.mutation.mutateAsync(data);
      },
      updateData: updateData,
      updateQuery: () => {
        return updateQuery.mutation.mutateAsync({collectionId: collection.data?.collectionId, scope: constants.collection.scopes.outside});
      },
      createTagGroup: (data) => {
        return createTagGroup.mutation.mutateAsync({
          collectionId: collection.data?.collectionId,
          tagsGroup: utils.toArray(data).map((tg) => ({...tg, tags: utils.underscoreEx(tg.tags)}))
        });
      },
      deleteTagGroup:  (data) => {
        return deleteTagGroup.mutation.mutateAsync({
          collectionId: collection.data?.collectionId,
          groupId: data?.groupId
        });
      },
      updateTagGroup: updateTagGroup,
      moveTagGroup: (data) => {
        return moveTagGroup.mutation.mutateAsync({
          collectionId: collection.data?.collectionId,
          groupId: data?.groupId,
          pos: data?.pos
        });
      },
      createCustomField: (data) => {
        return createCustomField.mutation.mutateAsync({
          clientId: client.data?.clientId,
          collectionId: collection.data?.collectionId,
          ...data
        });
      },
      deleteCustomField:  (data) => {
        return deleteCustomField.mutation.mutateAsync({
          clientId: client.data?.clientId,
          collectionId: collection.data?.collectionId,
          fieldId: data?.fieldId
        });
      },
      updateCustomField: (customField, data) => {
        return updateCustomField(collection.data, customField, data)
      },
      updateSource: (data) => {
        return updateSource.mutation.mutateAsync(data);
      },
      deleteSource: (data) => {
        return deleteSource.mutation.mutateAsync(data);
      },
      toggleLabel: toggleLabel,
      toggleFavorite: toggleFavorite,
      toggleVisibility: toggleVisibility
    }
  }, [updateData, createData.mutation, cloneData.mutation, mergeData.mutation, deleteData.mutation, updateQuery.mutation,
    moveTagGroup.mutation, updateTagGroup, createTagGroup.mutation, deleteTagGroup.mutation,
    deleteSource.mutation, updateSource.mutation,
    updateCustomField, createCustomField.mutation, deleteCustomField.mutation,
    collection.data, client.data, toggleLabel, toggleFavorite, toggleVisibility]);

  return <ProfileProvider {...props}
                          view={view}
                          dataKey={dataKey}
                          data={data}
                          onData={onData}
                          context={context}
                          onContext={onContext}
                          options={options}
                          fieldData={fieldData}
                          updaters={updaters}
                          isDefinitionsLoading={isDefinitionsLoading}
                          isLoading={isLoading}>
    {props.children}
  </ProfileProvider>
};

CollectionProfileProvider.propTypes = {
}

export default CollectionProfileProvider;
