import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import profile from 'helpers/profile';
import StyledClientProfile from 'components/organisms/Profiles/ClientProfile/ClientProfile.styles';
import ClientProfileCardContent from 'components/organisms/Cards/ClientProfileCardContent/ClientProfileCardContent';
import ClientPlanProfileCard from 'components/organisms/Cards/ClientPlanProfileCard/ClientPlanProfileCard';
import ClientPlanProfileCardContent
  from 'components/organisms/Cards/ClientPlanProfileCardContent/ClientPlanProfileCardContent';
import DealflowStatusGroupProfileCardContent
  from 'components/organisms/Cards/DealflowStatusGroupProfileCardContent/DealflowStatusGroupProfileCardContent';
import DealflowStatusGroupProfileCard
  from 'components/organisms/Cards/DealflowStatusGroupProfileCard/DealflowStatusGroupProfileCard';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ClientCollaboratorsProfileCard
  from 'components/organisms/Cards/ClientCollaboratorsProfileCard/ClientCollaboratorsProfileCard';
import ClientCollaboratorsProfileCardContent
  from 'components/organisms/Cards/ClientCollaboratorsProfileCardContent/ClientCollaboratorsProfileCardContent';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import logger from 'helpers/logger';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import ClientConnectionProfileCard
  from 'components/organisms/Cards/ClientConnectionProfileCard/ClientConnectionProfileCard';
import ClientConnectionProfileCardContent
  from 'components/organisms/Cards/ClientConnectionProfileCardContent/ClientConnectionProfileCardContent';

const ClientProfile = (props) => {
  const {
    onCanUpdate,
    forceEditNew,
    ...innerProps
  } = useComponentProps(props, 'ClientProfile');

  const profileProvider = useProfile();
  const client = profileProvider.data?.data;
  const cardDefinitions = profileProvider.cardDefinitions;

  const authorize = useAuthorize();
  const snackbar = useSnackbar();

  const updateEvent = useEffectEvent(profileProvider.updaters?.updateData);
  const createCollaboratorEvent = useEffectEvent(profileProvider.updaters?.createCollaborator);
  const deleteCollaboratorEvent = useEffectEvent(profileProvider.updaters?.deleteCollaborator);
  const updateCollaboratorEvent = useEffectEvent(profileProvider.updaters?.updateCollaborator);
  const createStatusGroupEvent = useEffectEvent(profileProvider.updaters?.createStatusGroup);
  const updateStatusGroupEvent = useEffectEvent(profileProvider.updaters?.updateStatusGroup);
  const deleteStatusGroupEvent = useEffectEvent(profileProvider.updaters?.deleteStatusGroup);

  const handlePatch = useCallback((client, field, value, onSuccess, onError) => {
    const promises = [];
    if (field.name === 'promise') {
      promises.push(value);
    } else if (field.name.startsWith('statusGroup')) {
      if (+value?.groupId > 0) {
        promises.push(updateStatusGroupEvent?.(client, value));
      } else {
        promises.push(deleteStatusGroupEvent?.({groupId: field.name.split('-')[1]}));
      }
    } else if (field.name.startsWith('collaborator')) {
      const action = field.name.split('-')[1];
      if (action === 'create') {
        promises.push(createCollaboratorEvent?.(client.clientId, value));
      } else if (+value?.userId > 0) {
        promises.push(updateCollaboratorEvent?.(client.clientId, value));
      } else {
        promises.push(deleteCollaboratorEvent?.(client.clientId, field.name.split('-')[1]));
      }
    } else if (['name', 'description'].includes(field.name)) {
      promises.push(updateEvent?.(client, {[field.name]: value}, {}));
    } else {
      promises.push(updateEvent?.(client, {}, {[field.name]: value}));
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          onSuccess?.();
        }).catch((err) => {
          logger.trace('Client save failed', err);
          onError?.();
        });
    } else {
      onSuccess?.();
    }
  }, [updateEvent, updateStatusGroupEvent, deleteStatusGroupEvent,
    createCollaboratorEvent, updateCollaboratorEvent, deleteCollaboratorEvent]);

  const handleSubmit = useCallback((client, values, fields, actions, onSuccess, onError) => {
    let promise, statusGroup, collaborator;
    const props = {}, profile = {};
    Object.keys(values).forEach((k) => {
      const field = fields.find((f) => f.name === k);
      if (field) {
        if (['name', 'description'].includes(field.name)) {
          profile[k] = values[k];
        } else if (field.name.startsWith('statusGroup')) {
          statusGroup = values[k] ?? {groupId: field.name.split('-')[1], delete: true};
        } else if (field.name.startsWith('collaborator')) {
          const action = field.name.split('-')[1];
          collaborator = values[k] ?? {userId: action, delete: true};
        } else if (field) {
          props[k] = values[k];
        }
      } else if (k === 'promise') {
        promise = values[k];
      }
    });

    const promises = [];
    if (utils.isDefined(statusGroup)) {
      if (statusGroup.delete) {
        promises.push(deleteStatusGroupEvent?.(statusGroup));
      } else if (+statusGroup.groupId > 0) {
        promises.push(updateStatusGroupEvent?.(client, statusGroup));
      } else {
        promises.push(createStatusGroupEvent?.(statusGroup));
      }
    }
    if (utils.isDefined(collaborator)) {
      if (collaborator.delete) {
        promises.push(deleteCollaboratorEvent?.(client.clientId, collaborator.userId));
      } else if (collaborator.userIds?.length > 0) {
        promises.push(createCollaboratorEvent?.({clientId: client.clientId, userIds: collaborator.userIds}));
      } else {
        promises.push(updateCollaboratorEvent?.(client.clientId, collaborator));
      }
    }
    if (!utils.isEmpty(profile) || !utils.isEmpty(props)) {
      promises.push(updateEvent?.(client, profile, props));
    }
    if (utils.isDefined(promise)) {
      promises.push(promise);
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          onSuccess?.();
        }).catch((err) => {
          logger.trace('Client save failed', err);
          onError?.();
        });
    } else {
      onSuccess?.();
    }
  }, [updateEvent, createStatusGroupEvent, updateStatusGroupEvent, deleteStatusGroupEvent,
    createCollaboratorEvent, updateCollaboratorEvent, deleteCollaboratorEvent]);

  const moveStatusGroupEvent = useEffectEvent(profileProvider.updaters?.moveStatusGroup);
  const handleMove = useCallback((client, card, containerId, position, onSuccess, onError) => {
    moveStatusGroupEvent?.(client, card.data.groupId, position + 1)
      .catch(() => {
        snackbar.show('Moving group failed', null,
          {color: 'error', autoHideDuration: constants.delay.error});
        onError?.();
      });
  }, [moveStatusGroupEvent, snackbar]);

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const cardsMemo = useMemo(() => {
    if (cardDefinitions) {
      return cardDefinitions
        .filter((cardDef) => !['tagGroup', 'service', 'source'].find((n) => cardDef.name.includes(n)))
        .map((cardDef) => {
          const canEditCard = (client) => {
            const canEditClient = (onCanUpdateEvent ? onCanUpdateEvent?.(client) : true) &&
              authorize({attribute: 'client.update', meta: {client}});

            return canEditClient && !(cardDef.readOnly ?? false) && authorize({
              ...cardDef.auth?.update,
              meta: {...cardDef.auth?.update?.meta, client}
            });
          }

          const fields = cardDef.fields?.map((field) => {
            const canEditField = (client) => {
              return onCanUpdateEvent ? onCanUpdateEvent?.(client, field) : (field.auth?.update ?
                authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, client}}) : true);
            }

            return {
              ...field,
              editable: ({data}) => canEditField(data)
            }
          });

          if (cardDef.name === 'collaborators') {
            return {
              ...cardDef,
              editable: ({profile}) => {
                return canEditCard(profile.data);
              },
              contents: [
                {
                  id: 1,
                  fields,
                  Content: ({ref, profile, card, content, isDialog, isLoading, onPatch}) => {
                    return <ClientCollaboratorsProfileCardContent ref={ref}
                                                                  card={card}
                                                                  content={content}
                                                                  client={profile?.data}
                                                                  isDialog={isDialog}
                                                                  isLoading={isLoading}
                                                                  onPatch={onPatch}/>
                  },
                  Loader: () => {
                    return <Box className="ProfileCardContent-loader">
                      {(new Array(Math.max(10, Math.ceil(Math.random() * 16)))).fill(null)
                        .map((f, idx) => {
                        return <Box key={idx}>
                          <Box><Typography isLoading={true} min={20} max={40}/></Box>
                          <Box><Typography isLoading={true} min={8} max={20}/></Box>
                          <Typography isLoading={true} min={3} max={5}/>
                        </Box>
                      })}
                    </Box>
                  }
                }
              ],
              Card: ({ref, profile, card, action, isDialog, isLoading, renderedContent}) => {
                return <ClientCollaboratorsProfileCard ref={ref}
                                                       card={card}
                                                       action={action}
                                                       client={profile?.data}
                                                       isDialog={isDialog}
                                                       isLoading={isLoading}
                                                       renderedContent={renderedContent}/>
              },
              onSubmit: handleSubmit,
              onPatch: handlePatch
            };
          } else if (cardDef.name.startsWith('plan')) {
            return {
              ...cardDef,
              editable: ({profile}) => {
                return canEditCard(profile.data) && cardDef.data?.planId === profile.data?.props?.plan;
              },
              confirmClose: true,
              contents: [
                {
                  id: 1,
                  fields,
                  Content: ({ref, profile, card, data, content, fieldData, isDialog, onPatch, onSubmit, onValidating}) => {
                    return <ClientPlanProfileCardContent ref={ref}
                                                         profile={profile}
                                                         card={card}
                                                         content={content}
                                                         client={profile?.data}
                                                         isDialog={isDialog}
                                                         fieldData={fieldData}
                                                         plan={data}
                                                         onPatch={onPatch}
                                                         onSubmit={onSubmit}
                                                         onValidating={onValidating}/>
                  }
                }
              ],
              Card: ({ref, profile, card, data, isDialog, renderedHeader, renderedContent}) => {
                return <ClientPlanProfileCard ref={ref}
                                              card={card}
                                              client={profile?.data}
                                              plan={data}
                                              isDialog={isDialog}
                                              renderedHeader={renderedHeader}
                                              renderedContent={renderedContent}/>
              },
              onSubmit: handleSubmit,
              onPatch: handlePatch
            };
          } else if (cardDef.name.startsWith('statusGroup')) {
            const isAddNew = !(cardDef.data?.groupId > 0);
            return {
              ...cardDef,
              editable: ({profile}) => canEditCard(profile.data),
              draggable: !isAddNew,
              droppable: !isAddNew,
              isEditing: () => {
                return (isAddNew && forceEditNew);
              },
              toggle: (isAddNew && forceEditNew),
              confirmClose: true,
              contents: [
                {
                  id: 1,
                  fields,
                  Content: ({ref, profile, card, data, content, fieldData, isDialog, onPatch, onSubmit, onValidating}) => {
                    return <DealflowStatusGroupProfileCardContent ref={ref}
                                                                  card={card}
                                                                  client={profile?.data}
                                                                  content={content}
                                                                  isDialog={isDialog}
                                                                  fieldData={fieldData}
                                                                  statusGroup={data}
                                                                  onPatch={onPatch}
                                                                  onSubmit={onSubmit}
                                                                  onValidating={onValidating}/>
                  }
                }
              ],
              Card: ({ref, card, data, action, fieldData, onHide, isDialog, renderedContent}) => {
                return <DealflowStatusGroupProfileCard ref={ref}
                                                       card={card}
                                                       onHide={onHide}
                                                       action={action}
                                                       statusGroup={data}
                                                       fieldData={fieldData}
                                                       isDialog={isDialog}
                                                       renderedContent={renderedContent}/>
              },
              onSubmit: handleSubmit,
              onPatch: handlePatch,
              onMove: handleMove
            };
          } else if (cardDef.name.startsWith('connection')) {
            const isAddNew = !utils.isDefined(cardDef.data?.connectionId);

            return {
              ...cardDef,
              data: cardDef.data,
              title: !isAddNew ? cardDef.data?.name : 'Add connection',
              editable: ({profile}) => canEditCard(profile.data),
              isEditing: () => {
                return (isAddNew && forceEditNew);
              },
              toggle: (isAddNew && forceEditNew),
              confirmClose: true,
              SaveButtonProps: isAddNew ? {
                color: 'success',
                startIcon: <Icon icon={Add}/>,
                children: 'Create connection'
              } : null,
              contents: [
                {
                  id: 1,
                  fields,
                  Content: ({ref, profile, card, data, content, fieldData, isDialog, renderedLoader, onPatch, onSubmit, onValidating}) => {
                    return <ClientConnectionProfileCardContent ref={ref}
                                                               card={card}
                                                               content={content}
                                                               client={profile?.data}
                                                               isDialog={isDialog}
                                                               fieldData={fieldData}
                                                               connection={data}
                                                               renderedLoader={renderedLoader}
                                                               onPatch={onPatch}
                                                               onSubmit={onSubmit}
                                                               onValidating={onValidating}/>
                  }
                }
              ],
              Card: ({ref, profile, card, data, action, fieldData, onHide, isDialog, renderedContent}) => {
                return <ClientConnectionProfileCard ref={ref}
                                                    card={card}
                                                    onHide={onHide}
                                                    action={action}
                                                    client={profile?.data}
                                                    connection={data}
                                                    fieldData={fieldData}
                                                    isDialog={isDialog}
                                                    renderedContent={renderedContent}/>
              },
              onSubmit: handleSubmit,
              onPatch: handlePatch,
              onMove: handleMove
            };
          } else {
            const contents = profile.fields2Contents(fields, [], {ContentComponent: ClientProfileCardContent});

            if (contents.length > 0) {
              return {
                ...cardDef,
                editable: ({profile}) => canEditCard(profile.data),
                contents: contents,
                onPatch: handlePatch,
                onSubmit: handleSubmit,
                onMove: handleMove
              }
            } else {
              return null;
            }
          }
        }).filter((_) => (_));
    } else {
      return null;
    }
  }, [cardDefinitions, forceEditNew, authorize, handlePatch, handleSubmit, handleMove, onCanUpdateEvent]);

  return <StyledClientProfile {...innerProps}
                              data={client}
                              cards={cardsMemo}/>
};

ClientProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func,
  forceEditNew: PropTypes.bool
};

ClientProfile.defaultProps = {
};

export default ClientProfile;
