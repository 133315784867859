import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Divider from 'components/atoms/Dividers/Divider/Divider';
import StyledTasksTooltip from 'components/molecules/Tooltips/TasksTooltip/TasksTooltip.styles';
import constants from 'helpers/constants';

const TasksTooltip = React.forwardRef((props, ref) => {
  const {
    activeIndex,
    activateByPayload,
    activePayload,
    payload,
    variant,
    ...innerProps
  } = useComponentProps(props, 'TasksTooltip', {
    children: ['content']
  });

  const extended = variant === 'extended' || variant === 'types';
  const payloads = activateByPayload ? payload?.filter((p) => `${p.dataKey}-${activeIndex}` === activePayload) : payload;

  const renderTitle = () => {
    const pl = payloads?.[0];
    if (pl) {
      const task = pl.payload[`${pl.dataKey}-meta`] ?? pl.payload?.['meta'];
      const types = task?.types?.filter((s) => !!s.count);

      return <Box className="TasksTooltip-content">
        <Box className="title">
          <Span>{task.label}</Span>
          <Badge variant="inline"
                 max={constants.numbers.thousand}
                 badgeContent={task.countLabel ?? pl.payload[pl.dataKey]}/>
        </Box>
        {extended && types?.length > 0 && [
          <Divider key="divider" />,
          <Box key="extended" className="extended">
            {types.map((s, idx) =>
              <Box key={idx} className="type">
                <Span>{s.name}</Span>
                <Badge className="count" variant="inline"
                       color={innerProps.theme.property('palette.primary.states.focusVisible')}
                       max={constants.numbers.thousand}
                       badgeContent={s.countLabel ?? s.count}/>
              </Box>)}
          </Box>
        ]}
      </Box>;
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTasksTooltip ref={ref} {...innerProps}
                             payload={payload}
                             title={renderTitle()}
                             activeIndex={activeIndex}
                             activateByPayload={activateByPayload}
                             activePayload={activePayload}/>
});

TasksTooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  activeIndex: PropTypes.any,
  activateByPayload: PropTypes.bool,
  activePayload: PropTypes.any,
  payload: PropTypes.array,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'extended', 'types']), PropTypes.string])
};

TasksTooltip.defaultProps = {
  title: 'TasksTooltip text'
};

export default TasksTooltip;
