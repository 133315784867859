import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedState} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Delete from '@mui/icons-material/Delete';
import FieldMappingsForm from 'components/organisms/Forms/FieldMappingsForm/FieldMappingsForm';
import ListMappingsForm from 'components/organisms/Forms/ListMappingsForm/ListMappingsForm';
import StyledMappingForm from 'components/organisms/Forms/MappingForm/MappingForm.styles';

const MappingForm = React.forwardRef((props, ref) => {
  const {
    mapping,
    only,
    mappedFields,
    connection,
    fieldData,
    required,
    readOnly,
    disabled,
    removable,
    hideActions,
    error,
    errorFrom,
    errorTo,
    onChange,
    onError,
    onDelete,
    ...innerProps
  } = useComponentProps(props, 'MappingForm');

  const formRef = useRef(null);
  const listsRef = useRef(null);
  const fieldsRef = useRef(null);
  const innerRef = useRef(null);

  const mappingForm = useMemo(() => ({
    refs: {
      ref: innerRef,
      formRef
    },
    submit: (touch = true) => {
      return formRef.current?.submit(touch);
    },
    validate: (touch = true) => {
      return formRef.current?.validate(touch);
    },
    reset: (state) => {
      listsRef.current?.reset(state);
      fieldsRef.current?.reset(state);
      setIsDirty(false);
      setInternalState({});
      return formRef.current?.reset(state);
    }
  }), []);

  useImperativeHandle(ref, () => mappingForm);

  const [isDirty, setIsDirty] = useState(false);
  const [internalState, setInternalState] = useState(mapping);
  const [internalValue] = useUpdatedState(mapping);

  const isNew = !utils.isDefined(internalValue.team?.teamId);

  useEffect(() => {
    if (required && error) {
      return utils.observeTimeout(() => {
        try {
          formRef.current?.validate(true);
        } catch (e) {
          /* SQUASH */
        }
      }, constants.debounce.minimal)
    }
  }, [required, error]);

  const fields = useMemo(() => {
    const flds = [];

    const team = internalState.team ?? internalValue.team;
    const lists = internalState.lists ?? internalValue.lists;

    flds.push({
      name: 'team',
      label: 'Team',
      placeholder: 'Type a team name',
      type: constants.formFieldTypes.autocomplete,
      validation: constants.formFieldValidationTypes.team,
      formGroup: 'header',
      options: 'teams',
      initial: internalValue.team,
      readOnly: readOnly,
      required: required || isDirty,
      disabled: disabled,
      FormFieldProps: {
        variant: 'staticLabel',
        size: 'smaller',
        hiddenLabel: true,
        fullWidth: false
      }
    });

    flds.push({
      name: `synchroniseDeletedItems`,
      label: 'Synchronise removed companies',
      type: constants.formFieldTypes.switch,
      validation: constants.formFieldValidationTypes.boolean,
      entity: 'mapping',
      formGroup: 'header',
      initial: internalValue.synchroniseDeletedItems ?? false,
      readOnly: readOnly,
      disabled: disabled || !utils.isDefined(team?.teamId),
      FormFieldProps: {
        variant: 'inlineLabel',
        fullWidth: false
      }
    });

    flds.push({
      name: 'lists',
      label: 'Lists',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.listMapping}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'mapping',
      valueProp: 'lists',
      formGroup: 'body',
      Component: <ListMappingsForm team={team}
                                   connection={connection}
                                   errorFrom={errorFrom}
                                   errorTo={errorTo}
                                   fieldData={fieldData}
                                   multiple={false} />,
      initial: {
        value: internalValue.lists ?? [],
        errors: false
      },
      required: utils.isDefined(team?.teamId),
      readOnly: readOnly,
      disabled: disabled || !utils.isDefined(team?.teamId),
      FormFieldProps: {
        ref: listsRef,
        variant: 'staticLabel',
        hiddenLabel: true,
        debounce: false,
        autoFocus: false
      }
    });

    flds.push({
      name: 'fields',
      label: 'Fields',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.unique}(${constants.formFieldValidationTypes.fieldMapping}))`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'mapping',
      valueProp: 'fields',
      formGroup: 'body',
      Component: <FieldMappingsForm list={lists?.[0]}
                                    mappedFields={mappedFields}
                                    connection={connection}
                                    fieldData={fieldData} />,
      initial: {
        value: internalValue.fields ?? [],
        errors: false
      },
      required: false,
      readOnly: readOnly,
      disabled: disabled || !utils.isDefined(lists?.[0]?.from?.id) || !utils.isDefined(lists?.[0]?.to?.id),
      FormFieldProps: {
        ref: fieldsRef,
        variant: 'staticLabel',
        hiddenLabel: false,
        debounce: false,
        autoFocus: false
      }
    });

    return flds;
  }, [internalValue, internalState, connection, mappedFields, readOnly, disabled, errorFrom, errorTo, fieldData, required, isDirty]);

  const handleSubmit = (values, actions) => {
    utils.asPromise(onChange)({...values})
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  const handleChange = () => {
    formRef.current?.submit(false);
  }

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    if (field) {
      if (field.name === 'team') {
        setInternalState((current) => {
          if (current.team?.value !== value?.value) {
            listsRef.current?.setValue?.({value: [], errors: false});
            listsRef.current?.reset?.({touched: {}});
            formRef.current?.touch?.({lists: false});

            return {
              ...current,
              team: value
            }
          } else {
            return current;
          }
        });
      } else if (field.name === 'lists') {
        setInternalState((current) => {
          if (!utils.compare((current.lists ?? []).map((l) => ({...l, id: null, direction: null})),
                             (value.value ?? []).map((l) => ({...l, id: null, direction: null})))) {
            fieldsRef.current?.setValue?.({value: [], errors: false});
            fieldsRef.current?.reset?.({touched: {}});
            formRef.current?.touch?.({fields: false});

            return {
              ...current,
              lists: value.value
            }
          } else {
            return current;
          }
        });
      }
    }
  }

  const handleValidating = (validating, dirty, errors) => {
    setIsDirty((current) => current || dirty || errors);
    onError?.(errors);
  }

  const onDeleteEvent = useEffectEvent(onDelete);
  const deleteAction = useMemo(() => ({
    label: 'Remove mapping',
    tooltip: 'Remove mapping',
    icon: Delete,
    ActionIconButtonProps: {
      IconProps: {
        size: 'smaller'
      }
    },
    IconButtonProps: {
      disabled: (disabled || readOnly || !removable || (only && isNew && !isDirty))
    },
    onClick: (e) => {
      onDeleteEvent?.(e);
    }
  }), [disabled, readOnly, removable, only, isDirty, isNew, onDeleteEvent]);

  const renderActions = () => {
    return <Box className="MappingForm-actions">
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="contained"
                        color="error"
                        action={deleteAction}/>
    </Box>
  }

  const renderFormContent = (groups) => {
    const header = groups.find((g) => g.name === 'header');
    const body = groups.find((g) => g.name === 'body');

    return <React.Fragment>
      <Box className="MappingForm-header">
        <Box className="Form-fields">
          {header.rendered}
        </Box>
        {!hideActions ? renderActions() : null}
      </Box>
      {body ? <Box className="MappingForm-body">
        <Box className="Form-fields">
          {body.rendered}
        </Box>
      </Box> : null}
    </React.Fragment>
  }

  return <StyledMappingForm ref={innerRef} {...innerProps}>
    <InlineForm ref={formRef}
                className="MappingForm-form"
                fieldData={fieldData}
                autoFocus={isNew && !only}
                onSubmit={handleSubmit}
                onChange={handleChange}
                onChangeDirect={handleChangeDirect}
                onValidating={handleValidating}
                renderContent={renderFormContent}
                fields={fields}/>
  </StyledMappingForm>
});

MappingForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  mapping: PropTypes.object,
  only: PropTypes.bool,
  mappedFields: PropTypes.array,
  connection: PropTypes.object,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  removable: PropTypes.bool,
  hideActions: PropTypes.bool,
  error: PropTypes.bool,
  errorFrom: PropTypes.bool,
  errorTo: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onDelete: PropTypes.func
};

MappingForm.defaultProps = {
  elevation: 1,
  radius: 'round'
};

export default MappingForm;
