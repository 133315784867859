import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import {DragIndicator} from '@mui/icons-material';
import {DragHandleContext, useDragHandle} from 'components/organisms/Utils/DragDrop/DndContext';
import StyledDealflowStatusGroupProfileCard
  from 'components/organisms/Cards/DealflowStatusGroupProfileCard/DealflowStatusGroupProfileCard.styles';

const DealflowStatusGroupProfileCard = React.forwardRef((props, ref) => {
  const {
    card,
    action,
    onHide,
    statusGroup,
    fieldData,
    isDialog,
    renderedContent,
    ...innerProps
  } = useComponentProps(props, 'DealflowStatusGroupProfileCard', {
    static: ['isAddNew', 'isReadOnly', 'isEditing', 'isDeleted', 'isDialog'],
    children: ['content']
  });

  const innerRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const isAddNew = !utils.isDefined(statusGroup?.groupId);
  const isReadOnly = !card.card.editable;
  const isLoading = !utils.isDefined(statusGroup?.name);

  const dialogControl = useDialogControl();

  useLayoutEffect(() => {
    const editing = card.state.isEditing ?? card.state.card.isEditing ?? card.state.dialog.isEditing;
    if (!isAddNew) {
      setIsEditing(editing);
    } else {
      if (!card.state.card.submitSuccess) { // wait for new card
        setIsEditing(editing);
      }
    }
  }, [card.state.isEditing, card.state.card.isEditing, card.state.dialog.isEditing, card.state.card.submitSuccess, isAddNew]);

  const handleDeleteClick = (e) => {
    const handleConfirm = () => {
      const field = {
        name: `statusGroup-${statusGroup.groupId}`
      };

      return utils.asPromiseCallback(card.card.onPatch)(field, null)
        .then(() => {
          onHide?.(true);
          setIsDeleted(true);
        });
    }

    dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this deal flow group?"
                                      explanation="The deal flow group will be permanently removed"
                                      challenge={statusGroup.name}
                                      challengeLabel="group name"
                                      onConfirm={handleConfirm}
                                      ConfirmButtonProps={{
                                        children: 'Remove group',
                                        startIcon: <Icon icon={Delete} />,
                                        color: 'error'
                                      }}/>, true);

    e.preventDefault();
  };

  const dragHandleContext = useDragHandle(DragHandleContext);

  const dragAction = useMemo(() => ({
    icon: <Icon icon={DragIndicator} size="smaller" />,
    tooltip: 'Move group',
    TooltipProps: dragHandleContext?.isDragging ? {
      open: false
    } : null,
    onClick: () => null,
    ActionIconButtonProps: {
      IconProps: {size: 'tiny'}
    },
    IconButtonProps: {
      ...utils.filterObject(dragHandleContext, 'isDragging'),
      className: 'ProfileCard-action dragHandle'
    }
  }), [dragHandleContext]);

  const deleteAction = useMemo(() => ({
    icon: <Icon icon={Delete} size="smaller" />,
    tooltip: 'Remove group',
    ActionIconButtonProps: {
      IconProps: {size: 'tiny'}
    },
    IconButtonProps: {
      onPointerDown: (e) => {
        e.preventDefault();
      }
    }
  }), []);

  const renderActions = () => {
    if (action && !isLoading) {
      return <React.Fragment>
        {dragHandleContext ? <ActionIconButton tabIndex={-1}
                                               action={dragAction}
                                               density="dense"
                                               disableRipple={true}
                                               size="smaller" variant="outlined" /> : null}
        {!(isEditing || isAddNew || isReadOnly) ? <ActionIconButton className="ProfileCard-action"
                                                                    variant="contained"
                                                                    size="smaller"
                                                                    color="error"
                                                                    density="dense"
                                                                    tabIndex={-1}
                                                                    action={deleteAction}
                                                                    onClick={handleDeleteClick} /> : null}
        <ActionIconButton className="ProfileCard-action"
                          tabIndex={-1}
                          action={action} density="dense"
                          size="smaller" variant="outlined"
                          IconProps={{size: 'tiny'}}
                          onPointerDown={(e) => {
                            e.preventDefault();
                          }}/>
      </React.Fragment>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isReadOnly: isReadOnly,
    isEditing: isEditing,
    isDeleted: isDeleted
  });

  return <StyledDealflowStatusGroupProfileCard ref={innerRef} {...innerProps}>
    {!(isAddNew || isDialog) ? <CardHeader className="ProfileCard-header"
                                           action={renderActions()}/> : null}
    {renderedContent}
  </StyledDealflowStatusGroupProfileCard>
});

DealflowStatusGroupProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  action: PropTypes.object,
  onHide: PropTypes.func,
  statusGroup: PropTypes.object,
  fieldData: PropTypes.object,
  isDialog: PropTypes.bool,
  renderedContent: PropTypes.any
};

DealflowStatusGroupProfileCard.defaultProps = {
};

export default DealflowStatusGroupProfileCard;
