import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import StyledConnectionCard from 'components/organisms/Cards/ConnectionCard/ConnectionCard.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Img from 'components/atoms/Images/Img/Img';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ConnectionCard = React.forwardRef((props, ref) => {
  const {
    connection,
    icon,
    img,
    isLoading,
    IconProps,
    ImgProps,
    ...innerProps
  } = useComponentProps(props, 'ConnectionCard');

  const renderDetails = () => {
    const failed = ['failed', 'inactive'].includes(connection?.status);
    const errorInfo = connection.active && failed && connection?.errors?.find((e) => e.type === 'info');

    return <Box className="ConnectionCard-details">
      <Box className="ConnectionCard-detail">
        <Typography className="ConnectionCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Active</Typography>
        <Typography className="ConnectionCard-detail-value"
                    isLoading={isLoading}
                    variant="caption">{connection.active ? 'Yes' : 'No'}</Typography>
      </Box>
      <Box className="ConnectionCard-detail">
        <Typography className="ConnectionCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">{(connection.active && !failed && connection?.lastRun) ? 'Updated' : 'Status'}</Typography>
        <Typography className={`ConnectionCard-detail-value ${failed ? 'error' : ''}`}
                    isLoading={isLoading}
                    variant="caption">{!connection.active ? 'Not active' : (failed ? 'Failed' : (
                      connection?.lastRun ? utils.timeAgo(new Date(connection?.lastRun)) : 'Synchronising...'
                    ))}
          {errorInfo ? <Tooltip title="Edit this connection for more detailed information"
                                enterDelay={0}
                                variant="emphasis"
                                placement="bottom">
            <Icon className="ConnectionCard-detail-info"
                  icon={InfoOutlined}
                  color="light.dark"
                  size="smaller"/>
          </Tooltip> : null}
        </Typography>
      </Box>
    </Box>
  }

  const renderDescription = () => {
    return <Box className="ConnectionCard-description">
      <Typography showTooltip={true}
                  isLoading={isLoading}
                  variant="body2">
        {connection?.comment ? <Markdown>{connection?.comment}</Markdown> : 'No description yet'}
      </Typography>
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledConnectionCard ref={ref} {...innerProps}>
    <Box className="ConnectionCard-content">
      {icon ? <Box className="ConnectionCard-logo">
        {utils.isReactElement(icon) ? icon : <Icon icon={icon} isLoading={isLoading} {...IconProps}/>}
      </Box> : null}
      {(!icon && img) ? <Box className="ConnectionCard-logo">
        {utils.isReactElement(img) ? img : <Img src={img} alt="logo" isLoading={isLoading} {...ImgProps}/>}
      </Box> : null}
      <Box className="ConnectionCard-info">
        <H6 className="ConnectionCard-title" showTooltip={true}
            min={8} max={20}
            isLoading={isLoading}>{connection?.name}</H6>
        {renderDetails()}
      </Box>
    </Box>
    {renderDescription()}
  </StyledConnectionCard>
});

ConnectionCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  connection: PropTypes.object,
  icon: PropTypes.any,
  img: PropTypes.any,
  isLoading: PropTypes.bool,
  IconProps: PropTypes.object,
  ImgProps: PropTypes.object
};

ConnectionCard.defaultProps = {};

export default ConnectionCard;
