import React from 'react';
import PropTypes from 'prop-types';
import StyledActionbar from 'components/organisms/Snackbars/Actionbar/Actionbar.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import Paper from 'components/atoms/Papers/Paper/Paper';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import useMediaQuery from '@mui/material/useMediaQuery';

const Actionbar = React.forwardRef((props, ref) => {
  const {
    title,
    actions,
    onActionClick,
    ...innerProps
  } = useComponentProps(props, 'Actionbar', {
    styled: ['color'],
    children:['content', 'title', 'actions']
  });

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const renderTitle = () => {
    if (!mdDown) {
      return <Box className="Actionbar-title">
        {title ? <Box className="Actionbar-title-text">
          {utils.isReactElement(title) ? title : <Typography variant="body1">{title}</Typography>}
        </Box> : null}
      </Box>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledActionbar ref={ref} {...innerProps}>
    <Paper className="Actionbar-content">
      {renderTitle()}
      <Box className="Actionbar-actions">
        {actions?.map((action, idx) => {
          return <ActionButton key={idx}
                               action={action}
                               onClick={onActionClick} />
        })}
      </Box>
    </Paper>
  </StyledActionbar>
});

Actionbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  actions: PropTypes.array,
}

Actionbar.defaultProps = {
  title: 'Actionbar text',
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  color: 'secondary'
};

export default Actionbar;
