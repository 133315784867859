import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledTextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell.styles';
import Typography from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import utils from 'helpers/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';
import Box from 'components/atoms/Layout/Box/Box';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import {withMemo} from 'helpers/wrapper';

const TextTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    icon,
    logo,
    avatar,
    title,
    subtitle,
    action,
    active,
    onClick,
    density,
    TitleProps,
    SubtitleProps,
    ...innerProps
  } = useComponentProps(props, 'TextTableCell', {
    static: ['canUpdate']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    canUpdate: Boolean(itm)
  });

  return <StyledTextTableCell ref={ref} {...innerProps} onClick={(!title || active) ? handleClick : null}>
    {(logo || avatar || icon) ? <Box className="TextTableCell-icon-wrap">
      {logo ? (utils.isReactElement(logo) ? logo : <Logo logo={logo}/>) : null}
      {avatar ? (utils.isReactElement(avatar) ? avatar : <Avatar>{avatar}</Avatar>) : null}
      {icon ? (utils.isReactElement(icon) ? icon : <Icon icon={icon}/>) : null}
    </Box> : null}

    <Box className="TextTableCell-text" onClick={handleClick}>
      <Box className="TextTableCell-text-title">
        {title ? <Box className="TextTableCell-text-title-text">
            {utils.isReactElement(title) ? title :
              <Typography showTooltip={true} variant="subtitle2" {...TitleProps}>{title}</Typography>}
        </Box> : null}
      </Box>
      {subtitle ? <Box className="TextTableCell-text-subtitle">
        <Box className="TextTableCell-text-subtitle-wrap">
          {utils.isReactElement(subtitle) ? subtitle : <Typography showTooltip={true} variant="body2" {...SubtitleProps}>{subtitle}</Typography>}
        </Box>
      </Box> : null}
    </Box>
    {itm ? <Icon className="TextTableCell-edit" onClick={handleClick} icon={itm?.icon} size="tiny"/> : null}
  </StyledTextTableCell>
}));

TextTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.any,
  subtitle: PropTypes.any,
  logo: PropTypes.any,
  icon: PropTypes.any,
  action: PropTypes.object,
  onClick: PropTypes.func
};

TextTableCell.defaultProps = {
};

export default TextTableCell;


