import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import StyledClientPlanProfileCardContent
  from 'components/organisms/Cards/ClientPlanProfileCardContent/ClientPlanProfileCardContent.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Chip from 'components/atoms/Chips/Chip/Chip';
import Typography, {H3, H5} from 'components/atoms/Text/Typography/Typography';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import Save from '@mui/icons-material/Save';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {AddCircle, RemoveCircle} from '@mui/icons-material';

const ClientPlanProfileCardContent = React.forwardRef((props, ref) => {
  const {
    profile,
    card,
    content,
    plan,
    client,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    ...innerProps
  } = useComponentProps(props, 'ClientPlanProfileCardContent', {
    static: ['isActive', 'isEditing', 'isDeleted', 'isCustom']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const dialogControl = useDialogControl();

  const isAddNew = !utils.isDefined(plan?.planId);
  const isEditing = content.state.isEditing;
  const isCustom = Boolean(plan?.custom);
  const isActive = plan?.planId === client?.props?.plan;
  const isLoading = !utils.isDefined(plan);

  const sections = useMemo(() => {
    const planIndex = constants.data.plans.findIndex((p) => p.value === plan?.value);
    const prevPlan = planIndex > 0 ? constants.data.plans[planIndex - 1] : null;

    const prevSections = (!isCustom && prevPlan) ? constants.data.planSections.map((ps) => ({
      ...ps,
      options: ps.options.filter((o) => {
        return !prevPlan.except.find((e) => o.value === e)
      })
    })).filter((s) => s.options.length > 0) : [];

    return constants.data.planSections.map((ps) => {
      const prevSection = prevSections.find((s) => s.value === ps.value);

      let options = ps.options.map((o) => {
        const included = Boolean(!plan?.except.find((e) => o.value === e));
        return {
          ...o,
          included: included,
          enabled: !isActive ? included : (o.properties?.length === 0 || o.properties?.filter((p) => client?.props?.[p]).length === o.properties.length),
          addition: !isCustom && prevPlan && prevSection && !Boolean(prevSection?.options?.find((po) => po.value === o.value))
        }
      }).filter((o) => {
        return o.included || o.enabled;
      });

      return {
        ...ps,
        all: ps.options,
        options: options,
        extra: options.filter((o) => o.enabled && !o.included).length === options.length,
        disabled: options.filter((o) => !o.enabled).length === options.length,
        hasPrevious: Boolean(prevSection)
      }
    }).filter((s) => s.options.length > 0);
  }, [client?.props, isActive, isCustom, plan]);

  const onSubmitEvent = useEffectEvent(onSubmit);
  const upgradeAction = useMemo(() => {
    const downgrade = constants.data.plans.findIndex((p) => p.value === plan?.planId) <
      constants.data.plans.findIndex((p) => p.value === client?.props?.plan);
    return {
      label: isCustom ? 'Get in touch' : (
        downgrade ? 'Downgrade' : 'Upgrade'
      ),
      color: isCustom ? 'secondary' : 'primary',
      auth: (isActive && !isCustom) ? utils.createAuth({attribute: 'system.null'}) :
        utils.createAuth({attribute: downgrade ? 'client.plan.downgrade' : 'client.plan.upgrade', meta: {plan}}),
      ButtonProps: {
        variant: (isCustom || !downgrade) ? 'contained': 'outlined',
        fullWidth: isCustom,
        href: isCustom ? 'mailto:sales@ventureiq.nl' : null
      },
      onClick: !isCustom ? () => {
        const handleConfirm = () => {
          const fields = [{
            name: 'plan',
            value: plan?.value
          }];

          const changes = fields.reduce((o, f) => {
            o[f.name] = f.value;
            return o;
          }, {});

          return utils.asPromiseCallback(onSubmitEvent)(changes, null, fields, plan, true);
        }

        dialogControl.show(<ConfirmDialog question={`Are you sure you want to ${downgrade ? 'downgrade' : 'upgrade'} your plan?`}
                                          explanation={`Your plan will be ${downgrade ? 'downgraded' : 'upgraded'} to ${plan?.label} this will take effect immediately`}
                                          onConfirm={handleConfirm}
                                          ConfirmButtonProps={{
                                            startIcon: <Icon icon={Save}/>,
                                            children: downgrade ? 'Downgrade' : 'Upgrade',
                                            color: 'primary'
                                          }}/>, true);
      } : null
    }
  }, [isActive, isCustom, plan, client?.props?.plan, dialogControl, onSubmitEvent]);

  const renderSections = () => {
    const planIndex = constants.data.plans.findIndex((p) => p.value === plan?.value);
    const prevPlan = planIndex > 0 ? constants.data.plans[planIndex - 1] : null;

    return <Box className="ClientPlanProfileCardContent-readOnly-sections">
      {(!isCustom && prevPlan) ? <Box key={prevPlan.value} className="ClientPlanProfileCardContent-readOnly-section">
        <Icon icon={CheckCircle} color="success"/>
        <Typography isLoading={isLoading} variant="body2">Everything from {prevPlan.subtitle}</Typography>
      </Box> : null}
      {sections.map((s) => {
        return <React.Fragment>
          {(!s.hasPrevious || s.disabled || s.extra) ?
            <Box key={s.value}
                   className="ClientPlanProfileCardContent-readOnly-section">
              <Icon className={s.extra ? 'extra' : (s.disabled ? 'disabled' : '')}
                    isLoading={isLoading}
                    icon={s.extra ? AddCircle : (s.disabled ? RemoveCircle : CheckCircle)}
                    color="success"/>
              <Typography isLoading={isLoading} variant="body2">{s.label}</Typography>
            </Box> : null}
          {(!s.disabled && !s.extra) ? s.options
            .filter((o) => (o.included && o.addition) || (o.included && !o.enabled) || (!o.included && o.enabled))
            .map((o) => {
              return <Box key={o.value} className="ClientPlanProfileCardContent-readOnly-section">
                <Icon className={(o.enabled && !o.included) ? 'extra' : (!o.enabled ? 'disabled' : '')}
                      isLoading={isLoading}
                      icon={(o.enabled && !o.included) ? AddCircle : (!o.enabled ? RemoveCircle : CheckCircle)}
                      color="success"/>
                <Typography isLoading={isLoading} variant="body2">{o.label}</Typography>
              </Box>
            }) : null}
        </React.Fragment>
      })}
    </Box>
  }

  const renderReadOnly = () => {
    const hasTeams = sections?.find((s) => s.options.find((o) => o.value === 'hasTeams' && o.enabled));

    return <Box className="ClientPlanProfileCardContent-readOnly">
      <Box className="ClientPlanProfileCardContent-readOnly-header">
        {isActive ? <Chip className="ClientPlanProfileCardContent-readOnly-chip"
                          showTooltip={false} isLoading={isLoading}
                          variant="filled"
                          color={isCustom ? 'white' : 'primary'}
                          label="Current plan"/> : null}
        <Typography className="ClientPlanProfileCardContent-readOnly-title"
                    variant="subtitle1"
                    color={plan?.color ?? 'primary'}>{plan?.label}</Typography>
        <H3 className="ClientPlanProfileCardContent-readOnly-subtitle">{plan?.subtitle}</H3>
        {(!isCustom || isActive) ? <Box className="ClientPlanProfileCardContent-readOnly-chips">
          <Chip className={(isActive && !isCustom && client?.props?.userLimit !== plan?.seats) ? 'changed' : ''}
                showTooltip={true}
                isLoading={isLoading}
                variant="outlined"
                color={isCustom ? 'white' : 'default'}
                label={`${utils.formatNumber(isActive ? client?.props?.userLimit : plan?.seats)} seats`}/>
          {hasTeams ?
            <Chip className={(isActive && !isCustom && client?.props?.teamLimit !== plan?.teams) ? 'changed' : ''}
                  showTooltip={true}
                  isLoading={isLoading}
                  variant="outlined"
                  color={isCustom ? 'white' : 'default'}
                  label={`${utils.formatNumber(isActive ? client?.props?.teamLimit : plan?.teams)} teams`}/> : null}
          <Chip className={(isActive && !isCustom && client?.props?.monthlyCreditLimit !== plan?.credits) ? 'changed' : ''}
                showTooltip={true}
                isLoading={isLoading}
                variant="outlined"
                color={isCustom ? 'white' : 'default'}
                label={`${utils.numberToLabel(isActive ? client?.props?.monthlyCreditLimit : plan?.credits)} credits`}/>
        </Box> : null}
      </Box>
      {(!isCustom || isActive) ? renderSections() : <Box className="ClientPlanProfileCardContent-readOnly-custom">
        <Typography variant="body1" isLoading={isLoading}>
          For teams that want to accelerate proactive deal sourcing and unburden their team.<br/><br/>
          The Venture IQ team can extend your team as needed. From data requests to full market landscapes.
        </Typography>
      </Box>}
      <Box className="ClientPlanProfileCardContent-readOnly-footer">
        {!isCustom ? <Box className="ClientPlanProfileCardContent-readOnly-price">
          <H5 isLoading={isLoading}>&euro; {plan?.price}</H5>
          <Typography variant="subtitle2" isLoading={isLoading}>per month</Typography>
        </Box> : null}
        <ActionButton action={upgradeAction}/>
      </Box>
    </Box>
  }

  const fields = useMemo(() => {
    return utils.initializeFormFields(content.fields, client)
      .map((f) => ({
        ...f,
        formGroup: f.variant
      }));
  }, [content.fields, client]);

  const renderForm = () => {
    const canPatch = Boolean(content.state.isEditing && !isDialog && !profile.state.isEditing);

    return <InlineForm ref={formRef}
                       key={content.id}
                       fields={fields}
                       fieldData={fieldData}
                       onChange={canPatch ? onPatch : null}
                       onSubmit={onSubmit}
                       onValidating={onValidating}/>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing,
    isActive: isActive,
    isCustom: isCustom
  });

  return <StyledClientPlanProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="ClientPlanProfileCardContent-content">
      {!isEditing ? renderReadOnly() : renderForm()}
    </Box>
  </StyledClientPlanProfileCardContent>
});

ClientPlanProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  client: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func,
};

ClientPlanProfileCardContent.defaultProps = {
};

export default ClientPlanProfileCardContent;


