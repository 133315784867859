import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitiesPage
  from 'components/pages/Collection/Entity/CollectionEntitiesPage/CollectionEntitiesPage.styles';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import CollectionEntitiesTable from 'components/organisms/Tables/CollectionEntitiesTable/CollectionEntitiesTable';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import CollectionEntitiesActionbar
  from 'components/organisms/Snackbars/CollectionEntitiesActionbar/CollectionEntitiesActionbar';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {EmptyCollection} from 'assets/icons';
import constants from 'helpers/constants';
import Typography, {H4} from 'components/atoms/Text/Typography/Typography';
import CollectionOnboarding from 'components/organisms/Onboardings/CollectionOnboarding/CollectionOnboarding';
import utils from 'helpers/utils';

const CollectionEntitiesPage = (props) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesPage');

  const authorize = useAuthorize();
  const tableProvider = useTable();
  const isSuggestion = tableProvider?.view?.name === 'suggestions';
  const collection = tableProvider.context?.data;

  const handleCanRowClick = (entity) => {
    return utils.isDefined(entity.entityId) && authorize({attribute: 'collection.profile.read', meta: {entity}});
  }

  const navigate = useLinkNavigate();
  const handleRowClick = (e, entity) => {
    navigate({
      event: e,
      to: './' + entity.entityId,
      keepSearchParams: true
    });
  }

  const renderOnBoarding = () => {
    return <CollectionOnboarding className="CollectionEntitiesPage-onboarding"
                                 collection={collection}
                                 showEmpty={true}
                                 isLoading={tableProvider.isLoading()}/>
  }

  const renderEmpty = () => {
    return <Box className="CollectionEntitiesPage-empty">
      <Icon icon={EmptyCollection}/>
      <H4>No companies in this collection yet</H4>
      <Typography variant="body2" color="text.secondary">
        Manually add companies or let Catalist search for candidates in sources you select
      </Typography>
    </Box>
  }

  const entitiesCount = collection?.newInCollection?.find((c) => c.scope === constants.collection.scopes.inside)?.total;

  return <StyledCollectionEntitiesPage as={TableWrapper} {...innerProps}
                                       borders={{left: '3sp', right: '3sp'}}>
    {(!isSuggestion && entitiesCount === 0) ? renderEmpty() :
      <CollectionEntitiesTable onCanRowClick={handleCanRowClick}
                               onRowClick={handleRowClick}
                               ActionbarComponent={<CollectionEntitiesActionbar tableProvider={tableProvider} />} />
    }
    {renderOnBoarding()}
  </StyledCollectionEntitiesPage>
};

CollectionEntitiesPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesPage.defaultProps = {
};

export default CollectionEntitiesPage;
