import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionCategoryWizard
  from 'components/organisms/Wizards/CollectionCategoryWizard/CollectionCategoryWizard.styles';
import ChooseCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ChooseCollectionCategoriesWizardContent/ChooseCollectionCategoriesWizardContent';
import ResolveCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ResolveCollectionCategoriesWizardContent/ResolveCollectionCategoriesWizardContent';

const CollectionCategoryWizard = React.forwardRef((props, ref) => {
  const {
    collection,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'CollectionCategoryWizard');

  const steps = useMemo(() => ([
    {
      name: 'tagGroups',
      label: 'Choose categories',
      shortLabel: 'Choose categories',
      Content: (props) => {
        return <ChooseCollectionCategoriesWizardContent ref={props.ref}
                                                        className={props.className}
                                                        wizard={props.wizard}
                                                        step={props.step}
                                                        collection={collection}
                                                        onComplete={props.onComplete}
                                                        onError={props.onError}
                                                        onDirty={props.onDirty}/>
      }
    },
    {
      name: 'resolve',
      label: 'Save categories',
      shortLabel: 'Save categories',
      Content: (props) => {
        return <ResolveCollectionCategoriesWizardContent ref={props.ref}
                                                         className={props.className}
                                                         wizard={props.wizard}
                                                         step={props.step}
                                                         collection={collection}
                                                         onComplete={props.onComplete}
                                                         onError={props.onError}
                                                         onDirty={props.onDirty}/>
      },
      enableSubmit: (data) => {
        return data.category?.outputTagGroups?.filter((tg) => tg.tags?.length > 0).length > 0;
      }
    }
  ]), [collection]);

  const handleSubmit = (data, actions, onSuccess, onError) => {
    const tagGroups = data.category.outputTagGroups.map((tg) => {
      return {
        name: tg.name,
        color: tg.color,
        tags: tg.tags.map((t) => {
          return {
            tagId: null,
            value: t.name,
            autoTagParams: { keywords: t.keywords ?? [] }
          };
        })
      };
    })

    return onSubmit?.(tagGroups, actions, onSuccess, onError);
  }

  return <StyledCollectionCategoryWizard ref={ref} {...innerProps}
                                         variant="simple"
                                         steps={steps}
                                         dataKey="category"
                                         onSubmit={handleSubmit}/>
});

CollectionCategoryWizard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  onSubmit: PropTypes.func
};

CollectionCategoryWizard.defaultProps = {};

export default CollectionCategoryWizard;
