import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledSourceAddDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: clamp(0px, ${props => props.theme.layout(884)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(884)}, 86vw);
  }
  
  .InlineForm {
    .FormControl {
      &.FormField-name-filterType {
        row-gap: ${props => props.theme.layout('1sp')};

        > .InputLabel, > .FormLabel {
          margin-top: ${props => props.theme.layout(4)} !important;
          ${props => props.theme.font2Css('subtitle1')};
          min-width: ${props => props.theme.layout(86)};
        }
        
        .List {
          width: fit-content;
          .ListItem {
            white-space: nowrap;
          }
        }
      }
    }
  }

  ${FormStyles}
  ${ComponentStyles}
`;

StyledSourceAddDialog.propTypes = {
  theme: PropTypes.object
};

StyledSourceAddDialog.defaultProps = {}

export default StyledSourceAddDialog;
