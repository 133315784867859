import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDashboardPage = styled(Page)`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${props => props.theme.layout('3sp')};
  row-gap: ${props => props.theme.layout('3sp')};
  padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};
  
  .DashboardPage {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: ${props => props.theme.layout(40)};
      
      &-teammates {
        column-gap: ${props => props.theme.layout('0.5sp')};
      }
    }
    
    &-cards {
      padding: 0;  
      width: 100%;
      height: 100%;
    }
    
    &-dealflow {
      height: ${props => props.theme.layout(416)};
    }

    &-tasks {
      min-height: ${props => props.theme.layout(444)};
    }

    &-insights {
      min-height: ${props => props.theme.layout(520)};
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
    
    .DashboardPage {
      .Card {
        height: unset;
      }
      
      &-dealflow {
        max-height: ${props => props.theme.layout(416)};
      }
      &-themeBreakdown {
        padding: ${props => props.theme.spacing(2)} 0;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDashboardPage.propTypes = {
  theme: PropTypes.object
};

StyledDashboardPage.defaultProps = {}

export default StyledDashboardPage;
