import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {lighten} from 'polished';
import utils from 'helpers/utils';

const StyledClientConnectionProfileCardContent = styled(Box)`
  &.ClientConnectionProfileCardContent {
    display: flex;
    gap: ${props => props.theme.layout('1sp')};
    width: 100%;
    
    .ClientConnectionProfileCardContent {
      &-content {
        display: flex;
        flex-direction: row;
        column-gap: ${props => props.theme.layout('1sp')};
        width: 100%;

        .ConnectionCard {
          flex-grow: 1;
          width: 1px;
          
          &-suggested {
            width: unset;
          }
        }

        .Form-group-fields, .Form-group-mapping {
          width: 100%;
          padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)};
          background-color: ${props => props.theme.property('palette.primary.states.hover')};
          border: 1px solid ${props => props.theme.property('palette.divider')};
          border-radius: ${props => props.theme.radius(2)};
          
          display: flex;
          flex-direction: column;

          > .FormControl {
            > .InputLabel, > .FormLabel {
              ${props => props.theme.font2Css('subtitle1')};
              margin-bottom: calc(${props => props.theme.spacing(1)} - 1px);
            }
          }
          
          .ComponentField-container {
            .Card {
              border: unset;
              border-radius: 0;
              box-shadow: none;
              padding: 0;
              background-color: unset;

              .FormControl {
                .MuiInputBase-root {
                  background-color: ${props => props.theme.property('palette.background.paper')};
                }

                .PopperField-button {
                  background-color: ${props => props.theme.property('palette.background.paper')};
                }
              }
            }
          }

          .FormField-name-mappings {
            .FormField-name-fields {
              background-color: ${props => utils.rgba2Rgb(lighten(0.4, props.theme.property('palette.primary.states.hover')))};
            }
          }
        }

        .Form-group-mapping {
          margin-bottom: ${props => props.theme.layout('2sp')};
        }
        
        .Form-section {
          > * {
            ${props => props.theme.font2Css('subtitle2')};
          }
          
          .InfoPaper {
            &-info {
              .info-lines {
                line-height: 1;
                
                .Box {
                  padding-top: ${props => props.theme.spacing(0.5)};
                  &:not(:first-child) {
                    padding-top: ${props => props.theme.spacing(1)};
                  }
                  
                  > .Span {
                    display: inline-flex;
                    align-items: flex-start;
                    padding-top: ${props => props.theme.spacing(1)};

                    .Icon {
                      margin-top: -${props => props.theme.layout(3.5)};
                      margin-right: ${props => props.theme.layout('0.5sp')};
                    }

                    &:first-child {
                      padding-top: ${props => props.theme.spacing(1)};
                      padding-bottom: ${props => props.theme.spacing(0.5)};
                    }

                    > .Span {
                      &:not(:last-child) {
                        font-weight: 500;
                        margin-right: ${props => props.theme.layout('0.5sp')};
                      }
                    }
                  }
                }
              }
            }
          }
        }
        
        .FormControl {
          &.FormField-name-active {
            .ListField-container {
              flex-grow: unset;
            }
          }
        }
      }
    }
  }

  &:not(.ClientConnectionProfileCardContent-isEditing) {
    &.ClientConnectionProfileCardContent-isAddNew {
      width: 100%;
      height: 100%;
      padding: 0;
      min-height: ${props => props.theme.layout(280)};
      max-height: ${props => props.theme.layout(280)};
      background-color: ${props => props.theme.property(`palette.primary.states.focus`)};

      .ClientConnectionProfileCardContent-content {
        width: 100%;
        height: 100%;

        .Card {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
        }
      }
    }
  }

  ${ComponentStyles}
`;

StyledClientConnectionProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledClientConnectionProfileCardContent.defaultProps = {}

export default StyledClientConnectionProfileCardContent;
