import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionOnboarding = styled(Box)`
  .ActionFab {
    visibility: hidden;
    &-closed {
      visibility: unset;
    }
  }
  
  .CollectionOnboarding {
    &-progress {
      width: ${props => props.theme.layout(28)} !important;
      height: ${props => props.theme.layout(28)} !important;
      
      svg {
        opacity: 0.7;
      }
      
      .CircularProgress-label {
        .Typography {
          font-weight: 500;
          font-size: ${props => props.theme.fontSize(9)};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionOnboarding.propTypes = {
  theme: PropTypes.object
};

StyledCollectionOnboarding.defaultProps = {
}

export default StyledCollectionOnboarding;
