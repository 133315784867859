import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledListMappingForm = styled(Card)`
  width: 100%;
  padding: ${props => props.theme.spacing(1.5)};
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  .ListMappingForm {
    &-form {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1.5sp')};
    }
    
    &-header {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      align-items: flex-start;
      
      .Form-fields {
        flex-grow: 1;
        flex-wrap: nowrap;
        display: grid;
        grid-template-columns: 1fr fit-content(100%) 1fr;
        
        .FormField-name-direction {
          .InputContainer {
            flex-grow: unset;
          }
        }
      }
    }

    &-actions {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledListMappingForm.propTypes = {
  theme: PropTypes.object
};

StyledListMappingForm.defaultProps = {}

export default StyledListMappingForm;
