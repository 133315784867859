import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledViewBar from 'components/organisms/Bars/ViewBar/ViewBar.styles';
import Typography from 'components/atoms/Text/Typography/Typography';
import Close from '@mui/icons-material/Close';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useLocation} from 'react-router-dom';
import {useLinkNavigate} from 'helpers/hooks/links';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import Box from 'components/atoms/Layout/Box/Box';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ViewBar = React.forwardRef((props, ref) => {
  const {
    title,
    progress,
    progressText,
    progressTooltip,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'ViewBar', {
    styled: ['color']
  });

  const tableProvider = useTable();

  const view = tableProvider?.view;

  const location = useLocation();
  const navigate = useLinkNavigate();

  const action = useMemo(() => ({
    label: 'Reset view',
    icon: Close,
    onClick: (e) => {
      navigate({event: e, to: location.pathname});
    },
    ButtonProps: {
      size: 'small',
      variant: 'text'
    }
  }), [location.pathname, navigate]);

  const renderProgress = () => {
    if (utils.isDefined(progress)) {
     return <Tooltip title={progressTooltip}
                     placement="bottom">
       <Box className="ViewBar-progress">
        <LinearProgress variant="determinate" value={progress}/>
        <Typography isLoading={isLoading} variant="body2">{progressText ?? `${progress}%`}</Typography>
      </Box>
     </Tooltip>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  if (view && (view?.name !== 'default')) {
    return <StyledViewBar ref={ref} {...innerProps}>
      {(title ?? view.title) ? (utils.isReactElement((title ?? view.title)) ? (title ?? view.title) :
        <Typography variant="body2">{title ?? view.title}</Typography>) : null}
      {renderProgress()}
      <ActionButton action={action} />
      {innerProps.children}
    </StyledViewBar>
  }
});

ViewBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  progress: PropTypes.number,
  progressText: PropTypes.string,
  progressTooltip: PropTypes.any
};

ViewBar.defaultProps = {
  color: 'primary'
};

export default ViewBar;
