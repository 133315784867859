import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ClientProfile from 'components/organisms/Profiles/ClientProfile/ClientProfile';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledPlanSettingPage from 'components/pages/Setting/PlanSettingPage/PlanSettingPage.styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const info = 'Select the plan that suits your needs. Custom plans can only be changed by your Venture IQ representative';

const PlanSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'PlanSettingPage');

  const contentRef = useRef(null);

  const profileProvider = useProfile();
  const authorize = useAuthorize();

  const bBox = useBbox(() => contentRef.current, ['width']);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleShowCard = (card) => {
    return card.name.startsWith('plan');
  };

  const handleCanUpdate = () => {
    return authorize({attribute: 'settings.plan.update'});
  }

  const handleDirty = (dirty) => {
    profileProvider.dirty?.(dirty);
  }

  const columns = bBox?.width ? Math.max(Math.floor((bBox?.width ?? 0) / 286), 1) : 0;

  return <StyledPlanSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                title="Plan"
                                info={!smDown ? info : null}>
    <Box ref={contentRef} className="PlanSettingPage-content">
      <ClientProfile columns={columns}
                     variant="grid"
                     onShowCard={handleShowCard}
                     onDirty={handleDirty}
                     onCanUpdate={handleCanUpdate}/>
    </Box>
  </StyledPlanSettingPage>
};

PlanSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

PlanSettingPage.defaultProps = {};

export default PlanSettingPage;
