import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import {useAuthClientId} from 'services/auth/auth.utils';
import TeamsSettingsCard from 'components/organisms/Cards/TeamsSettingsCard/TeamsSettingsCard';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import Cards from 'components/atoms/Cards/Cards/Cards';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import TeamAddDialog from 'components/organisms/Dialogs/TeamAddDialog/TeamAddDialog';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import {useClientTeamCreate} from 'services/client/team/team.utils';
import {useClientTeamList} from 'services/client/team/team.hooks';
import utils from 'helpers/utils';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledTeamsSettingPage from 'components/pages/Setting/TeamsSettingPage/TeamsSettingPage.styles';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Box from 'components/atoms/Layout/Box/Box';

const info = 'Add and manage dedicated team environments';

const TeamsSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'TeamsSettingPage');

  const contentRef = useRef(null);

  const authorize = useAuthorize();
  const wrapper = useWrapper();

  const bBox = useBbox(() => contentRef.current, ['width']);

  const clientId = useAuthClientId();
  const teamList = useClientTeamList({clientId}, {enabled: (clientId >= 0)})
  const teams = teamList.data;

  const teamAdd = useClientTeamCreate();

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [showTeamAddDialog, setShowTeamAddDialog] = useState(false);

  const action = useMemo(() => ({
    label: !mdDown ? 'Add team' : null,
    tooltip: mdDown ? 'Add team' : null,
    icon: <Icon icon={Add} />,
    auth: !authorize({attribute: 'settings.teams.update'}) ? utils.createAuth({attribute: 'system.null'}) :
        utils.createAuth({attribute: 'team.create'}),
    onClick: (e) => {
      setShowTeamAddDialog(true);
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      color: 'success'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'success',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [authorize, mdDown]);

  const renderTeamAddDialog = () => {
    if (showTeamAddDialog) {
      const handleSubmit = (team) => {
        return teamAdd(clientId, team)
          .then(() => {
            setShowTeamAddDialog(false);
          });
      }

      const handleClose = () => {
        setShowTeamAddDialog(false);
      };

      return <TeamAddDialog open={true}
                            onSubmit={handleSubmit}
                            onClose={handleClose}/>
    }
  };

  const columns = bBox?.width ? Math.max(Math.floor((bBox?.width ?? 0) / 452), 1) : 0;

  return <StyledTeamsSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                 title="Teams"
                                 info={!smDown ? info : null}
                                 $wrapper={wrapper}
                                 buttons={!mdDown ? <ActionButton action={action} /> : <ActionIconButton action={action} />}>
    <Box ref={contentRef} className="TeamsSettingPage-content">
      <Cards className="TeamsSettingPage-teams"
             columns={columns} gap={!lgDown ? 24 : 16}>
        {teams?.length > 0 ? teams.map((team, idx) => {
          return <CardItem>
            <TeamsSettingsCard className="TeamsSettingPage-teams-team"
                               key={idx}
                               team={team}
                               canUpdate={authorize({attribute: 'settings.teams.update'})} />
          </CardItem>
        }) : null}
      </Cards>
    </Box>
    {renderTeamAddDialog()}
  </StyledTeamsSettingPage>
};

TeamsSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

TeamsSettingPage.defaultProps = {};

export default TeamsSettingPage;
