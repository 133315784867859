import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';

const StyledBannerWrapper = styled(Box)`
  display: flex;
  min-height: calc(100vh - (${props => props.$wrapper?.boundsCum?.top ?? '0px'} + ${props => props.$wrapper?.boundsCum?.bottom ?? '0px'}));
  width: calc(100vw - (${props => props.$wrapper?.boundsCum?.left ?? '0px'} + ${props => props.$wrapper?.boundsCum?.right ?? '0px'}));
  
  .BannerWrapper {
    &-banner {
      position: fixed;
    }
    
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      
      flex-grow: 1;
      z-index: 1;
      
      > * {
        width: ${props => utils.isDefined(props.$width) ? props.$width : null};
        min-width: ${props => utils.isDefined(props.$width) ? props.$width : null};
        height: unset;
        min-height: unset;

        ${props => props.theme.breakpoints.down('sm')} {
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    .BannerWrapper {
      &-banner {
        position: absolute;
      }

      &-content {
        margin-left: 0 !important;
        padding: 0 ${props => props.theme.spacing(7)};  
      }
    }
  }
  
  ${ComponentStyles}
`;

export default StyledBannerWrapper;
