import utils from 'helpers/utils';

function getOS () {
  let userAgent = window.navigator.userAgent.toLowerCase(),
    macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
    windowsPlatforms = /(win32|win64|windows|wince)/i,
    iosPlatforms = /(iphone|ipad|ipod)/i,
    os = '';

  if (macosPlatforms.test(userAgent)) {
    os = "macos";
  } else if (iosPlatforms.test(userAgent)) {
    os = "ios";
  } else if (windowsPlatforms.test(userAgent)) {
    os = "windows";
  } else if (/android/.test(userAgent)) {
    os = "android";
  } else if (/linux/.test(userAgent)) {
    os = "linux";
  }

  return os;
}

function getComputedStyle (ele) {
  if (ele) {
    return window.getComputedStyle(ele);
  } else {
    return null;
  }
}

function computedStyleToInlineStyle (ele, styles = null, options = {}) {
  Array.prototype.forEach.call(ele.children, (child) => {
    computedStyleToInlineStyle(child, styles, options);
  });

  const computedStyle = getComputedStyle(ele);
  Array.prototype.forEach.call(computedStyle, (property) => {
    const extra = (options.extra ?? [])
      .filter((e) => !utils.isDefined(e.value) && ele.tagName.toLowerCase() === e.tag)
      .map((e) => e.property);

    const stylesMatch = (styles ?? []).find((s) => property.match(`^${s}$`));
    const extraMatch = (extra ?? []).find((s) => property.match(`^${s}$`));
    if (stylesMatch || extraMatch) {
      const value = computedStyle.getPropertyValue(property);

      if (value && !ele.style[property]) {
        let formatted = value;
        if (utils.toString(value).includes('px')) {
          if (utils.toNumber(value) !== 0) {
            if (options.pixel2Rem) {
              formatted = utils.pixel2Rem(utils.toNumber(value), options.fontBase);
            }
          }
        }

        const valid = (!options.ignore || extraMatch) ? true : !options.ignore.find((ig) => `${property}: ${formatted}`.match(ig));
        if (valid) {
          ele.style.setProperty(property, formatted, options.important ? 'important' : '');
        }
      }
    }
  });

  if (options.extra?.length > 0) {
    options.extra.forEach((e) => {
      if (utils.isDefined(e.value) && ele.tagName.toLowerCase() === e.tag && !ele.style[e.property]) {
        ele.style.setProperty(e.property, e.value, (e.important || options.important) ? 'important' : '');
      }
    });
  }

  if (options.removeClass) {
    ele.removeAttribute('class');
  }
}

function isVisible (ele) {
  while (ele) {
    if (ele === window || ele === document.body) {
      break;
    }
    const cs = getComputedStyle(ele);
    const hidden = cs.display === 'none' || cs.visibility === 'hidden';
    if (hidden) {
      return false;
    }
    ele = ele.parentNode;
  }

  return true;
}

function isClamped (ele) {
  return ele && ((ele.offsetWidth < ele.scrollWidth) || (ele.offsetHeight < ele.scrollHeight));
}

function hasScrollableContent (ele, X, Y) {
  if (ele) {
    const hasScrollableXContent = ele === window ? document.body.scrollWidth > window.innerWidth : ele.scrollWidth > ele.clientWidth;
    const hasScrollableYContent = ele === window ? document.body.scrollHeight > window.innerHeight : ele.scrollHeight > ele.clientHeight;

    return (X && hasScrollableXContent) || (Y && hasScrollableYContent);
  } else {
    return false;
  }
}

function isScrollable (ele, X = true, Y = true) {
  if (ele) {
    const overflowXStyle = getComputedStyle(ele).overflowX;
    const isOverflowXHandled = overflowXStyle.indexOf('hidden') !== -1 || overflowXStyle.indexOf('visible') !== -1 || overflowXStyle.indexOf('clip') !== -1;
    const isOverflowXEnabled = overflowXStyle.indexOf('scroll') !== -1 || overflowXStyle.indexOf('auto') !== -1;
    const hasScrollableXContent = hasScrollableContent(ele, true, false);

    const overflowYStyle = getComputedStyle(ele).overflowY;
    const isOverflowYHandled = overflowYStyle.indexOf('hidden') !== -1 || overflowYStyle.indexOf('visible') !== -1 || overflowYStyle.indexOf('clip') !== -1;
    const isOverflowYEnabled = overflowYStyle.indexOf('scroll') !== -1 || overflowYStyle.indexOf('auto') !== -1;
    const hasScrollableYContent = hasScrollableContent(ele, false, true);

    return (X && (hasScrollableXContent || isOverflowXEnabled) && !isOverflowXHandled) ||
      (Y && (hasScrollableYContent || isOverflowYEnabled) && !isOverflowYHandled);
  } else {
    return false;
  }
}

function getScrollElement (ele, X = true, Y = true) {
  return !ele ? ele :
    ele === document.body
      ? window
      : isScrollable(ele, X, Y)
        ? ele
        : getScrollElement(ele.parentNode, X, Y);
}

function scrollIntoView (ele, options = {}) {
  const {
    behavior = 'smooth',
    align = 'auto',
    preventScroll = true
  } = options;

  let scrolled = false;

  if (ele) {
    const scrollElementX = getScrollElement(ele, true, false);
    const scrollElementY = getScrollElement(ele, false, true);

    // apply scroll margins
    if (scrollElementX) {
      const marginLeft = utils.toNumber(getComputedStyle(ele)?.scrollMarginLeft);
      const marginRight = utils.toNumber(getComputedStyle(ele)?.scrollMarginRight);

      const innerWidth = scrollElementX === window ? window.innerWidth : scrollElementX.clientWidth;
      const scrollWidth = scrollElementX === window ? document.body.scrollWidth : scrollElementX.scrollWidth;
      const scrollX = (scrollElementX === window ? scrollElementX.scrollX : scrollElementX.scrollLeft);
      const scrollBbox = getBbox(scrollElementX);
      const eleBbox = getBbox(ele);
      const offsetLeft = eleBbox.left - scrollBbox.left + scrollX;

      const min = scrollX;
      const max = scrollX + innerWidth;

      const extraOffset = (align === 'center' ? Math.max(0, Math.floor((innerWidth / 2) - (eleBbox.width / 2))) : 0);
      if (offsetLeft < (min + marginLeft)) {
        scrollElementX.scrollTo({
          left: Math.max(0, (offsetLeft - marginLeft) - extraOffset),
          behavior
        });
        scrolled = true;
      } else if (((eleBbox.width + marginLeft + marginRight) <= innerWidth) && ((offsetLeft + eleBbox.width) > (max - marginRight))) {
        scrollElementX.scrollTo({
          left: Math.min(scrollWidth, (offsetLeft + eleBbox.width) + marginRight + extraOffset) - innerWidth,
          behavior
        });
        scrolled = true;
      } else if (!preventScroll) {
        if (align !== 'auto') {
          if (offsetLeft !== Math.max(0, (offsetLeft - marginLeft) + extraOffset)) {
            scrollElementY.scrollTo({
              top: Math.max(0, (offsetLeft - marginLeft) - extraOffset),
              behavior
            });
            scrolled = true;
          }
        }
      }
    }

    if (scrollElementY) {
      const marginTop = utils.toNumber(getComputedStyle(ele)?.scrollMarginTop);
      const marginBottom = utils.toNumber(getComputedStyle(ele)?.scrollMarginBottom);

      const innerHeight = scrollElementY === window ? window.innerHeight : scrollElementY.clientHeight;
      const scrollHeight = scrollElementY === window ? document.body.scrollHeight : scrollElementY.scrollHeight;
      const scrollY = (scrollElementY === window ? scrollElementY.scrollY : scrollElementY.scrollTop);
      const scrollBbox = getBbox(scrollElementY);
      const eleBbox = getBbox(ele);
      const offsetTop = eleBbox.top - scrollBbox.top + scrollY;

      const min = scrollY;
      const max = scrollY + innerHeight;

      const extraOffset = (align === 'center' ? Math.max(0, Math.floor((innerHeight / 2) - (eleBbox.height / 2))) : 0);
      if (offsetTop < (min + marginTop)) {
        scrollElementY.scrollTo({
          top: Math.max(0, (offsetTop - marginTop) - extraOffset),
          behavior
        });
        scrolled = true;
      } else if (((eleBbox.height + marginTop + marginBottom) < innerHeight) && ((offsetTop + eleBbox.height) > (max - marginBottom))) {
        scrollElementY.scrollTo({
          top: Math.min(scrollHeight, (offsetTop + eleBbox.height) + marginBottom + extraOffset) - innerHeight,
          behavior
        });
        scrolled = true;
      } else if (!preventScroll) {
        if (align !== 'auto') {
          if (offsetTop !== Math.max(0, (offsetTop - marginTop) + extraOffset)) {
            scrollElementY.scrollTo({
              top: Math.max(0, (offsetTop - marginTop) - extraOffset),
              behavior
            });
            scrolled = true;
          }
        }
      }
    }
  }

  return scrolled;
}

function getBbox (ele, ignoreVisibility = true) {
  const bBoxRect = ele?.getBoundingClientRect?.() || {};
  const bBox = {
    top: bBoxRect.top || 0,
    right: bBoxRect.right || 0,
    bottom: bBoxRect.bottom || 0,
    left: bBoxRect.left || 0,
    width: bBoxRect.width || 0,
    height: bBoxRect.height || 0,
    x: bBoxRect.x || 0,
    y: bBoxRect.y || 0,
    clamped: isClamped(ele) ?? false
  };

  if (!ignoreVisibility && !isVisible(ele)) {
    bBox.width = 0;
    bBox.height = 0;
  }

  return bBox;
}

function getScrollLimits (ele) {
  return {
    X: ele === window ? document.body.scrollWidth - window.innerWidth : ele.scrollWidth - ele.clientWidth,
    Y: ele === window ? document.body.scrollHeight - window.innerHeight : ele.scrollHeight - ele.clientHeight
  }
}

function getBiggerParent (ele, margin = 4) {
  const bBox = getBbox(ele);
  const isBigger = (ele) => {
    const parentBBox = getBbox(ele);
    return parentBBox.left < (bBox.left - margin) && parentBBox.right > (bBox.right + margin) &&
      parentBBox.top < (bBox.top - margin) && parentBBox.bottom > (bBox.bottom + margin);
  }

  while (ele) {
    if (isBigger(ele)) {
      return ele;
    }
    if (ele === document.body) {
      break;
    }
    ele = ele.parentNode;
  }

  return ele;
}

function getParent (ele, parent, classes) {
  const hasClasses = (ele) => {
    return utils.toArray(classes || []).some((cls) => ele?.classList?.contains(cls))
  }

  while (ele) {
    if (ele === parent || hasClasses(ele)) {
      return ele;
    }
    if (ele === document.body) {
      break;
    }
    ele = ele.parentNode;
  }

  return null;
}

function isPartOfParent (ele, parent, classes) {
  const hasClasses = (ele) => {
    return utils.toArray(classes || []).some((cls) => ele?.classList?.contains(cls))
  }

  while (ele) {
    if (ele === parent || hasClasses(ele)) {
      return true;
    }
    if (ele === document.body) {
      break;
    }
    ele = ele.parentNode;
  }

  return false;
}

function focusElement (ele, options = {}, maxDepth = null, depth = 0) {
  if (ele && (!utils.isDefined(maxDepth) || depth <= maxDepth)) {
    if (ele && ele.tabIndex >= 0 && !ele.disabled && isVisible(ele)) {
      if (document.activeElement !== ele) {
        ele.focus(options);
        if (isTextInput(ele, true)) {
          ele.setSelectionRange(0, ele.value?.length);
        }
      }
      return true;
    } else if (ele?.children) {
      return Array.from(ele.children).some?.((c) => {
        return focusElement(c, options, maxDepth, depth + 1);
      });
    }
  }

  return false;
}

function focusElements (els, options = {}, maxDepth = null, depth = 0) {
  return els?.some((el) => {
    return focusElement(el, options, maxDepth, depth);
  });
}

function isFocused (ele, deep = true) {
 return document.activeElement === ele || (deep && isPartOfParent(document.activeElement, ele));
}

function isNativeButton (ele) {
  return ele && (ele.tagName === 'BUTTON' || (ele.tagName === 'A' && ele.href) || (ele.tagName === 'INPUT' && ele.type === 'button'));
}

function isInput (ele) {
  return ele && ['TEXTAREA', 'INPUT'].includes(ele.tagName) && !(ele.tagName === 'INPUT' && ele.type === 'button');
}

function isTextInput (ele, singleLine = false) {
  return ele && isInput(ele) && (
    (ele.tagName === 'INPUT' && ['text', 'password'].includes(ele.type)) ||
    (!singleLine && ele.tagName === 'TEXTAREA')
  );
}

function isForm (ele) {
  return ele && ['FORM'].includes(ele.tagName);
}

function isFormControl (ele) {
  return ele && Array.from(ele.classList || []).find((cls) => {
    return cls.toLowerCase().includes('formfield') || cls.toLowerCase().includes('formcontrol')
  });
}

function isDialog (ele) {
  return ele && Array.from(ele.classList || []).find((cls) => cls.toLowerCase().includes('dialog'));
}

function isClickable (ele) {
  return ele && (['A', 'BUTTON', 'INPUT'].includes(ele.tagName) || Array.from(ele.classList || []).find((cls) => cls.toLowerCase().includes('clickable')));
}

function isPartOfClickable (ele, parent) {
  while (ele && ele !== parent) {
    if (isClickable(ele)) {
      return true;
    }
    ele = ele.parentNode;
  }

  return false;
}

function isPartOfForm (ele, parent) {
  while (ele && ele !== parent) {
    if (isInput(ele) || isForm(ele) || isFormControl(ele)) {
      return true;
    }
    ele = ele.parentNode;
  }

  return false;
}

function isPartOfDialog (ele, parent) {
  while (ele && ele !== parent) {
    if (isDialog(ele)) {
      return true;
    }
    ele = ele.parentNode;
  }

  return false;
}

// events
function isSubmitFormEvent (e) {
  return e.code === 'Enter' && e.target.tagName !== 'TEXTAREA' && !dom.isNativeButton(e.target);
}

const dom = {
  getOS,
  getComputedStyle,
  computedStyleToInlineStyle,

  isVisible,
  isClamped,
  hasScrollableContent,
  getScrollElement,
  scrollIntoView,
  getBbox,
  getScrollLimits,

  getBiggerParent,
  getParent,
  isPartOfParent,
  focusElement,
  focusElements,
  isFocused,
  isNativeButton,
  isInput,
  isTextInput,
  isForm,
  isDialog,
  isClickable,
  isPartOfClickable,
  isPartOfForm,
  isPartOfDialog,
  isSubmitFormEvent
}

export default dom;
