import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import TextField from 'components/molecules/Fields/TextField/TextField';

const StyledLinkField = styled(TextField)`
  &:not(.LinkField-showLinkIcon) {
    .TextField-readOnly {
      .Link {
        line-break: anywhere;
      }
    }
  }
  
  &.LinkField-showLinkIcon {
    .TextField-readOnly {
       > *:not(.Icon, .InputAdornment) {
         flex-grow: 1;
         width: 1px;
         max-width: fit-content;
       }
    }
  }
  
  ${ComponentStyles}
`;

StyledLinkField.propTypes = {
  theme: PropTypes.object
};

StyledLinkField.defaultProps = {
}

export default StyledLinkField;
