import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import StyledTextField from 'components/molecules/Fields/TextField/TextField.styles';
import {useComponentProps} from 'helpers/hooks/utils';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import FilledInput from 'components/atoms/Inputs/FilledInput/FilledInput';
import Input from 'components/atoms/Inputs/Input/Input';
import OutlinedInput from 'components/atoms/Inputs/OutlinedInput/OutlinedInput';
import utils from 'helpers/utils';
import {useFormControl} from '@mui/material';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Box from 'components/atoms/Layout/Box/Box';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import MentionsInput from 'components/molecules/Mentions/MentionsInput/MentionsInput';
import Mention from 'components/molecules/Mentions/Mention/Mention';
import {withMemo} from 'helpers/wrapper';

const InputComponent = React.forwardRef((props, ref) => {
  const formControl = useFormControl();

  const variant = props.variant ?? formControl.variant;
  const InputVariant = variant === 'outlined' ? OutlinedInput :
    (variant === 'filled' ? FilledInput : Input);

  return <InputVariant ref={ref} {...props} variant={variant} />;
})

const TextField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    placeholder,
    value,
    type,
    helperText,
    trim,
    format,
    size,
    rows,
    minRows,
    maxRows,
    multiline,
    markdown,
    mentions,
    onChange,
    autoFocus,
    showTooltip,
    hiddenPlaceholder,
    renderReadOnly,
    inputProps,
    InputProps,
    InputLabelProps,
    MarkdownProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'TextField', {
    children: ['input', 'label', 'helper'],
    static: ['disabled', 'multiline', 'markdown']
  });

  const innerRef = useRef(null);
  const cursorPos = useRef({});

  const formatValue = useCallback((value, isClean = false, remove = false) => {
    if (utils.isDefined(value)) {
      if (format) {
        return utils.fieldValue2FormattedValue(format, value, isClean, remove);
      } else if (trim) {
        return remove ? (trim ? value.toString().trim() : value.toString()) : value.toString();
      } else {
        return value;
      }
    } else {
      return value;
    }
  }, [format, trim]);

  const [internalValue, setInternalValue] = useState(value);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('input, textarea'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const handleChange = (e) => {
    const newValue = e?.target?.value ?? '';
    const formatted = formatValue(newValue, false, false);

    setInternalValue(formatValue(newValue, false, false));

    if (newValue !== formatted) {
      const start = innerRef.current?.querySelector('.MuiInputBase-input')?.selectionStart;
      cursorPos.current = {
        start: start,
        count: newValue ? (newValue.slice(0, start).match(/[,.]/g) ?? []).length : 0
      };

      if (utils.isDefined(cursorPos.current.start)) {
        setTimeout(() => {
          const el = innerRef.current?.querySelector('.MuiInputBase-input');
          if (el) {
            let pos = cursorPos.current.start;
            if (format) {
              const count = el.value ? (el.value.slice(0, cursorPos.current.start).match(/[,.]/g) ?? []).length : 0;
              pos += (count - cursorPos.current.count);
              if (pos > 0 && el.value?.[pos - 1]?.match(/[,.]/g)) {
                pos -= 1;
              }
            }
            el.setSelectionRange(pos, pos);
          }
        })
      }
    }

    const clean = formatValue(newValue, false, true);
    if (clean !== value) {
      onChange?.({
        ...e,
        target: {
          name: name,
          value: clean
        }
      });
    }
  }

  useLayoutEffect(() => {
    setInternalValue((current) => {
      if (format) {
        return current !== formatValue(value, true, false) ?
          formatValue(value, true, false) : current
      } else if (trim) { // special case keep spaces in input
        return formatValue(current, false, true) !== formatValue(value, true, true) ?
          formatValue(value, true, false) : current
      } else {
        return value;
      }
    });
  }, [value, format, trim, formatValue]);

  const markDownComponents = useMemo(() => {
    const renderLink = ({node, ...props}) => {
      if (node?.properties?.href) {
        const uri = decodeURI(node.properties.href);
        const match = uri.match(/.\[(.+):(.+)\]/i);
        if (match?.length > 0) {
          const mention = mentions.find((m) => m.trigger === uri[0]);
          if (mention) {
            const Component = mention.Component ?? Mention;
            return <Component id={match[2]}
                              display={match[1]}
                              readOnly={true}
                              data={mention.data}
                              appendSpaceOnAdd={true}
                              {...mention.MentionProps}/>
          }
        }
      }

      return <a {...props}>{props.children}</a>
    }

    return {
      a: renderLink
    };
  }, [mentions]);

  const renderValue = () => {
    if (renderReadOnly) {
      return renderReadOnly(value, {formatted: internalValue, markdown, placeholder, mentions, showTooltip, hiddenPlaceholder})
    } else {
      if (internalValue) {
        if (mentions) {
          const split = utils.splitMentions(internalValue, mentions);
          if (markdown) {
            const markdown = split.reduce((a, s) => {
              if (s.mention) {
                a.push(`[${s.display}](${s.mention.trigger}[${s.display}:${s.id}])`);
              } else {
                a.push(s.value);
              }
              return a;
            }, []).join('');

            return <Markdown components={markDownComponents} {...MarkdownProps}>{markdown}</Markdown>;
          } else {
            return split.reduce((a, s, idx) => {
              if (s.mention) {
                const Component = s.mention.Component ?? Mention;
                a.push(<Component key={idx}
                                  id={s.id}
                                  display={s.display}
                                  readOnly={true}
                                  data={s.mention.data}
                                  appendSpaceOnAdd={true}
                                  {...s.mention.MentionProps}/>);
              } else {
                a.push(<Span key={idx}>{s.value}</Span>);
              }
              return a;
            }, []);
          }
        } else {
          if (markdown) {
            return <Markdown showTooltip={showTooltip} {...MarkdownProps}>{internalValue.toString()}</Markdown>;
          } else {
            return <Span showTooltip={showTooltip}>{internalValue}</Span>;
          }
        }
      } else {
        return (placeholder && !hiddenPlaceholder) ? <Span className="placeholder">{placeholder}</Span> :
          <Span>&nbsp;</Span>;
      }
    }
  }

  const inputPropsMemo = useMemo(() => {
    return utils.mergeObjects(mentions ? {
      mentions,
      multiline,
      rows,
      minRows: multiline ? (minRows ?? 2) : null,
      maxRows: multiline ? (maxRows ?? 12) : null,
    } : {}, {
      ...inputProps,
      onChange: (e) => {
        inputProps?.onChange?.({
          ...e,
          target: {
            ...e.target,
            value: formatValue(e?.target?.value, false, true)
          }
        })
      }
    });
  }, [mentions, multiline, rows, minRows, maxRows, inputProps, formatValue]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTextField ref={innerRef} {...innerProps}>
    <InputLabel size={size}
                {...utils.cleanObject({
                  htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                  shrink: innerProps.readOnly || innerProps.disabled || null
                })}
                className={utils.classNames('TextField-label', InputLabelProps?.className)}
                {...InputLabelProps}>
      {label}
    </InputLabel>
    <InputContainer className="TextField-container">
      {!innerProps.readOnly ? <InputComponent id={id}
                                              name={name}
                                              value={internalValue}
                                              label={label}
                                              type={type}
                                              size={size}
                                              minRows={multiline ? (minRows ?? 2) : null}
                                              maxRows={multiline ? (maxRows ?? 12) : null}
                                              multiline={multiline}
                                              placeholder={hiddenPlaceholder ? '' : placeholder ?? label}
                                              className="TextField-input"
                                              autoComplete="off"
                                              onChange={handleChange}
                                              inputProps={inputPropsMemo}
                                              {...utils.cleanObject({
                                                inputComponent: mentions ? MentionsInput : null
                                              })}
                                              {...InputProps}/> :
        <Box className="TextField-readOnly Input-readOnly">
          {InputProps?.startAdornment}
          {renderValue()}
          {InputProps?.endAdornment}
        </Box>}
      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('TextField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledTextField>
}));

TextField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  helperText: PropTypes.any,
  size: PropTypes.string,
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  multiline: PropTypes.bool,
  markdown: PropTypes.bool,
  trim: PropTypes.bool,
  format: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  showTooltip: PropTypes.bool,
  readOnly: PropTypes.bool,
  renderReadOnly: PropTypes.func,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  MarkdownProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object
};

TextField.defaultProps = {
  label: 'TextFieldText text',
  size: 'small',
  trim: true,
  inputProps: {}
};

export default TextField;
