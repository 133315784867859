import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Drawer from 'components/atoms/Drawers/Drawer/Drawer';
import utils from 'helpers/utils';
import {darken} from 'polished';

const StyledMenuDrawer = styled(Drawer)`
  &.MenuDrawer {
    &-anchorTop, &-anchorBottom {
      width: auto;
      .MuiPaper-root {
        width: auto;
      }
    }
  }

  &.MenuDrawer > .MuiPaper-root {
    border-width: 0;
    width: ${props => utils.isDefined(props.$width) ? props.$width : null};
  }
  
  &.MenuDrawer-persistent {
    &:not(.MenuDrawer-open) > .MuiPaper-root {
      width: 0;
    }
  }

  .MenuDrawer {
    &-content {
      height: ${props => props.theme.layout('100%')};
      padding: ${props => props.theme.spacing(2)} 0;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      gap: ${props => props.theme.layout('1sp')};
      
      position: relative;
    }
    
    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};

      .IconButton {
        width: ${props => props.theme.layout('5sp')};
        height: ${props => props.theme.layout('5sp')};
        font-size: ${props => props.theme.layout(20)};
      }
      
      &-middle {
        flex-grow: 1;
      }
    }
    
    &-logo {
      height: ${props => props.theme.layout('5sp')}; 
      margin-bottom: ${props => props.theme.layout('2sp')};
      
      .Icon, .Logo {
        height: 100%;
        width: 100%;
      }
    }
    
    &-avatar {
      align-self: center;
      width: ${props => props.theme.layout('5sp')};
      height: ${props => props.theme.layout('5sp')};
      font-size: ${props => props.theme.fontSize(15)};

      margin-bottom: ${props => props.theme.layout('2sp')};
    }

    &-swipe {
      position: absolute;
      visibility: visible;
      cursor: ${props => props.$swipeDisabled ? 'pointer' : 'grab'};
      pointer-events: ${props => props.$swipeDisabled ? 'all' : 'none'};
      height: 100%;
      width: ${props => props.theme.layout('2.5sp')};
      right: -${props => props.theme.layout('2.5sp')};
      top: 0;
    }
    
    &-pull {
      position: absolute;
      height: ${props => props.theme.layout('6sp')};
      width: ${props => props.theme.layout('0.75sp')};
      top: calc(50% - (${props => props.theme.layout('6sp')} / 2));
      right: ${props => props.theme.layout('1.125sp')};
      background-color: ${props => darken(0.1, props.theme.property('palette.divider'))};
      border-radius: ${props => props.theme.radius(1)};
    }
  }
  
  &.MenuDrawer-temporary {
    &:not(.MenuDrawer-open) {
      visibility: hidden;

      > .MuiPaper-root {
        overflow: visible;
        visibility: visible;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledMenuDrawer.propTypes = {
};

StyledMenuDrawer.defaultProps = {
}

export default StyledMenuDrawer;
