import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionsTableBar from 'components/organisms/Bars/CollectionsTableBar/CollectionsTableBar.styles';

const CollectionsTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionsTableBar');

  const tableProvider = useTable();

  const searchField = useMemo(() => ({
    placeholder: 'Search for a collection'
  }), []);

  const filterAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'collection.filters'})
  }), []);

  const columnAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'collection.columns'})
  }), []);

  const collectionsCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionsTableBar ref={ref} {...innerProps}
                                    title={`${collectionsCount} collection${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? '' : 's'}`}
                                    searchField={searchField}
                                    hideChat={true}
                                    hideFilter={true}
                                    hideColumn={true}
                                    filterAction={filterAction}
                                    columnAction={columnAction}/>
});

CollectionsTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionsTableBar.defaultProps = {
};

export default CollectionsTableBar;
