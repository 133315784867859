import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StyledActionButton from 'components/molecules/Buttons/ActionButton/ActionButton.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import List from 'components/atoms/Lists/List/List';
import utils from 'helpers/utils';

const ActionButton = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    autoOpen,
    TooltipProps,
    IconProps,
    DropDownPopperProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionButtonProps}, 'ActionButton', {
    static: ['open']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const innerRef = useRef(null);
  useImperativeHandle(ref, () => innerRef.current);

  const [internalState, setInternalState] = useState({open: false, closed: true});

  const renderButton = (onClick) => {
    return <Tooltip title={action.tooltip} showDisabled={true}
                    fullWidth={innerProps.fullWidth ?? action.ButtonProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionButton ref={innerRef} {...innerProps}
                          startIcon={(action.icon && (action.iconPosition || 'start') === 'start') ? <Icon icon={action.icon} {...IconProps}/> : null}
                          endIcon={(action.icon && action.iconPosition === 'end') ? <Icon icon={action.icon} {...IconProps}/> : null}
                          color={action.color ?? innerProps.color}
                          badge={action.badge}
                          onClick={onClick}
                          disabled={!itm}
                          {...action.ButtonProps}>
        {action.label}
      </StyledActionButton>
    </Tooltip>
  }

  useEffect(() => {
    if (autoOpen) {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          open: true,
          closed: false
        })(current);
      });
    }
  }, [autoOpen]);

  const handleButtonClick = (e) => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        open: !current.open,
        closed: !current.open ? false : current.closed
      })(current);
    });
    e.preventDefault();
  }

  const handlePopperToggle = (open) => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        open: open,
        closed: open ? false : current.closed
      })(current);
    });
  }

  const handlePopperClosed = () => {
    setInternalState(utils.updater({closed: true}, true));
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    open: internalState.open,
    closed: internalState.closed
  });

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    if (action.children || action.popper) {
      return <React.Fragment>
        {renderButton(handleButtonClick)}
        <DropdownPopper anchorEl={innerRef.current}
                        open={internalState.open}
                        onToggle={handlePopperToggle}
                        onClosed={handlePopperClosed}
                        {...DropDownPopperProps}>
          {action.popper ? action.popper : <List track={true} gap={4}>
            {action.children.map((action, idx) => {
              return <ListItem key={idx}>
                <ActionButton action={action}
                              onClick={() => setInternalState(utils.updater({open: false}, true))}
                              fullWidth={true}/>
              </ListItem>
            })}
          </List>}
        </DropdownPopper>
      </React.Fragment>
    } else {
      return renderButton(handleClick);
    }
  }
});

ActionButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  autoOpen: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionButton.defaultProps = {
  label: 'ActionButton text'
};

export default ActionButton;
