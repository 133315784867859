import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import FormStyles from 'styles/mixins/Form.styles';

const StyledProfileCardContent = styled(CardContent)`
  &.ProfileCardContent {
    display: flex;
    padding-top: 0;
    
    .Form {
      .Form-fields, .Form-group {
        row-gap: ${props => props.theme.layout('1.25sp')};

        .TextField {
          &.FormControl-readOnly {
            .TextField-readOnly {
              &:has(.feedback, .info) {
                display: flex;
                gap: ${props => props.theme.layout('1sp')};
                .value {
                  flex-grow: 1 !important;
                  max-width: unset !important;
                }
                .feedback, .info {
                  align-self: center;
                }
              }
            }
          }
        }
      }
    }
    
    .ProfileCardContent {
      &-loader {
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('1.25sp')};
        
        > .Box {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-content: flex-start;
          overflow: hidden;
          gap: ${props => props.theme.layout('1.25sp')};

          &:has(> .Box) {
            flex-direction: row;
          }
          
          > .Box:first-child:not(:has(.MuiSkeleton-circular)) {
            min-width: ${props => props.theme.layout(160)};
            max-width: ${props => props.theme.layout(160)};
          }
          
          .MuiSkeleton-circular {
            height: ${props => props.theme.layout(40)};
            width: ${props => props.theme.layout(40)};
          }
          
          * {
            width: 100%;
            line-break: anywhere;
            transform: scale(1, 1);
            font-size: ${props => props.theme.fontSize(12)};
          }
          
          &.summary, &.links {
            > *:first-child {
              max-width: unset !important;
            }
          }
          
          &.tags {
            flex-direction: column;
            > .Box {
              display: flex;
              flex-wrap: wrap;
              min-width: 100% !important;
              gap: ${props => props.theme.layout('0.5sp')};

              * {
                font-size: ${props => props.theme.fontSize(12)} !important;
              }
            }
          }

          &.tagGroups {
            flex-direction: column;
            > .Box {
              width: 100%;
              max-width: unset !important;
              
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              * {
                font-size: ${props => props.theme.fontSize(12)} !important;
              }
            }
            
            > .Typography {
              min-width: 100%;
            }
          }
          
          &.systemInfo {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: ${props => props.theme.layout('1sp')};
            
            > .Box {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              max-width: unset !important;
              gap: ${props => props.theme.layout('0.5sp')};
            }
          }
        }
        
        &:has(.Box.options) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    
    &-isEditing {
      .ProfileCardContent {
        &-loader {
          > .Box {
            * {
              font-size: ${props => props.theme.fontSize(16)};
            }

            &:has(> .Box) {
              > *:last-child {
                min-width: 100%;
              }
            }
          }
        }
      }
    }
  }
  
  // variants
  &.ProfileCardContent-summary {
    &:not(.ProfileCardContent-isEditing) {
      .FormControl-staticLabel {
        row-gap: ${props => props.theme.layout('0.25sp')};
      }
    }
  }

  &.ProfileCardContent-tags {
    overflow: hidden;
    &:not(.ProfileCardContent-isEditing) {
      max-height: ${props => props.theme.layout(322)};
    }

    .Form-fields {
      height: 100%;
    }
  }

  &.ProfileCardContent-tagGroups {
    .Form-fields {
      row-gap: ${props => props.theme.layout('2sp')} !important;

      .FormControl-staticLabel {
        row-gap: ${props => props.theme.layout('0.5sp')};
      }
    }
    
    &:not(.ProfileCardContent-isEditing) {
      .Form-fields {
        row-gap: ${props => props.theme.layout('2sp')} !important;
      }
    }

    .FormControl {
      &-readOnly {
        &.ListField {
          .FormLabel {
            > .Box {
              display: flex;
              width: 100%;

              &:has(.FormField-labelPostfix) {
                > *:nth-child(2) {
                  flex-grow: 1;
                }
              }
              &:not(:has(.FormField-labelPostfix)) {
                > *:first-child {
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }
      
      &:not(&-readOnly) {
        .FormLabel {
            > .Box {
              display: flex;
              align-items: center;

              .Icon {
                margin-left: ${props => props.theme.layout(-6)};
              }
            }
          }
          
          &.ListField {
            .InputContainer {
              padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
              border-radius: ${props => props.theme.radius(1)};
              background-color: ${props => props.theme.property('palette.primary.states.hover')};
            }
          }
      }
    }
  }

  &.ProfileCardContent-links {
    .SuggestionField {
      &.FormControl-readOnly {
        .Input-readOnly {
          .Link {
            line-break: anywhere;
          }

          .value {
            flex-grow: 1 !important;
            max-width: unset !important;
          }

          .feedback, .info {
            align-self: center;
          }
        }
      }
    }
  }

  &.ProfileCardContent-options {
    .Form-fields {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      row-gap: ${props => props.theme.layout('0.5sp')} !important;
      column-gap: ${props => props.theme.layout('2sp')} !important;
      
      .FormControl-inlineLabel {
        width: fit-content;
        > .InputLabel, > .FormLabel {
          min-width: ${props => props.theme.layout(180)};
          max-width: ${props => props.theme.layout(180)};
        }
      }
    }
  }
  
  &.ProfileCardContent-systemInfo {
    padding: ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(1.5)} ${props => props.theme.spacing(2)};
    background-color: ${props => props.theme.property('palette.primary.states.focus')};
  }

  &.ProfileCardContent-options {
    ${props => props.theme.breakpoints.down('md')} {
      .Form-fields {
        grid-template-columns: repeat(1, 1fr);
        
        .FormControl-inlineLabel {
          > .InputLabel, > .FormLabel {
            min-width: ${props => props.theme.layout(160)};
            max-width: ${props => props.theme.layout(160)};
          }
        }
      }
    }
  }

  ${FormStyles}
  ${ComponentStyles}
`;

StyledProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledProfileCardContent.defaultProps = {
}

export default StyledProfileCardContent;
