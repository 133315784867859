import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useTableReset} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import {useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDealflowEntitiesBarWrapper
  from 'components/templates/Wrappers/Dealflow/DealflowEntitiesBarWrapper/DealflowEntitiesBarWrapper.styles';
import EntitiesViewBar from 'components/organisms/Bars/EntitiesViewBar/EntitiesViewBar';
import utils from 'helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import {CheckList} from 'assets/icons';
import Badge from 'components/atoms/Badges/Badge/Badge';
import DealflowNotificationList from 'components/organisms/Lists/DealflowNotificationList/DealflowNotificationList';
import {useClientTimelineList} from 'services/client/timeline/timeline.hooks';
import List from '@mui/icons-material/List';
import {ArticleOutlined, TravelExplore, ViewKanbanOutlined} from '@mui/icons-material';
import Equalizer from '@mui/icons-material/Equalizer';

const DealflowEntitiesBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesBarWrapper');

  const tableProvider = useTable();
  const client = tableProvider.context?.data;
  const selectedEntityId = tableProvider.selected()?.entityId;
  const isLoading = !client;
  const isTableEmpty = tableProvider?.list?.data?.length === 0;

  const [pathPrefix, pathPostfix] = useSplitPath('dealflow');

  useTableReset(pathPrefix, pathPostfix, 'table');

  const notificationList = useClientTimelineList({
    clientId: client?.clientId,
    collectionId: client?.universeCollectionId,
    filter: [
      {id: 'notification', value: true},
      {id: 'mentioned', value: true},
      {id: 'excludedTypes', value: ['additions', 'status', 'csi', 'deal_leader']},
    ],
    pageSize: 0
  }, {
    enabled: utils.isDefined(client?.clientId) && client?.universeCollectionId > 0,
    matchCallback: () => {
      // custom match to refetch on all watches that are invalidated to update the number
      return true;
    }
  });
  const notificationCount = notificationList.meta?.resultsCount ?? 0;

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const openProfileEvent = useEffectEvent(tableProvider.openProfile);
  const rightActions = useMemo(() => {
    const actions = [];

    if (!(smDown || mdDown)) {
      actions.push({
        auth: utils.createAuth({ attribute: 'dealflow.notifications' }),
        onClick: () => {},
        icon: CheckList,
        tooltip: 'Notifications',
        popper: <DealflowNotificationList client={client} />,
        ActionIconButtonProps: {
          color: 'default',
          variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          IconProps: {
            size: 'smaller'
          },
          BadgeProps: {
            isLoading: notificationList.status.isLoading
          },
          DropDownPopperProps: {
            ContextPopperProps: {
              density: 'densest',
              placement: 'bottom-end',
              closeOnNavigate: true,
              strategy: 'fixed'
            }
          }
        },
        IconButtonProps: {
          disabled: isLoading
        },
        badge: <Badge badgeContent={notificationCount || '0'} color="light"/>
      });
    }

    actions.push({
      label: !smDown ? 'Add company' : null,
      tooltip: smDown ? 'Add company' : null,
      icon: Add,
      auth: utils.createAuth({attribute: 'dealflow.entity.create'}),
      onClick: () => {
        openProfileEvent?.(-1);
      },
      ActionButtonProps: !smDown ? {
        color: 'success',
        variant: 'contained',
        radius: 'round'
      } : null,
      ActionIconButtonProps: smDown ? {
        color: 'success',
        variant: 'contained',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      } : null
    });

    return actions;
  }, [client, isLoading, smDown, mdDown, notificationCount, notificationList.status.isLoading, openProfileEvent]);

  const toggleActions = useMemo(() => {
    return [
      {
        label: !(smDown || lgDown) ? 'Kanban' : null,
        tooltip: (smDown || lgDown) ? 'Kanban' : null,
        icon: (smDown || lgDown) ? ViewKanbanOutlined : null,
        navigation: {
          to: `${pathPrefix}`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        }
      },
      {
        label: !(smDown || lgDown) ? 'Table' : null,
        tooltip: (smDown || lgDown) ? 'Table' : null,
        icon: (smDown || lgDown) ? List : null,
        navigation: {
          to: `${pathPrefix}/table`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        }
      },
      {
        label: !(smDown || lgDown) ? 'Profile' : null,
        tooltip: (smDown || lgDown) ? 'Profile' : null,
        icon: (smDown || lgDown) ? ArticleOutlined : null,
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        },
        ToggleButtonProps: {
          disabled: isTableEmpty || !selectedEntityId
        }
      },
      {
        label: !(smDown || lgDown) ? 'Browser' : null,
        tooltip: (smDown || lgDown) ? 'Browser' : null,
        icon: (smDown || lgDown) ? TravelExplore : null,
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}/browser`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        },
        ToggleButtonProps: {
          disabled: isTableEmpty || !selectedEntityId
        }
      },
      {
        label: !(smDown || lgDown) ? 'Graphs' : null,
        tooltip: (smDown || lgDown) ? 'Graphs' : null,
        icon: (smDown || lgDown) ? Equalizer : null,
        navigation: {
          to: `${pathPrefix}/graphs`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        }
      },
    ];
  }, [pathPrefix, selectedEntityId, isTableEmpty, smDown, lgDown]);

  return <StyledDealflowEntitiesBarWrapper ref={ref} {...innerProps}
                                           ToolbarProps={{
                                             title: 'Deal flow',
                                             rightActions,
                                             toggleActions,
                                             ViewBarComponent: EntitiesViewBar,
                                             isLoading
                                           }}>
    {innerProps.children}
  </StyledDealflowEntitiesBarWrapper>
});

DealflowEntitiesBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesBarWrapper.defaultProps = {
  children: 'DealflowEntitiesBarWrapper text',
};

export default DealflowEntitiesBarWrapper;
