import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledEntityTaskProfileFoldout
  from 'components/organisms/Foldouts/EntityTaskProfileFoldout/EntityTaskProfileFoldout.styles';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';

const EntityTaskProfileFoldout = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntityTaskProfileFoldout');

  const profileProvider = useProfile();
  const task = profileProvider?.data?.data;

  const dialogControl = useDialogControl();
  const snackbar = useSnackbar();

  const deleteEvent = useEffectEvent(profileProvider.updaters?.deleteData);
  const onCloseEvent = useEffectEvent(innerProps.onClose);
  const actions = useMemo(() => [{
    auth: utils.createAuth({attribute: 'entity.task.delete', meta: {task}}),
    label: 'Remove task',
    tooltip: 'Remove task',
    icon: Delete,
    ActionIconButtonProps: {
      color: 'error',
      variant: 'contained'
    },
    onClick: (e) => {
      const handleConfirm = () => {
        return deleteEvent?.({commentId: task.commentId})
          .catch(() => {
            snackbar.show('Removing task failed', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          })
          .then(() => {
            onCloseEvent?.(e);
          });
      }

      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this task?"
                                        explanation="The task will be removed permanently"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove task',
                                          startIcon: <Icon icon={Delete} />,
                                          color: 'error'
                                        }}/>, true);

    }
  }], [task, snackbar, dialogControl, onCloseEvent, deleteEvent]);

  return <StyledEntityTaskProfileFoldout ref={ref} {...innerProps}
                                         actions={actions}/>
});

EntityTaskProfileFoldout.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntityTaskProfileFoldout.defaultProps = {};

export default EntityTaskProfileFoldout;
