import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListField from 'components/molecules/Fields/ListField/ListField';

const StyledIconField = styled(ListField)`
  &.IconField {
    min-width: ${props => props.theme.layout(props.$minWidth ?? 100)};
    .List {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(${props => props.$columns}, 1fr);
      grid-template-rows: repeat(${props => props.$rows}, 1fr);
      gap: ${props => props.theme.layout('0.5sp')};
    }
  }
  ${ComponentStyles}
`;

StyledIconField.propTypes = {
  theme: PropTypes.object
};

StyledIconField.defaultProps = {
}

export default StyledIconField;
