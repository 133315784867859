import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import CardHeader from '@mui/material/CardHeader';

const StyledCardHeader = styled(CardHeader)`
  .CardHeader {
    &-badge {
      > .Typography {
        flex-grow: 1;
        width: 1px;
        max-width: fit-content;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      .MuiBadge-badge {
        position: relative;
        align-self: center;
        padding: ${props => props.theme.layout(5)};
        margin-left: ${props => props.theme.layout('1sp')};
        font-size: ${props => props.theme.fontSize(11)};

        height: ${props => props.theme.layout(16)};
        min-width: ${props => props.theme.layout(16)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCardHeader.propTypes = {
  theme: PropTypes.object
};

StyledCardHeader.defaultProps = {}

export default StyledCardHeader;
