import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useCollectionCustomFieldStatus () {
  return useServiceStatus(path);
}

export function useCollectionCustomFieldList ({clientId, collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {clientId, collectionId, search, filter, sort, page, pageSize, ...rest}, queryOptions);
}

export function useCollectionCustomFieldAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionCustomFieldUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useCollectionCustomFieldDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useCollectionCustomFieldCache ({clientId, collectionId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {clientId, collectionId, includeDefault: true}, {
    overridePath: 'clients/:clientId/customFieldsCache?collectionId=:collectionId',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
