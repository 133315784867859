import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import {useCollectionEntityScoreBreakDown} from 'services/collection/entity/entity.utils';
import StyledAnalysisScoreInline from 'components/molecules/Inlines/AnalysisScoreInline/AnalysisScoreInline.styles';
import AnalysisScoreBarChart from 'components/organisms/Charts/AnalysisScoreBarChart/AnalysisScoreBarChart';
import AnalysisScoreInlineEdit from 'components/organisms/InlineEdits/AnalysisScoreInlineEdit/AnalysisScoreInlineEdit';
import constants from 'helpers/constants';
import {withMemo} from 'helpers/wrapper';

const AnalysisScoreInline = withMemo(React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    fieldData,
    onClick,
    onEdit,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreInline');

  const anchorRef = useRef(null);
  const hoverRef = useRef({});
  const [open, setOpen] = useState(false);
  const [hoverScore, setHoverScore] = useState(null);

  const [analysisBreakdown, total] = useCollectionEntityScoreBreakDown(collection, entity);

  const handleHover = (e, data) => {
    const score = data?.[`${data?.dataKey}-meta`];
    if (score) {
      setHoverScore(score);
      setOpen(true);
    }
  }

  const handleClick = (e) => {
    onClick?.(e);

    if (!e.defaultPrevented) {
      setOpen(false);
    }
  }

  const handleEdit = (e) => {
    onEdit?.(e);

    if (!e.defaultPrevented) {
      setOpen(false);
    }
  }

  const handleToggle = (open) => {
    setOpen(open);
  };

  const debouncedHover = useMemo(() => {
    return utils.debounce((e) => {
      if (hoverRef.current.next !== hoverRef.current.prev) {
        if (hoverRef.current.next) {
          setOpen(true);
        } else {
          setOpen(false);
        }
        hoverRef.current.prev = hoverRef.current.next;
      }
    }, constants.debounce.hover);
  }, []);

  const handleDropdownEnter = (e) => {
    hoverRef.current = {...hoverRef.current, next: true};
    debouncedHover(e);
  }

  const handleDropdownLeave = (e) => {
    // hoverRef.current = {...hoverRef.current, next: false};
    // debouncedHover(e);
  }

  const handleBoxEnter = (e) => {
    hoverRef.current = {...hoverRef.current, next: true};
    debouncedHover(e);
  }

  const handleBoxLeave = (e) => {
    // hoverRef.current = {...hoverRef.current, next: false};
    // debouncedHover(e);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledAnalysisScoreInline ref={ref} {...innerProps}
                                    onMouseEnter={handleBoxEnter}
                                    onMouseLeave={handleBoxLeave}>
    <Box ref={anchorRef}
         className="AnalysisScoreInline-content">
      <AnalysisScoreBarChart total={total}
                             breakdown={analysisBreakdown}
                             onClick={onClick ? handleClick : null}
                             onHover={handleHover}
                             animate={false}
                             showPercentage={true}
                             isLoading={isLoading}/>
    </Box>
    <DropdownPopper anchorEl={anchorRef.current}
                    open={open}
                    fullWidth={true}
                    autoFocus={false}
                    onToggle={handleToggle}
                    density="densest"
                    onMouseEnter={handleDropdownEnter}
                    onMouseLeave={handleDropdownLeave}
                    ContextPopperProps={{
                      density: 'densest',
                      placement: 'bottom',
                      stretch: true,
                      width: 404,
                      minWidth: 306,
                      maxWidth: 404
                    }}>
      <AnalysisScoreInlineEdit entity={entity}
                               collection={collection}
                               score={hoverScore}
                               fieldData={fieldData}
                               onEdit={onEdit ? handleEdit : null}/>
    </DropdownPopper>
  </StyledAnalysisScoreInline>
}));

AnalysisScoreInline.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  collection: PropTypes.object,
  fieldData: PropTypes.object,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  isLoading: PropTypes.bool
};

AnalysisScoreInline.defaultProps = {};

export default AnalysisScoreInline;
