import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import Typography, {P, Span} from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StyledEntitiesChatDialog from 'components/organisms/Dialogs/EntitiesChatDialog/EntitiesChatDialog.styles';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Box from 'components/atoms/Layout/Box/Box';
import Send from '@mui/icons-material/Send';
import {useChatCleanup, useChatEntities} from 'services/chat/chat.hooks';
import utils from 'helpers/utils';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Img from 'components/atoms/Images/Img/Img';
import ActionLink from 'components/molecules/Links/ActionLink/ActionLink';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitiesUploadDialog from 'components/organisms/Dialogs/EntitiesUploadDialog/EntitiesUploadDialog';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useCollectionUploadCreate} from 'services/collection/upload/upload.utils';
import {linkModifier} from 'helpers/hooks/links';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';

const EntitiesChatDialog = React.forwardRef((props, ref) => {
  const {
    collection,
    listState,
    ...innerProps
  } = useComponentProps(props, 'EntitiesChatDialog', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const messageFieldRef = useRef(null);
  const conversationRef = useRef(null);
  const formRef = useRef(null);

  const [dirty, setDirty] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [assistant, setAssistant] = useState(constants.data.lookup('assistantTypes', constants.chat.assistantTypes.budget));

  const [conversation, setConversation] = useState({
    context: {},
    messages: [{
      assistant: assistant.value,
      content: {
        type: 'text',
        text: {
          value: 'Welcome to company chat, how can i assist?'
        }
      }
    }]
  });
  const [inputDisabled, setInputDisabled] = useState(true);

  useImperativeHandle(ref, () => innerRef.current);

  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const chatEntities = useChatEntities();
  const chatCleanup = useChatCleanup();
  const createUpload = useCollectionUploadCreate();

  const fields = useMemo(() => ([{
    name: 'message',
    placeholder: 'Type your message',
    type: constants.formFieldTypes.textarea,
    validation: constants.formFieldValidationTypes.text,
    initial: null,
    FormFieldProps: {
      ref: messageFieldRef,
      disabled: submitting,
      autoFocus: true,
      hiddenLabel: true,
      variant: 'standard',
      size: 'small',
      debounce: false,
      minRows: 1,
      maxRows: 8,
      InputLabelProps: {
        shrink: true,
      },
      InputProps: {
        disableUnderline: true
      }
    }
  }]), [submitting]);

  const handleSubmit = (values, actions) => {
    setSubmitting(true);
    actions.resetForm();

    setConversation((current) => {
      return {
        ...current,
        messages: current.messages.concat([{
          assistant: assistant.value,
          user: values['message']
        }])
      };
    });
    chatEntities.mutation.mutateAsync({
      message: values['message'],
      assistantType: assistant.assistantType,
      collectionId: collection?.collectionId,
      search: listState.search,
      context: {
        ...conversation.context[assistant.value],
        credits: conversation.context.credits
      },
      ...utils.filter2Object(listState.filter)
    })
      .then((res) => {
        setConversation((current) => {
          return {
            context: {
              ...current.context,
              credits: res.response.data.data.context?.credits ?? 0,
              [assistant.value]: res.response.data.data.context
            },
            messages: res.response.data.data.messages?.length > 0 ?
              current.messages.concat(res.response.data.data.messages.map((m) => ({
                ...m,
                assistant: assistant.value
              }))) :
              current.messages.concat([{
                assistant: assistant.value,
                analyser: 'Could not answer your message in time'
              }])
          };
        });

        setInputDisabled(true);
      })
      .catch((error) => {
        let msg = 'Failed to process your message';
        if (error?.response?.status === constants.http.status.conflict) {
          msg = '0 credits left in your wallet';
        }

        setConversation((current) => {
          return {
            ...current,
            messages: current.messages.concat([{
              assistant: assistant.value,
              error: msg
            }])
          };
        });

        setInputDisabled(false);
      })
      .finally(() => {
        setSubmitting(false);
        actions.setSubmitting(false);

        const focus = () => {
          return messageFieldRef.current?.focus();
        }

        utils.retry(focus, 3);
      });
  };

  const assistantAction = useMemo(() => {
    return {
      label: assistant.label,
      color: assistant.value === constants.chat.assistantTypes.budget ? 'greyScale' : 'success',
      onClick: () => {
        const next = assistant.value === constants.chat.assistantTypes.budget ?
          constants.data.lookup('assistantTypes', constants.chat.assistantTypes.advanced) :
          constants.data.lookup('assistantTypes', constants.chat.assistantTypes.budget);

        setAssistant(next);
        setConversation((current) => {
          const showMessage = utils.isDefined(current.context[assistant.value]?.threadId) ||
            utils.isDefined(current.context[next.value]?.threadId);
          const resume = utils.isDefined(current.context[next.value]?.threadId);

          return {
            ...current,
            messages: current.messages.concat(showMessage ? (
              !resume ? [{
                assistant: next.value,
                analyser: `I see you have switched the type of conversation from ${assistant.label} to ${next.label}.` +
                ' The next message you send will start a new conversation. You can resume you previous conversation by switching back.' +
                '       \nWhat can i do for you today? Feel free to ask.'
              }] : [{
                assistant: next.value,
                analyser: `I see you have switched the type of conversation from ${assistant.label} to ${next.label}.` +
                  ' We will resume the previous conversation. Feel free to ask.'
              }]
            ) : [])
          };
        });
      },
      ActionChipProps: {
        size: 'small',
        radius: 'round',
        variant: assistant.value === constants.chat.assistantTypes.budget ? 'outlined' : 'filled'
      },
      ChipProps: {
        disabled: submitting
      }
    }
  }, [assistant, submitting]);

  const sendAction = useMemo(() => ({
    icon: <Icon icon={Send} size="smaller" />,
    tooltip: 'Send',
    onClick: () => {
      formRef.current?.submit();
    },
    IconButtonProps: {
      disabled: inputDisabled || submitting,
      size: 'smaller',
      density: 'sparse',
      color: 'primary',
      variant: 'contained'
    }
  }), [inputDisabled, submitting]);

  useEffect(() => {
    conversationRef.current?.scrollTo({top: conversationRef.current?.scrollHeight, behavior: 'smooth'});
  }, [submitting, assistant?.value]);

  const handleChangeDirect = (e) => {
    e.target.value?.length > 0 ? setInputDisabled(false) : setInputDisabled(true);
  };

  const handleValidating = (isValidating, isDirty) => {
    setDirty((current) => isDirty ? true : current);
  }

  const handleClose = (e, reason) => {
    if (!dirty) {
      innerProps.onClose?.(e, reason);
    } else if (['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      const handleConfirm = () => {
        const fileIds = Object.keys(conversation.context).reduce((a, k) => {
          return a.concat(conversation.context[k]?.fileIds ?? [])
        }, []);

        chatCleanup.mutation.mutateAsync({
          fileIds
        })
          .catch(() => {
            /* SQUASH */
          });
        innerProps.onClose?.(e, reason);
      }

      dialogControl.show(<ConfirmDialog question="Are you sure you want to exit this chat?"
                                        explanation="The dialog will close and your chat will be lost"
                                        onConfirm={handleConfirm}/>, true);

    }
  }

  const renderMessage = (message, idx) => {
    const parseLink = (href, type) => {
      if (href.includes(`/${type}`)) {
        return href.replace('data', '').replace('text', '').replace('base64', '').replace(`${type}`, '').replace(/[:;,/]+/gi, '');
      } else {
        return null;
      }
    }

    const markDownComponents = {
      a: ({node, ...props}) => {
        if (node.properties?.href?.startsWith('blob:')) {
          const split = node.properties?.href.split(/[&?]/);
          const params = {};
          split.slice(1).forEach((s) => {
            const split = decodeURIComponent(s).split('=');
            if (split.length === 2) {
              params[split[0]] = split[1];
            }
          });

          if (params.filename) {
            return <a {...props} download={params.filename} href={split[0]}>{props.children}</a>
          } else {
            return <a {...props} href={split[0]}>{props.children}</a>
          }
        } else if (parseLink(node.properties?.href, 'filter')) {
            const params = parseLink(node.properties?.href, 'filter');
            let valid = false;
            try {
              valid = utils.toArray(JSON.parse(atob(params)))
                .filter((id) => +id > 0).length > 0;
            } catch (e) {
              /* SQUASH */
            }

            if (valid) {
              return <ActionLink action={{
                label: props.children,
                navigation: {
                  to: `?custom=chat:${params}`,
                  resetSearchParams: true
                },
                onClick: (e) => {
                  if (!linkModifier(e)) {
                    innerRef.current?.close?.(e, 'closeButtonClick');
                  }
                }
              }}/>
            } else {
              return <Span color="error">It's not possible to show in Catalist</Span>
            }
        } else if (parseLink(node.properties?.href, 'upload')) {
          const canUpdate = collection ? authorize({attribute: 'entity.create'}) :
            authorize({attribute: 'collection.entity.create', meta: {collection}});

          if (canUpdate) {
            let blob;
            try {
              blob = utils.base64ToBlob(parseLink(node.properties?.href, 'upload'))
            } catch (e) {
              /* SQUASH */
            }

            if (blob) {
              return <ActionLink action={{
                label: props.children,
                onClick: (e) => {
                  const file = utils.blobToFile(blob, 'companies.csv');

                  const handleSubmit = (upload) => {
                    return createUpload({
                      name: upload.name,
                      params: upload.params,
                      period: upload.period,
                      subType: '',
                      type: upload.type
                    }, collection)
                      .then(() => {
                        snackbar.show('File upload is scheduled', null,
                          {color: 'success', autoHideDuration: constants.delay.success});
                        dialogControl.hide();
                      });
                  };

                  const handleClose = () => {
                    dialogControl.hide();
                  };

                  dialogControl.show(<EntitiesUploadDialog onSubmit={handleSubmit}
                                                           EntitiesUploadWizardProps={{
                                                             collection,
                                                             file
                                                           }}/>, true, handleClose);

                  e.preventDefault();
                }
              }}/>
            } else {
              return <Span color="error">Invalid file provided</Span>
            }
          } else {
            return <Span color="error">Saving company data is not allowed</Span>
          }
        } else if (parseLink(node.properties?.href, 'invalid')) {
          let error;
          try {
            error = atob(parseLink(node.properties?.href, 'invalid'));
          } catch (e) {
            /* SQUASH */
          }
          return <Span color="error">{error ?? 'Invalid'}</Span>
        } else {
          return <a {...props}>{props.children}</a>
        }
      }
    }

    const fileToDataUrl = (file_data, file_info, isImage) => {
      let contentType = isImage ? 'image/png' : 'text/plain';

      if (file_info?.filename?.toLowerCase()?.endsWith('.html')) {
        contentType = 'text/html';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.pdf')) {
        contentType = 'application/pdf';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.xlsx')) {
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.xls')) {
        contentType = 'application/vnd.ms-excel';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.csv')) {
        contentType = 'text/csv';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.json')) {
        contentType = 'application/json';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.png')) {
        contentType = 'image/png';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.jpeg') || file_info?.filename?.toLowerCase()?.endsWith('.jpg')) {
        contentType = 'image/jpeg';
      } else if (file_info?.filename?.toLowerCase()?.endsWith('.gif')) {
        contentType = 'image/gif';
      }

      let url;
      try {
        url = URL.createObjectURL(utils.base64ToBlob(file_data, contentType));

        if (!isImage) {
          url += (file_info.filename ? `?filename=${file_info.filename.split('/').slice(-1)[0]}` : '') +
            (contentType ? `${file_info.filename ? '&' : '?'}contentType=${contentType}` : '');
        }
      } catch (e) {
        /* SQUASH */
      }

      return url ?? `data:text/invalid;base64,${btoa('Invalid file provided')}`;
    }

    const processAnnotations = (content, attachments) => {
      let text = content?.text?.value;

      utils.toArray(content?.text?.annotations).forEach((annotation) => {
        if (annotation.type === 'file_path') {
          const file = attachments.find((a) => a.file_id === annotation.file_path.file_id);
          if (file) {
            let url = fileToDataUrl(file.file_data, file.file_info, false);

            text = utils.replace(text, annotation.text, url, 'gi');
          }
        } else if (annotation.type === 'file_citation') {
          text = utils.replace(text, annotation.text, '', 'gi');
        }
      });

      // fix possibly bad links
      return text.replace(/(\[.*\]\([^)}]+)\}+/g, '$1)');
    }

    const renderResult = () => {
      return utils.toArray(message?.content).map((c, idx) => {
        if (c?.type === 'text') {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            <Markdown components={markDownComponents}>{processAnnotations(c, message.attachments)}</Markdown>
          </P>
        } else if (c?.type === 'image_url') {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            <Img alt={c?.image_url?.url?.split('/').slice(-1)[0] ?? 'image'}
                 src={c?.image_url?.url}/>
          </P>
        } else if (c?.type === 'image_file') {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            <Img alt={c?.image_info?.filename?.split('/').slice(-1)[0] ?? 'image'}
                 src={fileToDataUrl(c?.image_data, c?.image_info, true)}/>
          </P>
        } else {
          return <P key={idx}>
            {idx === 0 ? <Span className="title">Analyser:</Span> : null}{idx === 0 ? <br/> : null}
            {JSON.stringify(c)}
          </P>
        }
      });
    }

    const msgAssistant = constants.data.lookup('assistantTypes', message?.assistant);
    return <Box key={idx} className="EntitiesChatDialog-message">
      <Chip className="EntitiesChatDialog-message-chip"
            showTooltip={false}
            size="small"
            radius="round"
            variant={msgAssistant.value === constants.chat.assistantTypes.budget ? 'outlined' : 'filled'}
            color={msgAssistant.value === constants.chat.assistantTypes.budget ? 'greyScale' : 'success'}
            label={msgAssistant.label}/>

      {message.error ? <P>
        <Span className="title">Error:</Span><br />
        <Span color="error"><Markdown>{message.error}</Markdown></Span>
      </P> : (message.user ? <P>
        <Span className="title">User:</Span><br />
        <Markdown>{message.user}</Markdown>
      </P> : (message.analyser ? <P>
        <Span className="title">Analyser:</Span><br />
        <Markdown>{message.analyser}</Markdown>
      </P> : renderResult()))}
    </Box>
  }

  return <StyledEntitiesChatDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader className="EntitiesChatDialog-header"
                  title="Company chat" />
    <DialogContent className="EntitiesChatDialog-content">
      <Box ref={conversationRef} className="EntitiesChatDialog-conversation">
        {conversation.messages.map((message, idx) => {
          return renderMessage(message, idx);
        })}
        {submitting ? <Box className="EntitiesChatDialog-loader">
          <CircularProgress />
          <P>Please wait</P>
        </Box> : null}
      </Box>
      <Box className="EntitiesChatDialog-footer">
        <Box className="EntitiesChatDialog-info">
          <Typography className="EntitiesChatDialog-credits" variant="subtitle2">
            {utils.formatNumber(Math.round(conversation.context?.credits ?? 0))} credits
          </Typography>
          <ActionChip action={assistantAction} />
        </Box>
        <Box className="EntitiesChatDialog-form">
          <InlineForm ref={formRef}
                      fields={fields}
                      onValidating={handleValidating}
                      onChangeDirect={handleChangeDirect}
                      onSubmit={handleSubmit} />
          <ActionIconButton action={sendAction} />
        </Box>
      </Box>
    </DialogContent>
  </StyledEntitiesChatDialog>
});

EntitiesChatDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.func
};

EntitiesChatDialog.defaultProps = {};

export default EntitiesChatDialog;
