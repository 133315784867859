import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import ClientProfile from 'components/organisms/Profiles/ClientProfile/ClientProfile';
import Add from '@mui/icons-material/Add';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledConnectionsSettingPage
  from 'components/pages/Setting/ConnectionsSettingPage/ConnectionsSettingPage.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const info = 'Add and manage your connections to other systems like your CRM system';

const ConnectionsSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'ConnectionsSettingPage');

  const contentRef = useRef(null);

  const profileProvider = useProfile();
  const cardDefinitions = profileProvider.cardDefinitions;
  const [internalState, setInternalState] = useState({
    addNew: false,
    lastNew: null
  });

  const authorize = useAuthorize();

  const bBox = useBbox(() => contentRef.current, ['width']);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleShowCard = (card) => {
    if (internalState.addNew && utils.isDefined(internalState.lastNew)) {
      if (card.name.startsWith('connection-new') && card.name !== internalState.lastNew) {
        setInternalState(utils.updater({addNew: false}, true));
      }
    }
    return card.name.startsWith('connection') && (internalState.addNew || !card.name.startsWith('connection-new'));
  };

  const handleCardClose = (e, reason, card) => {
    if (card.name.startsWith('connection-new')) {
      setInternalState(utils.updater({addNew: false}, true));
    }
  };

  const action = useMemo(() => ({
    label: !mdDown ? 'Add connection' : null,
    tooltip: mdDown ? 'Add connection' : null,
    icon: Add,
    auth: utils.createAuth({attribute: 'client.connection.create'}),
    iconPosition: 'start',
    onClick: (e) => {
      const newCard = cardDefinitions.find((c) => c.name.startsWith('connection-new'));
      setInternalState(utils.updater({addNew: true, lastNew: newCard?.name}, true));
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      color: 'success'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'success',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [cardDefinitions, mdDown]);

  const handleCanUpdate = () => {
    return authorize({attribute: 'settings.connections.update'});
  }

  const handleDirty = (dirty) => {
    profileProvider.dirty?.(dirty);
  }

  const columns = bBox?.width ? Math.max(Math.floor((bBox?.width ?? 0) / 324), 1) : 0;

  return <StyledConnectionsSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                       title="Connections"
                                       info={!smDown ? info : null}
                                       buttons={!mdDown ? <ActionButton action={action} /> : <ActionIconButton action={action} />}>
    <DndContext>
      <Box ref={contentRef} className="ConnectionsSettingPage-content">
        <ClientProfile columns={columns}
                       variant="grid"
                       onShowCard={handleShowCard}
                       forceEditNew={internalState.addNew}
                       onDirty={handleDirty}
                       onCanUpdate={handleCanUpdate}
                       ProfileCardProps={{
                         onClose: handleCardClose
                       }}/>
      </Box>
    </DndContext>
  </StyledConnectionsSettingPage>
};

ConnectionsSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ConnectionsSettingPage.defaultProps = {};

export default ConnectionsSettingPage;
