import logger from 'helpers/logger';
import {AppService} from 'services/app/app.service';
import {SystemService} from 'services/system/system.service';
import {ExportService} from 'services/export/export.service';
import {ChatService} from 'services/chat/chat.service';
import {ConnectionService} from 'services/connection/connection.service';
import {TestService} from 'services/test/test.service';
import {AuthService} from 'services/auth/auth.service';
import {TagService} from 'services/tag/tag.service';
import {CollectionService} from 'services/collection/collection.service';
import {CollectionEntityService} from 'services/collection/entity/entity.service';
import {CollectionStatService} from 'services/collection/stat/stat.service';
import {CollectionUserService} from 'services/collection/user/user.service';
import {EntityUploadService} from 'services/entity/upload/upload.service';
import {EntityService} from 'services/entity/entity.service';
import {EntityTagService} from 'services/entity/tag/tag.service';
import {EntityTagGroupService} from 'services/entity/tagGroup/tagGroup.service';
import {EntityPatentService} from 'services/entity/patent/patent.service';
import {EntityPatentCpcService} from 'services/entity/patent/cpc/cpc.service';
import {ClientService} from 'services/client/client.service';
import {ClientStatusService} from 'services/client/status/status.service';
import {ClientTeamService} from 'services/client/team/team.service';
import {ClientTimelineService} from 'services/client/timeline/timeline.service';
import {PortalService} from 'services/portal/portal.service';
import {PortalApplicantService} from 'services/portal/applicant/applicant.service';
import {ApplicantUploadService} from 'services/portal/applicant/upload/upload.service';
import {PortalClientService} from 'services/portal/client/client.service';
import {PortalCollectionService} from 'services/portal/collection/collection.service';
import {ClientTeamMemberService} from 'services/client/team/member/member.service';
import {ClientStatisticService} from 'services/client/statistic/statistic.service';
import {EntityCsiService} from 'services/entity/csi/csi.service';
import {CommentService} from 'services/comment/comment.service';
import {EntityCommentService} from 'services/entity/comment/comment.service';
import {EntityTimelineService} from 'services/entity/timeline/timeline.service';
import {CollectionQuestionService} from 'services/collection/question/question.service';
import {CollectionQuestionnaireService} from 'services/collection/questionnaire/questionnaire.service';
import {CollectionTagGroupService} from 'services/collection/tagGroup/tagGroup.service';
import {CollectionLabelService} from 'services/collection/label/label.service';
import {ClientCustomFieldService} from 'services/client/customField/customField.service';
import {CollectionCustomFieldService} from 'services/collection/customField/customField.service';
import {ClientCurrencyService} from 'services/client/currency/currency.service';
import {ClientBudgetService} from 'services/client/budget/budget.service';
import {CollectionTimelineService} from 'services/collection/timeline/timeline.service';
import {CollectionEntityAnswerService} from 'services/collection/entity/answer/answer.service';
import {ClientUserService} from 'services/client/user/user.service';
import {SourceService} from 'services/source/source.service';
import {CollectionUploadService} from 'services/collection/upload/upload.service';

export default class Services {
  constructor () {
    // init services
    this.services = {};
    this.initialised = false;
  }

  init = (app) => {
    const registerService = (ServiceClass, children = []) => {
      const service = new ServiceClass(app, {find: this.find, findByEntity: this.findByEntity});

      service.children = children;
      children.forEach((child) => {
        child.parent = service;
      });

      this.services[service.path] = service;

      return service;
    };

    // initialize services
    if (!this.initialised) {
      try {
        this.initialised = true;
        registerService(AppService);
        registerService(AuthService);
        registerService(SystemService);
        registerService(ExportService);
        registerService(ChatService);
        registerService(ConnectionService);
        registerService(ClientService, [
          registerService(ClientStatusService),
          registerService(ClientStatisticService),
          registerService(ClientTimelineService),
          registerService(ClientUserService),
          registerService(ClientCustomFieldService),
          registerService(ClientCurrencyService),
          registerService(ClientBudgetService),
          registerService(ClientTeamService, [
            registerService(ClientTeamMemberService),
          ])
        ]);
        registerService(EntityService, [
          registerService(EntityCommentService),
          registerService(EntityCsiService),
          registerService(EntityUploadService),
          registerService(EntityTagService),
          registerService(EntityTagGroupService),
          registerService(EntityTimelineService),
          registerService(EntityPatentService, [
            registerService(EntityPatentCpcService)
          ])
      ]);
        registerService(CollectionService, [
          registerService(CollectionCustomFieldService),
          registerService(CollectionTimelineService),
          registerService(CollectionEntityService, [
            registerService(CollectionEntityAnswerService)
          ]),
          registerService(CollectionUserService),
          registerService(CollectionStatService),
          registerService(CollectionQuestionService),
          registerService(CollectionQuestionnaireService),
          registerService(CollectionTagGroupService),
          registerService(CollectionUploadService),
          registerService(CollectionLabelService)
        ]);
        registerService(CommentService);
        registerService(SourceService);
        registerService(PortalService, [
          registerService(PortalClientService),
          registerService(PortalCollectionService),
          registerService(PortalApplicantService, [
            registerService(ApplicantUploadService)
          ])
        ]);
        registerService(TagService);
        registerService(TestService);

        Object.keys(this.services).forEach((path) => {
          const service = this.services[path];
          if (service.storePath() !== path) {
            logger.error('Invalid path for child service', service.getPath(), service.path)
          }
        })
      } catch (err) {
        logger.error('Service initialization failed', err);
      }
    }
  }

  find = (path) => {
    return this.services[path];
  }

  findByEntity = (entity) => {
    return Object.keys(this.services)
      .map((path) => this.services[path])
      .filter((s) => s.hasEntity(entity));
  }
}
