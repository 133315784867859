import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledClientSettingPage = styled(Page)`
  background-color: unset;
  
  .PageBar {
    &-leftActions {
      padding-left: ${props => props.theme.spacing(3)};
    }
    
    .SettingsHeader {
      padding-left: 0;
    }
  }
  
  .ClientSettingPage {
    &-title {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.layout('1sp')};
      
      .H4 {
        text-overflow: ellipsis;
        flex-grow: 1;
        overflow: hidden;
      }
    }
    &-content {
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};

      .ClientProfile {
        background-color: unset;
        padding: 0;
        max-width: 100%;
      }
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .PageBar {
      &-leftActions {
        padding-left: ${props => props.theme.spacing(2)};
      }
    }
  }

  ${ComponentStyles}
`;

StyledClientSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledClientSettingPage.defaultProps = {}

export default StyledClientSettingPage;
