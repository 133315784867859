import {useServiceApiMutation} from 'stores/hooks/query';
import utils from 'helpers/utils';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useChatEntities (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'chat/entities',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}

export function useChatCleanup (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'chat/cleanup',
    overrideDataType: constants.dataTypes.other,
    ...mutationOptions
  });
}
