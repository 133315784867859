import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import StyledFieldCard from 'components/organisms/Cards/FieldCard/FieldCard.styles';
import constants from 'helpers/constants';
import Chip from 'components/atoms/Chips/Chip/Chip';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';

const FieldCard = React.forwardRef((props, ref) => {
  const {
    field,
    groups,
    hasAutoLookup,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'FieldCard');

  const renderDetails = () => {
    return <Box className="FieldCard-details">
      <Box className="FieldCard-detail">
        <Typography className="FieldCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Type</Typography>
        <Typography className="FieldCard-detail-value"
                    isLoading={isLoading}
                    variant="caption">{constants.data.lookup('customFieldRendererTypes', field?.renderer)?.label}</Typography>
      </Box>
      <Box className="FieldCard-detail">
        <Typography className="FieldCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Auto lookup</Typography>
        {field?.autoLookup ?
          <Chip className="FieldCard-detail-chip"
                tooltip={!hasAutoLookup ? 'Auto lookup service is not active' : ''}
                showTooltip={!hasAutoLookup}
                isLoading={isLoading}
                size="small" variant="filled"
                label="By AI" color={hasAutoLookup ? 'success' : 'warning'} /> :
          <Chip className="FieldCard-detail-chip"
                showTooltip={false}
                isLoading={isLoading}
                size="small" variant="filled"
                label="Not active" />}
      </Box>
      <Box className="FieldCard-detail">
        <Typography className="FieldCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Group</Typography>
        <Chip className="FieldCard-detail-chip"
              showTooltip={false}
              label={groups?.find((g) => g.value === field?.groupName)?.label}
              isLoading={isLoading}
              size="small" variant="filled" />
      </Box>
    </Box>
  }

  const renderDescription = () => {
    return <Box className="FieldCard-description">
      <Typography showTooltip={true}
                  isLoading={isLoading}
                  variant="body2">
        {field?.tooltip ? <Markdown>{field?.tooltip}</Markdown> : 'No description yet'}
      </Typography>
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledFieldCard ref={ref} {...innerProps}>
    <Box className="FieldCard-content">
      <Box className="FieldCard-info">
        <H6 className="FieldCard-title" showTooltip={true}
            min={8} max={20}
            isLoading={isLoading}>{field?.label}</H6>
        {renderDetails()}
      </Box>
    </Box>
    {renderDescription()}
  </StyledFieldCard>
});

FieldCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  field: PropTypes.object,
  groups: PropTypes.array,
  hasAutoLookup: PropTypes.bool,
  isLoading: PropTypes.bool
};

FieldCard.defaultProps = {};

export default FieldCard;
