import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledCollectionSourceProfileCard
  from 'components/organisms/Cards/CollectionSourceProfileCard/CollectionSourceProfileCard.styles';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';

const CollectionSourceProfileCard = React.forwardRef((props, ref) => {
  const {
    card,
    action,
    onHide,
    source,
    collection,
    fieldData,
    isDialog,
    renderedContent,
    ...innerProps
  } = useComponentProps(props, 'CollectionSourceProfileCard', {
    static: ['isAddNew', 'isReadOnly', 'isEditing', 'isDeleted', 'isDialog'],
    children: ['content']
  });

  const innerRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const isAddNew = !utils.isDefined(source?.sourceId);
  const isReadOnly = !card.card.editable;
  const isNewCollection = !(collection.collectionId > 0);
  const isLoading = !utils.isDefined(source?.name) || !utils.isDefined(source?.type);

  const dialogControl = useDialogControl();

  useLayoutEffect(() => {
    const editing = card.state.isEditing ?? card.state.card.isEditing ?? card.state.dialog.isEditing;
    if (!isAddNew) {
      setIsEditing(editing);
    } else {
      if (!card.state.card.submitSuccess) { // wait for new card
        setIsEditing(editing);
      }
    }
  }, [card.state.isEditing, card.state.card.isEditing, card.state.dialog.isEditing, card.state.card.submitSuccess, isAddNew]);

  const handleDeleteClick = (e) => {
    const handleConfirm = () => {
      const sources = (collection?.projectSources ?? []).filter((s) => +s.sourceId !== +source.sourceId);
      const sourcesValue = utils.uniqueArray(sources, 'sourceId', true);
      const field = {
        name: 'projectSources'
      };

      return utils.asPromiseCallback(card.card.onPatch)(field, sourcesValue)
        .then(() => {
          onHide?.(true);
          setIsDeleted(true);
        });
    }

    if (!isNewCollection) {
      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this source?"
                                        explanation="The source will be removed from this collection"
                                        challenge={source.name}
                                        challengeLabel="source name"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove source',
                                          startIcon: <Icon icon={Delete} />,
                                          color: 'error'
                                        }}/>, true);
    } else {
      handleConfirm();
    }

    e.preventDefault();
  };

  const renderActions = () => {
    if (action && !isLoading) {
      return <React.Fragment>
        {!(isEditing || isAddNew || isReadOnly) ? <ActionIconButton className="ProfileCard-action"
                                                      variant="contained"
                                                      size="smaller"
                                                      color="error"
                                                      density="dense"
                                                      tabIndex={-1}
                                                      action={{
                                                        icon: <Icon icon={Delete} size="smaller" />,
                                                        tooltip: 'Remove source'
                                                      }}
                                                      IconProps={{size: 'tiny'}}
                                                      onClick={handleDeleteClick} /> : null}
        <ActionIconButton className="ProfileCard-action"
                          tabIndex={-1}
                          action={action} density="dense"
                          size="smaller" variant="outlined"
                          IconProps={{size: 'tiny'}}/>
      </React.Fragment>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isReadOnly: isReadOnly,
    isEditing: isEditing,
    isDeleted: isDeleted
  });

  return <StyledCollectionSourceProfileCard ref={innerRef} {...innerProps}>
    {!(isAddNew || isDialog) ? <CardHeader className="ProfileCard-header"
                                           title=""
                                           action={renderActions()}/> : null}
    {renderedContent}
  </StyledCollectionSourceProfileCard>
});

CollectionSourceProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  action: PropTypes.object,
  onHide: PropTypes.func,
  source: PropTypes.object,
  collection: PropTypes.object,
  fieldData: PropTypes.object,
  isDialog: PropTypes.bool,
  renderedContent: PropTypes.any
};

CollectionSourceProfileCard.defaultProps = {
};

export default CollectionSourceProfileCard;
