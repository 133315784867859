import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useTableReset} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import {useSplitPath} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledDatabaseEntitiesBarWrapper
  from 'components/templates/Wrappers/Database/DatabaseEntitiesBarWrapper/DatabaseEntitiesBarWrapper.styles';
import EntitiesViewBar from 'components/organisms/Bars/EntitiesViewBar/EntitiesViewBar';
import utils from 'helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/icons-material/List';
import {ArticleOutlined, TravelExplore} from '@mui/icons-material';

const DatabaseEntitiesBarWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesBarWrapper');

  const tableProvider = useTable();
  const collection = tableProvider.context?.data;
  const selectedEntityId = tableProvider.selected()?.entityId;
  const isLoading = !collection;
  const isTableEmpty = tableProvider?.list?.data?.length === 0;

  const [pathPrefix, pathPostfix] = useSplitPath('database');

  useTableReset(pathPrefix, pathPostfix);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const openProfileEvent = useEffectEvent(tableProvider.openProfile);
  const rightActions = useMemo(() => [
    {
      label: !smDown ? 'Add company' : null,
      tooltip: smDown ? 'Add company' : null,
      icon: Add,
      auth: utils.createAuth({attribute: 'database.entity.create' }),
      onClick: () => {
        openProfileEvent?.(-1);
      },
      ActionButtonProps: !smDown ? {
        color: 'success',
        variant: 'contained',
        radius: 'round'
      } : null,
      ActionIconButtonProps: smDown ? {
        color: 'success',
        variant: 'contained',
        size: 'smaller',
        density: 'sparse',
        IconProps: {
          size: 'smaller'
        }
      } : null
    }
  ], [openProfileEvent, smDown]);

  const toggleActions = useMemo(() => {
    return [
      {
        label: !(smDown || lgDown) ? 'Table' : null,
        tooltip: (smDown || lgDown) ? 'Table' : null,
        icon: (smDown || lgDown) ? List : null,
        navigation: {
          to: `${pathPrefix}`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        }
      },
      {
        label: !(smDown || lgDown) ? 'Profile' : null,
        tooltip: (smDown || lgDown) ? 'Profile' : null,
        icon: (smDown || lgDown) ? ArticleOutlined : null,
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        },
        ToggleButtonProps: {
          disabled: isTableEmpty || !selectedEntityId
        }
      },
      {
        label: !(smDown || lgDown) ? 'Browser' : null,
        tooltip: (smDown || lgDown) ? 'Browser' : null,
        icon: (smDown || lgDown) ? TravelExplore : null,
        navigation: {
          to: `${pathPrefix}/${selectedEntityId}/browser`,
          keepSearchParams: true
        },
        ActionToggleButtonProps: {
          showInactive: true,
          IconProps: (smDown || lgDown) ? {
            size: 'smaller'
          } : null
        },
        ToggleButtonProps: {
          disabled: isTableEmpty || !selectedEntityId
        }
      }
    ];
  }, [pathPrefix, selectedEntityId, isTableEmpty, smDown, lgDown]);

  return <StyledDatabaseEntitiesBarWrapper ref={ref} {...innerProps}
                                           ToolbarProps={{
                                             title: 'Database',
                                             rightActions,
                                             toggleActions,
                                             ViewBarComponent: EntitiesViewBar,
                                             isLoading
                                           }}>
    {innerProps.children}
  </StyledDatabaseEntitiesBarWrapper>
});

DatabaseEntitiesBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesBarWrapper.defaultProps = {
  children: 'DatabaseEntitiesBarWrapper text',
};

export default DatabaseEntitiesBarWrapper;
