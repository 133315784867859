import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCollectionCategoriesSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionCategoriesSectionPanelContent/CollectionCategoriesSectionPanelContent.styles';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import CollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import utils from 'helpers/utils';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import constants from 'helpers/constants';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import CollectionCategoryWizardDialog
  from 'components/organisms/Dialogs/CollectionCategoryWizardDialog/CollectionCategoryWizardDialog';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';

const info = 'Add and manage categories for your collection company profiles';

const CollectionCategoriesSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    section,
    collection,
    isLoading,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'CollectionCategoriesSectionPanelContent');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const profileProvider = useProfile();
  const setSettingsEvent = useEffectEvent(profileProvider.setSettings);
  const onboarding = profileProvider?.state?.settings?.onboarding;

  const dialogControl = useDialogControl();

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);

  const createTagGroupEvent = useEffectEvent(profileProvider.updaters?.createTagGroup);
  const wizardAction = useMemo(() => {
    const canUpdate = onCanUpdateEvent?.(collection) ?? false;
    const entitiesCount = collection?.newInCollection?.find((c) => c.scope === constants.collection.scopes.inside)?.total;

    return {
      label: 'Category wizard',
      icon: AutoAwesome,
      tooltip: entitiesCount >= 3 ? null : 'The category wizard needs at least 3 companies in the collection to generate the best results',
      TooltipProps: entitiesCount >= 3 ? null : {enterDelay: 0},
      auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) : null,
      onClick: (e) => {
        const handleSubmit = (tagGroups) => {
          return createTagGroupEvent?.(tagGroups);
        };

        dialogControl.show(<CollectionCategoryWizardDialog collection={collection} onSubmit={handleSubmit}/>, true);

        e.preventDefault();
      },
      ActionButtonProps: {
        IconProps: {
          size: 'smaller'
        }
      },
      ButtonProps: {
        color: 'secondary',
        variant: 'outlined',
        flatBackground: true,
        plainBorder: true,
        radius: 'round',
        disabled: !(entitiesCount >= 3)
      }
    }
  }, [collection, onCanUpdateEvent, dialogControl, createTagGroupEvent]);

  useEffect(() => {
    if (onboarding) {
      wizardAction.onClick(utils.createEvent('click'));
      setSettingsEvent?.({onboarding: false});
    }
  }, [wizardAction, onboarding, setSettingsEvent]);

  const handleShowCard = (card) => {
    return card.name.startsWith('tagGroup');
  };

  const renderButtons = () => {
    return <React.Fragment>
      <ActionButton showInactive={true}
                    action={wizardAction} />
    </React.Fragment>
  }

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return <StyledCollectionCategoriesSectionPanelContent ref={innerRef} {...innerProps}>
    <CollectionSectionPanelHeader title={section.title} info={!smDown ? info : null} buttons={renderButtons()}/>
    <DndContext>
      <CollectionProfile columns={smDown ? 1 : (lgDown ? 2 : 3)}
                         variant="grid"
                         sortable={true}
                         dragHandle={true}
                         onShowCard={handleShowCard}/>
    </DndContext>
  </StyledCollectionCategoriesSectionPanelContent>
});

CollectionCategoriesSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  section: PropTypes.object,
  collection: PropTypes.object,
  isLoading: PropTypes.bool,
  onCanUpdate: PropTypes.func
};

CollectionCategoriesSectionPanelContent.defaultProps = {};

export default CollectionCategoriesSectionPanelContent;
