import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFileField from 'components/molecules/Fields/FileField/FileField.styles';
import Typography from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import constants from 'helpers/constants';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputOutline from 'components/atoms/Fieldsets/InputOutline/InputOutline';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import Close from '@mui/icons-material/Close';
import dom from 'helpers/dom';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import {UploadFileOutlined} from '@mui/icons-material';
import Link from 'components/atoms/Links/Link/Link';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import TextField from 'components/molecules/Fields/TextField/TextField';
import {withMemo} from 'helpers/wrapper';
import Logo from 'components/atoms/Logos/Logo/Logo';

const FileField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    value,
    types,
    minSize,
    maxSize,
    helperText,
    fileVariant,
    fileDescription,
    multiple,
    addDescription,
    status,
    autoFocus,
    onBlur,
    onChange,
    inputProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'FileField', {
    static: ['disabled', 'focused', 'error'],
    variable: ['fileVariant'],
    children: ['dropzone', 'files', 'file']
  });

  const [focusActive, setFocusActive] = React.useState(false);
  const [dragActive, setDragActive] = React.useState(false);

  const innerRef = useRef(null);
  const dropZoneRef = useRef(null);
  const inputRef = useRef(null);
  const openRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const isLogo = fileVariant === 'logo';
  const logo = useMemo(() => {
    if (isLogo && utils.toArray(value, true).length > 0 && !innerProps.error) {
      return URL.createObjectURL(utils.toArray(value, true)[0]);
    } else {
      return null;
    }
  }, [isLogo, value, innerProps.error]);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector('.add'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const doChange = (filesArray) => {
    if (!innerProps.disabled) {
      // keep only last added
      if (!multiple) {
        filesArray = filesArray.length > 0 ? filesArray[filesArray.length - 1] : null;
      }

      onChange?.({
        target: {
          name: name,
          value: filesArray
        }
      });
    }
  }

  const handleChange = (files) => {
    let filesArray = (multiple && value) ? [...value] : [];
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        filesArray.push(files.item(i));
      }
    } else {
      filesArray.push(files);
    }

    doChange(filesArray);
  };

  const handleDescriptionChange = (idx) => (e) => {
    let filesArray = utils.clone(value);
    filesArray[idx].description = e.target.value?.trim();

    doChange(filesArray);
  }

  const handleDelete = (index) => (e) => {
    const filesArray = utils.toArray(value, true)
      .reduce((a, v, idx) => {
        if (+idx !== +index) {
          a.push(v);
        }
        return a;
      }, []);

    doChange(filesArray);

    e.preventDefault();
    return false;
  };

  const handleDrag = (e) => {
    if (!innerProps.disabled) {
      if (e.type === "dragenter" || e.type === "dragover") {
        setDragActive(true);
      } else if (e.type === "dragleave") {
        setDragActive(false);
      }

      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleDrop = (e) => {
    if (!innerProps.disabled) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleChange(e.dataTransfer.files);
      }

      setDragActive(false);
      dom.focusElement(dropZoneRef.current, {preventScroll: true});
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleChange(e.target.files);
    }

    openRef.current = false;
    inputRef.current.value = null;
    e.preventDefault();
  };

  const handleOpenClick = (e) => {
    if (!innerProps.disabled) {
      openRef.current = true;
      inputRef.current.click();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setFocusActive(!innerProps.readOnly);
  };

  const handleBlur = (e) => {
    setFocusActive(false);
    if (focusActive && !openRef.current) {
      if (innerRef.current && !dom.isPartOfParent(e.relatedTarget, innerRef.current.querySelector('.InputContainer'))) {
        onBlur?.({
          target: {
            name: name
          }
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Space' || (e.code === 'Enter' && !dom.isNativeButton(e.target))) {
      handleOpenClick(utils.createEvent('click'));
      e.preventDefault();
    }
  };

  useEffect(() => {
    return utils.observeEvent(inputRef.current, 'cancel', () => {
      openRef.current = false
    });
  }, []);

  innerProps.focused = innerProps.focused ?? (focusActive || dragActive);

  innerProps.className = utils.flattenClassName(innerProps.className, {focused: innerProps.focused});

  return <ClickAwayListener onClickAway={handleBlur}>
    <StyledFileField ref={innerRef}
                     onFocus={handleFocus}
                     onBlur={handleBlur}
                     {...innerProps}>
      <InputLabel {...utils.cleanObject({
                    htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                    shrink: innerProps.readOnly || innerProps.disabled || null
                  })}
                  {...InputLabelProps}
                  className={utils.classNames('FileField-label', InputLabelProps?.className)}>
        {label}
      </InputLabel>
      <InputContainer className="FileField-container">
        <Box className={`FileField-dropzone ${innerProps.disabled ? 'disabled' : ''}` }
             ref={dropZoneRef}
             tabIndex={!(innerProps.readOnly || innerProps.disabled) ? 0 : -1}
             onKeyDown={handleKeyDown}
             onDragEnter={handleDrag}
             onDragLeave={handleDrag}
             onDragOver={handleDrag}
             onDrop={handleDrop}>
          <input ref={inputRef}
                 type="file"
                 id={id} name={name}
                 disabled={innerProps.disabled}
                 multiple={multiple}
                 accept={utils.toArray(types)?.map((t) => `.${t}`).join(',')}
                 onChange={handleFileChange}
                 {...inputProps}/>
          <Box className="FileField-dropzone-inner" onClick={handleOpenClick}>
            {(!isLogo || !logo) ? <React.Fragment>
              <Avatar color="white" size="larger">
                <Icon color="primary" icon={UploadFileOutlined}/>
              </Avatar>
              <Typography variant="body1" className="title">
                <Link href="#" onClick={handleOpenClick} disabled={innerProps.disabled}>Click to upload</Link> or drag and drop
              </Typography>
              <Typography variant="body2" className="description">
                {fileDescription ? fileDescription : `All file types`} {`(max ${utils.bytesToFileSize(maxSize)})`}
              </Typography>
            </React.Fragment> :
              <Logo className="logo"
                    density="sparse"
                    logo={logo} />
            }
          </Box>
          <InputOutline label={label} />
        </Box>
        {utils.toArray(value, true).length > 0 ? <Box className="FileField-files">
          {utils.toArray(value, true).map((f, idx) => {
            const progress = status?.[idx]?.progress;
            const error = status?.[idx]?.error;
            const success = status?.[idx]?.success;
            const statusText = error ? 'failed' : (success ? 'success' : (
              utils.isDefined(status?.[idx]?.progress) ? 'loading' : 'waiting'
            ));

            return <Box className="FileField-file" key={idx}>
              <Avatar color="white" size="larger">
                <Icon color="primary" icon={UploadFileOutlined}/>
              </Avatar>
              <Box className="middle">
                <Typography variant="body1" className="name" showTooltip={true}>{f.name}</Typography>
                {(addDescription && statusText === 'waiting') ?
                  <TextField hiddenLabel={true}
                             hiddenHelperText={true}
                             placeholder="Description"
                             size="tiny"
                             value={f.description}
                             error={!f.description && innerProps.error}
                             disabled={innerProps.disabled}
                             onBlur={handleBlur}
                             onChange={handleDescriptionChange(idx)}/> : null}
                {(!addDescription || statusText !== 'waiting') ? <Box className="info">
                  <Typography variant="body2" className="size">{utils.bytesToFileSize(f.size)}</Typography>
                  {(statusText !== 'waiting') ? <Typography variant="body2" className="split">.</Typography> : null}
                  <Typography variant="body2"
                              color={error ? 'error' : null}
                              className="status">{statusText !== 'waiting' ? statusText : ''}</Typography>
                </Box> : null}
                {statusText !== 'waiting' ? <LinearProgress variant="determinate" value={progress ?? 0} /> : null}
              </Box>
              <Box className="button">
                <Tooltip title={'Remove'}
                         showDisabled={true}
                         placement="bottom">
                  <IconButton size="medium"
                              density="dense"
                              variant="transparent"
                              onClick={handleDelete(idx)}
                              disabled={innerProps.disabled || statusText === 'loading'}>
                    <Icon icon={Close} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          })}
        </Box> : null}
        <FormHelperText component="div" {...FormHelperTextProps}
                        className={utils.classNames('FileField-helper', FormHelperTextProps?.className)}>
          {helperText}
        </FormHelperText>
      </InputContainer>
    </StyledFileField>
  </ClickAwayListener>
}));

FileField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  types: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  helperText: PropTypes.any,
  fileDescription: PropTypes.any,
  fileVariant: PropTypes.oneOfType([PropTypes.oneOf(['default', 'logo']), PropTypes.string]),
  multiple: PropTypes.bool,
  addDescription: PropTypes.bool,
  status: PropTypes.object,
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

FileField.defaultProps = {
  minSize: 1,
  maxSize: constants.numbers.GB,
  inputProps: {},
  fileVariant: 'default'
};

export default FileField;
