import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Close from '@mui/icons-material/Close';
import StyledFieldsActionbar from 'components/organisms/Snackbars/FieldsActionbar/FieldsActionbar.styles';
import {useAuthClientId} from 'services/auth/auth.utils';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useClientCustomFieldDelete} from 'services/client/customField/customField.hooks';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

const FieldsActionbar = React.forwardRef((props, ref) => {
  const {
    listSelection,
    data,
    ...innerProps
  } = useComponentProps(props, 'FieldsActionbar');

  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const clientId = useAuthClientId();

  const fields = listSelection.dataSelection;

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const deleteField = useClientCustomFieldDelete();
  const clearSelectionEvent = useEffectEvent(listSelection.clearSelection);
  const actionItems = useMemo(() => {
    const actions =[];

    if (!mdDown) {
      actions.push({
        label: 'Remove Selection',
        icon: Close,
        ButtonProps: {
          variant: 'outlined',
          color: 'white'
        },
        onClick: () => {
          clearSelectionEvent?.();
        }
      });
    }

    if (listSelection.count === fields.length) {
      actions.push({
        label: 'Delete fields',
        auth: utils.createAuth({attribute: 'settings.fields.delete', meta: {fields}}),
        icon: Delete,
        ButtonProps: {
          color: 'error'
        },
        onClick: (e) => {
          const handleConfirm = () => {
            const fieldIds = fields?.map((f) => +f.fieldId);

            return Promise.all(fieldIds.map((fieldId) => {
              return deleteField.mutation.mutateAsync({
                clientId,
                fieldId
              });
            })).catch(() => {
              snackbar.show(`Deleting field${fields.length > 1 ? 's' : ''} failed`, null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
          }

          const question = `Are you sure you want to remove ${fields.length} field${fields.length > 1 ? 's' : ''}?`;
          const explanation = `This will remove the field${fields.length > 1 ? 's' : ''} including all field related data`;

          dialogControl.show(<ConfirmDialog question={question}
                                            explanation={explanation}
                                            challenge={`Remove ${fields.length === 1 ? fields[0].label : fields.length}`}
                                            challengeLabel="Field count"
                                            onConfirm={handleConfirm}
                                            ConfirmButtonProps={{
                                              children: `Remove field${fields.length > 1 ? 's' : ''}`,
                                              color: 'error'
                                            }}/>, true);
          e.preventDefault();
        }
      });
    }

    return actions;
  }, [
    snackbar, dialogControl, fields, clientId, mdDown,
    listSelection.count, clearSelectionEvent, deleteField.mutation
  ]);

  const renderTitle = () => {
    const count = utils.formatNumber(listSelection.count ?? 0);
    return `${count} field${listSelection.count > 1 ? 's' : ''} selected`;
  }

  return <StyledFieldsActionbar ref={ref} {...innerProps}
                               title={renderTitle()}
                               actionItems={actionItems} />
});

FieldsActionbar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  listSelection: PropTypes.object,
  data: PropTypes.array
};

FieldsActionbar.defaultProps = {};

export default FieldsActionbar;
