import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledTimelinePanel = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  ${props => props.theme.breakpoints.down('sm')} {
    width: unset;
  }
  
  .TimelinePanel {
    position: relative;

    &-feed {
      height: 100%;
      margin-right: 0;

      .DataList-list {
        width: 100%;
        overflow-x: hidden;
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
        overscroll-behavior: contain;
        
        .TimelineCard-comment {
          .TimelineCard-content {
            background-color: ${props => props.theme.property('palette.alert.infoFill')};
          }
        }
      }
      
      &:has(.DataList-empty) {
        .DataList-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .TimelinePanel{
      &-feed {
      .DataList-list {
        padding: ${props => props.theme.spacing(2)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTimelinePanel.propTypes = {
  theme: PropTypes.object
};

StyledTimelinePanel.defaultProps = {}

export default StyledTimelinePanel;
