import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledProgressTooltip from 'components/molecules/Tooltips/ProgressTooltip/ProgressTooltip.styles';
import Box from 'components/atoms/Layout/Box/Box';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import Typography from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';

const ProgressTooltip = React.forwardRef((props, ref) => {
  const {
    progress,
    ...innerProps
  } = useComponentProps(props, 'ProgressTooltip');

  return <StyledProgressTooltip ref={ref} {...innerProps}>
    {progress?.map((item, idx) => {
      return <Box key={idx} className="ProgressTooltip-item">
        <Box>
          <Typography variant="caption">
            {item.label}
          </Typography>
          <Typography variant="caption">
            {item.value}%
          </Typography>
        </Box>
        <Box>
          <LinearProgress variant="determinate" color="primary" value={item.value}/>
          {item.msg ? <Typography variant="caption">
            ({item.msg})
          </Typography> : null}
          {!item.msg && utils.isDefined(item.eta) ? <Typography variant="caption">
            {item.eta !== 0 ? ` (${utils.timeAgo(new Date(Date.now() + item.eta))})` : ' (finished)'}
          </Typography> : null}
        </Box>
      </Box>
    })}
  </StyledProgressTooltip>
});

ProgressTooltip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  progress: PropTypes.array
};

ProgressTooltip.defaultProps = {
};

export default ProgressTooltip;
