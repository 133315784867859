import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useOptimistic} from 'helpers/hooks/utils';
import StyledSearchDialog from 'components/organisms/Dialogs/SearchDialog/SearchDialog.styles';
import DialogTitle from 'components/atoms/Dialogs/DialogTitle/DialogTitle';
import SearchForm from 'components/organisms/Forms/SearchForm/SearchForm';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import ActionTabs from 'components/molecules/Tabs/ActionTabs/ActionTabs';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Badge from 'components/atoms/Badges/Badge/Badge';
import utils from 'helpers/utils';
import {useEntityQuickSearch} from 'services/entity/entity.hooks';
import Table from 'components/organisms/Tables/Table/Table';
import FolderSharp from '@mui/icons-material/FolderSharp';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import {useEntityCsi} from 'services/entity/entity.utils';
import DialogActions from 'components/atoms/Dialogs/DialogActions/DialogActions';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useCollectionList} from 'services/collection/collection.hooks';
import ButtonTableCell from 'components/molecules/TableCells/ButtonTableCell/ButtonTableCell';
import Add from '@mui/icons-material/Add';
import Logo from 'components/atoms/Logos/Logo/Logo';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import LinearProgress from 'components/atoms/Progress/LinearProgress/LinearProgress';
import DealflowStatusTableCell from 'components/molecules/TableCells/DealflowStatusTableCell/DealflowStatusTableCell';
import DealflowBarChartTableCell from 'components/molecules/TableCells/DealflowBarChartTableCell/DealflowBarChartTableCell';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {useClientUserList} from 'services/client/user/user.hooks';
import UserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import CollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog';
import {useCollectionEntityToggle} from 'services/collection/entity/entity.utils';
import ChipListTableCell from 'components/molecules/TableCells/ChipListTableCell/ChipListTableCell';
import CollectionVisibilityTableCell
  from 'components/molecules/TableCells/CollectionVisibilityTableCell/CollectionVisibilityTableCell';
import constants from 'helpers/constants';
import {useAuthClientId} from 'services/auth/auth.utils';
import useMediaQuery from '@mui/material/useMediaQuery';

const SearchDialog = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'SearchDialog');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const [search, setSearch] = useState('');
  const [tabIdx, setTabIdx] = useState(0);
  const [entitiesState, setEntitiesState] = useState({entities: [], depth: 1, done: true});

  const maxDepthEntities = 3;

  const clientId = useAuthClientId();
  const authorize = useAuthorize();
  const dialogControl = useDialogControl();

  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const entitiesQuickSearch = useEntityQuickSearch({
    search: search,
    depth: entitiesState.depth
  }, {
    enabled: search?.length > 0 && authorize({attribute: 'search.entity'})
  });

  const collectionsQuickSearch = useCollectionList({
    page: 0,
    pageSize: 25,
    search: search
  }, {
    enabled: search?.length > 0 && authorize({attribute: 'search.collection'}),
    ...constants.queryOptions.infinite
  });

  const usersQuickSearch = useClientUserList({
    clientId,
    page: 0,
    pageSize: 25,
    search: search
  }, {
    enabled: search?.length > 0 && authorize({attribute: 'search.user'}),
    ...constants.queryOptions.infinite
  });

  const loadingEntities = entitiesState.entities?.length === 0 && !entitiesState.done;
  const loadingCollections = collectionsQuickSearch.status.isLoading || !collectionsQuickSearch.data;
  const loadingUsers = usersQuickSearch.status.isLoading || !usersQuickSearch.data;
  const showProgressBar = (tabIdx === 0 && !loadingEntities && !entitiesState.done) ||
    (tabIdx === 1 && !loadingCollections && collectionsQuickSearch.status.isLoadingNext) ||
    (tabIdx === 2 && !loadingUsers && usersQuickSearch.status.isLoadingNext);

  const handleChange = (value) => {
    if (value?.length > 0) {
      setEntitiesState((current) => {
        return {...current, depth: 1, done: false};
      });
    }
    setSearch(value);
  }

  const entityColumns = useMemo(() => {
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Company name',
      enableEditing: false,
      Cell: ({cell}) => {
        const entity = cell.row.original;

        return <TextTableCell title={entity.name || entity.entityId}
                              logo={<Logo logo={entity.logoUrl}
                                          altLogo={entity.altLogoUrl}
                                          fallbackIcon={HomeWorkOutlined}
                                          density="sparse"
                                          outlined={true}/>}/>
      },
    });

    if (!mdDown) {
      columns.push({
        accessorKey: 'collections',
        id: 'collections',
        header: 'Collections',
        size: 200,
        enableEditing: false,
        Cell: ({cell}) => {
          const originalCollections = [...cell.row.original.collections];
          const toggleEntity = useCollectionEntityToggle();
          const [collections, toggleEntityOptimistic] = useOptimistic(originalCollections, toggleEntity);

          const entityId = cell.row.original.entityId;
          const actions = [];

          collections
            .sort((a, b) => (new Date(b.addedAt)).getTime() - (new Date(a.addedAt)).getTime())
            .forEach((c) => {
              actions.push({
                label: c.name,
                tooltip: c.name,
                icon: FolderSharp,
                navigation: {
                  to: `/collections/${c.collectionId}/entities`
                },
                onClick: (e) => {
                  innerRef.current?.close?.(e);
                },
                ChipProps: {
                  selected: false
                }
              });
            });

          const addAction = {
            label: 'Add to collection',
            tooltip: 'Add to collection',
            icon: Add,
            auth: utils.createAuth({attribute: 'collection.update'}),
            onClick: (e) => {
              const handleChange = (collection, selection, remove) => {
                return toggleEntityOptimistic(selection, collection.collectionId, entityId, remove);
              };

              dialogControl.show(<CollectionSelectionDialog title="Add to collection"
                                                            subtitle="For 1 company"
                                                            collections={collections}
                                                            hideCollections={true}
                                                            onChange={handleChange}/>, true);
              e.preventDefault();
            }
          };

          if (addAction && collections.length === 0) {
            return <ButtonTableCell ActionButtonProps={{
              variant: 'text',
              color: 'primary',
              size: 'medium',
              action: addAction
            }}/>
          } else {
            return <ChipListTableCell action={addAction}
                                      ActionChipListProps={{
                                        variant: 'compact',
                                        nowrap: true,
                                        actions,
                                        ActionChipProps: {
                                          variant: 'outlined',
                                          color: 'primary',
                                          size: 'medium'
                                        }
                                      }}/>
          }
        }
      });
    }

    if (!xsDown) {
      columns.push({
        accessorKey: 'dealflow',
        header: 'Dealflow',
        size: 200,
        Cell: ({cell}) => {
          const entity = cell.row.original;
          const csi = useEntityCsi(entity);
          const statusId = cell.column.columnDef.optimistic.get(cell, csi?.status ?? 0);

          return <DealflowStatusTableCell statusId={statusId}
                                          size="medium"/>
        }
      });
    }

    return columns;
  }, [dialogControl, xsDown, mdDown]);

  const collectionColumns = useMemo(() => {
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Collection name',
      Cell: ({cell}) => {
        const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        return <TextTableCell title={name}
                              icon={<Icon icon={FolderSharp} color="primary"/>}/>
      }
    });

    if (!mdDown) {
      columns.push({
        accessorKey: 'visibility',
        id: 'visibility',
        header: 'Privacy',
        size: 100,
        Cell: ({cell}) => {
          const visibility = cell.column.columnDef.optimistic.get(cell, cell.getValue());

          return <CollectionVisibilityTableCell visibility={visibility}
                                                size="medium"/>
        }
      });
    }

    if (!xsDown) {
      columns.push({
        accessorKey: 'dealflow',
        id: 'dealflow',
        header: 'Dealflow',
        size: 200,
        Cell: ({cell}) => {
          const total = +cell.row.original.entities;
          const dealflow = cell.getValue();

          return <DealflowBarChartTableCell total={total}
                                            dealflow={dealflow ?? []}/>
        }
      });
    }

    return columns;
  }, [xsDown, mdDown]);

  const userColumns = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        id: 'name',
        header: 'User name',
        Cell: ({cell}) => {
          return <UserTableCell user={cell.row.original} />
        }
      }
    ];
  }, []);

  useEffect(() => {
    if (utils.isDefined(entitiesQuickSearch.data)) {
      setEntitiesState((current) => {
        const entities = utils.uniqueArray(((current.depth === 1) ? [] :
          current.entities?.map((entity) => {
            return entitiesQuickSearch.data?.find((e) => +e.entityId === +entity.entityId) ?? entity;
          }))
          .concat(entitiesQuickSearch.data), 'entityId');

        const done = current.depth >= maxDepthEntities;
        const depth = !done ? current.depth + 1 : current.depth;

        return {
          entities,
          depth,
          done
        };
      });
    }
  }, [entitiesQuickSearch.query?.queryKey, entitiesQuickSearch.data, entitiesQuickSearch.meta?.resultsCount, maxDepthEntities]);

  const tabs = [];
  if (search?.length > 0) {
    tabs.push({
      tab: {
        label: 'Companies',
        auth: utils.createAuth({attribute: 'search.entity'}),
        badge: <Badge isLoading={loadingEntities}
                      badgeContent={entitiesState.entities?.length ?? 0}
                      color={entitiesState.entities?.length > 0 ? 'primary' : 'light'}/>
      },
      action: {
        label: 'Show all',
        navigation: {
          to: '/database?search=' + search,
          resetSearchParams: true,
          keepSearchParams: false
        },
        onClick: (e) => {
          innerRef.current?.close?.(e);
        }
      }
    });
    tabs.push({
      tab: {
        label: 'Collections',
        auth: utils.createAuth({attribute: 'search.collection'}),
        badge: <Badge badgeContent={collectionsQuickSearch.meta?.resultsCount ?? 0}
                      color={collectionsQuickSearch.meta?.resultsCount > 0 ? 'primary' : 'light'}/>
      },
      action: {
        label: 'Show all',
        navigation: {
          to: '/collections?search=' + search,
          resetSearchParams: true,
          keepSearchParams: false
        },
        onClick: (e) => {
          innerRef.current?.close?.(e);
        }
      }
    })
    tabs.push({
      tab: {
        label: 'Users',
        auth: utils.createAuth({attribute: 'search.user'}),
        badge: <Badge badgeContent={usersQuickSearch.meta?.resultsCount ?? 0}
                      color={usersQuickSearch.meta?.resultsCount > 0 ? 'primary' : 'light'}/>
      },
      action: {
        label: 'Show all',
        navigation: {
          to: '/users?search=' + search,
          resetSearchParams: true,
          keepSearchParams: false
        },
        onClick: (e) => {
          innerRef.current?.close?.(e);
        }
      }
    })
  }

  useEffect(() => {
    setTabIdx((current) => {
      return current > (tabs.length - 1) ? 0 : current;
    });
  }, [tabs.length]);

  const handleTabChange = (e, value) => {
    setTabIdx(value);
  }

  const navigate = useLinkNavigate();

  const renderEntityTable = () => {
    const handleCanRowClick = (entity) => {
      return utils.isDefined(entity.entityId) && authorize({attribute: 'database.profile.read', meta: {entity}});
    }

    const handleRowClick = (e, entity) => {
      innerRef.current?.close?.(e);
      navigate({
        event: e,
        to: `/database/${entity.entityId}?custom=entity:${entity.entityId}`,
        resetSearchParams: true
      });
    }

    return <Table key="entities-table"
                  className="SearchDialog-table"
                  dataKey="entityId"
                  onCanRowClick={handleCanRowClick}
                  onRowClick={handleRowClick}
                  enableTableHead={false}
                  enableParentScroll={false}
                  enableColumnVirtualization={false}
                  columns={entityColumns}
                  data={entitiesState.entities}
                  rowCount={entitiesState.entities?.length}
                  state={{
                    isLoading: loadingEntities,
                    columnOrder: ['name', 'collections', 'dealflow'],
                    pagination: {
                      pageIndex: 0,
                      pageSize: entitiesState.entities?.length || 8
                    }
                  }} />
  }

  const renderCollectionTable = () => {
    const handleCanRowClick = (collection) => {
      return utils.isDefined(collection.collectionId) && authorize({attribute: 'collection.read', meta: {collection}});
    }

    const handleRowClick = (e, collection) => {
      innerRef.current?.close?.(e);
      navigate({
        event: e,
        to: `/collections/${collection.collectionId}/entities`
      });
    }

    return <Table key="collections-table"
                  className="SearchDialog-table"
                  dataKey="collectionId"
                  onCanRowClick={handleCanRowClick}
                  onRowClick={handleRowClick}
                  enableTableHead={false}
                  enableParentScroll={false}
                  enableColumnVirtualization={false}
                  columns={collectionColumns}
                  data={collectionsQuickSearch.data}
                  onFetchMore={collectionsQuickSearch.query.fetchNextPage}
                  onRefetch={collectionsQuickSearch.query.refetch}
                  rowCount={collectionsQuickSearch.meta?.resultsCount}
                  state={{
                    isLoading: loadingCollections,
                    columnOrder: ['name', 'visibility', 'dealflow'],
                    pagination: {
                      pageIndex: 0,
                      pageSize: collectionsQuickSearch.data?.length || 8
                    }
                  }} />
  }

  const renderUserTable = () => {
    const handleCanRowClick = (user) => {
      return utils.isDefined(user.userId) ?? authorize({attribute: 'user.read', meta: {user}});
    }

    const handleRowClick = (e, user) => {
      innerRef.current?.close?.(e);
      navigate({
        event: e,
        to: `/users/${user.userId}?custom=entity:${user.userId}`,
        resetSearchParams: true
      });
    }

    return <Table key="users-table"
                  className="SearchDialog-table"
                  dataKey="userId"
                  onCanRowClick={handleCanRowClick}
                  onRowClick={handleRowClick}
                  enableTableHead={false}
                  enableParentScroll={false}
                  enableColumnVirtualization={false}
                  columns={userColumns}
                  data={usersQuickSearch.data}
                  onFetchMore={usersQuickSearch.query.fetchNextPage}
                  onRefetch={usersQuickSearch.query.refetch}
                  rowCount={usersQuickSearch.meta?.resultsCount}
                  state={{
                    isLoading: loadingUsers,
                    columnOrder: ['name'],
                    pagination: {
                      pageIndex: 0,
                      pageSize: usersQuickSearch.data?.length || 8
                    }
                  }} />
  }

  const renderTable = () => {
    switch (tabIdx) {
      case 1:
        return renderCollectionTable();
      case 2:
        return renderUserTable();
      default:
        return renderEntityTable()
    }
  }

  const hasContent = tabs?.length > 0;

  innerProps.fullWidth = innerProps.fullWidth ?? true;
  innerProps.maxWidth = innerProps.maxWidth ?? 'md';

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledSearchDialog ref={innerRef} {...innerProps} $hasContent={hasContent}>
    <DialogTitle className="SearchDialog-title">
      <SearchForm placeholder={'Search for company, collection or user'}
                  keepFocus={!hasContent}
                  onChange={handleChange}/>
      {hasContent ? <ActionTabs className="SearchDialog-tabs"
                                onChange={handleTabChange}
                                actions={tabs?.map((t) => t.tab)} /> : null}
    </DialogTitle>

    {hasContent ? <DialogContent className="SearchDialog-content" dividers>{renderTable()}</DialogContent> : null}
    {hasContent ? <DialogActions className="SearchDialog-actions">
      {<LinearProgress className={`SearchDialog-progressBar ${!showProgressBar ? 'hide' : ''}`}/>}
      <ActionButton action={tabs?.[tabIdx]?.action} variant="text"/>
    </DialogActions> : null}
  </StyledSearchDialog>
});

SearchDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

SearchDialog.defaultProps = {
  children: 'SearchDialog text'
};

export default SearchDialog;
