import React, {useEffect, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledChip from 'components/atoms/Chips/Chip/Chip.styles';
import utils from 'helpers/utils';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import dom from 'helpers/dom';
import Close from '@mui/icons-material/Close';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {withMemo} from 'helpers/wrapper';
import Component from 'components/organisms/Utils/Component/Component';

const Chip = withMemo(React.forwardRef((props, ref) => {
  const {
    icon,
    badge,
    tooltip,
    fullWidth,
    autoFocus,
    isLoading,
    showTooltip,
    onClick,
    onDelete,
    disabled,
    radius,
    BadgeProps,
    TooltipProps,
    ...innerProps
  } = useComponentProps(props, 'Chip', {
    static: ['disabled', 'clickable', 'deletable', 'selected', 'fullWidth']
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        if (!dom.isPartOfParent(document.activeElement, innerRef.current)) {
          return dom.focusElement(innerRef.current);
        }
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const variant = innerProps.variant === 'transparent' ? 'filled' : innerProps.variant;
  const clickable = Boolean(onClick);
  const deletable = Boolean(onDelete);

  const handleKeyDown = (e) => {
    if (e.target === innerRef.current && onClick) {
      if (e.code === 'Space' || (e.code === 'Enter' && !dom.isNativeButton(e.target))) {
        e.target?.click();
        e.preventDefault();
      }
    }
  };

  const renderIcon = () => {
    if (icon) {
      if (badge) {
        return <Component Original={badge}
                          className="Chip-badge"
                          variant="dot"
                          size="small"
                          disabled={innerProps.disabled}
                          isLoading={isLoading}
                          {...BadgeProps}>
          {utils.isReactElement(icon) ? icon : <Icon icon={icon}/>}
        </Component>;
      } else {
        return icon;
      }
    } else {
      return null;
    }
  }

  innerProps.deleteIcon = innerProps.deleteIcon ?? <Icon icon={Close} />;
  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: clickable,
    deletable: deletable
  });

  const renderChip = () => {
    return <Tooltip title={showTooltip ? (tooltip ?? innerProps.label) : null}
                    fullWidth={fullWidth}
                    showDisabled={true}
                    placement="bottom"
                    {...TooltipProps}>
      <StyledChip ref={innerRef} {...innerProps}
                  variant={variant}
                  icon={renderIcon()}
                  disabled={disabled}
                  onClick={onClick}
                  onDelete={onDelete}
                  tabIndex={(!disabled && (clickable || deletable)) ? 0 : -1}
                  clickable={clickable}
                  onKeyDown={handleKeyDown}/>
    </Tooltip>
  }

  if (isLoading) {
    return <Skeleton variant="rounded">{renderChip()}</Skeleton>
  } else {
    return renderChip();
  }
}));

Chip.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.any,
  badge: PropTypes.any,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  tooltip: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small', 'large']) || PropTypes.string,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['filled', 'outlined', 'transparent']), PropTypes.string]),
  disabled: PropTypes.bool,
  skipFocusWhenDisabled: PropTypes.bool,
  clickable: PropTypes.bool,
  isLoading: PropTypes.bool,
  showTooltip: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  BadgeProps: PropTypes.object,
  TooltipProps: PropTypes.object
};

Chip.defaultProps = {
  label: 'Chip text',
  size: 'medium',
  color: 'default',
  variant: 'filled',
  disabled: false,
  showTooltip: true,
  skipFocusWhenDisabled: undefined,
};

export default Chip;
