import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import DatePicker from 'components/atoms/Inputs/DatePicker/DatePicker';

const StyledDateField = styled(DatePicker)`
  .DateField-readOnly {
    &:has(.placeholder) {
      color: ${props => props.theme.property('palette.text.disabled')};
    }
  }
  
  .MuiIconButton-root {
    margin-right: 0;
  }
  
  .MuiOutlinedInput-root {
    .MuiIconButton-root {
      margin-right: ${props => props.theme.layout(-4)};
    }
  }
  .MuiFilledInput-root {
    .MuiIconButton-root {
      margin-right: ${props => props.theme.layout(-2)};
    }
  }
  .MuiInput-underline {
    .MuiIconButton-root {
      margin-right: ${props => props.theme.layout(2)};
    }
  }
  
  ${ComponentStyles}
`;

StyledDateField.propTypes = {
  theme: PropTypes.object
};

StyledDateField.defaultProps = {
}

export default StyledDateField;
