import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledApp = styled(Box)`
  color: ${props => props.theme.property('palette.text.primary')};
  background-color: ${props => props.theme.property('palette.background.paper')};

  .notistack-SnackbarContainer {
    width: unset; !important;

    margin-right: -${props => props.theme.layout('0.5sp')};
    margin-top: -${props => props.theme.layout('0sp')};
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .notistack-SnackbarContainer {
      margin-left: -${props => props.theme.layout('0.75sp')});
      margin-top: -${props => props.theme.layout('0.5sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledApp.propTypes = {
  theme: PropTypes.object
};

StyledApp.defaultProps = {
}

export default StyledApp;
