import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import Cards from 'components/atoms/Cards/Cards/Cards';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import StyledCollectionEntitiesGraphsPage
  from 'components/pages/Collection/Entity/Graph/CollectionEntitiesGraphsPage/CollectionEntitiesGraphsPage.styles';
import CollectionEntitiesTasksGraphCard
  from 'components/organisms/Cards/CollectionEntitiesTasksGraphCard/CollectionEntitiesTasksGraphCard';
import CollectionEntitiesTaskBreakdownGraphCard
  from 'components/organisms/Cards/CollectionEntitiesTaskBreakdownGraphCard/CollectionEntitiesTaskBreakdownGraphCard';
import CollectionEntitiesDealflowBreakdownGraphCard
  from 'components/organisms/Cards/CollectionEntitiesDealflowBreakdownGraphCard/CollectionEntitiesDealflowBreakdownGraphCard';
import CollectionEntitiesCompletenessGraphCard
  from 'components/organisms/Cards/CollectionEntitiesCompletenessGraphCard/CollectionEntitiesCompletenessGraphCard';
import CollectionEntitiesTagDistributionGraphCard
  from 'components/organisms/Cards/CollectionEntitiesTagDistributionGraphCard/CollectionEntitiesTagDistributionGraphCard';
import CollectionEntitiesRecentChangesGraphCard
  from 'components/organisms/Cards/CollectionEntitiesRecentChangesGraphCard/CollectionEntitiesRecentChangesGraphCard';
import CollectionEntitiesRelevancyDistributionGraphCard
  from 'components/organisms/Cards/CollectionEntitiesRelevancyDistributionGraphCard/CollectionEntitiesRelevancyDistributionGraphCard';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';

const CollectionEntitiesGraphsPage = (props) => {
  const innerProps = useComponentProps(props, 'CollectionEntitiesGraphsPage');

  const tableProvider = useTable();
  const graphDefinitions = tableProvider.graphDefinitions;

  const graphs = useMemo(() => {
    const graphs = [];
    if (graphDefinitions) {
      graphDefinitions
        .sort((a, b) => a.position - b.position)
        .forEach((graphDef) => {
          if (graphDef.name === 'dealflowBreakdown') {
            graphs.push({
              ...graphDef,
              anchor: 'left',
              span: 10,
              content: <CollectionEntitiesDealflowBreakdownGraphCard className="CollectionEntitiesGraphsPage-dealflowBreakdown"/>
            });
          } else if (graphDef.name === 'completeness') {
            graphs.push({
              ...graphDef,
              anchor: 'right',
              span: 14,
              content: <CollectionEntitiesCompletenessGraphCard className="CollectionEntitiesGraphsPage-completeness"/>
            });
          } else if (graphDef.name === 'recentChanges') {
            graphs.push({
              ...graphDef,
              anchor: 'left',
              span: 15,
              content: <CollectionEntitiesRecentChangesGraphCard className="CollectionEntitiesGraphsPage-recentChanges"/>
            });
          } else if (graphDef.name === 'tagDistribution') {
            graphs.push({
              ...graphDef,
              anchor: 'right',
              span: 9,
              content: <CollectionEntitiesTagDistributionGraphCard className="CollectionEntitiesGraphsPage-tagDistribution"/>
            });
          } else if (graphDef.name === 'relevancyDistribution') {
            graphs.push({
              ...graphDef,
              anchor: 'left',
              span: 12,
              content: <CollectionEntitiesRelevancyDistributionGraphCard className="CollectionEntitiesGraphsPage-relevancyDistribution"/>
            });
          } else if (graphDef.name === 'tasks') {
            const openTasks = graphs.find((g) => g.name === 'openTasks');
            if (openTasks) {
              openTasks.fit = false;
              openTasks.content = <Box className="CollectionEntitiesGraphsPage-tasks-wrapper">
                <CollectionEntitiesTaskBreakdownGraphCard className="CollectionEntitiesGraphsPage-tasks"/>
                {openTasks.content}
              </Box>
            } else {
              graphs.push({
                ...graphDef,
                anchor: 'right',
                span: 12,
                fit: true,
                content: <CollectionEntitiesTaskBreakdownGraphCard className="CollectionEntitiesGraphsPage-tasks"/>
              });
            }
          } else if (graphDef.name === 'openTasks') {
            const tasks = graphs.find((g) => g.name === 'tasks');
            if (tasks) {
              tasks.fit = false;
              tasks.content = <Box className="CollectionEntitiesGraphsPage-tasks-wrapper">
                {tasks.content}
                <CollectionEntitiesTasksGraphCard className="CollectionEntitiesGraphsPage-openTasks"/>
              </Box>
            } else {
              graphs.push({
                ...graphDef,
                anchor: 'right',
                span: 12,
                content: <CollectionEntitiesTasksGraphCard className="CollectionEntitiesGraphsPage-openTasks"/>
              });
            }
          }
        });
    }

    return graphs;
  }, [graphDefinitions]);

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const columns = !mdDown ? 24 : 1;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntitiesGraphsPage {...innerProps}>
    <Cards className="CollectionEntitiesGraphsPage-cards"
           columns={columns} gap={!lgDown ? 24 : 16}>
      {graphs?.map((graph, idx) => {
        return <CardItem key={graph.name}
                         data-key={graph.name}
                         className="CollectionEntitiesGraphsPage-card"
                         {...utils.filterObject(graph, ['anchor', 'span', 'rows', 'grow', 'fit'], false)}>
          <Debounce key={graph.name}
                    timeout={constants.debounce.minimal * Math.floor(idx / 2)}>
            {graph.content}
          </Debounce>
        </CardItem>
      })}
    </Cards>
  </StyledCollectionEntitiesGraphsPage>
};

CollectionEntitiesGraphsPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionEntitiesGraphsPage.defaultProps = {
};

export default CollectionEntitiesGraphsPage;
