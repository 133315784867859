import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledSearchForm = styled(Box)`
  display: flex;
  align-items: center;
  height: ${props => props.theme.layout(36)};
  
  .SearchForm {
    &-button {
      margin-left: ${props => props.theme.layout('1sp')};
    }
    &-form {
      .FormControl {
        flex-grow: 1;
        .Input {
          margin: 0;
          font-size: ${props => props.theme.fontSize(14)};
          .MuiInput-input {
            padding: 0;
          }
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledSearchForm.propTypes = {
  theme: PropTypes.object
};

StyledSearchForm.defaultProps = {}

export default StyledSearchForm;
