import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledClientConnectionProfileCard = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;

  transition: ${props => props.theme.transitions.create(['height'], {
    easing: props.theme.property('transitions.easing.easeOut'),
    duration: props.theme.property('transitions.duration.leavingScreen')
  })} !important;

  .ProfileCardContent {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  .ProfileCard {
    &-header {
      position: absolute;
      width: 100%;
      z-index: 1;
      pointer-events: none;

      .MuiCardHeader-action {
        display: flex;
        flex-direction: column-reverse;
        gap: ${props => props.theme.layout('0.5sp')};
        pointer-events: all;
      }
    }
  }

  &.ClientConnectionProfileCard {
    &:hover {
      .ConnectionCard-title {
        width: calc(100% - ${props => props.theme.spacing(3)});
      }
    }
  }

  &.ClientConnectionProfileCard-isDeleted {
    visibility: hidden;
  }
  
  ${ComponentStyles}
`;

StyledClientConnectionProfileCard.propTypes = {
  theme: PropTypes.object
};

StyledClientConnectionProfileCard.defaultProps = {
}

export default StyledClientConnectionProfileCard;
