import React, {useLayoutEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import IconButton from 'components/atoms/Buttons/IconButton/IconButton';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';
import StyledIconField from 'components/molecules/Fields/IconField/IconField.styles';

const IconField = withMemo(React.forwardRef((props, ref) => {
  const {
    value,
    iconSet,
    groupSize,
    orientation,
    IconButtonProps,
    IconProps,
    ListProps,
    ...innerProps
  } = useComponentProps(props, 'IconField');

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const options = useMemo(() => {
    if (utils.isString(iconSet)) {
      const icons = constants.icons[iconSet];
      return icons ? Object.keys(icons).map((icon) => ({
        value: icon,
        label: icon,
        icon: icons[icon]
      })) : [];
    } else {
      return iconSet;
    }
  }, [iconSet]);

  const renderOption = (option, props) => {
    const handleClick = () => {
      props.onChange?.({
        target: {
          name: props.name,
          checked: !props.checked
        }
      })
    };

    return <IconButton variant="outlined"
                       color={option.value}
                       selected={props.checked}
                       disabled={props.disabled}
                       onClick={handleClick}
                       {...IconButtonProps}>
      <Icon icon={option.icon} {...IconProps} />
    </IconButton>
  };

  const renderReadOnlyOption = (option) => {
    return <IconButton variant="outlined"
                       color={option.value}
                       disabled={true}
                       {...IconButtonProps}>
      <Icon icon={option.icon}
            color={option.value}
            {...IconProps} />
    </IconButton>
  };

  useLayoutEffect(() => {
    const optIdx = options?.findIndex((opt) => opt.value === (value?.value ?? value) || opt.label === (value?.value ?? value));
    if (optIdx !== -1) {
      setSelectedIndex(optIdx);
    }
  }, [value, options]);

  const currentIndex = useMemo(() => {
    const optIdx = options?.findIndex((opt) => opt.value === (value?.value ?? value) || opt.label === (value?.value ?? value));
    if (optIdx !== -1) {
      return selectedIndex !== -1 ? selectedIndex : optIdx;
    } else {
      return selectedIndex;
    }
  }, [selectedIndex, value, options]);

  const handleSelectionChange = (e, idx) => {
    setSelectedIndex(idx);
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledIconField ref={ref} {...innerProps}
                          value={value}
                          options={options}
                          $columns={innerProps.columns}
                          $rows={innerProps.rows}
                          $minWidth={innerProps.minWidth}
                          renderOption={renderOption}
                          renderReadOnlyOption={renderReadOnlyOption}
                          ListProps={{
                            orientation,
                            selectedIndex: currentIndex,
                            onSelectionChange: handleSelectionChange,
                            columns: innerProps.columns,
                            rows: innerProps.rows,
                            ...ListProps
                          }}/>
}));

IconField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  value: PropTypes.any,
  iconSet: PropTypes.any,
  groupSize: PropTypes.number,
  orientation: PropTypes.string,
  ColorIconButtonProps: PropTypes.object,
  ColorIconProps: PropTypes.object,
  ListProps: PropTypes.object
};

IconField.defaultProps = {
  columns: 4,
  rows: 4,
  orientation: 'horizontal'
};

export default IconField;
