import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useEntityCommentCreate} from 'services/entity/comment/comment.utils';
import {useEntityCommentMarkAllRead} from 'services/entity/comment/comment.hooks';
import {useEntityTimelineList} from 'services/entity/timeline/timeline.hooks';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import StyledEntityTimelinePanel from 'components/organisms/Panels/EntityTimelinePanel/EntityTimelinePanel.styles';
import {useTimelineMarkAllRead} from 'services/client/timeline/timeline.hooks';
import {useAuthClientId, useAuthUserId} from 'services/auth/auth.utils';

const EntityTimelinePanel = React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    mentioned,
    commentsOnly,
    TimelineListProps,
    ...innerProps
  } = useComponentProps(props, 'EntityTimelinePanel');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const clientId = useAuthClientId();
  const userId = useAuthUserId();

  const entityCommentCreate = useEntityCommentCreate();
  const entityCommentAllRead = useEntityCommentMarkAllRead();
  const entityTimelineAllRead = useTimelineMarkAllRead();

  const timeline = useEntityTimelineList({
    entityId: entity?.originalEntityId ?? entity?.entityId,
    collectionContextId: collection?.collectionId,
    page: 0,
    pageSize: 15,
    filter: [
      {id: 'mentioned', value: mentioned},
      {id: 'excludedTypes', value: ['task']
          .concat(commentsOnly ? ['evidence', 'profile', 'status', 'deal_leader', 'csi'] : [])},
    ]
  }, {
    ...constants.queryOptions.infinite,
    enabled: Boolean(entity?.originalEntityId ?? entity?.entityId)
  });

  useEffect(() => {
    const scroll = () => {
      return innerRef.current?.querySelector('.DataList-list')?.scrollTo({top: 0, left: 0});
    }

    utils.retry(scroll, 3);
  }, [entity?.entityId]);

  useEffect(() => {
    const unreadComments = timeline.data?.filter?.((ti) => ti.recordType === 'comment' && ti.isRead === false)
    if (unreadComments?.length > 0) {
      entityCommentAllRead.mutation.mutate({
        entityId: entity?.entityId,
        commentIds: unreadComments.map((d) => d.commentId)
      });
    }
    const unreadTasks = timeline.data?.filter?.((ti) => ti.relationType === 'task' && +ti.userId === +userId && ti.isRead === false)
    if (unreadTasks?.length > 0) {
      entityTimelineAllRead.mutation.mutate({
        clientId: clientId,
        entityId: entity?.entityId,
        taskIds: unreadTasks.map((d) => d.relationId)
      });
    }
  }, [clientId, userId, entityCommentAllRead.mutation, entityTimelineAllRead.mutation, timeline.data, entity?.entityId]);

  const handleSubmit = (value) => {
    return entityCommentCreate(value, [entity?.entityId], collection?.collectionId);
  };

  const TimelineListPropsMemo = useMemo(() => {
    return utils.mergeObjects({
      TimelineCardProps: {
        isEntity: true
      }
    }, TimelineListProps)
  }, [TimelineListProps]);

  return <StyledEntityTimelinePanel ref={innerRef} {...innerProps}
                                    timeline={timeline}
                                    TimelineListProps={TimelineListPropsMemo}
                                    onSubmit={handleSubmit} />
});

EntityTimelinePanel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  collection: PropTypes.object,
  mentioned: PropTypes.bool,
  commentsOnly: PropTypes.bool,
  TimelineListProps: PropTypes.object
};

EntityTimelinePanel.defaultProps = {};

export default EntityTimelinePanel;
