import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import StyledPopperField from 'components/molecules/Fields/PopperField/PopperField.styles';
import FormControlLabel from 'components/atoms/Labels/FormControlLabel/FormControlLabel';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import dom from 'helpers/dom';
import DropdownPopper from 'components/molecules/Poppers/DropdownPopper/DropdownPopper';
import Button from 'components/atoms/Buttons/Button/Button';
import Box from 'components/atoms/Layout/Box/Box';
import {withMemo} from 'helpers/wrapper';

const PopperField = withMemo(React.forwardRef((props, ref) => {
  const {
    id,
    name,
    label,
    value,
    size,
    placeholder,
    helperText,
    autoFocus,
    renderButton,
    onBlur,
    onChange,
    PopperComponent,
    FormFieldComponent,
    FormFieldProps,
    ButtonProps,
    PopperProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'PopperField', {
    static: ['disabled', 'focused', 'error'],
    children: ['popper', 'label', 'helper']
  });

  const innerRef = useRef(null);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (autoFocus) {
      const focus = () => {
        return dom.focusElement(innerRef.current?.querySelector?.('input'));
      }

      utils.retry(focus, 3);
    }
  }, [autoFocus]);

  const inlineLabel = innerProps.variant === 'inlineLabel';

  const handleChange = (e) => {
    onChange?.({
      target: {
        name: name,
        value: e?.target?.value
      }
    });
    setOpen(false);
  };

  const handleToggle = (open) => {
    setOpen(open);
  }

  const renderControlButton = () => {
    const handleClick  = () => {
      setOpen((current) => !current);
    };

    if (renderButton) {
      return renderButton(value, {
        ref: anchorRef,
        className: 'PopperField-button',
        readOnly: innerProps.readOnly,
        disabled: innerProps.disabled,
        open,
        success: innerProps.success,
        error: innerProps.error,
        onClick: handleClick
      });
    } else {
      return <Button ref={anchorRef}
                     label={value ?? placeholder}
                     className="PopperField-button"
                     color="secondary"
                     plainBorder={true}
                     variant="outlined"
                     size={size}
                     fullWidth={true}
                     active={open}
                     disabled={innerProps.readOnly}
                     onClick={handleClick}
                     {...ButtonProps}/>
    }
  };

  const renderContext = () => {
    return <FormFieldComponent {...utils.cleanObject({
                                 id: id,
                                 name: name,
                                 value: value,
                                 onChange: handleChange,
                                 onBlur: onBlur,
                                 autoFocus: autoFocus,
                                 size: size,
                                 placeholder: placeholder,
                                 hiddenLabel: true,
                                 hiddenHelperText: true,
                                 helperText: false,
                                 fullWidth: innerProps.fullWidth,
                                 readOnly: innerProps.readOnly,
                                 variant: innerProps.variant,
                               })}
                               {...FormFieldProps}/>
  };

  innerProps.focused = innerProps.focused ?? open;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledPopperField ref={innerRef} {...innerProps}>
    {(innerProps.readOnly || inlineLabel) ? <InputLabel size={size}
                                                        {...utils.cleanObject({
                                                          htmlFor: (!(innerProps.readOnly || innerProps.disabled) ? id : null),
                                                          shrink: innerProps.readOnly || innerProps.disabled || null
                                                        })}
                                                        className={utils.classNames('PopperField-label', InputLabelProps?.className)}
                                                        {...InputLabelProps}>
      {label}
    </InputLabel> : null}

    <InputContainer className="PopperField-container">
      {!innerProps.readOnly ? <FormControlLabel className={utils.classNames('PopperField-label', InputLabelProps?.className)}
                                                label={inlineLabel ? '' : label}
                                                control={<React.Fragment>
                                                  {renderControlButton()}
                                                  <PopperComponent className="PopperField-popper"
                                                                   anchorEl={anchorRef.current}
                                                                   open={open}
                                                                   onToggle={handleToggle}
                                                                   {...PopperProps}>
                                                    {renderContext()}
                                                  </PopperComponent>
                                                </React.Fragment>}
                                                {...InputLabelProps}/> : null}
      {innerProps.readOnly ? <Box className="PopperField-readOnly Input-readOnly">
        {renderControlButton()}
      </Box> : null}

      <FormHelperText component="div" {...FormHelperTextProps}
                      className={utils.classNames('PopperField-helper', FormHelperTextProps?.className)}>
        {helperText}
      </FormHelperText>
    </InputContainer>
  </StyledPopperField>
}));

PopperField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.string,
  helperText: PropTypes.any,
  autoFocus: PropTypes.bool,
  renderButton: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  PopperComponent: PropTypes.elementType,
  FormFieldComponent: PropTypes.elementType,
  FormFieldProps: PropTypes.object,
  ActionButtonProps: PropTypes.object,
  PopperProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

PopperField.defaultProps = {
  size: 'medium',
  PopperComponent: DropdownPopper
};

export default PopperField;
