import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntityProfileBar from 'components/organisms/Bars/EntityProfileBar/EntityProfileBar.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Edit from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import EntityHeading from 'components/molecules/Headings/EntityHeading/EntityHeading';
import Save from '@mui/icons-material/Save';
import useMediaQuery from '@mui/material/useMediaQuery';

const EntityProfileBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'EntityProfileBar');

  const profileProvider = useProfile();
  const tableProvider = useTable();

  const entity = profileProvider.data?.data;
  const isLoading = profileProvider.isLoading();

  const prevEntity = tableProvider.prev();
  const nextEntity = tableProvider.next();

  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const lsDown = useMediaQuery((theme) => theme.breakpoints.down('ls'));

  const editEvent = useEffectEvent(profileProvider?.edit);
  const submitEvent = useEffectEvent(profileProvider?.submit);
  const cancelEvent = useEffectEvent(profileProvider?.cancel);

  const prevAction = useMemo(() => ({
    tooltip: 'Previous company',
    icon: ArrowBack,
    keyboard: 'Arrow Left',
    auth: (prevEntity && !profileProvider.state.isDirty) ? null : utils.createAuth({attribute: 'system.null'}),
    navigation: {
      to: `../${prevEntity?.entityId}`,
      keepSearchParams: true
    },
    active: false,
    ActionIconButtonProps: {
      showInactive: true
    }
  }), [prevEntity, profileProvider.state.isDirty]);

  const nextAction = useMemo(() => ({
    tooltip: 'Next company',
    icon: ArrowForward,
    keyboard: 'Arrow Right',
    auth: (nextEntity && !profileProvider.state.isDirty) ? null : utils.createAuth({attribute: 'system.null'}),
    navigation: {
      to: `../${nextEntity?.entityId}`,
      keepSearchParams: true
    },
    active: false,
    ActionIconButtonProps: {
      showInactive: true
    }
  }), [nextEntity, profileProvider.state.isDirty]);

  const editAction = useMemo(() => ({
    label: !xsDown ? 'Edit company' : 'Edit',
    icon: Edit,
    auth: !profileProvider?.state.isEditing ?
      utils.createAuth({attribute: 'entity.update'}) :
      utils.createAuth({attribute: 'system.null'}),
    ActionButtonProps: {
      color: 'secondary',
      plainBorder: true,
      variant: 'outlined'
    },
    onClick: () => {
      editEvent?.();
    }
  }), [editEvent, profileProvider?.state.isEditing, xsDown]);

  const cancelAction = useMemo(() => ({
    label: 'Cancel',
    icon: Close,
    auth: profileProvider?.state.isEditing ?
      utils.createAuth({attribute: 'entity.update'}) :
      utils.createAuth({attribute: 'system.null'}),
    ActionButtonProps: {
      variant: 'text'
    },
    onClick: () => {
      cancelEvent?.();
    }
  }), [cancelEvent, profileProvider?.state.isEditing]);

  const saveAction = useMemo(() => ({
    label: !xsDown ? 'Save changes' : 'Save',
    icon: Save,
    auth: profileProvider?.state.isEditing ?
      utils.createAuth({attribute: 'entity.update'}) :
      utils.createAuth({attribute: 'system.null'}),
    ButtonProps: {
      disabled: profileProvider?.state.isSubmitting
    },
    onClick: () => {
      submitEvent?.();
    }
  }), [submitEvent, profileProvider?.state.isSubmitting, profileProvider?.state.isEditing, xsDown]);

  innerProps.title = innerProps.title ?? <EntityHeading entity={entity}
                                                        showName={!profileProvider?.state.isEditing || (!smDown && (!mdDown || lsDown))}
                                                        showLinks={!profileProvider?.state.isEditing}
                                                        isLoading={isLoading} />

  innerProps.rightActions = innerProps.rightActions ?? (!isLoading ? [
    cancelAction,
    saveAction,
    editAction,
    prevAction,
    nextAction
  ]: [
    prevAction,
    nextAction
  ]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntityProfileBar ref={ref} {...innerProps} />
});

EntityProfileBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

EntityProfileBar.defaultProps = {
};

export default EntityProfileBar;
