import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledSectionPanelContent = styled(ListWrapper)`
  height: 100%;
  
  .ListWrapper-content {
    padding: ${props => props.theme.spacing(1.25)} 0;
    overflow-y: auto;
    overscroll-behavior: contain;
  
    &:not(:hover, :focus) {
      ::-webkit-scrollbar {
        width: 0;
      }
    }
    
    > .List {
      > .ListItem {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('2sp')};
        
        padding-left: ${props => props.theme.spacing(3)};
        padding-right: ${props => props.theme.spacing(3)};
      }
    }
  }
  
  .ContextCard {
    width: 100%;
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .ListWrapper-content {
      > .List {
        > .ListItem {
          padding-left: ${props => props.theme.spacing(2)};
          padding-right: ${props => props.theme.spacing(2)};
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledSectionPanelContent.defaultProps = {}

export default StyledSectionPanelContent;
