import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledMappingForm = styled(Card)`
  width: 100%;
  padding: ${props => props.theme.spacing(1.5)};
  border: 1px solid ${props => props.theme.property('palette.divider')};
  
  .MappingForm {
    &-form {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('1.5sp')};
    }
    
    &-header {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
      align-items: flex-start;
      
      .Form-fields {
        flex-grow: 1;
        width: 1px;
        flex-wrap: nowrap;
        gap: ${props => props.theme.layout('1sp')};
        justify-content: space-between;
        
        .FormField-name-team {
          width: ${props => props.theme.layout(320)};
          .TextField {
            width: 100%;
          }
        }

        .FormField-name-synchroniseDeletedItems {
          .InputLabel, .FormLabel {
            white-space: nowrap;
            min-width: unset;
            max-width: unset;
            overflow: hidden;
            text-overflow: ellipsis;
            * {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    
    &-body {
      .Form-fields {
        .FormField-name-fields {
          padding: ${props => props.theme.spacing(1.5)};
          border: 1px solid ${props => props.theme.property('palette.divider')};
          border-radius: ${props => props.theme.radius(2)};

          > .InputLabel, > .FormLabel {
            ${props => props.theme.font2Css('subtitle2')};
            margin-bottom: calc(${props => props.theme.spacing(1)} - 1px);
          }
        }
      }
    }

    &-actions {
      display: flex;
      gap: ${props => props.theme.layout('1sp')};
    }
  }
  
  ${ComponentStyles}
`;

StyledMappingForm.propTypes = {
  theme: PropTypes.object
};

StyledMappingForm.defaultProps = {}

export default StyledMappingForm;
