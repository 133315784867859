import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Typography from 'components/atoms/Text/Typography/Typography';
import StyledQueryBar from 'components/organisms/Bars/QueryBar/QueryBar.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Link from 'components/atoms/Links/Link/Link';
import utils from 'helpers/utils';
import SimpleQuery from 'components/organisms/Queries/SimpleQuery/SimpleQuery';
import AdvancedQuery from 'components/organisms/Queries/AdvancedQuery/AdvancedQuery';
import ToggleButton from 'components/atoms/Toggles/ToggleButton/ToggleButton';
import ToggleButtonGroup from 'components/atoms/Toggles/ToggleButtonGroup/ToggleButtonGroup';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

const QueryBar = React.forwardRef((props, ref) => {
  const {
    search,
    filter,
    query,
    onChange,
    filterGroups,
    fieldData,
    SimpleQueryProps,
    AdvancedQueryProps,
    ...innerProps
  } = useComponentProps(props, 'QueryBar', {
    static: ['collapse', 'advanced']
  });

  const innerRef = useRef(null);
  const queryFormRef = useRef(null);

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const [internalState, setInternalState] = useState({
    advanced: null
  });

  const queryBar = useMemo(() => {
    const state = {
      ...internalState,
      ...utils.cleanObject({
        simpleQuery: query?.advanced ? null : query,
        advancedQuery: query?.advanced ? query : null
      })
    };

    return {
      refs: {
        ref: innerRef
      },
      state: state
    }
  }, [internalState, query]);

  useImperativeHandle(ref, () => queryBar);

  const handleToggle = (e, value) => {
    if (value) {
      setInternalState(utils.updater({advanced: value === 'advanced'}, true));
    }
  }

  const handleSimpleChange = (search, query) => {
    setInternalState(utils.updater({
      simpleQuery: query,
      advancedQuery: null
    }, true));
    onChange?.(search, query);
  }

  const handleAdvancedChange = (query) => {
    setInternalState(utils.updater({
      simpleQuery: null,
      advancedQuery: query
    }, true));
    onChange?.(search, query);
  }

  useLayoutEffect(() => {
    setInternalState((current) => {
      return utils.updater({
        advanced: Boolean(query?.advanced) === Boolean(current.advanced) ? null : current.advanced
      }, true)(current);
    });
  }, [query?.advanced]);

  const count = utils.formatNumber(10000000);
  const advanced = Boolean(queryBar.state.advanced ?? query?.advanced);

  const renderContent = () => {
    if (advanced) {
      return <AdvancedQuery ref={queryFormRef}
                            className="QueryBar-advanced"
                            search={search}
                            filter={filter}
                            debounce={constants.debounce.query}
                            query={queryBar.state.advancedQuery}
                            filterGroups={filterGroups}
                            fieldData={fieldData}
                            onChange={handleAdvancedChange}
                            {...AdvancedQueryProps}/>
    } else {
      return <SimpleQuery ref={queryFormRef}
                          className="QueryBar-simple"
                          search={search}
                          filter={filter}
                          query={queryBar.state.simpleQuery}
                          onChange={handleSimpleChange}
                          {...SimpleQueryProps}/>
    }
  }

  const renderExplanation = () => {
    return <Typography className="QueryBar-explanation" variant="body2">
      {`You're searching in our database with more than ${count} companies from `}
      <Link href={utils.cleanExternalLink('https://www.crunchbase.com/')}
            target="_blank">Crunchbase</Link>
      ,{' '}
      <Link href={utils.cleanExternalLink('https://worldwide.espacenet.com/')}
            target="_blank">Patents</Link>,{' '}
      <Link href={utils.cleanExternalLink('https://github.com/')}
            target="_blank">Github</Link> and{' '}
      <Link href={utils.cleanExternalLink('https://www.ventureiq.nl/')}
            target="_blank">Venture IQ</Link>
    </Typography>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    advanced: advanced
  });

  return <StyledQueryBar ref={innerRef} {...innerProps}>
    <Box className="QueryBar-content">
      <Box className="QueryBar-query">
        {renderContent()}
      </Box>
      {!mdDown ? <Box className="QueryBar-toggle">
        <ToggleButtonGroup color="primary"
                           size="small"
                           value={advanced ? 'advanced' : 'simple'}
                           exclusive={true}
                           onChange={handleToggle}>
          <ToggleButton value="simple">Simple</ToggleButton>
          <ToggleButton value="advanced">Advanced</ToggleButton>
        </ToggleButtonGroup>
      </Box> : null}
    </Box>
    {renderExplanation()}
  </StyledQueryBar>
});

QueryBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  search: PropTypes.string,
  filter: PropTypes.array,
  query: PropTypes.object,
  onChange: PropTypes.func,
  filterGroups: PropTypes.array,
  fieldData: PropTypes.object,
  SimpleQueryProps: PropTypes.object,
  AdvancedQueryProps: PropTypes.object
};

QueryBar.defaultProps = {};

export default QueryBar;
