import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import Icon from 'components/atoms/Icons/Icon/Icon';
import StyledActionToggleButton from 'components/molecules/Toggles/ActionToggleButton/ActionToggleButton.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const ActionToggleButton = React.forwardRef((props, ref) => {
  const {
    action,
    onClick,
    showInactive,
    ToggleButtonProps,
    TooltipProps,
    IconProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionToggleButtonProps}, 'ActionToggleButton');

  const innerRef = useRef(null);

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const actionToggleButton = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    active: itm?.active
  }), [itm?.active]);

  useImperativeHandle(ref, () => actionToggleButton);

  const toggleButtonChildren = <React.Fragment>
    {(action.icon && (action.iconPosition || 'start') === 'start') ? <Icon icon={action.icon} {...IconProps}/> : null}
    {action.label}
    {(action.icon && action.iconPosition === 'end') ? <Icon icon={action.icon} {...IconProps}/> : null}
  </React.Fragment>

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    return <Tooltip title={action.tooltip} showDisabled={true}
                    fullWidth={innerProps.fullWidth ?? action.ToggleButtonProps?.fullWidth}
                    placement={action.tooltipPosition ?? 'bottom'}
                    {...TooltipProps} {...action.TooltipProps}>
      <StyledActionToggleButton ref={innerRef} {...innerProps}
                                selected={innerProps.selected ?? itm?.active}
                                disabled={!itm}
                                onClick={handleClick}
                                {...action.ToggleButtonProps}>
        {toggleButtonChildren}
      </StyledActionToggleButton>
    </Tooltip>
  }
});

ActionToggleButton.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  showInactive: PropTypes.bool,
  ToggleButtonProps: PropTypes.object,
  TooltipProps: PropTypes.object,
  IconProps: PropTypes.object
};

ActionToggleButton.defaultProps = {
  label: 'ActionToggleButton text'
};

export default ActionToggleButton;
