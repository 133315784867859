import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDealflowEntitiesGraphsPage = styled(Page)`
  padding: ${props => props.theme.spacing(3)};
  background-color: ${props => props.theme.property('palette.primary.states.hover')};
  
  .DealflowEntitiesGraphsPage {
    &-breakdown {
      aspect-ratio: 430/508;
    }
    &-monthlyBreakdown {
      aspect-ratio: 868/508;
      min-height: ${props => props.theme.layout(346)};
    }
    &-development {
      aspect-ratio: 648/480;
      min-height: ${props => props.theme.layout(346)};
    }
    &-rundown {
      aspect-ratio: 648/480;
      min-height: ${props => props.theme.layout(346)};
    }
    &-tasks-wrapper {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('3sp')};

      > *:not(:first-child) {
        flex-grow: 1;
      }
    }
    &-tasks {
      height: ${props => props.theme.layout(226)};
    }
    &-openTasks {
      min-height: ${props => props.theme.layout(440)};
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    padding: ${props => props.theme.spacing(2)};
    
    .DealflowEntitiesGraphsPage {
      &-breakdown {
        max-height: ${props => props.theme.layout(484)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowEntitiesGraphsPage.propTypes = {
  theme: PropTypes.object
};

StyledDealflowEntitiesGraphsPage.defaultProps = {
};

export default StyledDealflowEntitiesGraphsPage;
