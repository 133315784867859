import React from 'react';
import _ from 'lodash';
import hash from 'object-hash';
import logger from 'helpers/logger';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {parseToRgb, rgbToColorString} from 'polished';
import equal from 'fast-deep-equal/react';
import ASCIIFolder from 'fold-to-ascii';
import {numericFormatter} from 'react-number-format';
import {formatNumber as formatPhoneNumber} from 'libphonenumber-js'

dayjs.extend(relativeTime);

function isRefComponent (component) {
  return (
    component &&
    typeof component === 'object' &&
    typeof component.$$typeof === 'symbol'
  )
}

function isClassComponent (component) {
  return (
    isFunction(component) &&
    !!component?.prototype?.isReactComponent
  )
}

function isFunctionComponent (component) {
  return (
    isFunction(component) &&
    String(component).includes('return React.createElement')
  )
}

function isReactComponent (component) {
  return (
    isRefComponent(component) ||
    isClassComponent(component) ||
    isFunctionComponent(component)
  )
}

function isIconComponent (component) {
  return isReactComponent(component) && component?.type?.render?.muiName === 'SvgIcon';
}

function isReactElement (element) {
  return React.isValidElement(element);
}

function isDOMTypeElement (element) {
  return isReactElement(element) && typeof element.type === 'string';
}

function isCompositeTypeElement (element) {
  return isReactElement(element) && isFunction(element.type);
}

function isRef (obj) {
  return (
    isObject(obj) &&
    obj.hasOwnProperty('current') &&
    Object.keys(obj).length === 1
  )
}

function isClass (v) {
  const cls = Object.getPrototypeOf(v)?.constructor?.name;

  return cls && !['String', 'Number', 'Object', 'Array', 'Boolean'].includes(cls);
}

function isPromise (p) {
  return p && p.then && isFunction(p.then);
}

function asPromise (fn) {
  return (...args) => {
    return (async () => fn?.(...args))();
  }
}

function asPromiseCallback (fn) {
  return (...args) => {
    return new Promise((resolve, reject) => {
      fn(...args, resolve, reject);
    });
  }
}

function reactElementToText (component) {
  let texts = [];

  const propToText = (name, value) => {
    let texts = [];
    if (isReactElement(value)) {
      const text = reactElementToText(value);
      if (text) {
        texts.push(text);
      }
    } else if (isObject(value)) {
      Object.keys(value).forEach((k) => {
        const text = propToText(k, value[k]);
        if (text) {
          texts.push(text);
        }
      });
    } else if (isArray(value)) {
      value.forEach((p) => {
        const text = propToText('value', p);
        if (text) {
          texts.push(text);
        }
      });
    } else if (isString(value)) {
      if (value.trim() && ['name', 'label', 'title', 'alt', 'description', 'value', 'children'].includes(name)) {
        texts.push(value.trim());
      }
    }

    return texts.join(' ');
  }

  Object.keys(component).forEach((k) => {
    if (k === 'props') {
      Object.keys(component[k]).forEach((p) => {
        const text = propToText(p, component[k][p]);
        if (text) {
          texts.push(text);
        }
      });
    }
  });

  return texts.join(' ');
}

function cloneElement (element, override, children) {
  const assignProps = (props, override) => {
    const inheritRef = (prev, next) => {
      return (el) => {
        if (prev) {
          if (isFunction(prev)) {
            prev(el);
          } else {
            prev.current = el;
          }
        }
        if (next) {
          if (isFunction(next)) {
            next(el);
          } else {
            next.current = el;
          }
        }
      }
    };

    const res = {};
    if (override) {
      Object.keys(override).forEach((k) => {
        if (k === 'ref') {
          res.ref = inheritRef(props?.ref, override?.ref);
        } else {
          if (props?.[k] !== override[k]) {
            res[k] = override[k];
          }
        }
      });
    }

    return res;
  }

  return children ? React.cloneElement(element, assignProps(element.props, override), children) :
    React.cloneElement(element, assignProps(element.props, override));
}


function cloneable (v) {
  return !isDefined(v) || !(isFunction(v) || isReactElement(v) || isReactComponent(v) || isRef(v) || isClass(v));
}

function clone (object, deep = false) {
  return deep ? _.cloneDeepWith(object, (value) => {
      if (!cloneable(value)) {
        return value;
      }
    }) :
    (cloneable(object) ? _.clone(object) : object);
}

function mergeObjects (object, override, cloneFirst = false, customizer = 'default') {
  if (!isDefined(object) && !isDefined(override)) {
    return null;
  }

  object = object ?? {};
  object = cloneFirst ? clone(object, true) : object;

  const customizers = {};

  customizers.overwriteAllSkipComplex = (objValue, srcValue, key, object, source) => {
    if (isArray(srcValue) || !cloneable(srcValue)) {
      return srcValue;
    } else {
      if (source.hasOwnProperty(key) && !isDefined(srcValue)) {
        return null;
      }
    }
  }

  customizers.default = customizers.overwriteAllSkipComplex;

  customizer = isFunction(customizer) ? customizer : customizers[customizer];

  return _.mergeWith(object, override, customizer);
}

function pixel2Rem (pixel, base) {
  return `${pixel/base}rem`;
}

function rem2Pixel (rem, base) {
  return toNumber(rem) * base;
}

function pixel2Em (pixel, base) {
  return `${pixel/base}em`;
}

function pixel2factor (pixel, base) {
  return `${pixel/base}`;
}

function pixel2percentage (pixel, base) {
  return `${(pixel/base) * 100}%`;
}

function rgba2Rgb(rgbaColor, bgColor = '#fff') {
  if (rgbaColor) {
    const rgbaParsed = parseToRgb(rgbaColor);
    const bgParsed = parseToRgb(bgColor);

    const flattenedColor = rgbaParsed.alpha ? {
      red: Math.round(rgbaParsed.alpha * rgbaParsed.red + (1 - rgbaParsed.alpha) * bgParsed.red),
      green: Math.round(rgbaParsed.alpha * rgbaParsed.green + (1 - rgbaParsed.alpha) * bgParsed.green),
      blue: Math.round(rgbaParsed.alpha * rgbaParsed.blue + (1 - rgbaParsed.alpha) * bgParsed.blue),
    } : rgbaParsed;

    return rgbToColorString(flattenedColor);
  } else {
    return rgbaColor;
  }
}

function flattenClassName (className, props) {
  if (isFunction(className)) {
    return className(props);
  } else {
    return className;
  }
}

function classNames (className1, className2) {
  if (isFunction(className1) || isFunction(className2)) {
    let func = (args) => {
      const cls1 = isFunction(className1) ? className1(args) : className1;
      const cls2 = isFunction(className2) ? className2(args) : className2;
      return classNames(cls1, cls2);
    };
    return func;
  } else {
    return `${className1 ? className1 : ''}${className2 ? (className1 ? ' ' : '') + className2 : ''}`;
  }
}

function cleanProps (props, names) {
  names = names ? toArray(names) : [];
  names.push(/^\$.*/i);
  names.push(/.*Props$/i);

  let clean = {...props};
  Object.keys(clean).forEach((k) => {
    if (names.some((n) => {
      if (typeof n === 'object') {
        return k.match(n);
      } else {
        return k.toLowerCase() === n.toLowerCase();
      }
    })) {
      delete clean[k];
    }
  });

  return clean;
}

function styleProps(props, names) {
  return cleanProps(props, names);
}

function objectProp (object, prop, defaultValue = null, required = false) {
  let current = object, found = true;
  const split = !prop.match(/[({[].*\..*[\]})]/) ? prop.split('.') : [prop];
  split.forEach((p) => {
    if (current && current.hasOwnProperty(p)) {
      current = current[p];
    } else {
      found = false;
    }
  });

  if (!found) {
    if (defaultValue !== null) {
      current = defaultValue;
    } else {
      current = null;
      if (required) {
        throw new Error('Prop not found');
      } else {
        logger.trace('Property not found', prop, object);
      }
    }
  }

  return current;
}

function object2Array (o, key = 'name') {
  if (o) {
    return Object.keys(o).reduce((a, k) => {
      return a.concat({...o[k], [key]: k});
    }, []);
  } else {
    return [];
  }
}

function types2Array (o) {
  if (o) {
    return Object.keys(o).reduce((a, k) => {
      return a.concat(o[k]);
    }, []);
  } else {
    return [];
  }
}

function camelcaseEx (src, deep = true) {
  if (isDefined(src)) {
    if (typeof src === 'string') {
      return `${src[0].toLowerCase()}${_.camelCase(src.slice(1))}`;
    } else if (isObject(src)) {
      let tgt = {};
      for (let key in src) {
        if (!src.hasOwnProperty(key)) continue;
        let transform = (key?.length > 0) ? `${key[0].toLowerCase()}${_.camelCase(key).slice(1)}` : key;
        tgt[transform] = (deep && (isObject(src[key]) || isArray(src[key]))) ? camelcaseEx(src[key], deep) : src[key];
      }
      return tgt;
    } else if (isArray(src)) {
      let tgt = [];
      for (let key in src) {
        tgt[key] = (isObject(src[key]) || isArray(src[key])) ? camelcaseEx(src[key], deep) : src[key];
      }
      return tgt;
    } else {
      return src;
    }
  } else {
    return src;
  }
}

function camelcase (src) {
  return camelcaseEx(src, false);
}

function underscoreEx (src, deep = true) {
  if (typeof src === 'string') {
    return `${src[0].toLowerCase()}${_.snakeCase(src).slice(1)}`;
  } else if (isObject(src)) {
    let tgt = {};
    for (let key in src) {
      if (!src.hasOwnProperty(key)) continue;
      let transform = (key?.length > 0) ? `${key[0].toLowerCase()}${_.snakeCase(key).slice(1)}` : key;
      if (!(key?.length > 0)) {
        transform = key;
      }
      tgt[transform] = (deep && (isObject(src[key]) || isArray(src[key]))) ? underscoreEx(src[key], deep) : src[key];
    }
    return tgt;
  } else if (isArray(src)) {
    let tgt = [];
    for (let key in src) {
      tgt[key] = (isObject(src[key]) || isArray(src[key])) ? underscoreEx(src[key], deep) : src[key];
    }
    return tgt;
  } else {
    return src;
  }
}

function underscore (src) {
  return underscoreEx(src, false);
}

function isEmpty (v) {
  if (!isDefined(v)) {
    return true;
  } else if (isClass(v)) {
    return false;
  } else if (isArray(v)) {
    return v.length === 0;
  } else if (isObject(v)) {
    return Object.keys(v).length === 0;
  } else {
    return v.toString().length === 0
  }
}

function isDefined (v) {
  return !(v === undefined || v === null);
}

function toNumber (n) {
  return (typeof n === 'string') ? _.toNumber(parseFloat(n)) : _.toNumber(n);
}

function isNumber (n, exact = true) {
  const p = toNumber(n);
  return isDefined(n) && !_.isNaN(p) && (
    !exact || p.toString().length === n.toString().length ||
    (p === 0 && n.toString().replace(/-|\+|0+|\.|,/gi, '').length === 0)
  );
}

function toInt (n) {
  return (typeof n === 'string') ? _.toInteger(parseInt(n)) : _.toInteger(n);
}

function isInt (n, exact = true) {
  const p = toInt(n);
  return !_.isNaN(p) && (
    !exact || p.toString().length === n.toString().length ||
    (p === 0 && n.toString().replace(/-|\+|0+|\.|,/gi, '').length === 0)
  );
}

function isBoolean (b) {
  return typeof b === 'boolean';
}

function toBoolean (b) {
  return isDefined(b) ? (isBoolean(b) ? b : (b === 'true' || b === 1)) : b;
}

function isDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (isNaN(d.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

function isString (s) {
  return _.isString(s);
}

function toString (s) {
  return s.toString();
}

function toAsciiString (s) {
  return ASCIIFolder.foldReplacing(s);
}

function isArray (a) {
  return _.isArray(a);
}

function toArray (a, skipEmpty = false) {
  return (isDefined(a) && (!skipEmpty || !isEmpty(a))) ? _.castArray(a) : [];
}

function uniqueArray (a, key = null, onlyDefined = false, compare = null) {
  const getKey = (itm) => {
    if (key) {
      return itm[key];
    } else {
      return itm;
    }
  }

  return a.filter((v, idx1, self) => self.findIndex((i, idx2) => {
    if (onlyDefined && !isDefined(getKey(i)) && !isDefined(getKey(v))) {
      return idx1 === idx2;
    } else if (compare) {
      return compare(i, v);
    } else {
      return getKey(i) === getKey(v);
    }
  }) === idx1);
}

function isObject (o, allowArray = false) {
  return _.isObjectLike(o) && (allowArray || !isArray(o));
}

function toObject (a) {
  return _.fromPairs(a);
}

function cleanObject(o, allowEmpty = true, allowNotCloneable = true) {
  return o ? Object.keys(o).reduce((obj, k) => {
    if (isDefined(o[k]) &&
        (allowEmpty || !isEmpty(o[k])) &&
        (allowNotCloneable || cloneable(o[k]))) {
      obj[k] = o[k];
    }
    return obj;
  }, {}) : o;
}

function filterObject (o, keys, exclude = true) {
  return o ? Object.keys(o).reduce((obj, k) => {
    const found = toArray(keys).find((f) => f === k);
    if ((exclude && !found) || (!exclude && found)) {
      obj[k] = o[k];
    }
    return obj;
  }, {}) : o;
}

function isFunction (o) {
  return _.isFunction(o);
}

function isColor (c, strict = false) {
  let parsed = false;
  try {
    if (isString(c)) {
      parseToRgb(c);
      parsed = true;
    }
  } catch (err) {
    /* SQUASH */
  }

  return isString(c) && (c.toLowerCase().startsWith('#') || c.toLowerCase().startsWith('rgb') || (!strict && parsed));
}

function string2Int (string) {
  let hash = 0;

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}

function string2Color (string) {
  const hash = string2Int(string);

  let color = '#';

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function number2Color(number) {
  number = number * 4729;
  if (number < 1) {
    return string2Color(number.toString(36).slice(2, 7));
  } else {
    return string2Color(toNumber(`0.${number}`).toString(36).slice(2, 7));
  }
}

function randomString (min, max = null) {
  min = Math.floor(min);
  max = Math.floor(max ?? min);

  const length = min + Math.round(Math.random() * (max - min));
  let str = Math.random().toString(36).slice(2, 7);
  while (str.length < length) {
    str += Math.random().toString(36).slice(2, 7);
  }
  return str.substring(0, length);
}

function fillYear (year) {
  if (isNumber(year)) {
    year = toNumber(year);
    return `${year < 10 ? `000${year}` : year < 100 ? `00${year}` : year < 1000 ? `0${year}` : year}`;
  } else {
    return year;
  }
}

function transpose (matrix) {
  if (matrix) {
    return _.unzip(matrix);
  } else {
    return matrix;
  }
}

function decimalSeparator () {
  const num = 1.2;
  if (typeof Intl === "object" && Intl && Intl.NumberFormat) {
    // I'm surprised it's this much of a pain and am hoping I'm missing
    // something in the API
    const formatter = new Intl.NumberFormat();
    const parts = formatter.formatToParts(num);
    const decimal = parts.find(({ type }) => type === "decimal").value;
    return decimal;
  }
  // Doesn't support `Intl.NumberFormat`, fall back to dodgy means
  const str = num.toLocaleString();
  const parts = /1(\D+)2/.exec(str);
  return parts[1];
}

function thousandSeparator () {
  return decimalSeparator() === ',' ? '.' : ',';
}

function formatNumber (n) {
  if (isDefined(n)) {
    return numericFormatter(n.toString(), {
      decimalSeparator: utils.decimalSeparator(),
      thousandSeparator: utils.thousandSeparator()
    });
  } else {
    return n;
  }
}

function range (from, to) {
  return _.range(from, to);
}

function sha1 (v) {
  try {
    return hash(isDefined(v) ? v : null);
  } catch (err) {
    logger.trace('sha1 failed', err, v);
  }
}

function md5 (v) {
  try {
    return hash.MD5(isDefined(v) ? v : null);
  } catch (err) {
    logger.trace('MD5 failed', err, v);
  }
}

function compare (v1, v2) {
  return equal(v1, v2);
}

function updater (v, merge = false, cf = compare) {
  return (current) => {
    v = merge ? {...current, ...v} : v;
    return !cf(current, v) ? v : current;
  }
}

function comparable (v1, deep = false) {
  return !isDefined(v1) || (cloneable(v1) && (
    (isObject(v1) && deep) ? !Object.keys(v1).some((k) => !comparable(v1[k])) : (
      (isArray(v1) && deep) ? !v1.some((v) => !comparable(v)) : cloneable(v1)
    )
  ));
}

function filter2Object (filter) {
  if (isArray(filter)) {
    return filter.reduce((acc, filter) => {
      acc[filter.id] = acc[filter.id] || [];
      acc[filter.id] = toArray(acc[filter.id]).concat(toArray(filter.value));
      acc[filter.id] = acc[filter.id].length === 1 ? acc[filter.id][0] : acc[filter.id];
      return acc;
    }, {});
  } else {
    return filter;
  }
}

function object2Filter (filter) {
  if (isObject(filter)) {
    return Object.keys(filter).reduce((acc, key) => {
      acc.push({
        id: key,
        value: filter[key]
      });
      return acc;
    }, []);
  } else {
    return [];
  }
}

function query2Param (query) {
  if (isObject(query)) {
    return [['query', query.id]]
  } else {
    return query;
  }
}

function filter2Param (filter) {
  if (isArray(filter)) {
    return filter.reduce((acc, filter) => {
      toArray(filter.value).forEach((v) => {
        acc.push([filter.id, v]);
      });
      return acc;
    }, []);
  } else {
    return filter;
  }
}

function param2Filter (filter) {
  return toArray(filter).reduce((a, value) => {
    const found = a.find((f) => f.id === value[0]);
    if (found) {
      found.value = toArray(found.value).concat([value[1]]);
    } else {
      a.push({id: value[0], value: value[1]});
    }
    return a;
  }, []);
}

function applyFilters (original, changes, ids) {
  original = clone(original, true);
  return toArray(original)
    .filter((f) => !toArray(ids).find((id) => id === f.id))
    .concat(toArray(changes))
    .filter((f) => !isEmpty(f.value));
}

function addFilter (original, filter) {
  if (filter) {
    if (isArray(filter)) {
      filter.forEach((f) => {
        original = addFilter(original, f);
      });

      return original;
    } else {
      original = clone(original, true);

      const old = toArray(original).find((f) => f.id === filter.id);
      if (old) {
        old.value = uniqueArray(
          toArray(old.value).map((v) => v.toString())
            .concat(toArray(filter.value).map((v) => v.toString())));
        return toArray(original);
      } else {
        return toArray(original).concat([filter]);
      }
    }
  } else {
    return toArray(original);
  }
}

function sort2Http (sort, keepPostFix = false) {
  const cleanSort = (sort) => {
    return keepPostFix ? sort : sort.split('|')[0];
  };

  if (isArray(sort)) {
    return sort.map((sort) => {
      if (typeof sort === 'string') {
        sort = sort.split(':');
        if (sort.length === 1) {
          return cleanSort(sort[0]);
        } else {
          return sort[1] === 'desc' ? `-${cleanSort(sort[0])}` : cleanSort(sort[0]);
        }
      } else if (isObject(sort)) {
        return `${sort.desc ? '-' : ''}${cleanSort(sort.id)}`;
      }
      return null;
    }).filter((x) => x);
  } else {
    return sort;
  }
}

function sort2Param (sort) {
  return sort2Http(sort, true);
}

function param2Sort (sort) {
  return toArray(sort).reduce((a, value) => {
    const desc = value[0] === '-';
    const id = desc ? value.slice(1) : value;

    if (!a.find((x) => x.id === id)) {
      a.push({id, desc});
    }
    return a;
  }, []);
}

function addSort (original, sort) {
  if (sort) {
    if (isArray(sort)) {
      sort.forEach((s) => {
        original = addSort(original, s);
      });

      return original;
    } else {
      original = clone(original, true);

      const old = toArray(original).find((f) => f.id === sort.id);
      if (old) {
        old.desc = sort.desc;
        return toArray(original);
      } else {
        return toArray(original).concat([sort]);
      }
    }
  } else {
    return toArray(original);
  }
}

function indexObject2Array (columnOrder) {
  return columnOrder ? (
    isArray(columnOrder) ? columnOrder : Object.keys(columnOrder)
      .sort((a, b) => toNumber(a) - toNumber(b))
      .reduce((a, k) => {
        return a.concat(isDefined(columnOrder[k]) ? [columnOrder[k]] : []);
      }, [])
  ) : [];
}

function upperFirst (s) {
  return _.upperFirst(s);
}

function lowerFirst (s) {
  return _.lowerFirst(s);
}

function personName (first, last) {
  const f = first ? upperFirst(first) : null;
  const l = last ? upperFirst(last) : null;

  let res = f;
  if (res && l) {
    res += ' ' + l;
  }

  return res;
}

function avatarLabel (name) {
  if (name) {
    const split = name.replace(/[^a-z,A-Z\s]+/g, '').split(/\s/).filter((_) => (_));

    let label = '';
    if (split.length > 1) {
      label += split[0][0];
      label += split[1][0];
    } else {
      label = split[0]?.slice(0, 2);
    }

    return label?.toUpperCase();
  } else {
    return name;
  }
}

function cleanPath (path, lower = false) {
  if (path) {
    path = (path.length > 1 && path.endsWith('/')) ? path.slice(0, -1) : path;
    return lower ? path.toLowerCase() : path;
  } else {
    return path;
  }
}

function splitPath (path, splitter) {
  path = cleanPath(path);

  const pathTail = path.split('/');
  const startIndex = pathTail.findIndex((p) => p === splitter);
  const prefix = startIndex === -1 ? path : pathTail.slice(0, startIndex + 1).join('/');
  const postFix = startIndex === -1 ? '' : pathTail.slice(startIndex + 1).join('/');

  return [prefix, postFix];
}

function comparePath (newPath, currentPath, start = false) {
  if (!start) {
    return cleanPath(decodeURIComponent(newPath)) === cleanPath(decodeURIComponent(currentPath));
  } else {
    return cleanPath(decodeURIComponent(currentPath)).startsWith(cleanPath(decodeURIComponent(newPath)));
  }
}

function cleanPathParams (path) {
  const split = cleanPath(path).split(/[&?]/);
  const decoded = split.slice(1).map((s) => ({
    original: s,
    decoded: decodeURIComponent(s)
  }));
  const params = uniqueArray(decoded, 'decoded').map((e) => e.original);

  return split[0] + (params.length > 0 ? `?${params.join('&')}` : '');
}

function removePathParams (path, params) {
  const split1 = cleanPath(path).split(/[&?]/);
  const decoded1 = split1.slice(1).map((s) => ({
    original: s,
    decoded: decodeURIComponent(s)
  }));
  const split2 = cleanPath(params).split(/[&?]/);
  const decoded2 = split2.map((s) => ({
    original: s,
    decoded: decodeURIComponent(s)
  }));

  const filteredParams = decoded1
    .filter((e1) => !decoded2.find((e2) => e1.decoded === e2.decoded))
    .map((e) => e.original);

  return split1[0] + (filteredParams.length > 0 ? `?${filteredParams.join('&')}` : '');
}

function removePathKeys (path, params) {
  const split = cleanPath(path).split(/[&?]/);
  const rest = split.slice(1).filter((r) => {
    return !toArray(params).find((p) => r.toLowerCase() === p.toLowerCase() || r.toLowerCase().startsWith(`${p.toLowerCase()}=`));
  });

  return split[0] + (rest.length > 0 ? `?${uniqueArray(rest).join('&')}` : '');
}

function validRedirect (path, clean = true) {
  if (path) {
    const noRedirect = ['auth'];
    const systemKeys = ['key', 'token', 'activatePass', 'activateTeam'];

    const pathname = isObject(path) ? path.pathname : path;
    const valid = pathname && !noRedirect.find((rd) => pathname.replace('/', '').startsWith(rd));

    const cleanPath = clean ? removePathKeys(pathname + path.search, systemKeys) : (pathname + path.search);

    return valid ? cleanPath : '/';
  } else {
    return '/';
  }
}

function cleanInternalLink (link) {
  if (link) {
    return `${!link.toLowerCase().startsWith('http') ? (!link.startsWith('//') ? window.location.origin : 'http:') : ''}${link}`;
  } else {
    return link;
  }
}

function cleanExternalLink (link) {
  if (link) {
    return `${!link.toLowerCase().startsWith('http') ? (!link.startsWith('//') ? 'http://' : 'http:') : ''}${link}`;
  } else {
    return link;
  }
}

function displayExternalLink (link) {
  if (link) {
    const split = link?.split?.('//');
    if (split.length > 1) {
      const clean = split.length > 1 ? link.split('//')[1] : link;
      return clean.replace(/\/$/g, '');
    } else {
      return link;
    }
  } else {
    return link;
  }
}

function escapeRegExp (s) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function url (link) {
  try {
    return new URL(link);
  } catch {
    return null;
  }
}

function cleanUrl (url, protocol = 'https') {
  try {
    url = url.replace(/^https?:/i, '');
    if (!url.startsWith('//')) {
      url = `${protocol}://${url}`;
    } else {
      url = `${protocol}:${url}`;
    }

    let urlParsed = new URL(url.toLowerCase());
    let hostParts = urlParsed.host.split('.');

    if (hostParts.length === 2 && hostParts[0] !== 'www') {
      hostParts.unshift('www');
    }

    let host = hostParts.join('.');

    if (urlParsed.pathname[urlParsed.pathname.length - 1] === '/') {
      urlParsed.pathname = urlParsed.pathname.slice(0, urlParsed.pathname.length - 1);
    }

    return urlParsed.protocol + '//' + host + urlParsed.pathname;
  } catch {
    return null;
  }
}

function cleanWebsite (url) {
  try {
    let clean = cleanUrl(url);
    try {
      let url = new URL(clean);
      return url.origin;
    } catch {
      return clean;
    }
  } catch {
    return null;
  }
}

function cleanLinkedInHandle (url) {
  try {
    url = new URL(url);
    url = url.origin + (url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname);
    let match = url.match(/^(?:https?:\/\/)?(?:www\.|[a-zA-Z]{2}\.)?(?:linkedin.com\/company\/|linked.in\/company\/)([a-zA-Z0-9.\-_&]+)/i);
    match = (match && match[1] && !['linkedin.com', 'linked.in'].includes(match[1].toLowerCase())) ? match[1] : null;
    if (!match) {
      return match;
    } else {
      return decodeURIComponent(match);
    }
  } catch {
    return null;
  }
}

function baseUrl (url, base) {
  try {
    const pUrl = new URL(url);
    const rUrl = new URL(base);
    pUrl.hostname = rUrl.hostname;
    pUrl.pathname = rUrl.pathname;
    pUrl.port = rUrl.port;
    return pUrl.href;
  } catch {
    return null;
  }
}

function prefixUrl (url, https = false) {
  if (url) {
    const prefix = https ? 'https://' : 'http://';
    return (!url.toLowerCase().startsWith('http') && !url.toLowerCase().startsWith('://')) ? `${prefix}${url}` : url;
  } else {
    return url;
  }
}

function location2URL (location) {
  try {
    return new URL(location.pathname + location.search, window.location);
  } catch {
    return null;
  }
}

function longestCommonSubstr (str1, str2) {
  let m = str1.length;
  let n = str2.length;
  let suff = (new Array(m + 1)).fill(0).map(() => (new Array(n + 1)).fill(0));

  let result = 0;

  for (let i = 0; i < m+1; i++) {
    for (let j = 0; j < n+1; j++) {
      if (i === 0 || j === 0) {
        suff[i][j] = 0;
      } else if (str1[i - 1] === str2[j - 1]) {
        suff[i][j] = suff[i - 1][j - 1] + 1;
        result = Math.max(result, suff[i][j]);
      } else {
        suff[i][j] = 0;
      }
    }
  }

  return result;
}

function calcOverlap (s1, s2) {
  if (typeof(s1) === 'string' && typeof(s2) === 'string' && s1.length > 0 && s2.length > 0) {
    s1 = s1.replace(/\W+/gi, '').toLowerCase();
    s2 = s2.replace(/\W+/gi, '').toLowerCase();
    return longestCommonSubstr(s1, s2) / Math.max(s1.length, s2.length);
  }
  return 0;
}

function getHostname (link) {
  try {
    const REGEX = /(?:https?:\/\/)?(?:www\.)?([-a-zA-Z0-9@:%._+~#=]+\.[a-zA-Z0-9()]+)/i;
    let hostname = cleanUrl(link).match(REGEX)[1];
    if (!hostname.toLowerCase().startsWith('www.')) {
      return hostname;
    } else {
      throw new Error('invalid link');
    }
  } catch {
    return null;
  }
}

function nameWebsiteMatch (name, website) {
  if (name) {
    try {
      let s1 = name.replace(/[^\w._-]+/gi, '').toLowerCase();
      let s2Full = getHostname(website).split('.').map((s) => s.replace(/[^\w._-]+/gi, '').toLowerCase()).join('.');
      let s2 = s2Full.split('.').slice(0, -1).join('.');

      let orginalOverlap = this.calcOverlap(s1, s2);
      let fullOverlap = this.calcOverlap(s1, s2Full);

      let s1Stripped = name.split(/\s+/).slice(0, -1).join('').replace(/[^\w._-]+/gi, '').toLowerCase();
      let strippedOverlap = (s1Stripped.length / s1.length > 0.50) ? this.calcOverlap(s1Stripped, s2) : orginalOverlap;
      let strippedFullOverlap = (s1Stripped.length / s1.length > 0.50) ? this.calcOverlap(s1Stripped, s2Full) : fullOverlap;

      if (((orginalOverlap + strippedOverlap + fullOverlap + strippedFullOverlap) / 4) >= 0.85) {
        return website;
      }
    } catch (err) {
      /* SQUASH */
    }
    return false;
  } else {
    return false;
  }
}

function matchFileType(filename, type, types) {
  return types.find((t) => {
    return type.toLowerCase().endsWith('/' + t) || filename.toLowerCase().endsWith('.' + t) ||
      (t.split('/').length === 2 && t.split('/')[1] === '*' && type.toLowerCase().startsWith(t.split('/')[0] + '/'));
  });
}

function cleanFilename (name) {
  if (name) {
    return name.replace(/[^a-z0-9_-]/gi, '_').toLowerCase();
  } else {
    return name;
  }
}

function filename2Name (name) {
  if (name) {
    return upperFirst(name.split('.').slice(0, -1).join('.').replace(/[^a-z0-9_-]/gi, ' ').replace(/\s+/gi, ' '));
  } else {
    return name;
  }
}

function extractToken (token) {
  const split = token?.split('.');
  if (split?.length === 3) {
    return JSON.parse(atob(split[1]));
  }

  return null;
}

function replace (s, from, to, flags = '') {
  return s.replace(new RegExp(escapeRegExp(from), flags), to);
}

function componentPath (url) {
  return url.match(/.*components\/(.*)\/[^/]*$/)[1];
}

function servicePath (url) {
  return url.match(/.*services\/(.*)\/[^/]*$/)[1];
}

function createAuth (auth) {
  return mergeObjects({
    mustLogin: true,
    group: null,
    attribute: null,
    meta: null
  }, auth);
}

function resolveAttribute (attribute, params, keepUnresolved = false) {
  const matches = attribute.match(/:(\w+)/gi);
  (matches || []).forEach((m) => {
    if (isDefined(params[m.slice(1)])) {
      attribute = replace(attribute, m, params[m.slice(1)], 'g');
    } else if (!keepUnresolved) {
      attribute = replace(attribute, m, '', 'g');
    }
  });

  return attribute.replace(/\.{2,}/g, '');
}

function responseMeta (resp) {
  let meta = {};
  const serverKeys = ['took', 'page', 'pageSize', 'resultsCount', 'hasMore', 'object'];

  if (isDefined(resp?.headers?.['x-server-time'])) {
    const serverTime = new Date();
    const took = isDefined(resp?.headers?.['x-server-took']) ? toInt(resp?.headers['x-server-took']) : 0;

    // operations take time, best serverTime is the time including how long it took
    serverTime.setTime(toInt(resp?.headers['x-server-time']) + took);

    meta = {...meta, time: new Date(), serverTime};
  } else {
    meta = {...meta, time: new Date()};
  }

  if (isObject(resp?.data)) {
    meta = {...meta, ...resp.data?.meta, ...filterObject(resp.data, serverKeys, false)};
  }

  return meta;
}

function filterOptions (options, filter) {
  const filters = toArray(filter);
  const positive = filters.filter((f) => !f.toString().startsWith('-') &&
    !f.toString().startsWith('>') && !f.toString().startsWith('<'));
  const negative = filters.filter((f) => f.toString().startsWith('-')).map((f) => f.toString().slice(1));
  const greater = filters.filter((f) => f.toString().startsWith('>')).map((f) => f.toString().slice(1));
  const smaller = filters.filter((f) => f.toString().startsWith('<')).map((f) => f.toString().slice(1));

  return options.filter((opt) => {
    const value = (opt?.value ?? opt);
    return (positive.length === 0 || positive.some((f) => {
      return value.toString().toLowerCase() === f.toString().toLowerCase();
    })) && !(negative.length > 0 && negative.some((f) => {
      return value.toString().toLowerCase() === f.toString().toLowerCase();
    })) && (greater.length === 0 || (greater.filter((f) => {
      return +value > +f;
    }).length === greater.length)) && (smaller.length === 0 || (smaller.filter((f) => {
      return +value < +f;
    }).length === smaller.length));
  });
}

function splitMentions (text, mentions) {
  if (text) {
    const split = [];
    mentions = mentions.concat([{trigger: '#'}]);
    const regex = new RegExp(mentions.reduce((a, m) => {
      return a.concat([(new RegExp(`(\\${m.trigger}\\[(\\${m.trigger}[^\\[\\]\\:]+)\\:([^\\[\\]\\:]+)\\])`, 'gi')).source]);
    }, []).join('|'), 'gi');

    const matches = [];
    Array.from(text.matchAll(regex)).forEach((match) => {
      const mentionIndex = mentions.findIndex((m) => m.trigger === match[0][0]);
      const offset = (mentionIndex * 3) + 1;
      matches.push({
        mention: mentions[mentionIndex],
        match: match[offset],
        index: match.index,
        display: match[offset + 1],
        id: match[offset + 2]
      })
    });

    if (matches.length === 0) {
      split.push({ value: text });
    } else {
      let lastIndex = 0;
      matches.forEach((match) => {
        if (match.index > lastIndex) {
          split.push({ value: text.slice(lastIndex, match.index)});
        }
        split.push({
          mention: match.mention,
          id: match.id,
          display: match.display
        });

        lastIndex = match.index + match.match.length;
      });

      if (lastIndex < text.length) {
        split.push({value: text.slice(lastIndex)});
      }
    }

    return split;
  } else {
    return [];
  }
}

function fileDownload (name, url) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    name,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

function blobDownload (blob, name) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    name
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

function bytesDownload (bytes, name, type) {
  const byteCharacters = atob(bytes);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  blobDownload(new Blob(
    [byteArray],
    {type}
  ), name);
}

function textDownload (text, name, type) {
  blobDownload(new Blob(
    [text],
    {type}
  ), name);
}

function base64ToBlob (base64, type) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob(
    [byteArray],
    {type}
  )
}

function blobToFile (blob, name) {
  return new File([blob], name, {
    type: blob.type,
  });
}

function openLink (url, target = '_blank') {
  if (target === '_window') {
    return window.open(url);
  } else {
    return window.open(url, target);
  }
}

function internalLink (url, target = '_blank') {
  url = cleanInternalLink(url);

  return openLink(url, target);
}

function externalLink (url, target = '_blank') {
  url = cleanExternalLink(url);

  return openLink(url, target);
}

function resolvePath (path, params = {}, keepUnresolved = false) {
  const matches = path.match(/:(\w+)/gi);
  (matches || []).forEach((m) => {
    if (isDefined(params[m.slice(1)])) {
      path = replace(path, m, encodeURIComponent(params[m.slice(1)]), 'g');
    } else if (!keepUnresolved) {
      path = replace(path, m, '', 'g');
    }
  });

  return path;
}

function debounce (fn, ms = 0, stop = null) {
  let timer = null, prev = null;
  return (...args) => {
    clearTimeout(timer);
    const {wait, data} = (stop?.(prev, ...args) ?? {wait: true, data: null});
    if (wait) {
      prev = data;
      timer = setTimeout(() => {
        timer = null;
        fn(...args);
      }, ms);
    } else {
      prev = null;
      fn(...args);
    }
  };
}


function memoizeItem (initial) {
  let prev = initial;

  return (next) => {
    if (!compare(prev, next)) {
      return next;
    } else {
      return prev;
    }
  }
}

function memoizeCallback (initial, cb) {
  let prev = initial;

  return (arg, e) => {
    if (!compare(prev, arg)) {
      cb(arg, e)
    }

    prev = arg
  }
}

function fetchWithTimeout(url, options = {}) {
  const { timeout, ...rest } = options;

  const controller = new AbortController();
  const timeoutId = setTimeout(() => isDefined(timeout) ? controller.abort() : null, timeout);
  return fetch(url, {
    ...rest,
    signal: controller.signal
  }).finally(() => {
    clearTimeout(timeoutId);
  });
}

function matchOptionToValue (option, value) {
  const valueA = isObject(option) ? option.value : option;
  const labelA = isObject(option) ? option.label : option;
  const valueB = isObject(value) ? value.value : value;
  const labelB = isObject(value) ? value.label : value;
  const matchValues = utils.isDefined(option?.value) && utils.isDefined(value?.value);

  return (matchValues && option.value.toString().trim() === value.value.toString().trim()) || (
    !matchValues && (
      (utils.isDefined(valueA) && utils.isDefined(valueB) && valueA.toString().trim() === valueB.toString().trim()) ||
      labelA?.toString().toLowerCase().trim() === labelB?.toString().toLowerCase().trim()
    )
  );
}

function address2String (address) {
  let str = address?.street;
  if (str && address?.houseNumber) {
    str += ' ';
  }
  str += address?.houseNumber;
  if (str && ((address?.postcode ?? address?.postalCode) || (address?.city))) {
    str += ', ';
  }
  str += (address?.postcode ?? address?.postalCode);
  if (str && (address?.postcode ?? address?.postalCode)) {
    str += ' ';
  }
  str += address?.city;

  return str;
}

function phone2String (phone) {
  if (phone) {
    return formatPhoneNumber(phone, 'INTERNATIONAL') || phone;
  } else {
    return phone;
  }
}

function sleep (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const utils = {
  // 3th party
  dayjs,

  // internal
  isRefComponent,
  isClassComponent,
  isFunctionComponent,
  isReactComponent,
  isIconComponent,
  isReactElement,
  isDOMTypeElement,
  isCompositeTypeElement,
  isRef,
  isClass,
  isPromise,
  asPromise,
  asPromiseCallback,

  reactElementToText,

  cloneElement,

  cleanProps,
  styleProps,
  flattenClassName,
  classNames,

  pixel2Rem,
  rem2Pixel,
  pixel2Em,
  pixel2factor,
  pixel2percentage,

  rgba2Rgb,

  clone,
  cloneable,
  mergeObjects,
  objectProp,
  object2Array,
  types2Array,

  camelcase,
  camelcaseEx,
  underscore,
  underscoreEx,

  isEmpty,
  isDefined,
  isNumber,
  toNumber,
  isInt,
  toInt,
  isBoolean,
  toBoolean,
  isDate,
  isString,
  toString,
  toAsciiString,
  isArray,
  toArray,
  uniqueArray,
  isObject,
  toObject,
  cleanObject,
  filterObject,
  indexObject2Array,
  isFunction,
  isColor,
  string2Int,
  string2Color,
  number2Color,
  randomString,
  fillYear,
  transpose,

  decimalSeparator,
  thousandSeparator,
  formatNumber,

  range,

  md5,
  sha1,
  compare,
  updater,
  comparable,

  filter2Object,
  object2Filter,
  query2Param,
  filter2Param,
  param2Filter,
  applyFilters,
  addFilter,
  sort2Http,
  sort2Param,
  param2Sort,
  addSort,

  upperFirst,
  lowerFirst,
  personName,
  avatarLabel,

  cleanPath,
  splitPath,
  comparePath,
  cleanPathParams,
  removePathParams,
  removePathKeys,
  validRedirect,
  cleanInternalLink,
  cleanExternalLink,
  displayExternalLink,
  escapeRegExp,

  url,
  cleanUrl,
  cleanWebsite,
  cleanLinkedInHandle,
  baseUrl,
  prefixUrl,
  location2URL,

  getHostname,
  calcOverlap,
  longestCommonSubstr,
  nameWebsiteMatch,

  matchFileType,
  cleanFilename,
  filename2Name,

  extractToken,

  replace,

  componentPath,
  servicePath,

  createAuth,
  resolveAttribute,
  responseMeta,

  filterOptions,

  splitMentions,

  fileDownload,
  blobDownload,
  bytesDownload,
  textDownload,
  base64ToBlob,
  blobToFile,
  openLink,
  internalLink,
  externalLink,
  resolvePath,

  debounce,
  memoizeItem,
  memoizeCallback,
  fetchWithTimeout,

  matchOptionToValue,

  address2String,
  phone2String,

  sleep
}

export default utils;
