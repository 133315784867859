import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {useServiceDataByIdEx} from 'stores/hooks/store';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useAppSetState, useAppStateValue} from 'stores/hooks/app';

export function useAuth (type = constants.appState.type.session) {
  return useAppStateValue(type, 'auth', constants.appState.scope.global);
}

export function useAuthSet (type = constants.appState.type.session) {
  return useAppSetState(type, 'auth', constants.appState.scope.global);
}

export function useAuthToken () {
  const session = useAuth(constants.appState.type.session);
  const local = useAuth(constants.appState.type.local);

  return session?.tokens?.token ?? local?.tokens?.token;
}

export function useAuthTokenTime () {
  const token = useAuthToken();

  return useMemo(() => {
    const jwt = utils.extractToken(token);

    return jwt?.iat ? (new Date(jwt.iat * 1000)) : null;
  }, [token]);
}

export function useAuthHolderToken () {
  const session = useAuth(constants.appState.type.session);
  const local = useAuth(constants.appState.type.local);

  return session?.tokens?.holder ?? local?.tokens?.holder;
}

export function useAuthHolderTokenTime () {
  const token = useAuthHolderToken();

  return useMemo(() => {
    const jwt = utils.extractToken(token);

    return jwt?.iat ? (new Date(jwt.iat * 1000)) : null;
  }, [token]);
}

export function useAuthUserId () {
  const auth = useAuth();

  return auth?.userId;
}

export function useAuthClientId () {
  const auth = useAuth();

  return auth?.clientId;
}

export function useAuthParentClientId () {
  const auth = useAuth();
  const isProxy = useAuthIsProxy();

  return isProxy ? auth?.client?.parentId : null;
}

export function useAuthTeamId () {
  const clientId = useAuthClientId();

  const session = useAuth(constants.appState.type.session);
  const local = useAuth(constants.appState.type.local);

  const teamId = session?.teamId ?? local?.teamId;

  return utils.isDefined(teamId) ? +teamId : (clientId === 0 ? 0 : null);
}

export function useAuthSetTeamId () {
  const setSession = useAuthSet(constants.appState.type.session);
  const setLocal = useAuthSet(constants.appState.type.local);

  return useCallback((teamId) => {
    setSession((current) => ({...current, teamId}));
    setLocal((current) => ({...current, teamId}));
  }, [setSession, setLocal]);
}

export function useAuthReset (callback) {
  const auth = useAuth();
  const clientId = useAuthClientId();
  const teamId = useAuthTeamId();

  const getState = useCallback(() => ({
    loggedIn: auth?.loggedIn,
    clientId: clientId,
    teamId: teamId
  }), [auth?.loggedIn, clientId, teamId]);

  const state = useRef(getState());

  useEffect(() => {
    const oldState = state.current;
    const newState = getState();

    if (!utils.compare(oldState, newState)) {
      let type;
      if (oldState.loggedIn) {
        if (!newState.loggedIn) {
          type = constants.resetTypes.logout;
        } else if (newState.clientId !== oldState.clientId) {
          type = constants.resetTypes.client;
        } else if (newState.teamId !== oldState.teamId) {
          type = constants.resetTypes.team;
        }
      }

      if (type) {
        callback(type);
      }

      state.current = newState;
    }
  }, [getState, callback]);
}

export function useAuthIsVentureIq () {
  const clientId = useAuthClientId();

  return clientId === 0;
}

export function useAuthIsProxyClient () {
  const isVentureIq = useAuthIsVentureIq();
  const parentClientId = useAuthParentClientId();

  return parentClientId >= 0 && !isVentureIq;
}

export function useAuthIsRegular () {
  const auth = useAuth();

  return auth?.type === constants.user.types.regular;
}

export function useAuthIsProxy () {
  const auth = useAuth();

  return auth?.type === constants.user.types.proxy;
}

export function useAuthIsNormal () {
  const token = useAuthToken();
  const jwt = utils.extractToken(token);

  return Boolean(jwt?.tokenType?.toLowerCase() === 'user');
}

export function useAuthIsPortal () {
  const token = useAuthToken();
  const jwt = utils.extractToken(token);

  return Boolean(jwt?.tokenType?.toLowerCase() === 'portal');
}

export function useLookupMeta (meta) {
  const lookupArray = utils.toArray(meta?.lookup);
  const lookupData0 = useServiceDataByIdEx(
    lookupArray?.[0]?.path || 'auth',
    lookupArray?.[0]?.id,
    lookupArray?.[0]?.params,
    lookupArray?.[0]?.dataType
  );
  const lookupData1 = useServiceDataByIdEx(
    lookupArray?.[1]?.path || 'auth',
    lookupArray?.[1]?.id,
    lookupArray?.[1]?.params,
    lookupArray?.[1]?.dataType
  );
  const lookupData2 = useServiceDataByIdEx(
    lookupArray?.[2]?.path || 'auth',
    lookupArray?.[2]?.id,
    lookupArray?.[2]?.params,
    lookupArray?.[2]?.dataType
  );

  let lookupData = null;
  if (lookupArray?.[0] && lookupData0?.data) {
    lookupData = {...lookupData, [lookupArray?.[0].name]: lookupData0?.data};
  }
  if (lookupArray?.[1] && lookupData1?.data) {
    lookupData = {...lookupData, [lookupArray?.[1].name]: lookupData1?.data};
  }
  if (lookupArray?.[2] && lookupData2?.data) {
    lookupData = {...lookupData, [lookupArray?.[2].name]: lookupData2?.data};
  }

  return {...meta, ...lookupData};
}

