import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';

const StyledSearchDialog = styled(Dialog)` 
  .MuiDialog-container {
    align-items: flex-start;  
  }
  
  .SearchDialog {
    &-title {
      font-size: ${props => props.theme.fontSize(16)};
      padding: ${props => props.theme.spacing(2)};
      box-shadow: inset 0px -1px 0px ${props => props.theme.property('palette.divider')};
    }
    
    &-content {
      padding: 0;
      border-top: unset;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      
      .Table {
        flex-grow: 1;
        
        .MuiTableBody-root {
          td:first-child {
            padding-left: ${props => props.theme.layout('2sp')};
          }

          td:last-child {
            padding-right: ${props => props.theme.layout('2sp')};
          }
        }
      }
    }
    
    &-actions {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${props => props.theme.layout(65)};
      background-color: ${props => props.theme.property('palette.primary.states.focusVisible')};
      
      &:not(:has(.ActionButton)) {
       display: none; 
      }
    }
    
    &-progressBar {
      width: 100%;
      position: absolute;
      top: -1px;
      height: ${props => props.theme.layout(2)};
      &.hide {
        visibility: hidden; 
      }
    }
  }

  ${props => props.$hasContent ? css`
    .MuiDialog-paper {
      min-height: clamp(${props => props.theme.layout(300)}, 75vh, ${props => props.theme.layout(535)});
      max-height: clamp(${props => props.theme.layout(300)}, 75vh, ${props => props.theme.layout(535)});
    }
    .SearchDialog {
      &-title {
        padding-bottom: 0;
      }
      
      &-tabs {
        padding-top: ${props => props.theme.spacing(2)};
      }
    }
    ` : null}
  
  ${ComponentStyles}
`;

StyledSearchDialog.propTypes = {
  theme: PropTypes.object
};

StyledSearchDialog.defaultProps = {}

export default StyledSearchDialog;
