import React, {useMemo} from 'react'
import ProfileSettingPage from 'components/pages/Setting/ProfileSettingPage/ProfileSettingPage';
import UsersSettingPage from 'components/pages/Setting/UsersSettingPage/UsersSettingPage';
import utils from 'helpers/utils';
import CategoriesSettingPage from 'components/pages/Setting/CategoriesSettingPage/CategoriesSettingPage';
import ServicesSettingPage from 'components/pages/Setting/ServicesSettingPage/ServicesSettingPage';
import PlanSettingPage from 'components/pages/Setting/PlanSettingPage/PlanSettingPage';
import CreditsSettingPage from 'components/pages/Setting/CreditsSettingPage/CreditsSettingPage';
import TeamsSettingPage from 'components/pages/Setting/TeamsSettingPage/TeamsSettingPage';
import FieldsSettingPage from 'components/pages/Setting/FieldsSettingPage/FieldsSettingPage';
import DealflowSettingPage from 'components/pages/Setting/DealflowSettingPage/DealflowSettingPage';
import useSettingClientRoutes from 'routes/settings/setting/client/client.routes';
import {Outlet} from 'react-router-dom';
import ConnectionsSettingPage from 'components/pages/Setting/ConnectionsSettingPage/ConnectionsSettingPage';

export default function useSettingRoutes () {
  const settingClientRoutes = useSettingClientRoutes();
  return useMemo(() => {
    const route = [
      {
        path: 'profile?',
        element: <ProfileSettingPage />,

        handle: {
          title: 'Settings - profile',
          auth: utils.createAuth({
            attribute: 'settings.profile.route'
          })
        }
      },
      {
        path: 'plan',
        element: <PlanSettingPage />,

        handle: {
          title: 'Settings - plan',
          auth: utils.createAuth({
            attribute: 'settings.plan.route'
          })
        }
      },
      {
        path: 'credits',
        element: <CreditsSettingPage />,

        handle: {
          title: 'Settings - credits',
          auth: utils.createAuth({
            attribute: 'settings.credits.route'
          })
        }
      },
      {
        path: 'users',
        element: <UsersSettingPage />,

        handle: {
          title: 'Settings - users',
          auth: utils.createAuth({
            attribute: 'settings.users.route'
          })
        }
      },
      {
        path: 'clients',
        element: <settingClientRoutes.WrapperComponent><Outlet/></settingClientRoutes.WrapperComponent>,
        children: settingClientRoutes.route,

        handle: {
          title: 'Settings - clients',
          auth: utils.createAuth({
            attribute: 'settings.clients.route'
          })
        }
      },
      {
        path: 'teams',
        element: <TeamsSettingPage />,

        handle: {
          title: 'Settings - teams',
          auth: utils.createAuth({
            attribute: 'settings.teams.route'
          })
        }
      },
      {
        path: 'fields',
        element: <FieldsSettingPage />,

        handle: {
          title: 'Settings - fields',
          auth: utils.createAuth({
            attribute: 'settings.fields.route'
          })
        }
      },
      {
        path: 'dealflow',
        element: <DealflowSettingPage />,

        handle: {
          title: 'Settings - deal flow',
          auth: utils.createAuth({
            attribute: 'settings.dealflow.route'
          })
        }
      },
      {
        path: 'categories',
        element: <CategoriesSettingPage />,

        handle: {
          title: 'Settings - categories',
          auth: utils.createAuth({
            attribute: 'settings.categories.route'
          })
        }
      },
      {
        path: 'services',
        element: <ServicesSettingPage />,

        handle: {
          title: 'Settings - services',
          auth: utils.createAuth({
            attribute: 'settings.services.route'
          })
        }
      },
      {
        path: 'connections',
        element: <ConnectionsSettingPage />,

        handle: {
          title: 'Connections - connections',
          auth: utils.createAuth({
            attribute: 'settings.connections.route'
          })
        }
      }
    ];

    return {
      WrapperComponent: React.Fragment,
      route: route
    };
  }, [settingClientRoutes]);
};
