import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledTimelineCard = styled(Card)`
  display: flex;
  background-color: unset;
  overflow: unset;
  width: 100%;
  
  .TimelineCard {
    &-prefix {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${props => props.theme.layout('0.75sp')};

      &-actions {
        display: none;
        flex-direction: column;
        gap: ${props => props.theme.layout('0.5sp')};
        top: ${props => props.theme.spacing(0.5)};
        right: ${props => props.theme.spacing(0.5)};
      }
    }
    
    &-content {
      width: 100%;
      margin-left: ${props => props.theme.spacing(2)};
      text-align: left;
      overflow: hidden;
      position: relative;

      background-color: unset;
      box-shadow: unset;

      .sender {
        font-weight: 700;
      }

      .title {
        font-weight: 500;
        margin-bottom: ${props => props.theme.layout('0.5sp')};
      }

      > *:not(.footer) {
        .Link {
          line-break: normal;
        }
      }
      
      .footer {
        display: block;
        
        .Tooltip-wrap {
          display: inline !important;
        }
      }
      
      .StatusText {
        white-space: nowrap;
        font-weight: 500;
      }

      .readMore {
        ${props => props.theme.font2Css('caption')};

        z-index: 1;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      &:has(.readMore) {
        .footer {
          max-width: calc(100% - ${props => props.theme.layout('8.5sp')});
        }
      }
    }
  }

  &:hover {
    .TimelineCard-prefix-actions {
      display: flex;
    }
  }

  &.TimelineCard-clampText {
    .InputContainer {
      overflow: hidden !important;
      .TextField-readOnly {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  // variants
  &.TimelineCard-comment {
    .TimelineCard-content {
      padding: ${props => props.theme.spacing(2)};
      border-radius: ${props => props.theme.radius(2)};
      background-color: ${props => props.theme.property('palette.background.paper')};
      border-top-left-radius: 0;
    }
  }

  &.TimelineCard-downloads, &.TimelineCard-task {
    .TimelineCard {
      &-content {
        > *:first-child {
          display: flex;
          gap: ${props => props.theme.layout('1.5sp')};

          > .Typography:first-child {
            flex-grow: 1;
          }

          > *:nth-child(2) {
            position: absolute;
            right: 0;
          }

          .IconButton {
            ${props => props.theme.state2Selector('IconButton', null, ['disabled'])} {
              opacity: ${props => props.theme.property('palette.action.disabledOpacity')};
            }
            &:not(${props => props.theme.state2Selector('IconButton', null, ['disabled'])}) {
              color: ${props => props.theme.property('palette.action.active')};
            }

            ${props => props.theme.state2Selector('IconButton', null, ['hover', 'focus', 'focusVisible'])} {
              color: ${props => props.theme.property('palette.text.primary')};
            }
          }
        }
        
        &:has(.ActionIconButton) {
          > *:first-child {
            > .Typography:first-child {
              overflow: hidden;
              margin-right: ${props => props.theme.layout('5sp')};
            }
          }
          .footer {
            overflow: hidden;
            margin-right: ${props => props.theme.layout('5sp')};
          }
        }
      }
    }
  }

  &.TimelineCard-evidence {
    p {
      padding-top: ${props => props.theme.layout('0.5sp')};

      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: ${props => props.theme.layout('0.5sp')};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledTimelineCard.propTypes = {
  theme: PropTypes.object
};

StyledTimelineCard.defaultProps = {}

export default StyledTimelineCard;
