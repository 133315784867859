import React, {useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import Add from '@mui/icons-material/Add';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import SettingsHeaderWrapper from 'components/templates/Wrappers/Settings/SettingsHeaderWrapper/SettingsHeaderWrapper';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledCategoriesSettingPage from 'components/pages/Setting/CategoriesSettingPage/CategoriesSettingPage.styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const info = 'Add and manage universal categories for all your company profiles';

const CategoriesSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'CategoriesSettingPage');

  const contentRef = useRef(null);

  const profileProvider = useProfile();
  const cardDefinitions = profileProvider.cardDefinitions;
  const [internalState, setInternalState] = useState({
    addNew: false,
    lastNew: null
  });

  const authorize = useAuthorize();

  const bBox = useBbox(() => contentRef.current, ['width']);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleShowCard = (card) => {
    if (internalState.addNew && utils.isDefined(internalState.lastNew)) {
      if (card.name.startsWith('tagGroup-new') && card.name !== internalState.lastNew) {
        setInternalState(utils.updater({addNew: false}, true));
      }
    }
    return card.name.startsWith('tagGroup') && (internalState.addNew || !card.name.startsWith('tagGroup-new'));
  };

  const handleCardClose = (e, reason, card) => {
    if (card.name.startsWith('tagGroup-new')) {
      setInternalState(utils.updater({addNew: false}, true));
    }
  };

  const action = useMemo(() => ({
    label: !mdDown ? 'Add connection' : null,
    tooltip: mdDown ? 'Add connection' : null,
    auth: utils.createAuth({attribute: 'client.tagGroup.create'}),
    icon: Add,
    iconPosition: 'start',
    onClick: (e) => {
      const newCard = cardDefinitions.find((c) => c.name.startsWith('tagGroup-new'));
      setInternalState(utils.updater({addNew: true, lastNew: newCard?.name}, true));
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      color: 'success'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'success',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [cardDefinitions, mdDown]);

  const handleCanUpdate = () => {
    return authorize({attribute: 'settings.categories.update'});
  }

  const handleDirty = (dirty) => {
    profileProvider.dirty?.(dirty);
  }

  const columns = bBox?.width ? Math.max(Math.floor((bBox?.width ?? 0) / 286), 1) : 0;

  return <StyledCategoriesSettingPage as={SettingsHeaderWrapper} {...innerProps}
                                      title="Categories"
                                      info={!smDown ? info : null}
                                      buttons={!mdDown ? <ActionButton action={action} /> : <ActionIconButton action={action} />}>
    <DndContext>
      <Box ref={contentRef} className="CategoriesSettingPage-content">
        <CollectionProfile columns={columns}
                           variant="grid"
                           sortable={true}
                           dragHandle={true}
                           onShowCard={handleShowCard}
                           forceEditNew={internalState.addNew}
                           onDirty={handleDirty}
                           onCanUpdate={handleCanUpdate}
                           ProfileCardProps={{
                             onClose: handleCardClose
                           }}/>
      </Box>
    </DndContext>
  </StyledCategoriesSettingPage>
};

CategoriesSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CategoriesSettingPage.defaultProps = {};

export default CategoriesSettingPage;
