import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {H5} from 'components/atoms/Text/Typography/Typography';
import DealflowDashboardCard from 'components/organisms/Cards/DealflowDashboardCard/DealflowDashboardCard';
import ThemeBreakdownDashboardCard
  from 'components/organisms/Cards/ThemeBreakdownDashboardCard/ThemeBreakdownDashboardCard';
import TeamMembersAvatarGroup from 'components/molecules/Avatars/TeamMembersAvatarGroup/TeamMembersAvatarGroup';
import StyledDashboardPage from 'components/pages/Dashboard/DashboardPage/DashboardPage.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import Cards from 'components/atoms/Cards/Cards/Cards';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useWrapper} from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import PeriodUpdateDashboardCard from 'components/organisms/Cards/PeriodUpdateDashboardCard/PeriodUpdateDashboardCard';
import TasksDashboardCard from 'components/organisms/Cards/TasksDashboardCard/TasksDashboardCard';
import RecentCollectionsDashboardCard
  from 'components/organisms/Cards/RecentCollectionsDashboardCard/RecentCollectionsDashboardCard';
import utils from 'helpers/utils';
import {useAuthIsProxy, useAuthTeamId} from 'services/auth/auth.utils';
import {useClientTeamMembers} from 'services/client/team/team.utils';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import {arrayMove} from '@dnd-kit/sortable';
import TeamManagerDialog from 'components/organisms/Dialogs/TeamManagerDialog/TeamManagerDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useAuthTeam} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Debounce from 'components/organisms/Utils/Debounce/Debounce';
import constants from 'helpers/constants';

const DashboardPage = (props) => {
  const innerProps = useComponentProps(props, 'DashboardPage');

  const wrapper = useWrapper();

  const profileProvider = useProfile();
  const cardState = profileProvider.cardState;
  const cardDefinitions = profileProvider.cardDefinitions;

  const teamId = useAuthTeamId();
  const team = useAuthTeam();
  const isProxy = useAuthIsProxy();
  const teamMembers = useClientTeamMembers(teamId, isProxy);
  const dialogControl = useDialogControl();

  const ref = useRef(null);

  const cards = useMemo(() => {
    const cards = [];
    if (cardDefinitions) {
      cardDefinitions
        .filter((cardDef) => cardState?.cardVisibility?.[cardDef.name] ?? true)
        .sort((a, b) => {
          const aPos = cardState.cardOrder.findIndex((c) => c === a.name);
          const bPos = cardState.cardOrder.findIndex((c) => c === b.name);
          return (aPos === -1 ? a.position : aPos) - (bPos === -1 ? b.position : bPos);
        })
        .forEach((cardDef) => {
          if (cardDef.name === 'periodUpdate') {
            cards.push({
              ...cardDef,
              anchor: 'left',
              span: 12,
              content: <PeriodUpdateDashboardCard className="DashboardPage-happening"/>
            });
          } else if (cardDef.name === 'tasks') {
            cards.push({
              ...cardDef,
              anchor: 'right',
              span: 12,
              content: <TasksDashboardCard className="DashboardPage-tasks"/>
            });
          } else if (cardDef.name === 'recentCollections') {
            cards.push({
              ...cardDef,
              span: 24,
              fit: true,
              content: <RecentCollectionsDashboardCard className="DashboardPage-recently"/>
            });
          } else if (cardDef.name === 'dealflow') {
            cards.push({
              ...cardDef,
              anchor: 'left',
              span: 11,
              content: <DealflowDashboardCard className="DashboardPage-dealflow"/>
            });
          } else if (cardDef.name === 'themeBreakdown') {
            cards.push({
              ...cardDef,
              anchor: 'right',
              fit: true,
              span: 13,
              content: <ThemeBreakdownDashboardCard className="DashboardPage-themeBreakdown"/>
            });
          }
          // else if (cardDef.name === 'insights') {
          //   cards.push({
          //     ...cardDef,
          //     anchor: 'right',
          //     span: 13,
          //     content: <InsightsDashboardCard className="DashboardPage-insights"/>
          //   });
          // }
        });
    }

    return cards;
  }, [cardDefinitions, cardState?.cardVisibility, cardState.cardOrder]);

  const addTeamMemberAction = useMemo(() => ({
    tooltip: 'Add team member',
    auth: utils.createAuth({attribute: 'team.member.create', meta: {team}}),
    onClick: (e) => {
      dialogControl.show(<TeamManagerDialog variant="extended"
                                            title="Add users"
                                            team={team}/>, true);
      e.preventDefault();
    }
  }), [dialogControl, team]);

  const handleDragDrop = (id, containerId, position) => {
    const oldPosition = cards?.findIndex((c) => c.name === id);
    if (oldPosition !== position) {
      const newArray = arrayMove([...cards], oldPosition, position);
      cardState.setCardOrder(newArray.map((c) => c.name));
    }
  }

  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const columns = !lgDown ? 24 : 1;

  return <StyledDashboardPage ref={ref} {...innerProps} $wrapper={wrapper}>
    <Box className="DashboardPage-header">
      <H5>Dashboard</H5>
      <TeamMembersAvatarGroup className="DashboardPage-header-teammates"
                              size="large"
                              teamMembers={teamMembers}
                              max={5} action={addTeamMemberAction}/>
    </Box>

    <DndContext>
      <Cards className="DashboardPage-cards"
             sortable={false}
             onDragDrop={handleDragDrop}
             columns={columns} gap={!lgDown ? 24 : 16}>
        {cards?.map((card, idx) => {
          return <CardItem key={idx}
                           data-key={card.name}
                           data-droppable={card.droppable !== false}
                           className="DashboardPage-card"
                           {...utils.filterObject(card, ['anchor', 'span', 'rows', 'grow', 'fit'], false)}>
            <Debounce key={card.name}
                      timeout={constants.debounce.minimal * Math.floor(idx / 2)}>
              {card.content}
            </Debounce>
          </CardItem>
        })}
      </Cards>
    </DndContext>
  </StyledDashboardPage>
};

DashboardPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

DashboardPage.defaultProps = {};

export default DashboardPage;
