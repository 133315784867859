import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import StyledActionLogo from 'components/molecules/Images/ActionLogo/ActionLogo.styles';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import utils from 'helpers/utils';
import Logo from 'components/atoms/Logos/Logo/Logo';

const ActionLogo = React.forwardRef((props, ref) => {
  const {
    action,
    size,
    color,
    onClick,
    showInactive,
    TooltipProps,
    ...innerProps
  } = useComponentProps({...props, ...props.action.ActionLogoProps}, 'ActionLogo', {
    static: ['disabled', 'clickable'],
    children: ['logo']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    disabled: !itm,
    clickable: Boolean(handleClick)
  });

  if (!itm && !showInactive) {
    return <React.Fragment/>;
  } else {
    return <StyledActionLogo ref={ref} {...innerProps}>
      <Tooltip title={action.tooltip}
               placement={action.tooltipPosition ?? 'bottom'}
               {...TooltipProps} {...action.TooltipProps}>
        <Box>
          {action.icon ? <Icon icon={action.icon}
                               color={color ?? action.color}
                               size={size ?? action.size}
                               className="ActionLogo-icon"
                               onClick={handleClick}
                               {...action.LogoProps} /> : null}
          {!action.icon ? <Logo logo={action.logo}
                                altLogo={action.altLogo}
                                fallbackIcon={action.fallbackIcon}
                                color={color ?? action.color}
                                size={size ?? action.size}
                                badge={action.badge}
                                className="ActionLogo-logo"
                                onClick={handleClick}
                                {...action.LogoProps} /> : null}
        </Box>
      </Tooltip>
    </StyledActionLogo>
  }
});

ActionLogo.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  action: PropTypes.object,
  onClick: PropTypes.func,
  color: PropTypes.string,
  showInactive: PropTypes.bool,
  TooltipProps: PropTypes.object
};

ActionLogo.defaultProps = {
};

export default ActionLogo;
