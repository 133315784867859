import React, {useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntityContextWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntityContextWrapper/CollectionEntityContextWrapper.styles';
import CollectionEntitySectionPanel
  from 'components/organisms/SectionPanels/CollectionEntitySectionPanel/CollectionEntitySectionPanel';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useSplitPath} from 'helpers/hooks/links';
import useMediaQuery from '@mui/material/useMediaQuery';

const CollectionEntityContextWrapper = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'CollectionEntityContextWrapper');

  const profileProvider = useProfile();

  const lsDown = useMediaQuery((theme) => theme.breakpoints.down('ls'));

  const [open, setOpen] = useState(!lsDown);
  const [size, setSize] = useState('medium');

  const [, pathPostfix] = useSplitPath('entities');
  const pathKey = pathPostfix.split('/')?.[1] ?? 'profile';

  const handleChange = (section) => {
    setSize(section.size ?? 'medium');
  }

  const handleContextToggle = (e, open) => {
    setOpen(open);
  }

  useLayoutEffect(() => {
    setOpen(!lsDown);
  }, [lsDown]);

  return <StyledCollectionEntityContextWrapper ref={ref} {...innerProps}
                                               variant={lsDown ? 'temporary' : 'standard'}
                                               context={<CollectionEntitySectionPanel onChange={handleChange}/>}
                                               open={open && Boolean(profileProvider.state?.showContext?.[pathKey])}
                                               onToggle={handleContextToggle}
                                               size={size}>
    {innerProps.children}
  </StyledCollectionEntityContextWrapper>
});

CollectionEntityContextWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
};

CollectionEntityContextWrapper.defaultProps = {};

export default CollectionEntityContextWrapper;
