import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledCollectionServiceProfileCardContent = styled(Box)`
  &.CollectionServiceProfileCardContent {
    display: flex;
    gap: ${props => props.theme.layout('1sp')};
    width: 100%;
    min-height: ${props => props.theme.layout(280)};
    max-height: ${props => props.theme.layout(280)};
    
    .CollectionServiceProfileCardContent {
      &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &-form {
        .FormControl {
          &.FormField-name-active {
            display: none;
          }
        }
      }

      &-readOnly, &-form-card {
        &-header {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          position: relative;
          border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
          background-color: ${props => props.theme.property('palette.primary.states.hover')};
          padding: ${props => props.theme.spacing(1)};

          > .Skeleton:has(.Chip), > .Span:has(.Chip), > .Chip {
            position: absolute;
            top: ${props => props.theme.spacing(1)};
            left: ${props => props.theme.spacing(1)};
          }
          
          > .Box {
            display: flex;
            align-items: center;
            gap: ${props => props.theme.layout('1sp')};
          }
        }
        
        &-image {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${props => props.theme.property('palette.background.paper')};
          border-radius: ${props => props.theme.radius(1.5)};
          box-shadow: ${props => props.theme.property('shadows.1')};
        }
      }
      
      &-readOnly {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        &-header {
          min-height: ${120 / 280 * 100}%;
          max-height: ${120 / 280 * 100}%;
        }
        
        &-image {
          width: ${props => props.theme.layout(40)};
          max-width: ${props => props.theme.layout(40)};
          height: ${props => props.theme.layout(40)};
          max-height: ${props => props.theme.layout(40)};
          padding: ${props => props.theme.spacing(1)};

          img, .Skeleton {
            width: ${props => props.theme.layout(21)};
            height: ${props => props.theme.layout(21)};
          }
        }
        
        &-content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding: ${props => props.theme.spacing(2)};
          gap: ${props => props.theme.layout('0.625sp')};
          
          .Markdown {
            flex-grow: 1;
            ${props => props.theme.font2Css('body2')};
            color: ${props => props.theme.property('palette.text.secondary')};
          }
        }
        
        &-price {
          display: flex;
          align-items: center;
          gap: ${props => props.theme.layout('0.5sp')};
          
          *:last-child {
            margin-top: ${props => props.theme.layout(2)};
          }
        }
      }

      &-form-card {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: clamp(0px, ${props => props.theme.layout(444)}, calc(86vw - ${props => props.theme.layout('3sp')}));
        min-width: clamp(0px, ${props => props.theme.layout(444)}, calc(86vw - ${props => props.theme.layout('3sp')}));
        max-width: clamp(0px, ${props => props.theme.layout(444)}, calc(86vw - ${props => props.theme.layout('3sp')}));

        &-header, &-content {
          .Markdown {
            flex-grow: 1;
            ${props => props.theme.font2Css('body1')};
            color: ${props => props.theme.property('palette.text.primary')};
          }
        }

        &-header {
          position: relative;
          min-height: ${props => props.theme.layout(164)};
          max-height: ${props => props.theme.layout(164)};
          justify-content: flex-end;
          gap: ${props => props.theme.layout('2sp')};
          padding-bottom: ${props => props.theme.layout('1sp')};

          > .Span:has(.IconButton), > .IconButton {
            position: absolute;
            top: ${props => props.theme.spacing(1.5)};
            right: ${props => props.theme.spacing(1.5)};
          }
        }

        &-image {
          width: ${props => props.theme.layout(56)};
          max-width: ${props => props.theme.layout(56)};
          height: ${props => props.theme.layout(56)};
          max-height: ${props => props.theme.layout(56)};
          padding: ${props => props.theme.spacing(2)};

          img {
            width: ${props => props.theme.layout(30)};
            height: ${props => props.theme.layout(30)};
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          height: clamp(0px, ${props => props.theme.layout(344)}, calc(86vh - ${props => props.theme.layout(144 + 68 + 3)} - ${props => props.theme.layout('3sp')}));
          min-height: clamp(0px, ${props => props.theme.layout(344)}, calc(86vh - ${props => props.theme.layout(144 + 68 + 3)} - ${props => props.theme.layout('3sp')}));
          max-height: clamp(0px, ${props => props.theme.layout(344)}, calc(86vh - ${props => props.theme.layout(144 + 68 + 3)} - ${props => props.theme.layout('3sp')}));
          padding: ${props => props.theme.spacing(3)};
          
          .Markdown {
            padding-bottom: ${props => props.theme.spacing(1.5)};
          }
        }

        &-price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: ${props => props.theme.layout('0.5sp')};
          padding: ${props => props.theme.spacing(1)};
          background-color: ${props => props.theme.property('palette.primary.states.hover')};
          border: 1px solid ${props => props.theme.property('palette.primary.main')};
          border-radius: ${props => props.theme.radius(2)};

          .H3 {
            display: inline-flex;
            align-items: center;
            gap: ${props => props.theme.layout('0.5sp')};
            color: ${props => props.theme.property('palette.primary.main')};

            *:last-child {
              margin-top: ${props => props.theme.layout(2)};
            }
          }
        }
      }
      
      &-footer {
        background-color: ${props => props.theme.property('palette.background.paper')};
        border-top: 1px solid ${props => props.theme.property('palette.divider')};
        padding: ${props => props.theme.spacing(2)};
        max-height: ${props => props.theme.layout(68)};
      }
    }
  }

  &.CollectionServiceProfileCardContent-isEditing {
    min-height: unset;
    max-height: unset;
  }

  &.CollectionServiceProfileCardContent-isExtended {
    .CollectionServiceProfileCardContent {
      &-form {
        height: 100%;

        &-context {
          height: 100%;
          overflow-y: auto;

          display: flex;
          flex-direction: column;
          
          &-header {
            overflow-y: auto;
            flex-grow: 1;
          }
          
          .Form-fields {
            flex-grow: 1;
            align-content: flex-start;
            padding: ${props => props.theme.spacing(2)};
            row-gap: ${props => props.theme.spacing(0.5)} !important;
  
            .Form-section {
              margin-bottom: ${props => props.theme.layout('0.5sp')};
            }
            
            .FormField-name-dealflow, .FormField-name-allCollections {
              justify-content: space-between;

              .SwitchField-label {
                margin-right: -${props => props.theme.layout(6)};
              }
              
              .InputContainer {
                flex-grow: unset;
              }
              .InputLabel {
                white-space: nowrap;
                max-width: unset;
                min-width: ${props => props.theme.layout(80)};
                font-size: ${props => props.theme.fontSize(16)};
              }
            }
          }
        }
        
        &-card {
          flex-grow: unset;
          width: unset;
          min-width: unset;
          max-width: unset;
          height: unset;
          max-height: unset;
          min-height: fit-content;
          background-color: ${props => props.theme.property('palette.primary.states.hover')};
          border-bottom: 1px solid ${props => props.theme.property('palette.divider')};

          &-header {
            background-color: transparent;
            min-height: fit-content;
            max-height: unset;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0;
            border: unset;
            margin: ${props => props.theme.spacing(2)};
            
            > .Typography {
              margin-bottom: -${props => props.theme.spacing(1)};
            }
            .Markdown {
              ${props => props.theme.font2Css('body2')};
              color: ${props => props.theme.property('palette.text.primary')};
            }
          }

          &-content {
            width: unset;
            min-width: unset;
            max-width: unset;
            height: unset;
            max-height: unset;
            min-height: fit-content;
            padding: 0;
            
            .Markdown {
              padding-bottom: 0;
            }
          }

          &-image {
            width: ${props => props.theme.layout(40)};
            max-width: ${props => props.theme.layout(40)};
            height: ${props => props.theme.layout(40)};
            max-height: ${props => props.theme.layout(40)};
            padding: ${props => props.theme.spacing(1)};
  
            img {
              width: ${props => props.theme.layout(21)};
              height: ${props => props.theme.layout(21)};
            }
          }
        }
        
        &-credits {
          border-top: 1px solid ${props => props.theme.property('palette.divider')};
          padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};

          &-header {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .Typography {
              font-size: ${props => props.theme.fontSize(13)};
              margin-bottom: ${props => props.theme.spacing(0.25)};
            }
          }
        }

        .PageBar-toolbar {
          padding: 0 ${props => props.theme.layout('2sp')};
        }
        
        .ContextWrapper-content {
          flex-grow: 1;
          width: 1px;
          .Form-fields {
            height: 100%;
            .FormField-name-collections {
              height: 100%;
              
              .ComponentField-container {
                height: 100%;

                .PageBar-title {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                   
                  * {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
        
        &-table {
          .MuiTableHead-root, .MuiTableBody-root {
            td, th {
              &:first-child {
                padding-left: ${props => props.theme.layout('2sp')};
              }
              &:last-child {
                padding-right: ${props => props.theme.layout('2sp')};
              }
            }
          }
        }
      }
    }
  }


  ${props => props.theme.breakpoints.down('lg')} {
    .CollectionServiceProfileCardContent {
      &-form-card {
        &-content {
          padding: ${props => props.theme.spacing(2)} !important;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionServiceProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionServiceProfileCardContent.defaultProps = {}

export default StyledCollectionServiceProfileCardContent;
