import React, {
  Children,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem, useRecycleSlots} from 'helpers/hooks/utils';
import StyledAutocompleteField from 'components/molecules/Fields/AutocompleteField/AutocompleteField.styles';
import utils from 'helpers/utils';
import TextField from 'components/molecules/Fields/TextField/TextField';
import MenuItem from 'components/atoms/Menus/MenuItem/MenuItem';
import Chip from 'components/atoms/Chips/Chip/Chip';
import {useVirtualizer} from '@tanstack/react-virtual'
import useMediaQuery from '@mui/material/useMediaQuery';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import {Span} from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import FormControl from 'components/atoms/Controls/FormControl/FormControl';
import InputLabel from 'components/atoms/Labels/InputLabel/InputLabel';
import FormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText';
import InputContainer from 'components/atoms/Layout/InputContainer/InputContainer';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import dom from 'helpers/dom';
import Icon from 'components/atoms/Icons/Icon/Icon';
import AutocompleteFieldPaper from 'components/molecules/Fields/AutocompleteField/AutocompleteFieldPaper';
import {withMemo} from 'helpers/wrapper';
import ActionChip from 'components/molecules/Chips/ActionChip/ActionChip';

const AutocompleteField = withMemo(React.forwardRef((props, ref) => {
  const {
    name,
    label,
    variant,
    placeholder,
    icon,
    value,
    size,
    radius,
    readOnly,
    multiple,
    autoFocus,
    openDirect,
    openOnFocus,
    fullWidth,
    hideEmpty,
    hideOpenClose,
    freeSolo,
    forceFiltering,
    hiddenLabel,
    hiddenHelperText,
    hiddenSelectionHelper,
    hiddenPlaceholder,
    hiddenIcons,
    onChange,
    onBlur,
    error,
    success,
    helperText,
    options,
    preload,
    loadEmpty,
    loadOnce,
    sorted,
    createOption,
    clearable,
    format,
    virtualize,
    readOnlyChip,
    readOnlyOptionClick,
    readOnlyAction,
    getTagProps,
    renderReadOnlyOption,
    renderInputAdornment,
    onFilterOption,
    clearAfterChange,
    isLoading,
    inputProps,
    InputProps,
    MenuItemProps,
    ChipListProps,
    ChipProps,
    IconProps,
    TagProps,
    InputLabelProps,
    FormHelperTextProps,
    ...innerProps
  } = useComponentProps(props, 'AutocompleteField', {
    static: ['disabled', 'focused', 'error', 'fullHeight', 'multiple', 'freeSolo', 'virtualize'],
    children: ['checkbox', 'label', 'helper']
  });

  const firstOpen = useRef(true);
  const [focusActive, setFocusActive] = React.useState(false);

  const [internalState, setInternalState] = useState({
    searchValue: '',
    inputValue: '',
    isLoading: false,
    open: false,
    listBox: null,
    internalOptions: {searchValue: null, options: []},
    filteredOptions: {inputValue: null, options: []}
  });

  const innerRef = useRef(null);
  const selectionRef = useRef([]);
  const selectionInvalidRef = useRef(false);
  const highlightedIndexRef = useRef(-1);
  const autocompleteField = useMemo(() => ({
    refs: {
      ref: innerRef,
      highlightedIndexRef
    },
    state: {
      ...internalState, // the state from start, and internal toggle
      ...utils.cleanObject({isLoading}) // the override state (nulls are cleaned), see onToggle
    },
    reset: () => {
      setInternalState((current) => ({
        ...current,
        internalOptions: {
          searchValue: null,
          options: []
        }
      }));
    }
  }), [internalState, isLoading]);

  useImperativeHandle(ref, () => autocompleteField);

  const indexOptions = useCallback((options) => {
    return (options || []).map((opt, idx) => {
      if (utils.isObject(opt)) {
        return {...opt, index: idx};
      } else {
        return {label: opt, value: opt, index: idx};
      }
    });
  }, []);

  const isOptionEqualToValue = useCallback((option, value) => {
    return utils.matchOptionToValue(option, value);
  }, []);

  const cacheSelectedResults = useCallback((values, lookup = true) => {
    selectionRef.current = !lookup ? values : values
      .map((v) => {
        return selectionRef.current.find((option) => isOptionEqualToValue(option, v)) ??
          (utils.isObject(v) ? {...v, undefinedOption: (!utils.isDefined(v.label) && !freeSolo)} :
            {label: v.toString(), value: v, undefinedOption: !freeSolo});
      });
  }, [isOptionEqualToValue, freeSolo]);

  const optionsIsFn = utils.isFunction(options);
  const optionsMemo = useEffectItem(optionsIsFn ? null : indexOptions(options));
  const availableOptions = optionsIsFn ? autocompleteField.state.internalOptions.options : optionsMemo;

  cacheSelectedResults(utils.toArray(value, true));
  const extraOptions = useEffectItem(selectionRef.current
    .filter((opt1) => {
      return !freeSolo && !selectionInvalidRef.current &&
        !availableOptions.find((opt2) => isOptionEqualToValue(opt1, opt2)) && !opt1.undefinedOption
    }));

  const indexedOptions = useMemo(() => {
    return indexOptions(availableOptions.concat(extraOptions))
      .filter((opt) => {
        return !opt.createdOption || multiple || !freeSolo
      });
  }, [availableOptions, extraOptions, indexOptions, multiple, freeSolo]);

  const selection = useMemo(() => {
    const getOption = (v) => {
      const opt = innerProps.autoComplete ? indexedOptions?.find((opt) => isOptionEqualToValue(opt, v)) : null;
      if (opt) {
        if (utils.isObject(v)) {
          return {...v, ...opt};
        } else {
          return opt;
        }
      } else {
        if (utils.isObject(v)) {
          return {...v, undefinedOption: (!utils.isDefined(v.label) && !freeSolo)};
        } else if (v && (utils.isString(v) || freeSolo)) {
          return {label: v.toString(), value: freeSolo ? v : null, undefinedOption: !freeSolo};
        } else if (utils.isDefined(v) && multiple) {
          return {label: '???', value: v, undefinedOption: !freeSolo};
        } else {
          return null;
        }
      }
    }

    let selection;
    if (multiple) {
      selection = (typeof value === 'string' && value) ?
        value.split(',') : value;
      selection = selection ? utils.toArray(selection, true)
        .map((v) => getOption(v)).filter((_) => (_)) : [];
      if (sorted) {
        selection = selection.sort((a, b) => a.label.localeCompare(b.label));
      }

      cacheSelectedResults(utils.toArray(selection, true), false);
      selection = selection.filter((opt) => freeSolo || !optionsIsFn || !opt.undefinedOption);
    } else {
      selection = getOption(value);

      cacheSelectedResults(utils.toArray(selection, true), false);
      selection = (freeSolo || !optionsIsFn || !selection?.undefinedOption) ? selection : null;
    }

    return selection;
  }, [value, multiple, sorted, freeSolo, innerProps.autoComplete, indexedOptions, isOptionEqualToValue, cacheSelectedResults, optionsIsFn]);

  const applyVirtualization = virtualize ?? (indexedOptions?.length > 1000);
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const recycleSlots = useRecycleSlots();
  const virtualizer = useVirtualizer({
    count: autocompleteField.state.filteredOptions.options.length,
    getScrollElement: () => {
      if (applyVirtualization) {
        return autocompleteField.state.listBox?.parentElement
      }
    },
    estimateSize: () => smUp ? 36 : 48,
    overscan: 16
  })

  const disableCloseOnSelect = innerProps.disableCloseOnSelect ??
    (multiple && autocompleteField.state.internalOptions.options?.length > 0);
  const isOpen = autocompleteField.state.open &&
    (!createOption || utils.isDefined(options) || autocompleteField.state.inputValue?.toString()?.trim?.()?.length > 0) &&
    (!(hideEmpty || hideOpenClose) || indexedOptions?.length > 0);

  const optionsEvent = useEffectEvent(options);
  const filterSelectedOptions = innerProps.filterSelectedOptions ?? multiple;

  const getOptions = useCallback((inputValue, callback) => {
    optionsEvent({
      search: inputValue,
      ids: filterSelectedOptions ? utils.toArray(selectionRef.current, true).map((opt) => `-${opt.value ?? opt}`) : null,
      callback
    });
  }, [optionsEvent, filterSelectedOptions, selectionRef]);

  const debouncedOptions = useMemo(() => {
    return utils.debounce((inputValue, callback) => {
      getOptions(inputValue, callback);
    }, constants.debounce.input);
  }, [getOptions]);

  const searchValue = autocompleteField.state.searchValue?.trim();
  useEffect(() => {
    let active = true;

    if (optionsIsFn) {
      if (!readOnly && ((focusActive && hideEmpty) || preload || autocompleteField.state.open)) {
        if ((loadOnce && !utils.isDefined(autocompleteField.state.internalOptions.searchValue)) ||
            (!loadOnce && autocompleteField.state.internalOptions.searchValue !== searchValue)) {
          if (loadEmpty || searchValue || autocompleteField.state.internalOptions.searchValue) {
            setInternalState(utils.updater({
              isLoading: true
            }, true));

            const optionsFn = !utils.isEmpty(searchValue) ? debouncedOptions : getOptions;
            optionsFn(searchValue, (results) => {
              if (active) {
                results = results.concat(
                  utils.toArray(selectionRef.current, true)
                    .filter((opt1) => !freeSolo && !selectionInvalidRef.current &&
                      !results.find((opt2) => isOptionEqualToValue(opt1, opt2))));

                setInternalState((current) => {
                  if (current.internalOptions.searchValue !== searchValue || !utils.compare(current.internalOptions.options, results)) {
                    return {
                      ...current,
                      isLoading: false,
                      internalOptions: {
                        searchValue: searchValue,
                        options: indexOptions(results.map((_) => (_)))
                      }
                    };
                  } else {
                    return {...current, isLoading: false};
                  }
                });
              } else {
                setInternalState(utils.updater({
                  isLoading: false
                }, true));
              }
            });
          } else {
            setInternalState((current) => {
              const results = utils.toArray(selectionRef.current, true).filter(() => !freeSolo);
              if (current.internalOptions.searchValue !== searchValue || !utils.compare(current.internalOptions.options, results)) {
                return {
                  ...current,
                  isLoading: false,
                  internalOptions: {
                    searchValue: '',
                    options: results
                  }
                };
              } else {
                return {...current, isLoading: false};
              }
            });
          }
        }
      }
    }

    return () => {
      active = false;
    };
  }, [optionsIsFn, focusActive, multiple, hideEmpty, preload, loadOnce, loadEmpty, readOnly, freeSolo, searchValue, indexOptions,
    debouncedOptions, getOptions, isOptionEqualToValue, autocompleteField.state.open, autocompleteField.state.internalOptions.searchValue]);

  const undefinedOptions = useEffectItem(utils.toArray(selectionRef.current, true).filter((s) => s.undefinedOption));
  useLayoutEffect(() => {
    let active = true;

    if (optionsIsFn) {
      if (undefinedOptions.length > 0 && autocompleteField.state.internalOptions.searchValue !== '') {
        optionsEvent({
          search: '',
          ids: undefinedOptions.map((opt) => `${opt.value ?? opt.label ?? opt}`),
          callback: (results) => {
            if (active) {
              results = results.concat(
                utils.toArray(selectionRef.current, true)
                  .filter((opt1) => !freeSolo && !results.find((opt2) => isOptionEqualToValue(opt1, opt2))));

              setInternalState((current) => {
                if (!utils.compare(current.internalOptions.options, results)) {
                  return {
                    ...current,
                    isLoading: false,
                    internalOptions: {
                      searchValue: null,
                      options: indexOptions(results.map((_) => (_)))
                    }
                  };
                } else {
                  return {...current, isLoading: false};
                }
              });
            } else {
              setInternalState(utils.updater({
                isLoading: false
              }, true));
            }
          }
        });
      }
    }

    return () => {
      active = false;
    };
  }, [optionsIsFn, undefinedOptions, debouncedOptions, freeSolo,
    optionsEvent, indexOptions, isOptionEqualToValue, autocompleteField.state.internalOptions.searchValue]);

  useLayoutEffect(() => {
    if (!optionsIsFn) {
      const results = optionsMemo ?? utils.toArray(selectionRef.current, true);
      setInternalState((current) => {
        const options = indexOptions(results.map((_) => (_)));
        if (!utils.compare(current.internalOptions.options, options)) {
          return {
            ...current,
            internalOptions: {
              searchValue: searchValue,
              options: options
            }
          };
        } else {
          return current;
        }
      });
    }
  }, [optionsIsFn, optionsMemo, indexOptions, searchValue, selectionRef]);

  const selectionMemo = useEffectItem(utils.filterObject(selection, ['label', 'value'], false));
  useLayoutEffect(() => {
    if (!multiple) {
      if (!selectionMemo) {
        setInternalState((current) => {
          return current.inputValue !== '' ?
            {
              ...current,
              searchValue: '',
              inputValue: ''
            } : current;
        });
      } else {
        setInternalState((current) => {
          const value = (!freeSolo ? selectionMemo.label : selectionMemo.value?.toString()) ?? selectionMemo;

          return (current.inputValue !== value) ?
            {
              ...current,
              searchValue: '',
              inputValue: value
            } : current;
        });
      }
    }
  }, [multiple, selectionMemo, isOptionEqualToValue, freeSolo]);

  const doClearInput = () => {
    if (optionsIsFn) {
      setInternalState(utils.updater({
        inputValue: '',
        searchValue: '',
        internalOptions: {
          searchValue: null,
          options: []
        }
      }, true));
    } else {
      setInternalState(utils.updater({
        inputValue: '',
        searchValue: ''
      }, true));
    }
  }

  const doChange = (value, forceBlur = false) => {
    const e = {
      target: {
        name: name,
        value: value ?? ''
      }
    };

    onChange?.(e);
    if (forceBlur) {
      onBlur?.(e);
    }

    if (clearAfterChange) {
      if (disableCloseOnSelect) {
        setInternalState(utils.updater({inputValue: ''}, true));
      } else {
        doClearInput();
      }
    }

    selectionInvalidRef.current = false;
  };

  const handleChange = (e, value, reason) => {
    autocompleteField.refs.highlightedIndexRef.current = -1;

    cacheSelectedResults(utils.toArray(value, true));

    if (multiple) {
      value = utils.toArray(value).map((v) => utils.isObject(v) ? utils.filterObject(v, ['index']) : v);
    } else {
      value = utils.isObject(value) ? utils.filterObject(value, ['index']) : value;
    }

    doChange(value, reason === 'removeOption');
  };

  const handleFocus = (e) => {
    innerProps.onFocus?.(e);
    setFocusActive(!readOnly);
  };

  const handleBlur = (e) => {
    setFocusActive(false);
    if (clearAfterChange) {
      doClearInput();
    } else {
      if (!multiple) {
        const exactMatches = indexedOptions.filter((opt) => (opt.label ?? opt).toLowerCase() === autocompleteField.state.inputValue?.toString()?.trim()?.toLowerCase());
        if (innerProps.autoComplete && exactMatches.length === 1 && !isOptionEqualToValue(value, exactMatches[0])) {
          doChange(exactMatches[0]);
          const value = (!freeSolo ? exactMatches[0].label : exactMatches[0].value?.toString()) ?? exactMatches[0];

          setInternalState(utils.updater({
            inputValue: value,
            searchValue: ''
          }, true));
        } else {
          if (!Boolean(value) && !loadOnce) {
            doClearInput();
          } else {
            const value = (!freeSolo ? selectionMemo?.label : selectionMemo?.value?.toString()) ?? selectionMemo ?? '';

            setInternalState(utils.updater({
              inputValue: value,
              searchValue: ''
            }, true));
          }
        }
      }
    }
    onBlur?.(e);

    selectionInvalidRef.current = false;
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter' && isOpen && !disableCloseOnSelect) {
      setInternalState(utils.updater({open: false}, true));
      e.preventDefault();
    }
  };

  const doScrollToIndex = useCallback((index) => {
    if (autocompleteField.state.listBox) {
      if (applyVirtualization) {
        if (virtualizer.getMeasurements().length > 0) {
          try {
            virtualizer.scrollToIndex(index);
          } catch {
            /* SQUASH */
          }
        }
      }

      utils.retry(() => {
        const el = autocompleteField.state.listBox?.querySelector?.(`[data-option-index="${index}"]`);

        if (el) {
          return dom.scrollIntoView(el, {
            align: 'auto',
            preventScroll: true
          });
        }
      }, 3);
    }
  }, [virtualizer, applyVirtualization, autocompleteField.state.listBox]);

  const debouncedListBox = useMemo(() => {
    return utils.debounce(() => {
      const el = window.document.querySelector(`[data-field-id="${name}"]`);
      const ref = el?.parentElement

      setInternalState((current) => {
        if (current.listBox !== ref) {
          if (ref) {
            if (dom.getComputedStyle(ref).maxHeight !== 'none') {
              ref.parentElement.style.maxHeight = dom.getComputedStyle(ref).maxHeight;
              ref.style.maxHeight = 'unset';
            }
          }
          return {...current, listBox: ref};
        } else {
          return current;
        }
      });
    }, constants.debounce.minimal);
  }, [name]);

  useLayoutEffect(() => {
    return utils.observeTimeout(() => {
      if (multiple) {
        doScrollToIndex(0);
      } else {
        doScrollToIndex(selection?.index ?? 0);
      }
    }, 100);
  }, [multiple, applyVirtualization, doScrollToIndex, selection?.index])

  useEffect(() => {
    if (focusActive) {
      setInternalState((current) => {
        const open = current.open || (openOnFocus ?? (
          current.inputValue?.toString()?.trim?.()?.length === 0
        ));

        return utils.updater({
          ...current,
          open
        })(current);
      });
    } else {
      setInternalState(utils.updater({open: false}, true));
    }
  }, [focusActive, openOnFocus])

  const handleOpen = () => {
    if (firstOpen.current) {
      firstOpen.current = false;
      if (openDirect || createOption) {
        setInternalState(utils.updater({open: true}, true));
      }
    } else {
      setInternalState(utils.updater({open: true}, true));
    }
  };

  const handleClose = () => {
    autocompleteField.refs.highlightedIndexRef.current = -1;
    setInternalState((current) => {
      return {
        ...current,
        value,
        searchValue: multiple ? '' : current.searchValue,
        inputValue: !multiple ? current.inputValue : '',
        open: false
      };
    });
  };

  const renderInput = (params) => {
    const handleRef = (ref) => {
      if (ref) {
        const paramRef = params?.inputProps?.ref;
        if (paramRef) {
          if (utils.isFunction(paramRef)) {
            paramRef(ref);
          } else {
            paramRef.current = ref;
          }
        }

        const inputValue = params?.inputProps?.value;
        const highlightOption = autocompleteField.state.filteredOptions.options.find((opt) => opt.index === autocompleteField.refs.highlightedIndexRef.current);
        if (highlightOption && innerProps.autoComplete) {
          const label = highlightOption?.label ?? highlightOption;
          ref.value = label;

          // The portion of the selected suggestion that has not been typed by the user,
          // a completion string, appears inline after the input cursor in the textbox.
          if (inputValue?.length > 0) {
            const index = label.toLowerCase().indexOf(inputValue.toLowerCase());
            if (index === 0 && inputValue.length > 0) {
              ref.setSelectionRange(inputValue.length, label.length);
            }
          }
        }
      }
    }

    const renderAdornment = () => {
      const isLoader = isLoading || ((hideEmpty || autocompleteField.state.open) && autocompleteField.state.isLoading);

      let adornment = [];
      if (isLoader) {
        adornment.push(<CircularProgress className="MuiAutocomplete-endAdornment loader"/>);
      } else if (InputProps?.endAdornment) {
        adornment.push(InputProps?.endAdornment);
      }

      if (!hideOpenClose && utils.isDefined(options)) {
        adornment = adornment.concat(Children.toArray(params?.InputProps?.endAdornment));
      }
      const renderedAdornment = adornment.map((child, idx) => utils.cloneElement(child, {key: idx}));

      if (renderInputAdornment) {
        return renderInputAdornment({
          value: selection,
          open: isOpen,
          readOnly: readOnly,
          disabled: innerProps.disabled,
          isLoading: isLoader,
          options: indexedOptions,
          renderedAdornment: renderedAdornment,
          onClick: (e) => {
            setInternalState((current) => {
              return utils.updater({
                ...current,
                open: !current.open
              })(current)
            });
            e.preventDefault();
          },
          InputProps
        });
      } else {
        return renderedAdornment;
      }
    }

    return <TextField ref={innerRef} {...params}
                      value={params?.inputProps?.value}
                      InputProps={{
                        ...params?.InputProps,
                        ...InputProps,
                        startAdornment: <React.Fragment>
                          {InputProps?.startAdornment}
                          {params?.InputProps.startAdornment}
                        </React.Fragment>,
                        endAdornment: renderAdornment(),
                        size
                      }}
                      inputProps={{
                        ...utils.filterObject(params?.inputProps, 'value'),
                        ref: handleRef,
                        ...inputProps
                      }}
                      {...utils.cleanObject({
                        autoFocus,
                        hiddenLabel,
                        hiddenHelperText,
                        fullWidth,
                        readOnly,
                        label,
                        name,
                        size,
                        radius,
                        format,
                        placeholder: (!value || value.length === 0) ? placeholder : '',
                        error,
                        success,
                        helperText,
                        variant,
                        InputLabelProps,
                        FormHelperTextProps
                      })} />
  };

  const debouncedFilteredOptions = useMemo(() => {
    return utils.debounce((inputValue, options) => {
      setInternalState((current) => {
        if (current.filteredOptions.inputValue !== inputValue || !utils.compare(current.filteredOptions.options, options)) {
          return {...current, filteredOptions: {inputValue, options}};
        } else {
          return current;
        }
      });
    }, constants.debounce.minimal);
  }, []);

  const renderOption = (props, option, state) => {
    const render = (idx, ref, style, extra) => {
      const selected = !multiple ?
        autocompleteField.state.filteredOptions.options.find((opt) => opt.index === option.index && isOptionEqualToValue(opt, value)) :
        autocompleteField.state.filteredOptions.options.find((opt) => opt.index === option.index &&
          utils.toArray(value, true).find((v) => isOptionEqualToValue(opt, v)));

      state = {...state, selected: Boolean(selected)};

      const renderedOption = <Span noWrap showTooltip>{option.addLabel ?? option.label}</Span>;
      return <MenuItem ref={ref} {...props}
                       style={{...props?.style, ...style}}
                       {...extra}
                       size={size}
                       aria-selected={state.selected}
                       value={option.value}
                       key={`key_${idx}`}
                       {...MenuItemProps}>
        {!innerProps.renderOption ? renderedOption : innerProps.renderOption(option, state, {renderedOption})}
      </MenuItem>
    }

    debouncedListBox();

    if (applyVirtualization) {
      const slotIdx = recycleSlots.slot(option.index)?.idx;
      const virtualRow = virtualizer.getVirtualItems()[recycleSlots.slot(option.index)?.row];
      if (virtualRow) {
        const className = autocompleteField.refs.highlightedIndexRef.current === virtualRow.index ? 'Mui-focus Mui-focusVisible' : null;

        return render(slotIdx, (ref) => {
          if (ref) {
            virtualizer.measureElement(ref);
          }
        }, {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${virtualRow.start}px)`,
        }, {
          className: utils.classNames(className, props.className),
          'data-field-id': name,
          'data-index': virtualRow.index
        });
      } else {
        return <li key={option.index}
                   data-index={option.index}
                   data-field-id={name}
                   data-option-index={props['data-option-index']}
                   tabIndex={props.tabIndex} />
      }
    } else {
      return render(option.index, null, null, { 'data-field-id': name });
    }
  };

  const renderTags = (value, getOrgTagProps, state) => {
    const chips = value.map((opt, idx) => {
      return <Chip size={size === 'medium' ? 'large' : 'medium'}
                   label={opt?.label ?? opt}
                   {...getTagProps?.(opt)}
                   {...getOrgTagProps({ index: idx })}
                   {...TagProps} />
    });

    if (state.focused) {
      return chips;
    } else {
      return <ChipList variant="compact"
                       nowrap={true}
                       className="MuiAutocomplete-chips"
                       drawFromStart
                       {...ChipListProps}>
        {chips}
      </ChipList>
    }
  };

  const applyFiltering = !optionsIsFn || forceFiltering;
  const onFilterOptionEvent = useEffectEvent(onFilterOption);
  const filterOptions = (opts) => {
    opts = opts.filter((opt) => {
      const filter = onFilterOptionEvent?.(opt);
      return !opt.createdOption && (utils.isDefined(filter) ? filter : true);
    });

    const value = !applyVirtualization ? autocompleteField.state.searchValue?.trim() :
      (autocompleteField.state.searchValue?.trim() || autocompleteField.state.inputValue?.trim());
    if (value?.length > 0 && (multiple || applyVirtualization || !isOptionEqualToValue(selection, value))) {
      opts = applyFiltering ? opts.filter((opt) => {
        return opt.alwaysVisibleOption || (opt?.label ?? opt).toLowerCase().includes(value.toLowerCase());
      }) : opts;

      if (!autocompleteField.state.isLoading && Boolean(createOption)) {
        const filter = onFilterOptionEvent?.(value);
        if (!opts.find((opt) => isOptionEqualToValue(opt, value)) &&
            (!utils.isDefined(filter) || filter) &&
            !utils.toArray(selection, true).find((opt) => isOptionEqualToValue(opt, value))) {
          const option = utils.mergeObjects({
            label: value,
            addLabel: `Add '${value}'`,
            createdOption: true,
            value: null
          }, utils.isFunction(createOption) ? createOption(value) : {});

          opts.push(option);
        }
      }
    }

    opts = indexOptions(opts);
    debouncedFilteredOptions(value, opts);
    return opts;
  };

  innerProps.onInputChange = (e, inputValue, reason) => {
    if (reason !== 'reset' || ['click', 'keydown'].includes(e?.type)) {
      setInternalState((current) => {
        if (!utils.compare(current.inputValue, inputValue)) {
          const newInputValue = ((!freeSolo && multiple) || reason === 'input');
          const newSearchValue = ((!freeSolo && !disableCloseOnSelect) || reason === 'input');

          selectionInvalidRef.current = newSearchValue ? !multiple : selectionInvalidRef.current;
          return {
            ...current,
            searchValue: newSearchValue ? inputValue : current.searchValue,
            inputValue: newInputValue ? inputValue : current.inputValue
          }
        } else {
          return current;
        }
      });
    }

    autocompleteField.refs.highlightedIndexRef.current = -1;
    if (!multiple && reason === 'input') {
      if (Boolean(value) && !(inputValue?.toString()?.trim()?.length > 0)) {
        if (clearable) {
          doChange('');
        }
      } else if (freeSolo) {
        if (autocompleteField.state.inputValue?.toString()?.trim() !== inputValue?.toString()?.trim()) {
          doChange({
            label: inputValue?.toString()?.trim(),
            value: inputValue?.toString()?.trim(),
            createdOption: true
          });
        }
      }
    }
  };

  const onHighlightChange = (e, option, reason) => {
    if (option && reason === 'keyboard') {
      if (applyVirtualization) {
        // remove all focus it's handled later
        const focusedItems = autocompleteField.state.listBox?.querySelectorAll('.Mui-focusVisible.Mui-focused');
        Array.from(focusedItems || []).forEach((el) => {
          el.classList.remove('Mui-focus', 'Mui-focusVisible');
        });
      }

      doScrollToIndex(option.index);

      autocompleteField.refs.highlightedIndexRef.current = option.index;
    } else {
      autocompleteField.refs.highlightedIndexRef.current = -1;
    }
  };

  const getOptionDisabled = (option) => {
    const selectedOption = utils.toArray(selection).find((opt) => isOptionEqualToValue(opt, option));
    if (multiple) {
      const allIsSelected = Boolean(utils.toArray(selection).find((opt) => opt.allSelectedOption));

      return (allIsSelected && !option.allSelectedOption) || Boolean(selectedOption?.disabled ?? option.disabled);
    } else {
      return Boolean(selectedOption?.disabled ?? option.disabled);
    }
  };

  // virtualization
  const totalSize = virtualizer.getTotalSize();
  innerProps.ListboxProps = applyVirtualization ? utils.mergeObjects({
    style: {
      height: `${totalSize}px`,
      width: '100%',
      position: 'relative',
      overflow: 'unset'
    }
  }, innerProps.ListboxProps) : innerProps.ListboxProps;

  innerProps.className = utils.flattenClassName(innerProps.className, {
    virtualize: applyVirtualization
  });

  if (applyVirtualization) {
    const virtualRows = virtualizer.getVirtualItems();
    recycleSlots.refresh(virtualRows, indexedOptions.map((opt) => ({id: opt.index})));
  }

  if (!readOnly) {
    return <StyledAutocompleteField {...innerProps}
                                    slotProps={{paper: {size}}}
                                    name={name}
                                    open={isOpen}
                                    multiple={multiple}
                                    options={indexedOptions}
                                    value={selection}
                                    freeSolo={freeSolo}
                                    autoHighlight={!freeSolo}
                                    inputValue={autocompleteField.state.inputValue}
                                    disableCloseOnSelect={disableCloseOnSelect}
                                    fullWidth={fullWidth}
                                    loading={autocompleteField.state.isLoading}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    filterOptions={filterOptions}
                                    filterSelectedOptions={filterSelectedOptions}
                                    onHighlightChange={onHighlightChange}
                                    getOptionDisabled={getOptionDisabled}
                                    renderOption={renderOption}
                                    renderInput={renderInput}
                                    renderTags={renderTags}
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyDown={handleKeyDown} />
  } else {
    const renderReadOnly = () => {
      const chipSize = size === 'smaller' ? 'small' : 'medium';
      const iconSize = size === 'medium' ? 'small' : 'smaller';
      const useChip = readOnlyChip ?? multiple;
      if (multiple) {
        if (selection) {
          if (useChip) {
            return <ChipList className="AutocompleteField-readOnly AutocompleteField-readOnly-chips Input-readOnly"
                             {...ChipListProps}>
              {utils.toArray(selection, true)
                .sort((a, b) => a.index - b.index)
                .map((v, idx) => {
                  if (renderReadOnlyOption) {
                    return renderReadOnlyOption(v, {readOnly: true, format, placeholder});
                  } else {
                    const optionIcon = icon ?? ChipProps?.icon ?? v.icon;
                    const optionAction = utils.isFunction(readOnlyAction) ? readOnlyAction(v) : (readOnlyAction ?? v.action);

                    const action = {
                      label: utils.fieldValue2FormattedValue(format, v.label, true, false),
                      icon: (optionIcon && !hiddenIcons) ?
                        <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null,
                      ChipProps: {
                        ...utils.filterObject(ChipProps, ['icon'])
                      },
                      onClick: readOnlyOptionClick?.(v),
                      ...optionAction
                    }

                    return <ActionChip key={idx} action={action} showInactive={true}/>
                  }
                })}
            </ChipList>
          } else {
            return <Box className="AutocompleteField-readOnly Input-readOnly">
              {utils.toArray(selection, true)
                .sort((a, b) => a.index - b.index)
                .map((v, idx) => {
                  if (renderReadOnlyOption) {
                    return renderReadOnlyOption(v, {readOnly: true, format, placeholder});
                  } else {
                    const optionIcon = icon ?? v.icon;
                    return <Box key={idx}>
                      {(optionIcon && !hiddenIcons) ? <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
                      <Span>{utils.fieldValue2FormattedValue(format, v.label, true, false)}</Span>
                    </Box>
                  }
                })}
            </Box>
          }
        }
      } else if (useChip) {
        const render = (option) => {
          if (renderReadOnlyOption) {
            return renderReadOnlyOption(option, {readOnly: true, format, placeholder, hiddenPlaceholder, iconSize});
          } else {
            const optionIcon = icon ?? option?.icon;
            if (option) {
              const optionAction = utils.isFunction(readOnlyAction) ? readOnlyAction(option) :
                (readOnlyAction ?? option?.action);
              const optionLabel = !freeSolo ? option?.label : option?.value;

              const action = {
                label: utils.fieldValue2FormattedValue(format, optionLabel, true, false),
                icon: (optionIcon && !hiddenIcons) ?
                  <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null,
                ChipProps: utils.filterObject(ChipProps, ['icon']),
                onClick: readOnlyOptionClick?.(option),
                ...optionAction
              }

              return <ActionChip size={chipSize} action={action} showInactive={true}/>
            } else {
              return <React.Fragment>
                {(optionIcon && !hiddenIcons) ?
                  <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
                {(placeholder && !hiddenPlaceholder) ?
                  <Span className="placeholder">{placeholder}</Span> : <Span>&nbsp;</Span>}
              </React.Fragment>
            }
          }
        }

        return <Box className="AutocompleteField-readOnly AutocompleteField-readOnly-chips Input-readOnly">
          {render(selection)}
        </Box>
      } else {
        const render = (option) => {
          if (renderReadOnlyOption) {
            return renderReadOnlyOption(option, {readOnly: true, format, placeholder, hiddenPlaceholder, iconSize});
          } else {
            const optionIcon = icon ?? option?.icon;
            const optionLabel = !freeSolo ? option?.label : option?.value;
            return <React.Fragment>
              {InputProps?.startAdornment}
              {(option && optionIcon && !hiddenIcons) ?
                <Icon icon={optionIcon} size={iconSize} {...IconProps}/> : null}
              {option ? <Span>{utils.fieldValue2FormattedValue(format, optionLabel, true, false)}</Span> :
                ((placeholder && !hiddenPlaceholder) ? <Span className="placeholder">{placeholder}</Span> :
                  <Span>&nbsp;</Span>)}
              {InputProps?.endAdornment}
            </React.Fragment>
          }
        }

        return <Box className="AutocompleteField-readOnly Input-readOnly">
          {render(selection)}
        </Box>
      }
    }

    return <StyledAutocompleteField as={FormControl}
                                    className={innerProps.className}
                                    {...utils.cleanObject({
                                      hiddenLabel,
                                      hiddenHelperText,
                                      fullWidth,
                                      readOnly,
                                      error,
                                      success,
                                      variant
                                    })}>
      <InputLabel size={size} shrink={true} {...InputLabelProps}
                  className={utils.classNames('AutocompleteField-label', InputLabelProps?.className)}>
        {label}
      </InputLabel>
      <InputContainer className="AutocompleteField-container">
        {renderReadOnly()}
        <FormHelperText component="div" {...FormHelperTextProps}
                        className={utils.classNames('AutocompleteField-helper', FormHelperTextProps?.className)}>
          {((!helperText || helperText.props?.children === '') && !multiple && !hiddenSelectionHelper && selection?.helper) ? selection?.helper : helperText}
        </FormHelperText>
      </InputContainer>
    </StyledAutocompleteField>
  }
}));

AutocompleteField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  name: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.string,
  radius: PropTypes.string,
  placeholder: PropTypes.any,
  icon: PropTypes.any,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  autofocus: PropTypes.bool,
  openDirect: PropTypes.bool,
  hideEmpty: PropTypes.bool,
  hideOpenClose: PropTypes.bool,
  freeSolo: PropTypes.bool,
  forceFiltering: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  hiddenLabel: PropTypes.bool,
  hiddenHelperText: PropTypes.bool,
  hiddenSelectionHelper: PropTypes.bool,
  hiddenPlaceholder: PropTypes.bool,
  hiddenIcons: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helperText: PropTypes.any,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  preload: PropTypes.bool,
  loadOnce: PropTypes.bool,
  loadEmpty: PropTypes.bool,
  sorted: PropTypes.bool,
  createOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  virtualize: PropTypes.bool,
  clearable: PropTypes.bool,
  format: PropTypes.string,
  getTagProps: PropTypes.func,
  readOnlyChip: PropTypes.bool,
  readOnlyOptionClick: PropTypes.func,
  readOnlyAction: PropTypes.any,
  renderOption: PropTypes.func,
  renderReadOnlyOption: PropTypes.func,
  renderInputAdornment: PropTypes.func,
  isLoading: PropTypes.bool,
  clearAfterChange: PropTypes.bool,
  onFilterOption: PropTypes.func,
  onHighlightChange: PropTypes.func,
  onInputChange: PropTypes.func,
  MenuItemProps: PropTypes.object,
  ChipListProps: PropTypes.object,
  ChipProps: PropTypes.object,
  IconProps: PropTypes.object,
  TagProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object
};

AutocompleteField.defaultProps = {
  loadEmpty: true,
  hiddenIcons: true,
  openDirect: true,
  size: 'small',
  options: [],
  createOption: false,
  clearable: true,
  disableClearable: true,
  clearOnBlur: true,
  autoComplete: true,
  autoHighlight: true,
  PaperComponent: AutocompleteFieldPaper
};

export default AutocompleteField;
