import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledDatabaseEntitiesPage
  from 'components/pages/Database/Entity/DatabaseEntitiesPage/DatabaseEntitiesPage.styles';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useLinkNavigate} from 'helpers/hooks/links';
import DatabaseEntitiesTable from 'components/organisms/Tables/DatabaseEntitiesTable/DatabaseEntitiesTable';
import EntitiesActionbar from 'components/organisms/Snackbars/EntitiesActionbar/EntitiesActionbar';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';
import utils from 'helpers/utils';

const DatabaseEntitiesPage = (props) => {
  const innerProps = useComponentProps(props, 'DatabaseEntitiesPage');

  const tableProvider = useTable();
  const authorize = useAuthorize();

  const handleCanRowClick = (entity) => {
    return utils.isDefined(entity.entityId) && authorize({attribute: 'database.profile.read', meta: {entity}});
  }

  const navigate = useLinkNavigate();
  const handleRowClick = (e, entity) => {
    navigate({
      event: e,
      to: entity.entityId,
      keepSearchParams: true
    });
  }

  return <StyledDatabaseEntitiesPage as={TableWrapper} {...innerProps}
                                     borders={{left: '3sp', right: '3sp'}}>
    <DatabaseEntitiesTable onCanRowClick={handleCanRowClick}
                           onRowClick={handleRowClick}
                           ActionbarComponent={<EntitiesActionbar tableProvider={tableProvider}
                                                                  showDownload={false}/>} />
  </StyledDatabaseEntitiesPage>
};

DatabaseEntitiesPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DatabaseEntitiesPage.defaultProps = {
};

export default DatabaseEntitiesPage;
