import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import PageBar from 'components/organisms/Bars/PageBar/PageBar';

const StyledHeaderBar = styled(PageBar)`
  height: ${props => props.theme.layout(64)};

  // variants
  &.HeaderBar-inset {
    height: ${props => props.theme.layout(65)};
    background-color: ${props => props.theme.property(`palette.${props.color}.states.hover`)};
    border: unset;
    
    .PageBar-actions {
      .IconButton {
        background-color: ${props => props.theme.property('palette.background.paper')};
      }
    }
  }
  
  &.HeaderBar.PageBar {
    .PageBar {
      &-title {
        max-width: unset;
      }
      
      &-center {
        &-wrapper {
          width: 100%;
          left: 0;
        }
      }

      &-toolbar {
        padding: 0 ${props => props.theme.spacing(3)};
      }
    }
  }
  
  &.HeaderBar-density-dense {
    height: unset;

    .PageBar-toolbar {
      height: unset;
      min-height: unset;
      padding: 0 !important;
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .PageBar {
      &-toolbar {
        padding: 0 ${props => props.theme.spacing(2)} !important;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledHeaderBar.propTypes = {
  theme: PropTypes.object
};

StyledHeaderBar.defaultProps = {
};

export default StyledHeaderBar;
