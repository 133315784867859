import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Component from 'components/organisms/Utils/Component/Component';
import StyledKanban from 'components/organisms/Kanbans/Kanban/Kanban.styles';
import KanbanPanel from 'components/organisms/Kanbans/KanbanPanel/KanbanPanel';
import CardItem from 'components/atoms/Cards/Carditem/CardItem';
import DndContext from 'components/organisms/Utils/DragDrop/DndContext';
import utils from 'helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';

const Kanban = React.forwardRef((props, ref) => {
  const {
    panels,
    lists,
    state,
    dataKey,
    onClick,
    onChange,
    onCanUpdate,
    isItemEqual,
    isLoading,
    disableReorder,
    ...innerProps
  } = useComponentProps(props, 'Kanban', {
    children: ['panel']
  });

  const innerRef = useRef(null);
  const panelRefs = useRef({});
  const [internalState] = useState({});

  const bBox = useBbox(() => innerRef.current?.refs?.ref?.current, ['width']);

  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const isItemEqualEvent = useEffectEvent(isItemEqual);
  const kanban = useMemo(() => ({
    refs: {
      ref: innerRef,
      panelRefs
    },
    state: {
      ...internalState,
      ...state
    },
    reset: () => {
      Object.keys(panelRefs.current).forEach((kp) => {
        panelRefs.current[kp]?.reset();
      });
    },
    dataKey: dataKey,
    isItemEqual: isItemEqualEvent
  }), [internalState, state, dataKey, isItemEqualEvent]);

  useImperativeHandle(ref, () => kanban);

  const panelsMemo = useMemo(() => {
    if (panels) {
      return panels
        .filter((panel) => kanban.state?.panelVisibility?.[panel.id] ?? true)
        .sort((a, b) => a.position - b.position);
    } else {
      return null;
    }
  }, [panels, kanban.state?.panelVisibility]);

  const handleChange = (item, panelId, position) => {
    utils.asPromise(onChange)(item, panelId, position)
      .catch(() => {
        Object.keys(panelRefs.current).forEach((kp) => {
          panelRefs.current[kp]?.reset();
        });
      });
  }

  const handlePanelRef = (panel) => (el) => {
    panelRefs.current[panel.id] = el;
  }

  const renderPanel = (panel) => {
    const renderedPanel = <KanbanPanel ref={handlePanelRef(panel)}
                                       className="Kanban-panel"
                                       kanban={kanban}
                                       panel={panel}
                                       lists={lists}
                                       dataKey={dataKey}
                                       onClick={onClick}
                                       onChange={handleChange}
                                       onCanUpdate={onCanUpdate}
                                       disableReorder={disableReorder}
                                       isLoading={isLoading}/>
    if (panel.Panel) {
      return <Component Original={panel.Panel}
                        ref={handlePanelRef(panel)}
                        className="Kanban-panel"
                        kanban={kanban}
                        panel={panel}
                        lists={lists}
                        dataKey={dataKey}
                        onClick={onClick}
                        onChange={handleChange}
                        onCanUpdate={onCanUpdate}
                        disableReorder={disableReorder}
                        isLoading={isLoading}
                        renderedPanel={renderedPanel} />
    } else {
      return renderedPanel;
    }
  }

  const columns = bBox?.width ? Math.max(Math.floor(
    ((bBox?.width ?? 0) / (panelsMemo?.length ?? 0) <= 446 && ((bBox?.width ?? 0) / (panelsMemo?.length ?? 0)) > 286) ?
      panelsMemo?.length : ((bBox?.width ?? 0) / ((446 + 286) / 2))
  ), 1) : 0;

  return <DndContext>
    <StyledKanban ref={innerRef} {...innerProps}
                  columns={columns} gap={!lgDown ? 24 : 16}>
      {panelsMemo?.map((panel) => {
        return <CardItem key={panel.id}
                         data-key={panel.id}
                         fit={true}
                         className="Kanban-card">
          {renderPanel(panel)}
        </CardItem>
      })}
    </StyledKanban>
  </DndContext>
});

Kanban.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  panels: PropTypes.array,
  state: PropTypes.object,
  dataKey: PropTypes.string,
  lists: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onCanUpdate: PropTypes.func,
  isItemEqual: PropTypes.func,
  disableReorder: PropTypes.bool,
  isLoading: PropTypes.bool
};

Kanban.defaultProps = {
  disableReorder: true
};

export default Kanban;
