import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {rgba} from 'polished';
import FormStyles from 'styles/mixins/Form.styles';

const StyledAnalysisScoreInlineEdit = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('1.5sp')};
  padding: ${props => props.theme.layout('2sp')};
  width: 100%;
  height: 100%;
  
  .AnalysisScoreInlineEdit {
    &-form {
      .Form-fields {
        .FormControl {
          &.FormField-name-tagGroup {
            .FormLabel {
              ${props => props.theme.font2Css('body2')};
              margin-bottom: ${props => props.theme.spacing(0.5)};

              > .Box {
                display: flex;
                width: 100%;

                &:has(.FormField-labelPostfix) {
                  > *:nth-child(2) {
                    flex-grow: 1;
                  }
                }
                &:not(:has(.FormField-labelPostfix)) {
                  > *:first-child {
                    flex-grow: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    
    &-unassigned {
      &-title {
        display: flex;
        width: 100%;

        > *:first-child {
          flex-grow: 1;
          ${props => props.theme.font2Css('body2')};
        }
      }
      &-content {
        text-align: center;
        color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
        font-style: italic;
        font-size: ${props => props.theme.fontSize(14)};
        margin: ${props => props.theme.spacing(1.5)} 0 ${props => props.theme.spacing(0.5)} 0;
      }
    }
  }

  ${FormStyles}
  ${ComponentStyles}
`;

StyledAnalysisScoreInlineEdit.propTypes = {
  theme: PropTypes.object
};

StyledAnalysisScoreInlineEdit.defaultProps = {}

export default StyledAnalysisScoreInlineEdit;
