import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import DialogContent from 'components/atoms/Dialogs/DialogContent/DialogContent';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import DialogFooter from 'components/molecules/Dialogs/DialogFooter/DialogFooter';
import Button from 'components/atoms/Buttons/Button/Button';
import DialogHeader from 'components/molecules/Dialogs/DialogHeader/DialogHeader';
import {Span} from 'components/atoms/Text/Typography/Typography';
import StyledSourceAddDialog from 'components/organisms/Dialogs/SourceAddDialog/SourceAddDialog.styles';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import AdvancedQuery from 'components/organisms/Queries/AdvancedQuery/AdvancedQuery';
import searchUtils from 'helpers/search';
import {useDatabaseEntitySettings} from 'services/entity/entity.utils';

const SourceAddDialog = React.forwardRef((props, ref) => {
  const {
    title,
    type,
    onSubmit,
    ...innerProps
  } = useComponentProps(props, 'SourceAddDialog', {
    children: ['header', 'content', 'footer']
  });

  const innerRef = useRef(null);
  const formRef = useRef(null);

  const [error, setError] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [override, setOverride] = useState(null);
  const [validation, setValidation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const [entityFieldData, entityFilterGroups] = useDatabaseEntitySettings();

  const fields = useMemo(() => {
    const fields = [];

    fields.push({
      name: 'name',
      label: 'Name',
      inlineLabel: 'name',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      entity: 'source',
      initial: '',
      required: true,
      FormFieldProps: {
       variant: 'staticLabel'
      },
    });

    if (type?.value === 'WebFeed') {
      fields.push({
        name: 'subType',
        label: 'Type',
        inlineLabel: 'type',
        type: constants.formFieldTypes.autocomplete,
        validation: constants.formFieldValidationTypes.text,
        conversion: constants.formFieldConversionTypes.value,
        entity: 'source',
        initial: type.subTypes[0].value,
        required: true,
        options: type.subTypes,
        FormFieldProps: {
          variant: 'staticLabel'
        },
      });
    }

    if ([
      constants.sources.types.google,
      constants.sources.types.twitter,
      constants.sources.types.webFeed,
      constants.sources.types.cordis,
      constants.sources.types.places,
    ].includes(type?.value)) {
      fields.push({
        name: 'query',
        label: 'Query',
        inlineLabel: 'query',
        type: constants.formFieldTypes.text,
        validation: constants.formFieldValidationTypes.text,
        entity: 'source',
        initial: '',
        relation: type?.value === 'WebFeed' ? 'subType(reddit,guardian,nyt)' : null,
        required: true,
        FormFieldProps: {
         variant: 'staticLabel'
        },
      });
    }


    if ([constants.sources.types.website, constants.sources.types.webFeed].includes(type?.value)) {
      fields.push({
        name: 'link',
        label: 'Link',
        inlineLabel: 'link',
        type: constants.formFieldTypes.text,
        validation: constants.formFieldValidationTypes.url,
        entity: 'source',
        initial: '',
        relation: type?.value === 'WebFeed' ? 'subType(rss)' : null,
        required: true,
        FormFieldProps: {
         variant: 'staticLabel'
        },
      });
    }

    fields.push({
      name: 'comment',
      label: 'Description',
      inlineLabel: 'description',
      placeholder: 'Describe this source',
      type: constants.formFieldTypes.textarea,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      entity: 'source',
      initial: '',
      FormFieldProps: {
        variant: 'staticLabel',
        minRows: 2,
      }
    });

    fields.push({
      name: 'filterType',
      label: 'Filter',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      options: constants.data.sourceFilterTypes,
      entity: 'source',
      initial: constants.sources.filterTypes.extend,
      required: true,
      FormFieldProps: {
        variant: 'inlineLabel',
        deselect: false,
        debounce: false,
        ListProps: {
          orientation: 'horizontal',
          gap: 16
        }
      }
    });

    fields.push({
      section: true,
      type: constants.formSectionTypes.info,
      info: override ? 'Only the companies matching these filters below will be added to your suggestions queue' :
        'The companies matching these filters below and the general source filters will be added to your suggestions queue'
    })

    fields.push({
      name: 'filter',
      label: 'Filter',
      type: constants.formFieldTypes.component,
      validation: `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.query})`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'source',
      valueProp: 'query',
      Component: <AdvancedQuery size="smaller"
                                fieldData={entityFieldData}
                                filterGroups={entityFilterGroups}/>,
      initial: {
        value: searchUtils.advancedQuery(),
        errors: false
      },
      required: [constants.sources.types.database].includes(type?.value),
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: true,
        debounce: false,
        autoFocus: false
      }
    });

    return fields;
  }, [type, override, entityFieldData, entityFilterGroups]);

  const handleChange = (field, value) => {
    setError(null);
    setValidation(null);

    if (field.name === 'filterType') {
      setOverride(value === 'override');
    }
  };

  const handleSubmit = (values, actions) => {
    setSubmitting(true);

    utils.asPromise(onSubmit)({...values, type: type?.value})
      .catch(() => {
        setError('Adding source failed');
      })
      .finally(() => {
        actions.setSubmitting(false);
        setSubmitting(false);
      });
  };

  const handleValidating = (isValidating, isDirty, hasErrors) => {
    setDirty(isDirty);
    if (hasErrors) {
      setValidation('Please check if all fields have the correct values');
    } else {
      setValidation(null);
    }
  }

  const handleSubmitClick = () => {
    formRef.current?.submit();
  };

  const handleCancel = (e) => {
    innerRef.current?.close?.(e, 'cancelButtonClick');
  }

  const handleClose = (e, reason) => {
    if ((!submitting && !error && !dirty) || ['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
      innerProps.onClose?.(e, reason);
    }
  }

  const renderButtons = () => {
    return <React.Fragment>
      <Button children={'Cancel'}
              variant="text"
              startIcon={<Icon icon={Close}/>}
              onClick={handleCancel}/>
      <Button disabled={submitting}
              type="submit"
              color="success"
              variant="contained"
              children={'Create source'}
              startIcon={<Icon icon={Add}/>}
              onClick={handleSubmitClick}/>
    </React.Fragment>
  }

  return <StyledSourceAddDialog ref={innerRef} {...innerProps} onClose={handleClose}>
    <DialogHeader className="SourceAddDialog-header"
                  title={title ?? 'Add source'} />
    <DialogContent className="SourceAddDialog-content">
      <InlineForm ref={formRef}
                  fields={fields}
                  onValidating={handleValidating}
                  onChange={handleChange}
                  onSubmit={handleSubmit} />
    </DialogContent>
    <DialogFooter className="SourceAddDialog-footer"
                  info={(error ?? validation) ? <Span color="error">{error ?? validation}</Span> : null}
                  buttons={renderButtons()} />
  </StyledSourceAddDialog>
});

SourceAddDialog.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  title: PropTypes.string,
  source: PropTypes.object,
  type: PropTypes.object
};

SourceAddDialog.defaultProps = {};

export default SourceAddDialog;
