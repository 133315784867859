import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledEntityAddSectionPanelContent = styled(ListWrapper)`
  background-color: ${props => props.theme.property('palette.primary.states.hover')};

  .ListWrapper {
    &-content {
      overflow-x: hidden;
      overflow-y: auto;
    }
    &-footer {
      .Button {
        white-space: nowrap;
      }
    }
  }
  
  .EntityAddSectionPanelContent {
    &-form {
      padding: ${props => props.theme.spacing(3)};

      .MuiInputBase-root {
        background-color: ${props => props.theme.property('palette.background.paper')};
      }

      .FormControl-staticLabel {
        row-gap: ${props => props.theme.layout('0.5sp')};

        > .InputLabel {
          ${props => props.theme.font2Css('body2')};
          transform: scale(1);
        }
      }
    }
    
    &-redirect {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: ${props => props.theme.layout('2sp')};

      width: 100%;
      text-align: center;
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .EntityAddSectionPanelContent {
      &-form {
        padding: ${props => props.theme.spacing(2)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledEntityAddSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledEntityAddSectionPanelContent.defaultProps = {}

export default StyledEntityAddSectionPanelContent;
