import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useColumnState, useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import {useAuthClientId, useAuthIsProxy, useAuthUserId} from 'services/auth/auth.utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import constants from 'helpers/constants';
import {useClientUserAdd, useClientUserDelete, useClientUserList} from 'services/client/user/user.hooks';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import IconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import Table from 'components/organisms/Tables/Table/Table';
import LinkTableCell from 'components/molecules/TableCells/LinkTableCell/LinkTableCell';
import ChipListTableCell from 'components/molecules/TableCells/ChipListTableCell/ChipListTableCell';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import SettingsHeader from 'components/molecules/Headers/SettingsHeader/SettingsHeader';
import UserTableCell from 'components/molecules/TableCells/UserTableCell/UserTableCell';
import UsersActionbar from 'components/organisms/Snackbars/UsersActionbar/UsersActionbar';
import utils from 'helpers/utils';
import UserAddDialog from 'components/organisms/Dialogs/UserAddDialog/UserAddDialog';
import Edit from '@mui/icons-material/Edit';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import {useClientUserPatch} from 'services/client/user/user.utils';
import {useClientTeamList} from 'services/client/team/team.hooks';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';
import StyledUsersSettingPage from 'components/pages/Setting/UsersSettingPage/UsersSettingPage.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';

const info = 'Add and manage users';

const UsersSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'UsersSettingPage');

  const [showUserAddDialog, setShowUserAddDialog] = useState(false);

  const profileProvider = useProfile();

  const dialogControl = useDialogControl();

  const userId = useAuthUserId();
  const clientId = useAuthClientId();
  const isProxy = useAuthIsProxy();
  const teamList = useClientTeamList({clientId}, {enabled: (clientId >= 0)})
  const teams = teamList.data;

  const snackbar = useSnackbar();
  const authorize = useAuthorize();

  const userAdd = useClientUserAdd();
  const patchUser = useClientUserPatch({refetchContext: true});
  const deleteUser = useClientUserDelete();

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25,
        filter: [
          {id: 'allowInactive', value: true}
        ].concat(isProxy ? [] : [{id: 'userType', value: constants.user.types.regular}])
      },
      options: {
        name: 'usersSettings'
      }
    },
    listSelection: {
      options: {
        max: constants.selection.max.user
      }
    },
    columnState: {
      initial: {
        columnPinning: {
          left: !smDown ? ['name'] : []
        },
        columnVisibility: !smDown ? null : {
          firstName: false,
          lastName: false,
          email: false,
          authGroup: false,
          teams: false,
          active: false,
          createdAt: false,
          lastActivity: false
        },
        columnSizing: {
          name: 170
        }
      },
      options: {
        name: 'usersSettings'
      }
    }
  }), [isProxy, smDown]);

  const listState = useListState(options.listState?.initial, options.listState?.options);
  const columnState = useColumnState(options?.columnState?.initial, options?.columnState?.options);

  const userList = useClientUserList({
    clientId,
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
  });

  const clearEvent = useEffectEvent(columnState.clear);
  useEffect(() => {
    clearEvent?.();
  }, [clearEvent, smDown]);

  const handleChange = useCallback((row, cell) => (field, value, onSuccess, onError) => {
    onSuccess?.(); // optimistic
    cell.column.columnDef.optimistic.set(cell, value);
    patchUser(clientId, row.original, {[field.name]: value}).catch(() => {
      onError?.();
      cell.column.columnDef.optimistic.reset(cell);
    });
  }, [patchUser, clientId]);

  const dirtyEvent = useEffectEvent(profileProvider.dirty);
  const handleDirty = useCallback((dirty) => {
    dirtyEvent?.(dirty);
  }, [dirtyEvent]);

  const columnsMemo = useMemo(() => {
    const canEditColumn = (user) => {
      return authorize({attribute: 'settings.users.update'}) &&
        authorize({attribute: 'user.update', meta: {user}});
    }
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Name',
      Cell: ({cell}) => {
        const user = cell.row.original;
        return <UserTableCell user={user}
                              short={true} />
      }
    });

    columns.push({
      accessorKey: 'firstName',
      id: 'firstName',
      header: 'First name',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'user.column.firstName.update', meta: {user: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const firstName = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.text,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          initial: firstName
        };
        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={firstName} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const firstName = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={firstName}
                              action={action} />
      }
    });

    columns.push({
      accessorKey: 'lastName',
      id: 'lastName',
      header: 'Last name',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'user.column.lastName.update', meta: {user: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const lastName = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.text,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          initial: lastName
        };
        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={lastName} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const lastName = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={lastName}
                              action={action} />
      }
    });

    columns.push({
      accessorKey: 'email',
      id: 'email',
      header: 'Email',
      size: 220,
      Cell: ({cell}) => {
        const email = cell.getValue() ?? '';
        const ActionLinkProps = {
          action: {
            label: email,
            navigation: {
              href: `mailto:${email}`
            }
          }
        }

        return <LinkTableCell ActionLinkProps={ActionLinkProps} />
      }
    });

    columns.push({
      accessorKey: 'authGroup',
      id: 'authGroup',
      header: 'Role',
      size: 120,
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'user.column.authGroup.update', meta: {user: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const authGroup = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const role = constants.data.lookup('roles', authGroup);

        const options = constants.data.roles
          .filter((v) => {
            return authorize({
              attribute: `user.column.authGroup.update.${v.value}`,
              meta: {user: row.original}
            })
          });

        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          required: true,
          initial: role.value,
          options: options
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={role?.label} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const authGroup = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const role = constants.data.lookup('roles', authGroup);
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={role?.label} action={action} />
      }
    });

    columns.push({
      accessorKey: 'teams',
      id: 'teams',
      header: 'Teams',
      enableSorting: false,
      Cell: ({cell}) => {
        const user = cell.row.original;
        const actions = (teams ?? []).filter((t) => t.members?.find((m) => +m.userId === +user.userId)).map((l) => {
          return {
            label: l.name
          };
        });

        return <ChipListTableCell ActionChipListProps={{
          variant: 'compact',
          actions,
          ActionChipProps: {
            variant: 'filled',
            size: 'medium'
          }
        }} />
      }
    });

    columns.push({
      accessorKey: 'active',
      id: 'active',
      header: 'Active',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'user.column.active.update', meta: {user: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const active = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          type: constants.formFieldTypes.list,
          conversion: constants.formFieldConversionTypes.value,
          required: true,
          initial: active,
          options: 'toggleYesNo'
        };
        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={active ? 'Yes' : 'No'} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const active = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };
        return <TextTableCell title={active ? 'Yes' : 'No'}
                              action={action} />
      }
    });

    columns.push({
      accessorKey: 'createdAt',
      id: 'createdAt',
      header: 'Date created',
      size: 140,
      Cell: ({cell}) => {
        const createdAt = cell.getValue();
        return <TextTableCell title={utils.dayjs(createdAt).format(constants.dates.format)} />
      }
    });

    columns.push({
      accessorKey: 'lastActivity',
      id: 'lastActivity',
      header: 'Last login',
      size: 140,
      Cell: ({cell}) => {
        const lastActivity = cell.getValue();
        const activity = lastActivity ? utils.dayjs(lastActivity).format(constants.dates.format) : '';
        return <TextTableCell title={activity} />
      }
    });

    columns.push({
      id: 'delete',
      size: 65,
      minSize: 65,
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      enableSorting: false,
      Cell: ({cell, row}) => {
        const user = cell.row.original;

        const enableEditing = canEditColumn(row.original);

        const action = {
          label: 'Remove user',
          tooltip: (+user.userId === +userId) ? 'Active user can not be removed' : (
            user.isDealLeader ? 'User is a deal leader' : 'Remove user'),
          icon: <Icon icon={Delete} size="smaller" />,
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) :
            utils.createAuth({attribute: 'user.delete', meta: {user}}),
          onClick: (e) => {
            const handleConfirm = () => {
              deleteUser.mutation.mutateAsync({
                clientId: clientId,
                userId: user.userId
              }).catch(() => {
                snackbar.show('Remove user failed', null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              });
            };

            dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this user?"
                                              explanation="The user will be removed including data related to this user"
                                              challenge={user.username}
                                              challengeLabel="username"
                                              onConfirm={handleConfirm}
                                              ConfirmButtonProps={{
                                                children: 'Remove user',
                                                startIcon: <Icon icon={Delete} />,
                                                color: 'error'
                                              }} />, true);
            e.preventDefault();
          }
        };

        return <IconButtonTableCell ActionIconButtonProps={{
                                      variant: 'outlined',
                                      size: 'smaller',
                                      density: 'sparse',
                                      showInactive: true,
                                      action: action }} />
      }
    });
    return columns;
  }, [
    dialogControl, snackbar, authorize, teams, clientId, userId,
    handleDirty, handleChange, deleteUser.mutation
  ]);

  const action = useMemo(() => ({
    label: !mdDown ? 'Add user' : null,
    tooltip: mdDown ? 'Add user' : null,
    icon: <Icon icon={Add} />,
    iconPosition: 'start',
    auth: !authorize({attribute: 'settings.users.update'}) ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'user.create'}),
    onClick: (e) => {
      setShowUserAddDialog(true);
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      color: 'success'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'success',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [authorize, mdDown]);

  const renderUserAddDialog = () => {
    if (showUserAddDialog) {
      const handleSubmit = (user) => {
        return userAdd.mutation.mutateAsync({clientId, ...utils.underscore(user)})
          .then(() => {
            setShowUserAddDialog(false);
          });
      };

      const handleClose = () => {
        setShowUserAddDialog(false);
      };

      return <UserAddDialog open={true}
                            onSubmit={handleSubmit}
                            onClose={handleClose}/>
    }
  };

  const searchField = useMemo(() => ({
    placeholder: 'Search for a user'
  }), []);

  const renderSettingsHeader = () => {
    return <SettingsHeader className="UsersSettingPage-header"
                           title={!smDown ? 'Users' : null}
                           info={!smDown ? info : null}
                           search={listState.search}
                           searchField={searchField}
                           onSearch={listState.setSearch}
                           buttons={!mdDown ? <ActionButton action={action} /> : <ActionIconButton action={action} />} />
  }

  return <StyledUsersSettingPage as={TableWrapper} {...innerProps}
                                 header={renderSettingsHeader()}
                                 dividers={true}
                                 borders={{left: !smDown ? '3sp' : '2sp', right: !smDown ? '3sp' : '2sp', bottom: !smDown ? '3sp' : '2sp'}}>
    <Table className="UsersSettingPage-table"
           dataKey="userId"
           enableParentScroll={true}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={true}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={true}
           listState={listState}
           columnState={columnState}
           onFetchMore={userList.query.fetchNextPage}
           onRefetch={userList.query.refetch}
           columns={columnsMemo}
           data={userList.data}
           rowCount={userList.meta?.resultsCount}
           state={{
             isLoading: userList.status.isLoading,
             showProgressBars: userList.status.isLoadingNext
           }}
           muiTableHeadCellProps={{align: 'left'}}
           muiTableBodyCellProps={{align: 'left'}}
           ActionbarComponent={<UsersActionbar />}/>
    {renderUserAddDialog()}
  </StyledUsersSettingPage>
};

UsersSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

UsersSettingPage.defaultProps = {};

export default UsersSettingPage;
