import React, {useImperativeHandle, useMemo, useRef, useState} from 'react';
import StyledTypography from 'components/atoms/Text/Typography/Typography.styles';
import PropTypes from 'prop-types';
import {useBbox, useComponentProps} from 'helpers/hooks/utils';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Typography = withMemo(React.forwardRef((props, ref) => {
  const {
    min,
    max,
    nowrap,
    copyText,
    selectable,
    isLoading,
    showTooltip,
    TooltipProps,
    ...innerProps
  } = useComponentProps(props, 'Typography', {
    static: ['disabled', 'copyText', 'nowrap', 'selectable'],
    styled: ['color', 'lineHeight']
  });

  const innerRef = useRef(null);
  const [copied, setCopied] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const alwaysTooltip = showTooltip === 'always';

  const textBox = useBbox(() => innerRef.current, ['clamped']);

  const randomString = useMemo(() => {
    if (isLoading && min && max) {
      return utils.randomString(min, max)
    } else {
      return null;
    }
  }, [isLoading, min, max]);

  const handleTooltipClose = (e) => {
    TooltipProps?.onClose?.(e);
    if (!e.defaultPrevented && copyText) {
      setCopied(false);
    }
  }

  const handleClick = (e) => {
    innerProps.onClick?.(e);

    if (!e.defaultPrevented && copyText && !isLoading) {
      if ((utils.isString(innerProps?.children) || utils.isNumber(innerProps?.children)) &&
          innerProps?.children.toString().trim().length > 0) {
        navigator.clipboard.writeText(innerProps?.children.toString().trim())
          .then(() => {
            setCopied(true);
          })
          .catch(() => {
            setCopied(false);
          });
      }
    }
  };

  const renderTooltip = () => {
    if (copyText) {
      if (copied) {
        return 'Copied to clipboard';
      } else {
        return !isLoading ? 'Click to copy' : null;
      }
    } else if (!isLoading && showTooltip && (alwaysTooltip || textBox?.clamped)) {
      return innerProps.children;
    }
  }

  innerProps.as = isLoading ? Skeleton : innerProps.as;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <Tooltip title={renderTooltip()}
                  placement="bottom"
                  {...(copyText ? {
                    enterDelay: 0
                  } : null)}
                  onClose={handleTooltipClose}
                  {...TooltipProps}>
    <StyledTypography ref={innerRef} {...innerProps}
                      onClick={handleClick}>
      {randomString ?? innerProps.children}
    </StyledTypography>
  </Tooltip>
}));

Typography.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  color: PropTypes.string,
  variant: PropTypes.string,
  lineHeight: PropTypes.any,
  copyText: PropTypes.bool,
  isLoading: PropTypes.bool,
  showTooltip: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  nowrap: PropTypes.bool,
  TooltipProps: PropTypes.object
};

Typography.defaultProps = {
  children: '...',
};

export const H1 = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'H1');
  return <Typography ref={ref} {...innerProps} variant="h1">{innerProps.children}</Typography>;
}));

export const H2 = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'H2');
  return <Typography ref={ref} {...innerProps} variant="h2">{innerProps.children}</Typography>;
}));

export const H3 = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'H3');
  return <Typography ref={ref} {...innerProps} variant="h3">{innerProps.children}</Typography>;
}));

export const H4 = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'H4');
  return <Typography ref={ref} {...innerProps} variant="h4">{innerProps.children}</Typography>;
}));

export const H5 = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'H5');
  return <Typography ref={ref} {...innerProps} variant="h5">{innerProps.children}</Typography>;
}));

export const H6 = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'H6');
  return <Typography ref={ref} {...innerProps} variant="h6">{innerProps.children}</Typography>;
}));

export const Span = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'Span');
  return <Typography ref={ref} {...innerProps} variant="span">{innerProps.children}</Typography>;
}));

export const P = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'P');
  return <Typography ref={ref} {...innerProps} component="p" variant="p">{innerProps.children}</Typography>;
}));

export default Typography;


