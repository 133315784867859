import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {P, Span} from 'components/atoms/Text/Typography/Typography';
import Edit from '@mui/icons-material/Edit';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import StyledAnalysisScoreInlineEdit
  from 'components/organisms/InlineEdits/AnalysisScoreInlineEdit/AnalysisScoreInlineEdit.styles';
import {withMemo} from 'helpers/wrapper';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Icon from 'components/atoms/Icons/Icon/Icon';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {processEntityInfo} from 'services/entity/entity.utils';

const AnalysisScoreInlineEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    entity,
    collection,
    score,
    fieldData,
    onEdit,
    ...innerProps
  } = useComponentProps(props, 'AnalysisScoreInlineEdit');

  const editable = Boolean(onEdit);

  const fields = useMemo(() => {
    if (score) {
      if (score.tagGroupId > 0) {
        const field = {
          name: 'tagGroup',
          entity: 'entity',
          tagGroupId: score.tagGroupId,
          tagGroup: score.tagGroup,
          required: false,
          readOnly: true,
          FormFieldProps: {
            variant: 'staticLabel',
            hiddenIcons: false,
            fullWidth: true
          }
        };
        const formField = utils.initializeFormFields([field], entity)[0];

        const fieldInfo = processEntityInfo(entity, field, formField.initial?.value ?? formField.initial);
        formField.label = <Box>
          <Span>{score.tagGroup.name}</Span>
          {fieldInfo ? <Span className="FormField-labelPostfix">
            <Tooltip title={fieldInfo}
                     enterDelay={0}
                     variant="emphasis"
                     placement="bottom">
              <Icon icon={InfoOutlined} color="light.dark" size="smaller"/>
            </Tooltip>
          </Span> : null}
          <Typography color="text.secondary" nowrap={true} variant="caption">{score.points} points</Typography>
        </Box>

        return [formField];
      }
    }
  }, [score, entity]);

  const onEditEvent = useEffectEvent(onEdit);
  const action = useMemo(() => ({
    label: 'Edit categories',
    auth: !editable ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      onEditEvent?.(e);
      e.preventDefault();
    }
  }), [editable, onEditEvent]);
  
  return <StyledAnalysisScoreInlineEdit ref={ref} {...innerProps}>
    {fields ? <InlineForm className="AnalysisScoreInlineEdit-form"
                          fields={fields}
                          fieldData={fieldData} /> : null}
    {!fields ? <Box className="AnalysisScoreInlineEdit-unassigned">
      <Box className="AnalysisScoreInlineEdit-unassigned-title">
        <Span>Unassigned</Span>
        <Typography color="text.secondary" nowrap={true} variant="caption">{score?.points ?? 0} points</Typography>
      </Box>
      <P className="AnalysisScoreInlineEdit-unassigned-content">
        This company scored {score?.scored} points of potentially {score?.total} points
      </P>
    </Box> : null}
    <ActionButton action={action}
                  fullWidth={true}
                  size="small"
                  variant="contained"/>
  </StyledAnalysisScoreInlineEdit>
}));

AnalysisScoreInlineEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  entity: PropTypes.object,
  collection: PropTypes.object,
  score: PropTypes.object,
  fieldData: PropTypes.object,
  onEdit: PropTypes.func
};

AnalysisScoreInlineEdit.defaultProps = {};

export default AnalysisScoreInlineEdit;
