import React, {useCallback, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import Add from '@mui/icons-material/Add';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Button from 'components/atoms/Buttons/Button/Button';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import StyledAdvancedQueryPart from 'components/organisms/Queries/AdvancedQueryPart/AdvancedQueryPart.styles';
import AdvancedQueryFilter from 'components/organisms/Queries/AdvancedQueryFilter/AdvancedQueryFilter';
import Box from '@mui/material/Box';

const AdvancedQueryPart = React.forwardRef((props, ref) => {
  const {
    part,
    depth,
    first,
    indent,
    size,
    parentType,
    canUpdate,
    canDelete,
    filterGroups,
    fieldData,
    required,
    error,
    onChange,
    onSwitch,
    onDelete,
    onBlur,
    onError,
    ...innerProps
  } = useComponentProps(props, 'AdvancedQueryPart', {
    variable: ['depth']
  });

  const innerRef = useRef(null);
  const partRefs = useRef({});
  const [internalState, setInternalState] = useState({});

  const checkNewParts = useCallback((state) => {
    return state.parts?.some((p) => p.isNew) ||
      Object.keys(partRefs.current).some((key) => {
        return partRefs.current[key].ref?.hasNewParts?.() ?? false;
      });
  }, []);

  const advancedQueryPart = useMemo(() => {
    const state = internalState;

    return {
      refs: {
        ref: innerRef,
        partRefs
      },
      state,
      submit: (touch = true) => {
        return Promise.all(Object.keys(partRefs.current).map((partId) => {
          return partRefs.current[partId]?.ref?.submit?.(touch);
        }));
      },
      validate: (touch = true) => {
        return Promise.all(Object.keys(partRefs.current).map((partId) => {
          return partRefs.current[partId]?.ref?.validate?.(touch);
        }))
          .then((errors) => {
            return errors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
          });
      },
      reset: (state) => {
        return Promise.all(Object.keys(partRefs.current).map((partId) => {
          return partRefs.current[partId]?.ref?.reset?.(state);
        }));
      },
      hasNewParts: () => {
        return checkNewParts(state);
      }
    }
  }, [internalState, checkNewParts]);

  useImperativeHandle(ref, () => advancedQueryPart);

  const partsMemo = useEffectItem(part?.parts);
  useLayoutEffect(() => {
    setInternalState(utils.updater({
      parts: (depth === 0 && !(partsMemo?.length > 0)) ? [{
        id: utils.sha1(`${part.id}_${Date.now()}`),
        queryType: constants.query.partTypes.and,
        isNew: true
      }] : partsMemo
    }, true));
  }, [partsMemo, part?.id, depth]);

  const doErrors = () => {
    const error = Object.keys(partRefs.current).reduce((e, k) => e || partRefs.current[k].error, false);
    onError?.(error);
    setInternalState(utils.updater({error}, true));
  }

  const handleAddClick = () => {
    const parts = utils.clone(internalState.parts, true);

    const queryType = parts?.length > 0 ? parts[parts?.length - 1].queryType : constants.query.partTypes.and;

    const addPartDef = {
      id: utils.sha1(`${part.id}_${Date.now() + (parts?.length ?? 0)}`),
      isNew: true,
      queryType: queryType === constants.query.partTypes.if ? constants.query.partTypes.and : queryType
    };

    parts.push(addPartDef);

    setInternalState((current) => {
      return {
        ...current,
        parts
      }
    });

    const newPart = utils.clone(part, true);
    newPart.parts = parts;
    onChange?.(newPart, true);
  }

  const handlePartSwitch = (id, type, isNewPart = false, childId = null) => {
    delete partRefs.current[id];

    if (!(internalState.parts?.length > 0)) {
      onSwitch?.(id, type);
    } else {
      const idx = internalState.parts.findIndex((p) => p.id === id);
      const childIdx = internalState.parts[idx].parts?.findIndex((p) => p.id === childId);

      // move up: first item or has related children
      const shouldCollapse = (idx <= 1 || childIdx === 0) &&
        (idx === 0 || (part.queryType ?? constants.query.partTypes.and) === type);
      if (depth > 0 && shouldCollapse) {
        onSwitch?.(part.id, type, internalState.parts[idx].isNew, id); // move id up
      } else {
        const parts = utils.clone(internalState.parts, true);
        isNewPart = isNewPart || parts[idx].isNew;

        const collapseParts = (parts, type) => {
          return parts.reduce((a, p) => {
            if (p.parts?.length > 0) {
              p.parts.forEach((p) => {
                a.push(p);
              })
            } else {
              a.push({
                ...p,
                queryType: type ?? p.queryType
              });
            }
            return a;
          }, []);
        }

        if (parts[idx].parts?.length > 0 && (idx === 0 || (parts[idx - 1].queryType ?? constants.query.partTypes.and) === type)) {
          let lastIndex = parts[idx].parts.findIndex((p, pIdx) => pIdx > childIdx && !(p.parts?.length > 0));
          lastIndex = lastIndex === -1 ? parts[idx].parts.length : lastIndex;

          const collapsedParts = collapseParts(parts[idx].parts.slice(
            (childIdx <= 1 ? 0 : childIdx),
            lastIndex
          ), type);

          const merged = [
            ...parts.slice(0, idx),
            ...((childIdx > 1) ? [{
              ...parts[idx],
              parts: parts[idx].parts.slice(0, childIdx)
            }] : []),
            ...((lastIndex < parts[idx].parts.length) ? collapsedParts.slice(0, -1) : collapsedParts.slice(0))
              .map((p, pIdx) => {
                p.id = utils.sha1(`${part.id}_${Date.now() + pIdx + idx}`);
                return p;
              }),
            ...((lastIndex < parts[idx].parts.length) ? [{
              ...parts[idx],
              parts: collapseParts(parts[idx].parts.slice(lastIndex - 1, lastIndex))
                .slice(-1).concat(parts[idx].parts.slice(lastIndex))
                .map((p, pIdx) => {
                  p.id = utils.sha1(`${parts[idx].id}_${Date.now() + pIdx + lastIndex}`);
                  return p;
                })
            }] : []),
            ...parts.slice(idx + 1)
          ];

          setInternalState((current) => {
            return {
              ...current,
              parts: merged
            }
          });

          const newPart = utils.clone(part, true);
          newPart.parts = merged;
          onChange?.(newPart, isNewPart);
        } else if (idx > 0 && (parts[idx - 1].queryType ?? constants.query.partTypes.and) !== type) {
          if (idx > 0 && parts[idx - 1].parts?.length > 0) {
            parts[idx - 1].parts = parts[idx - 1].parts.concat([
              ...(parts[idx].parts?.length > 0 ?
                collapseParts(parts[idx].parts, type)
                  .map((p, pIdx) => {
                    p.id = utils.sha1(`${parts[idx - 1].id}_${Date.now() + idx + pIdx}`);
                    return p;
                  }) : [{
                ...parts[idx],
                id: utils.sha1(`${parts[idx - 1].id}_${Date.now() + 1}`),
                queryType: type
              }])
            ]);
            parts.splice(idx, 1);
          } else {
            parts[idx - 1] = {
              id: utils.sha1(`${part.id}_${Date.now() + (idx - 1)}`),
              queryType: parts[idx - 1].queryType,
              parts: [{
                  ...parts[idx - 1],
                  queryType: type
                },
                ...(parts[idx].parts?.length > 0 ?
                  collapseParts(parts[idx].parts, type)
                    .map((p, pIdx) => {
                      p.id = utils.sha1(`${parts[idx - 1].id}_${Date.now() + idx + pIdx}`);
                      return p;
                    }) : [{
                  ...parts[idx],
                  id: utils.sha1(`${parts[idx - 1].id}_${Date.now() + 1}`),
                  queryType: type,
                  parts: null
                }])
              ]
            };
            parts.splice(idx, 1);
          }

          setInternalState((current) => {
            return {
              ...current,
              parts
            }
          });

          const newPart = utils.clone(part, true);
          newPart.parts = parts;
          onChange?.(newPart, isNewPart);
        }
      }
    }
    doErrors();
    onBlur?.();
  }

  const handlePartChange = (change, isNewPart = false, isDelete = false) => {
    const idx = internalState.parts.findIndex((p) => p.id === change.id);
    let parts = utils.clone(internalState.parts, true);

    if (isDelete) {
      delete partRefs.current[change.id];
      if (change.parts?.length === 0) {
        parts.splice(idx, 1);
      } else if (change.parts?.length === 1) {
        const oqt = parts[idx].queryType;
        parts[idx] = change.parts[0];
        parts[idx].queryType = oqt;
        parts[idx].id = utils.sha1(`${parts.id}_${Date.now() + idx}`);
      } else {
        parts[idx] = change;
        parts[idx].id = utils.sha1(`${parts.id}_${Date.now() + idx}`);
      }
    } else {
      parts[idx] = change;
    }

    setInternalState((current) => {
      return {
        ...current,
        parts
      }
    });

    const newPart = utils.clone(part, true);
    newPart.parts = parts;
    onChange?.(newPart, isNewPart, isDelete);
    doErrors();
    onBlur?.();
  }

  const handlePartDelete = (change) => {
    delete partRefs.current[change.id];

    const idx = internalState.parts.findIndex((p) => p.id === change.id);
    let parts = utils.clone(internalState.parts, true);

    parts.splice(idx, 1);

    if (depth === 0 && parts.length === 0) {
      parts = [{
        id: utils.sha1(`${part.id}_${Date.now()}`),
        queryType: constants.query.partTypes.and,
        isNew: true
      }];
    }

    setInternalState((current) => ({
      ...current,
      parts
    }));

    const newPart = utils.clone(part, true);
    newPart.parts = parts;
    onChange?.(newPart, change.isNew, true);
    doErrors();
    onBlur?.();
  }

  const handleRef = (id) => (ref) => {
    partRefs.current[id] = {
      ...partRefs.current[id],
      ref
    };
  }

  const handleError = (id) => (error) => {
    partRefs.current[id] = {
      ...partRefs.current[id],
      error
    };

    doErrors();
  }

  const renderParts = () => {
    return <Box className="AdvancedQueryPart-parts">
      {internalState.parts?.map((qp, idx) => {
        return <AdvancedQueryPart ref={handleRef(qp.id)}
                                  key={qp.id}
                                  part={qp}
                                  depth={depth + 1}
                                  first={first && idx === 0}
                                  indent={idx > 0 ? depth : 0}
                                  size={size}
                                  parentType={idx === 0 ? part.queryType : null}
                                  required={required && internalState.parts.length === 1}
                                  error={error}
                                  filterGroups={filterGroups}
                                  fieldData={fieldData}
                                  canUpdate={canUpdate}
                                  canDelete={canUpdate && canDelete}
                                  onError={handleError(qp.id)}
                                  onChange={handlePartChange}
                                  onSwitch={handlePartSwitch}
                                  onDelete={handlePartDelete}/>
      })}
      {!(first && internalState.parts?.[0]?.isNew) ?
        <Button className="AdvancedQueryPart-add"
                variant="text"
                startIcon={<Icon icon={Add} />}
                onClick={handleAddClick}
                disabled={Boolean(checkNewParts(internalState) || internalState.error || !canUpdate)}>
          Add filter
        </Button> : null}
    </Box>
  }

  const renderFilter = () => {
    return (part && !(part?.parts?.length > 0)) ? <AdvancedQueryFilter className="AdvancedQueryPart-filter"
                                                                       ref={handleRef(part.id)}
                                                                       key={part.id}
                                                                       part={part}
                                                                       first={first}
                                                                       indent={indent}
                                                                       depth={depth}
                                                                       size={size}
                                                                       required={required && first}
                                                                       error={error}
                                                                       parentType={parentType}
                                                                       filterGroups={filterGroups}
                                                                       fieldData={fieldData}
                                                                       canUpdate={canUpdate}
                                                                       canDelete={canDelete}
                                                                       onError={handleError(part.id)}
                                                                       onChange={onChange}
                                                                       onSwitch={onSwitch}
                                                                       onDelete={onDelete}/> : null;
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledAdvancedQueryPart ref={innerRef} {...innerProps}>
    {internalState.parts?.length > 0 ? renderParts() : renderFilter()}
  </StyledAdvancedQueryPart>
});

AdvancedQueryPart.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  part: PropTypes.object,
  first: PropTypes.bool,
  depth: PropTypes.number,
  indent: PropTypes.number,
  size: PropTypes.string,
  parentType: PropTypes.string,
  filterGroups: PropTypes.array,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onSwitch: PropTypes.func,
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
};

AdvancedQueryPart.defaultProps = {
  canDelete: true
};

export default AdvancedQueryPart;
