import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowIcon from 'components/atoms/Icons/DealflowIcon/DealflowIcon.styles';
import {useClientDealflowGroup, useClientDealflowStatus} from 'services/client/client.utils';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import Remove from '@mui/icons-material/Remove';
import HourglassTop from '@mui/icons-material/HourglassTop';
import CheckBox from '@mui/icons-material/CheckBox';
import RemoveRedEye from '@mui/icons-material/RemoveRedEye';
import {createSvgIcon} from '@mui/material';
import {withMemo} from 'helpers/wrapper';
import constants from 'helpers/constants';

const DealflowIcon = withMemo(React.forwardRef((props, ref) => {
  const {
    statusId,
    groupId,
    showTooltip,
    TooltipProps,
    ...innerProps
  } = useComponentProps(props, 'DealflowIcon');

  const group = useClientDealflowGroup(groupId);
  const status = useClientDealflowStatus(statusId);
  const label = group ? group.label : 'Deal flow status: ' + status?.label;
  const short = (group ? group.short : status?.short)?.toUpperCase();
  const statusColor = (group?.color ? group.color : status?.color);
  const statusIcon = (group ? group.icon : status?.icon);

  const [icon, color] = useMemo(() => {
    const renderIcon = (short = null) => {
      const wide = short ? ['M', 'W'].includes(short) : false;
      return createSvgIcon(
        <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <rect rx="2" ry="2" x="3" y="3" width="18" height="18" />
          {(short && short !== '?') ? <text x={wide ? '5.5' : '7'} y="17.5">{short[0]}</text> : null}
        </svg>,
        'Dealflow'
      )
    };

    let icon;
    if (utils.isDefined(statusIcon)) {
      icon = innerProps.icon ?? constants.icons.dealflow[statusIcon];
    }

    if (!icon) {
      switch (short) {
        case 'N':
          return [innerProps.icon ?? Remove, innerProps.color ?? 'secondary'];
        case 'R':
          return [innerProps.icon ?? renderIcon('R'), innerProps.color ?? utils.deprecatedColor(statusColor)];
        case 'H':
          return [innerProps.icon ?? HourglassTop, innerProps.color ?? utils.deprecatedColor(statusColor)];
        case 'A':
          return [innerProps.icon ?? RemoveRedEye, innerProps.color ?? utils.deprecatedColor(statusColor)];
        case 'P':
          return [innerProps.icon ?? CheckBox, innerProps.color ?? utils.deprecatedColor(statusColor)];
        default:
          return [innerProps.icon ?? renderIcon(short), innerProps.color ?? utils.deprecatedColor(statusColor)];
      }
    } else {
      return [icon, innerProps.color ?? utils.deprecatedColor(statusColor)];
    }
  }, [statusIcon, statusColor, short, innerProps.icon, innerProps.color]);

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <Tooltip title={showTooltip ? label : null} placement="bottom" enterDelay={0} {...TooltipProps}>
    <StyledDealflowIcon ref={ref} {...innerProps} icon={icon} color={color}/>
  </Tooltip>
}));

DealflowIcon.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  clientId: PropTypes.number,
  statusId: PropTypes.number,
  groupId: PropTypes.number,
  showTooltip: PropTypes.bool,
  TooltipProps: PropTypes.object
};

DealflowIcon.defaultProps = {
  showTooltip: true
};

export default DealflowIcon;
