import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import TextField from 'components/molecules/Fields/TextField/TextField';

const StyledPhoneField = styled(TextField)`
  &:not(.PhoneField-showLinkIcon) {
    .TextField-readOnly {
      .Link {
        line-break: anywhere;
      }
    }
  }
  
  &.PhoneField-showLinkIcon {
    .TextField-readOnly {
      > *:not(.Icon, .InputAdornment) {
        flex-grow: 1;
        width: 1px;
        max-width: fit-content;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledPhoneField.propTypes = {
  theme: PropTypes.object
};

StyledPhoneField.defaultProps = {
}

export default StyledPhoneField;
