import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {darken} from 'polished';

const StyledClientPlanProfileCardContent = styled(Box)`
  &.ClientPlanProfileCardContent {
    width: 100%;
    position: relative;

    .ClientPlanProfileCardContent {
      &-readOnly {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('1sp')};
        min-height: ${props => props.theme.layout(456)};
        max-height: ${props => props.theme.layout(456)};
        
        padding: ${props => props.theme.spacing(8)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
        
        .Span:has(.ClientPlanProfileCardContent-readOnly-chip) {
          position: absolute;
          top: ${props => props.theme.spacing(2)};
          left: ${props => props.theme.spacing(2)};
        }
        
        &-title {
          font-weight: 500;
          line-height: ${props => props.theme.lineHeight('1f')};
          white-space: nowrap;
        }
        &-subtitle {
          line-height: ${props => props.theme.lineHeight('1.3f')};
        }
        &-chips {
          display: flex;
          flex-wrap: wrap;
          gap: ${props => props.theme.layout('0.5sp')};
          margin-top: ${props => props.theme.layout('0.75sp')};
          
          .Chip.changed {
            .MuiChip-label {
              color: ${props => props.theme.property('palette.primary.main')};
              font-weight: 900;
            }
          }
        }
        &-sections {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          gap: ${props => props.theme.layout('0.5sp')};
          margin-top: ${props => props.theme.layout('2.5sp')};

          overflow-y: auto;
          ::-webkit-scrollbar {
            width: ${props => props.theme.layout('1sp')};
          }
          &:not(:hover, :focus) {
            ::-webkit-scrollbar {
              width: 0;
            }
          }
        }
        &-section {
          display: flex;
          align-items: center;
          gap: ${props => props.theme.layout('0.75sp')};
          
          .Icon {
            font-size: ${props => props.theme.fontSize(17)} !important;
            
            &.extra {
              color: ${props => props.theme.property('palette.primary.main')};
            }
            &.disabled {
              color: ${props => darken(0.2, props.theme.property('palette.divider'))};
            }
          }
        }
        &-custom {
          flex-grow: 1;
        }
        &-footer {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
        &-price {
          .H5 {
            font-weight: bold;
            line-height: ${props => props.theme.lineHeight('1.3f')};
          }
        }
      }
    }
  }
  
  &.ClientPlanProfileCardContent-isEditing {
    .Form-group-options {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      row-gap: ${props => props.theme.layout('0.5sp')} !important;
      column-gap: ${props => props.theme.layout('2sp')} !important;

      .FormControl-inlineLabel {
        width: fit-content;
        > .InputLabel, > .FormLabel {
          min-width: ${props => props.theme.layout(180)};
          max-width: ${props => props.theme.layout(180)};
        }
      }
    }  
  }
  
  &.ClientPlanProfileCardContent-isCustom {
    .ClientPlanProfileCardContent {
      &-readOnly {
        background-color: ${props => props.theme.property('palette.primary.main')};
        color: ${props => props.theme.property(`palette.primary.contrastText`)};
        
        &-section {
          .Icon {
            &.disabled {
              color: ${props => props.theme.property('palette.divider')};
            }
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledClientPlanProfileCardContent.propTypes = {
  theme: PropTypes.object
};

StyledClientPlanProfileCardContent.defaultProps = {}

export default StyledClientPlanProfileCardContent;
