import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledConnectionCard = styled(Card)`
  position: relative;
  padding: 0;
  border: unset;
  background-color: unset;
  box-shadow: unset;
  min-height: ${props => props.theme.layout(230)};
  max-height: ${props => props.theme.layout(230)};
  
  .ConnectionCard-content { 
    display: flex;
    padding: ${props => props.theme.spacing(2)};
    gap: ${props => props.theme.layout('2sp')};
    position: relative;

    .ConnectionCard-logo {
      width: fit-content;
      .Icon {
        font-size: ${props => props.theme.fontSize(40)};
      }

      img {
        width: ${props => props.theme.layout(40)};
        height: ${props => props.theme.layout(40)};
      }
    }

    .ConnectionCard {
      &-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 1px;
        gap: ${props => props.theme.layout('1sp')};

        .ConnectionCard-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: fit-content;
        }
      }

      &-details {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        gap: ${props => props.theme.layout('0.5sp')};
      }

      &-detail {
        display: flex;
        flex-wrap: nowrap;
        gap: ${props => props.theme.layout('0.5sp')};

        &-label {
          color: ${props => props.theme.property('palette.text.secondary')};
          min-width: ${props => props.theme.layout(64)};
          max-width: ${props => props.theme.layout(64)};
        }

        > .Span:has(.ConnectionCard-detail-chip), > .ConnectionCard-detail-chip {
          width: 1px;
          flex-grow: 1;
        }
        
        &-value, &-value > * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: fit-content;
        }

        &-value.error, &-value.error > * {
          color: ${props => props.theme.property('palette.error.main')};
        }
        
        &-value:has(.ConnectionCard-detail-info) {
          display: inline-flex;
        }
        
        &-info {
          margin-top: ${props => props.theme.layout(1)};
          margin-left: ${props => props.theme.layout('0.5sp')};
          align-self: flex-start;
        }
      }
    }
  }

  .ConnectionCard-description {
    overflow: hidden;
    padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(2)};
    margin-bottom: ${props => props.theme.spacing(2)};
    border-top: 1px solid ${props => props.theme.property('palette.divider')};
    flex-grow: 1;

    .Typography, .Typography * {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      
      color: ${props => props.theme.property('palette.text.secondary')};
    }
  }

  ${ComponentStyles}
`;

StyledConnectionCard.propTypes = {
  theme: PropTypes.object
};

StyledConnectionCard.defaultProps = {}

export default StyledConnectionCard;
