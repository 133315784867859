import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledStatusGroupCard = styled(Card)`
  position: relative;
  padding: 0;
  border: unset;
  display: flex;
  box-shadow: unset;
  background-color: unset;
  flex-direction: column;
  min-height: ${props => props.theme.layout(238)};
  max-height: ${props => props.theme.layout(238)};

  .StatusGroupCard {
    &-content {
      padding: ${props => props.theme.spacing(2)};
      gap: ${props => props.theme.layout('0.25sp')};
      flex-direction: column;
      background-color: ${props => props.theme.property('palette.primary.states.hover')};
    }
    
    &-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('2sp')};
    }

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.theme.property('palette.background.paper')};
      border-radius: ${props => props.theme.radius(1.5)};
      box-shadow: ${props => props.theme.property('shadows.1')};
      
      width: ${props => props.theme.layout(40)};
      max-width: ${props => props.theme.layout(40)};
      height: ${props => props.theme.layout(40)};
      max-height: ${props => props.theme.layout(40)};
      padding: ${props => props.theme.spacing(1)};
      
      .Icon, .Skeleton {
        width: ${props => props.theme.layout(21)};
        height: ${props => props.theme.layout(21)};
      }
    }
    
    &-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: fit-content;
      
      margin-top: -${props => props.theme.layout('0.25sp')};
    }
    
    &-details {
      display: flex;
      flex-direction: column;
      gap: ${props => props.theme.layout('0.5sp')};
    }
    
    &-detail {
      display: flex;
      flex-wrap: nowrap;
      gap: ${props => props.theme.layout('0.5sp')};

      &-label {
        color: ${props => props.theme.property('palette.text.secondary')};
        min-width: ${props => props.theme.layout(88)};
        max-width: ${props => props.theme.layout(88)};
      }
      &-value, &-value > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }
    
    &-statuses {
      overflow: hidden;
      padding: ${props => props.theme.spacing(2)};
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      flex-grow: 1;
      
      .ChipList {
        height: 100%;
        align-items: flex-start;
        align-content: flex-start;
        column-gap: ${props => props.theme.layout('0.75sp')};
        row-gap: ${props => props.theme.layout('0.4sp')};
        
        .ChipList-overflow-wrapper {
          margin-top: ${props => props.theme.layout(-2)};
          display: block;
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledStatusGroupCard.propTypes = {
  theme: PropTypes.object
};

StyledStatusGroupCard.defaultProps = {}

export default StyledStatusGroupCard;
