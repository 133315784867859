import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import ListWrapper from 'components/templates/Wrappers/Headers/ListWrapper/ListWrapper';

const StyledCollectionSourcesSectionPanelContent = styled(ListWrapper)`
  &.CollectionSourcesSectionPanelContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .Profile {
      flex-grow: 1;
      min-height: unset;
      padding-top: ${props => props.theme.spacing(1)};
      padding-bottom: ${props => props.theme.spacing(2)};
    }

    .ListWrapper-content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
  
  .CollectionSourcesSectionPanelContent {
    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: ${props => props.theme.layout('1sp')};
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};
      border-top: 1px solid ${props => props.theme.property('palette.divider')};
      background-color: ${props => props.theme.property('palette.background.paper')};
      
      &-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      
      .ScoreLinearProgress {
        width: 100%;
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .Profile {
      padding-left: ${props => props.theme.spacing(2)};
      padding-right: ${props => props.theme.spacing(2)};
    }
    .CollectionSourcesSectionPanelContent {
      &-footer {
        padding: ${props => props.theme.spacing(2)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledCollectionSourcesSectionPanelContent.propTypes = {
  theme: PropTypes.object
};

StyledCollectionSourcesSectionPanelContent.defaultProps = {}

export default StyledCollectionSourcesSectionPanelContent;
