import React, {useEffect, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledCollectionEntitySideBarWrapper
  from 'components/templates/Wrappers/Collections/Entities/CollectionEntitySideBarWrapper/CollectionEntitySideBarWrapper.styles';
import CollectionEntitiesList from 'components/organisms/Lists/CollectionEntitiesList/CollectionEntitiesList';
import utils from 'helpers/utils';
import {useLinkNavigate, useSplitPath} from 'helpers/hooks/links';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

const CollectionEntitySideBarWrapper = React.forwardRef((props, ref) => {
  const {
    stateKey,
    CollectionEntitiesListProps,
    ...innerProps
  } = useComponentProps(props, 'CollectionEntitySideBarWrapper');

  const profileProvider = useProfile();

  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [open, setOpen] = useState(!lgDown);
  const [animate, setAnimate] = useState(false);

  const navigate = useLinkNavigate();

  const [pathPrefix, pathPostfix] = useSplitPath('entities');
  const pathPostSplit = pathPostfix.split('/');
  const postFix = pathPostSplit.length > 1 ? `/${pathPostSplit.slice(1).join('/')}` : '';
  const pathKey = pathPostSplit[1] ?? 'profile';

  const handleClick = (e, entity) => {
    if (entity?.entityId) {
      navigate({
        event: e,
        to: `${pathPrefix}/${entity.entityId}${postFix}`, keepSearchParams: true
      });
    }
  };

  useLayoutEffect(() => {
    setAnimate(false);
  }, [pathKey]);

  useEffect(() => {
    setAnimate(true);
  }, [pathKey]);

  const handleContextToggle = (e, open) => {
    setOpen(open);
  }

  useLayoutEffect(() => {
    setOpen(!lgDown);
  }, [lgDown]);

  innerProps.size = innerProps.size ?? 'small';
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionEntitySideBarWrapper ref={ref} {...innerProps}
                                               variant={lgDown ? 'temporary' : 'standard'}
                                               context={<CollectionEntitiesList onClick={handleClick}
                                                                                {...CollectionEntitiesListProps}/>}
                                               open={open && Boolean(profileProvider.state?.showSidebar?.[stateKey ?? pathKey])}
                                               onToggle={handleContextToggle}
                                               animate={animate}/>
});

CollectionEntitySideBarWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  stateKey: PropTypes.string,
  CollectionEntitiesListProps: PropTypes.object,
};

CollectionEntitySideBarWrapper.defaultProps = {
  anchor: 'left',
};

export default CollectionEntitySideBarWrapper;
