import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Accordion from 'components/atoms/Accordions/Accordion/Accordion';

const StyledTableFiltersContentFilter = styled(Accordion)`
  &::before {
    height: 0;
    background-color: unset;
  }
  
  .AccordionSummary {
    .MuiAccordionSummary-content {
      flex-grow: 1;
      width: 1px;  
      
      .Typography { 
        &, * {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    
    &:not(.Mui-expanded) {
      .Badge .MuiBadge-badge {
        color: ${props => props.theme.property('palette.text.primary')};
        background-color: ${props => props.theme.property('palette.primary.states.focusVisible')};
      }
    }
  }
  
  .AccordionDetails {
    margin: ${props => props.theme.spacing(1)};
    padding: ${props => props.theme.spacing(2)};
    background-color: ${props => props.theme.property('palette.primary.states.hover')};

    border-radius: ${props => props.theme.radius(1)};
    
    &:has(.ListField) {
      &:not(:has(.ListField-search)) {
        padding: ${props => props.theme.spacing(1)} ${props => props.theme.spacing(2)};
      }
    }
    
    .MuiInputBase-root {
      background-color: ${props => props.theme.property('palette.background.paper')};
    }
  }
  
  ${ComponentStyles}
`;

StyledTableFiltersContentFilter.propTypes = {
  theme: PropTypes.object
};

StyledTableFiltersContentFilter.defaultProps = {
}

export default StyledTableFiltersContentFilter;
