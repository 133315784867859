import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';
import {rgba} from 'polished';
import utils from 'helpers/utils';

const StyledDashboardContextWrapper = styled(ContextWrapper)`
  .ContextDrawer-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.property('palette.primary.states.hover')};

    ${props => props.theme.breakpoints.down('sm')} {
      width: unset;
    }

    .DashboardContextWrapper {
      position: relative;

      &-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2.5)};
      }

      &-feed {
        height: 100%;
        margin-right: 0;
        
        .DataList-list {
          width: 100%;
          overflow-x: hidden;
          padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
          overscroll-behavior: contain;
        }

        .DataList-footer {
          background: linear-gradient(0deg, ${props => utils.rgba2Rgb(props.theme.property('palette.primary.states.hover'))} 0%, rgba(0, 0, 0, 0) 100%);
        }
      }

      &-empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: ${props => rgba(props.theme.property('palette.text.primary'), props.theme.property('palette.action.disabledOpacity'))};
        font-style: italic;

        P {
          white-space: pre-line;
          margin: ${props => props.theme.spacing(1)} 0;
        }
      }
    }
  }


  ${props => props.theme.breakpoints.down('lg')} {
    .ContextDrawer-content {
      .DashboardContextWrapper {
        &-header {
          padding: ${props => props.theme.spacing(1.75)} ${props => props.theme.spacing(2)};
        }
        &-feed {
          .DataList-list {
            padding: ${props => props.theme.spacing(2)};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDashboardContextWrapper.propTypes = {
  theme: PropTypes.object
};

StyledDashboardContextWrapper.defaultProps = {
};

export default StyledDashboardContextWrapper;
