import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import {useEntitySettings} from 'services/entity/entity.utils';
import utils from 'helpers/utils';
import StyledResolveEntityMergeWizardContent
  from 'components/organisms/WizardContent/ResolveEntityMergeWizardContent/ResolveEntityMergeWizardContent.styles';
import Box from 'components/atoms/Layout/Box/Box';
import Typography, {Span} from 'components/atoms/Text/Typography/Typography';
import Icon from 'components/atoms/Icons/Icon/Icon';
import ArrowBack from '@mui/icons-material/ArrowBack';
import HomeWorkOutlined from '@mui/icons-material/HomeWorkOutlined';
import Logo from 'components/atoms/Logos/Logo/Logo';

const ResolveEntityMergeWizardContent = React.forwardRef((props, ref) => {
  const {
    entity,
    wizard,
    step,
    ...innerProps
  } = useComponentProps(props, 'ResolveEntityMergeWizardContent');

  const data = wizard.data?.[wizard.dataKey];

  const [entityFieldData] = useEntitySettings();

  const fields = useMemo(() => ([{
    name: 'force',
    label: 'Force merge',
    type: constants.formFieldTypes.switch,
    conversion: constants.formFieldConversionTypes.none,
    required: true,
    target: 'data',
    initial: !data?.merge?.mergeAble,
    FormFieldProps: {
      hidden: true
    }
  }]), [data?.merge?.mergeAble]);

  const renderLogos = () => {
    return <Box className="ResolveEntityMergeWizardContent-logos">
      <Logo density="sparse"
            size="huge"
            outlined={true}
            logo={entity.logoUrl}
            altLogo={entity.altLogoUrl}
            fallbackIcon={HomeWorkOutlined} />

      <Icon icon={ArrowBack}
            size="small"
            color="primaryBackground"/>

      <Logo density="sparse"
            size="huge"
            outlined={true}
            logo={data?.target?.entity?.logoUrl}
            altLogo={data?.target?.entity?.altLogoUrl}
            fallbackIcon={HomeWorkOutlined} />
    </Box>
  }

  const renderExplanation = () => {
    const dealflowConficts = Boolean(data?.merge?.conflicts?.includes('dealflow') || data?.merge?.conflictingDsi);

    return <Box className="ResolveEntityMergeWizardContent-explanation">
      <Typography variant="subtitle1"><Span className="name">{data?.target?.entity?.name}</Span> will be merged into <Span className="name">{entity?.name}</Span>. If data exists for both companies, most recent data will be prioritised.</Typography>
      {!dealflowConficts ? <Typography variant="body1" color="text.secondary"><Span className="name">{data?.target?.entity?.name}</Span> will be permanently removed from your database after merging.</Typography> :
        (step.enableSubmit(wizard.data) ? <Typography variant="body1" color="warning">
          We have detected different deal flow information for the companies, possibly over multiple teams. If you continue the deal flow information for <Span className="name">{entity?.name}</Span> will be prioritised.
        </Typography> : <Typography variant="body1" color="error">
          We have detected different deal flow information for the companies, possibly over multiple teams. You don't have the required rights to merge these companies.
        </Typography>)
      }
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledResolveEntityMergeWizardContent ref={ref} {...innerProps}
                                                wizard={wizard}
                                                step={step}
                                                fields={fields}
                                                fieldData={entityFieldData}>
    <Box className="ResolveEntityMergeWizardContent-content">
      {renderLogos()}
      {renderExplanation()}
    </Box>
  </StyledResolveEntityMergeWizardContent>
});

ResolveEntityMergeWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  entity: PropTypes.object
};

ResolveEntityMergeWizardContent.defaultProps = {};

export default ResolveEntityMergeWizardContent;
