import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-margin: inherit;
    scroll-padding: inherit;
  }
  
  html {}
  
  body {
    color: ${props => props.theme.property('palette.text.primary')};
    background-color: ${props => props.theme.property('palette.background.paper')};
    font-family: 'Roboto', 'Helvetica', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    ::-webkit-scrollbar {
      display: none;
    }

    input:-webkit-autofill {
      transition-delay: 9999s;
      transition-property: background-color, color;
    }
    
    #root {
    }
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

export default GlobalStyles;
