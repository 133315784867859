import {useCallback} from 'react';
import sourceUtils from 'helpers/source';
import {useCollectionUploadAdd} from 'services/collection/upload/upload.hooks';

export function useCollectionUploadCreate (mutationOptions = {}) {
  const addUpload = useCollectionUploadAdd(mutationOptions);

  return useCallback((source, collection) => {
    const newSource = sourceUtils.createSource({
      ...source,
      params: {
        ...source.params,
        collectionId: collection?.collectionId
      }
    });

    return addUpload.mutation.mutateAsync({
      collectionId: collection?.collectionId,
      $body: newSource
    });
  }, [addUpload.mutation])
}
