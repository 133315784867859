import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import CollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import StyledCollectionServicesSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionServicesSectionPanelContent/CollectionServicesSectionPanelContent.styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const info = 'Select data enrichment services to enrich company profiles';

const CollectionServicesSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    section,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'CollectionServicesSectionPanelContent');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const handleShowCard = (card) => {
    return card.name.startsWith('service');
  }

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return <StyledCollectionServicesSectionPanelContent ref={innerRef} {...innerProps}>
    <CollectionSectionPanelHeader title={section.title} info={!smDown ? info : null}/>
    <CollectionProfile columns={smDown ? 1 : (lgDown ? 2 : 3)}
                       variant="grid"
                       onShowCard={handleShowCard}
                       onCanUpdate={onCanUpdate} />
  </StyledCollectionServicesSectionPanelContent>

});

CollectionServicesSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func
};

CollectionServicesSectionPanelContent.defaultProps = {};

export default CollectionServicesSectionPanelContent;
