import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';

const StyledBrowserBar = styled(HeaderBar)`
  &.BrowserBar {
    .PageBar-toolbar {
      padding: 0 ${props => props.theme.spacing(2)} 0 ${props => props.theme.spacing(3)};  
    }
    
    .PageBar-center {
      &:has(*) {
        flex: 1 0 calc(min(${props => props.theme.layout(640)}, 40%));
      }

      &-wrapper {
        position: unset;
      }
    }
    
    &:has(.PageBar-title) {
      .PageBar-center {
        &:has(*) {
          flex: 1 0 calc(min(${props => props.theme.layout(640)}, 30%));
          margin-right: 10%;
        }
      }
    }
  }


  ${props => props.theme.breakpoints.down('lg')} {
    .PageBar-center {
      display: none;
    }
  }
  
  ${ComponentStyles}
`;

StyledBrowserBar.propTypes = {
  theme: PropTypes.object
};

StyledBrowserBar.defaultProps = {
};

export default StyledBrowserBar;
