import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useUpdatedRef} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Box from 'components/atoms/Layout/Box/Box';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import StyledCollectionServiceProfileCardContent
  from 'components/organisms/Cards/CollectionServiceProfileCardContent/CollectionServiceProfileCardContent.styles';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Typography, {H3, H6} from 'components/atoms/Text/Typography/Typography';
import Markdown from 'components/atoms/Formatters/Markdown/Markdown';
import Img from 'components/atoms/Images/Img/Img';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import Close from '@mui/icons-material/Close';
import {useDialog} from 'components/organisms/Dialogs/Dialog/Dialog';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import dom from 'helpers/dom';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ContextWrapper from 'components/templates/Wrappers/Sidebars/ContextWrapper/ContextWrapper';
import {useCollectionList, useCollectionUpdateBulk} from 'services/collection/collection.hooks';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import FolderSharp from '@mui/icons-material/FolderSharp';
import Table from 'components/organisms/Tables/Table/Table';
import ToolbarWrapper from 'components/templates/Wrappers/Toolbars/ToolbarWrapper/ToolbarWrapper';
import HeaderBar from 'components/organisms/Bars/HeaderBar/HeaderBar';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import IconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell';
import CollectionSelectionDialog
  from 'components/organisms/Dialogs/CollectionSelectionDialog/CollectionSelectionDialog';
import {useClientStatisticCreditDistribution} from 'services/client/statistic/statistic.utils';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

const ContentField = React.forwardRef(({name, service, collectionFilter, collectionList, disabled, changes, onChange}, ref) => {
  const [showDialog, setShowDialog] = useState(false);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const changesRef = useUpdatedRef(changes);
  const onChangeEvent = useEffectEvent(onChange);
  const doChange = useCallback((collections, remove = true) => {
    onChangeEvent?.({
      ...changesRef.current,
      added: utils.uniqueArray(!remove ? changesRef.current.added.concat(collections) : changesRef.current.added.filter((c1) => {
        return !collections.find((c2) => +c1.collectionId === +c2.collectionId)
      }), 'collectionId'),
      removed: utils.uniqueArray(remove ? changesRef.current.removed.concat(collections) : changesRef.current.removed.filter((c1) => {
        return !collections.find((c2) => +c1.collectionId === +c2.collectionId)
      }), 'collectionId')
    });
  }, [onChangeEvent, changesRef]);

  const columnsMemo = useMemo(() => {
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Name',
      Cell: ({cell}) => {
        return <TextTableCell title={cell.getValue()}
                              icon={<Icon icon={FolderSharp} color="primary"/>}/>
      }
    });

    let activity = 'Updated';
    if (service?.serviceId === constants.services.types.autotags) {
      activity = 'Tagged';
    } else if (service?.serviceId === constants.services.types.autoLookup) {
      activity = 'Lookups';
    } else if ([
                 constants.services.types.linkedinEnrichment,
                 constants.services.types.companyInfoEnrichment,
                 constants.services.types.creditSafeEnrichment
               ].includes(service?.serviceId)) {
      activity = 'Enriched';
    }

    columns.push({
      accessorKey: 'enriched',
      id: 'enriched',
      header: `${activity} this week`,
      Cell: ({cell}) => {
        let count = 0;
        if (service?.serviceId === constants.services.types.autotags) {
          count = cell.row.original.feedbackStats?.autotags?.week ?? 0
        } else if (service?.serviceId === constants.services.types.autoLookup) {
          count = cell.row.original.feedbackStats?.autoLookup?.week ?? 0
        } else if (service?.serviceId === constants.services.types.linkedinEnrichment) {
          count = cell.row.original.feedbackStats?.enrichment?.week ?? 0
        } else if (service?.serviceId === constants.services.types.companyInfoEnrichment) {
          count = cell.row.original.feedbackStats?.companyInfo?.week ?? 0
        } else if (service?.serviceId === constants.services.types.creditSafeEnrichment) {
          count = cell.row.original.feedbackStats?.creditSafe?.week ?? 0
        }

        return <TextTableCell title={`${utils.formatNumber(count)} compan${((cell.row.original.enrichment?.week ?? 0) === 1) ? 'y' : 'ies'}`}/>
      }
    });

    columns.push({
      id: 'delete',
      size: 80,
      minSize: 80,
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      Cell: ({cell}) => {
        const collection = cell.row.original;

        const action = {
          label: 'Remove collection',
          tooltip: 'Remove collection',
          icon: <Icon icon={Delete} size="smaller" />,
          IconButtonProps: {
            disabled: disabled
          },
          onClick: () => {
            doChange([collection], true);
          },
        };

        return <IconButtonTableCell ActionIconButtonProps={{ variant: 'outlined',
          size: 'smaller',
          density: 'sparse',
          showInactive: true,
          action: action }} />
      }
    });

    return columns;
  }, [disabled, service?.serviceId, doChange]);

  const addCollectionAction = useMemo(() => ({
    label: !smDown ? 'Add collection' : 'Add',
    color: 'success',
    icon: Add,
    ActionButtonProps: {
      //  size: 'small'
    },
    ButtonProps: {
      disabled: disabled
    },
    onClick: () => {
      setShowDialog(true);
    }
  }), [disabled, smDown]);

  const collectionFilterMemo = useMemo(() => {
    return collectionFilter.map((cf) => {
      return {
        ...cf,
        value: utils.toArray(cf.value)
          .map((v) => v.toString())
          .map((v) => v.startsWith('-') ? v.slice(1) : `-${v}`)
      }
    })
      .concat([{id: 'allClient', value: true}])
      .concat(changes.added.length > 0 ? [{id: 'excludeIds', value: changes.added.map((c) => c.collectionId)}] : [])
      .concat(changes.removed.length > 0 ? [{id: 'includeIds', value: changes.removed.map((c) => c.collectionId)}] : []);
  }, [collectionFilter, changes.added, changes.removed]);

  const renderCollectionAddDialog = () => {
    const handleSubmit = (collections) => {
      doChange(collections, false);
    }

    const handleClose = () => {
      setShowDialog(false);
    }

    if (showDialog) {
      return <CollectionSelectionDialog title="Add collection"
                                        open={true}
                                        hideCreate={true}
                                        collectionFilter={collectionFilterMemo}
                                        hideCollections={true}
                                        SaveButtonProps={{
                                          color: 'success',
                                          startIcon: <Icon icon={Add}/>,
                                          children: 'Add'
                                        }}
                                        onClose={handleClose}
                                        onSubmit={handleSubmit}/>
    }
  }

  const [count, data] = useMemo(() => {
    const count = (collectionList.meta?.resultsCount ?? 0) - changes.removed.length + changes.added.length;
    const data = (collectionList.data ?? [])
      .filter((collection) => {
        return !changes.removed.find((c) => +collection.collectionId === +c.collectionId)
      })
      .concat(changes.added.filter((collection) => {
        return !(collectionList.data ?? []).find((c) => +collection.collectionId === +c.collectionId)
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    return [count, data];
  }, [collectionList.data, collectionList.meta?.resultsCount, changes.added, changes.removed]);

  const collectionCount = utils.formatNumber(count ?? 0);

  return <ToolbarWrapper ref={ref}
                         ToolbarComponent={HeaderBar}
                         ToolbarProps={{
                           title: `Active in ${collectionCount} collection${(count ?? 0) === 1 ? '' : 's'}`,
                           rightActions: [addCollectionAction]
                         }}>
    <Table className="CollectionServiceProfileCardContent-form-table"
           dataKey="collectionId"
           emptyText="No collection(s) selected"
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enableColumnVirtualization={false}
           onFetchMore={collectionList.query.fetchNextPage}
           onRefetch={collectionList.query.refetch}
           columns={columnsMemo}
           data={data}
           rowCount={count}
           state={{
             isLoading: collectionList.status.isLoading,
             showProgressBars: collectionList.status.isLoadingNext
           }}/>
    {renderCollectionAddDialog()}
  </ToolbarWrapper>
});

const CollectionServiceProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    service,
    collection,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    ...innerProps
  } = useComponentProps(props, 'CollectionServiceProfileCardContent', {
    static: ['isEditing', 'isDeleted', 'isExtended']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);
  const contentFieldRef = useRef(null);
  const profileProvider = useProfile();

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [resultsCount, setResultsCount] = useState(null);

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const dialog = useDialog();
  const snackbar = useSnackbar();

  const client = useAuthClient();
  const updateClientEvent = useEffectEvent(profileProvider.updaters?.updateData);

  const collectionUpdateBulk = useCollectionUpdateBulk();

  const isAddNew = !utils.isDefined(service?.serviceId);
  const isEditing = content.state.isEditing;
  const isExtended = utils.isDefined(collection?.universeClientId) && !service?.always && !service?.plan;
  const isLoading = !utils.isDefined(service);

  const [allCollections, dealflow] = useMemo(() => {
    let allCollections = false, dealflow = false;
    if (service?.serviceId === constants.services.types.autotags) {
      allCollections = Boolean(client?.props?.autotagPeriod !== constants.services.periods.never);
      dealflow = Boolean(collection?.autotagPeriod !== constants.services.periods.never);
    } else if (service?.serviceId === constants.services.types.autoLookup) {
      allCollections = Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never);
      dealflow = Boolean(collection?.autoLookupPeriod !== constants.services.periods.never);
    } else if (service?.serviceId === constants.services.types.linkedinEnrichment) {
      allCollections = Boolean(client?.props?.enrichmentPeriod !== constants.services.periods.never);
      dealflow = Boolean(collection?.enrichmentPeriod !== constants.services.periods.never);
    } else if (service?.serviceId === constants.services.types.companyInfoEnrichment) {
      allCollections = Boolean(client?.props?.companyInfoPeriod !== constants.services.periods.never);
      dealflow = Boolean(collection?.companyInfoPeriod !== constants.services.periods.never);
    } else if (service?.serviceId === constants.services.types.creditSafeEnrichment) {
      allCollections = Boolean(client?.props?.creditSafePeriod !== constants.services.periods.never);
      dealflow = Boolean(collection?.creditSafePeriod !== constants.services.periods.never);
    }

    return [allCollections, dealflow];
  }, [service?.serviceId, collection?.autotagPeriod, collection?.autoLookupPeriod, collection?.enrichmentPeriod, collection?.companyInfoPeriod,
    collection?.creditSafePeriod, client?.props?.autotagPeriod, client?.props?.autoLookupPeriod, client?.props?.enrichmentPeriod,
    client?.props?.companyInfoPeriod, client?.props?.creditSafePeriod]);

  const collectionFilter = useMemo(() => {
    if (client) {
      return (!(formValues.allCollections ?? allCollections) ? (
        (service?.serviceId === constants.services.types.autotags) ? [{
          id: 'autotagPeriod',
          value: `-${constants.services.periods.never}`
        }] : ((service?.serviceId === constants.services.types.autoLookup) ? [{
            id: 'autoLookupPeriod',
            value: `-${constants.services.periods.never}`
          }] : ((service?.serviceId === constants.services.types.linkedinEnrichment) ? [{
              id: 'enrichmentPeriod',
              value: `-${constants.services.periods.never}`
            }] : ((service?.serviceId === constants.services.types.companyInfoEnrichment) ? [{
              id: 'companyInfoPeriod',
              value: `-${constants.services.periods.never}`
              }] : ((service?.serviceId === constants.services.types.creditSafeEnrichment) ? [{
                id: 'creditSafePeriod',
                value: `-${constants.services.periods.never}`
              }]: []))))
      ) : [])
        // force refresh by added currentTime dummy
        .concat([{id: 'dummy', value: Date.now()}]);
    }
  }, [service?.serviceId, client, formValues.allCollections, allCollections]);

  const collectionList = useCollectionList({
    filter: collectionFilter,
    sort: [{id: 'name', desc: false}],
    lookupOnly: !isEditing,
    feedbackStats: isEditing,
    allClient: true,
    pageSize: isEditing ? 10 : 0
  }, {
    ...(isEditing ? constants.queryOptions.infinite : null),
    ...constants.queryOptions.runOnceFresh,
    enabled: isExtended
  });

  const [creditDistribution, isLoadingCredits] = useClientStatisticCreditDistribution(1);

  const [isActive, isOverride] = useMemo(() => {
    if (service?.active) {
      return [true, false];
    } else {
      if (service?.serviceId === constants.services.types.crunchbaseEnrichment) {
        return [Boolean(client?.props?.hasCrunchbase), false];
      } else if (service?.serviceId === constants.services.types.patentEnrichment) {
        return [Boolean(client?.props?.hasPatents), false];
      } else if (service?.serviceId === constants.services.types.autotags) {
        return [Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) ||
          (+resultsCount > 0) ||
          Boolean(collection?.autotagPeriod !== constants.services.periods.never), Boolean(client?.props?.autotagPeriod !== constants.services.periods.never)];
      } else if (service?.serviceId === constants.services.types.autoLookup) {
        return [Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never) ||
        (+resultsCount > 0) ||
        Boolean(collection?.autoLookupPeriod !== constants.services.periods.never), Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never)];
      } else if (service?.serviceId === constants.services.types.linkedinEnrichment) {
        return [Boolean(client?.props?.enrichmentPeriod !== constants.services.periods.never) ||
          (+resultsCount > 0) ||
          Boolean(collection?.enrichmentPeriod !== constants.services.periods.never), Boolean(client?.props?.enrichmentPeriod !== constants.services.periods.never)];
      } else if (service?.serviceId === constants.services.types.companyInfoEnrichment) {
        return [Boolean(client?.props?.companyInfoPeriod !== constants.services.periods.never) ||
        (+resultsCount > 0) ||
        Boolean(collection?.companyInfoPeriod !== constants.services.periods.never), Boolean(client?.props?.companyInfoPeriod !== constants.services.periods.never)];
      } else if (service?.serviceId === constants.services.types.creditSafeEnrichment) {
        return [Boolean(client?.props?.creditSafePeriod !== constants.services.periods.never) ||
        (+resultsCount > 0) ||
        Boolean(collection?.creditSafePeriod !== constants.services.periods.never), Boolean(client?.props?.creditSafePeriod !== constants.services.periods.never)];
      } else {
        return [Boolean(service?.always), false];
      }
    }
  }, [service, client?.props, collection, resultsCount]);

  useEffect(() => {
    if ((!isEditing || !utils.isDefined(resultsCount)) && utils.isDefined(collectionList.meta?.resultsCount)) {
      setResultsCount(collectionList.meta?.resultsCount);
    }
  }, [isEditing, collectionList.meta?.resultsCount, resultsCount]);

  const fields = useMemo(() => {
    const fields = [];

    if (isExtended) {
      fields.push({
        title: 'Activation settings',
        formGroup: 'context',
        section: true
      });

      fields.push({
        name: 'dealflow',
        label: 'Dealflow',
        inlineLabel: 'dealflow',
        type: constants.formFieldTypes.switch,
        validation: constants.formFieldValidationTypes.boolean,
        entity: 'service',
        formGroup: 'context',
        initial: dealflow,
        FormFieldProps: {
          variant: 'inlineLabel',
          fullWidth: true
        }
      });

      fields.push({
        name: 'allCollections',
        label: 'All collections',
        type: constants.formFieldTypes.switch,
        validation: constants.formFieldValidationTypes.boolean,
        entity: 'service',
        formGroup: 'context',
        initial: allCollections,
        FormFieldProps: {
          variant: 'inlineLabel',
          fullWidth: true
        }
      });

      fields.push({
        name: 'collections',
        type: constants.formFieldTypes.component,
        validation: constants.formFieldValidationTypes.component,
        conversion: constants.formFieldConversionTypes.component,
        entity: 'service',
        valueProp: 'changes',
        Component: <ContentField service={service}
                                 collectionFilter={collectionFilter}
                                 collectionList={collectionList} />,
        disabled: (formValues.allCollections ?? allCollections),
        initial: {
          value: {
            added: [],
            removed: []
          },
          errors: false
        },
        formGroup: 'content',
        FormFieldProps: {
          ref: contentFieldRef,
          variant: 'staticLabel',
          hiddenLabel: true,
          debounce: false,
          autoFocus: false
        }
      });
    }

    return fields
      .filter((f) => !f.readOnly || !utils.isEmpty(f.initial));
  }, [service, isExtended, formValues.allCollections, allCollections, dealflow, collectionList, collectionFilter]);

  useLayoutEffect(() => {
    setFormValues({});
  }, [allCollections, dealflow]);

  useLayoutEffect(() => {
    contentFieldRef.current?.clear({
      value: {
        added: [],
        removed: []
      },
      errors: false
    });
  }, [formValues.allCollections]);

  const handleChangeDirect = (e) => {
    const field = fields?.find((field) => field.name === e?.target?.name);
    const value = e?.target?.value;

    setFormValues(utils.updater({[field.name]: (value?.value ?? value)}, true));
  }

  const handleSubmit = (values) => {
    const doFinalSubmit = (promise) => {
      let fields = [];
      if (service?.serviceId === constants.services.types.autotags) {
        const activate = (isExtended && values['dealflow']) || (!isExtended && collection?.autotagPeriod === constants.services.periods.never);
        fields = [{
          name: 'autotagPeriod',
          value: activate ? service?.period : constants.services.periods.never
        }];
      } else if (service?.serviceId === constants.services.types.autoLookup) {
        const activate = (isExtended && values['dealflow']) || (!isExtended && collection?.autoLookupPeriod === constants.services.periods.never);
        fields = [{
          name: 'autoLookupPeriod',
          value: activate ? service?.period : constants.services.periods.never
        }];
      } else if (service?.serviceId === constants.services.types.linkedinEnrichment) {
        const activate = (isExtended && values['dealflow']) || (!isExtended && collection?.enrichmentPeriod === constants.services.periods.never);
        fields = [{
          name: 'enrichmentPeriod',
          value: activate ? service?.period : constants.services.periods.never
        }];
      } else if (service?.serviceId === constants.services.types.companyInfoEnrichment) {
        const activate = (isExtended && values['dealflow']) || (!isExtended && collection?.companyInfoPeriod === constants.services.periods.never);
        fields = [{
          name: 'companyInfoPeriod',
          value: activate ? service?.period : constants.services.periods.never
        }];
      } else if (service?.serviceId === constants.services.types.creditSafeEnrichment) {
        const activate = (isExtended && values['dealflow']) || (!isExtended && collection?.creditSafePeriod === constants.services.periods.never);
        fields = [{
          name: 'creditSafePeriod',
          value: activate ? service?.period : constants.services.periods.never
        }];
      }

      const changes = fields.reduce((o, f) => {
        o[f.name] = f.value;
        return o;
      }, {});

      if (promise) {
        changes.promise = promise;
      }

      return utils.asPromiseCallback(onSubmit)(changes, null, fields, service, true)
        .then(() => {
          setError(null);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }

    setSubmitting(true);
    if (isExtended) {
      let key;
      const value = values['allCollections'] ? service?.period : constants.services.periods.never;
      if (service?.serviceId === constants.services.types.autotags) {
        key = 'autotagPeriod';
      } else if (service?.serviceId === constants.services.types.autoLookup) {
        key = 'autoLookupPeriod';
      } else if (service?.serviceId === constants.services.types.linkedinEnrichment) {
        key = 'enrichmentPeriod';
      } else if (service?.serviceId === constants.services.types.companyInfoEnrichment) {
        key = 'companyInfoPeriod';
      } else if (service?.serviceId === constants.services.types.creditSafeEnrichment) {
        key = 'creditSafePeriod';
      }

      const promises = [];
      if (value === constants.services.periods.never) {
        if (values['collections'].added?.length > 0) {
          promises.push(collectionUpdateBulk.mutation.mutateAsync({
            $body: values['collections'].added.map(
              (c) => ({
                collectionId: c.collectionId,
                [key]: service?.period
              })
            )
          }))
        }
        if (values['collections'].removed?.length > 0) {
          promises.push(collectionUpdateBulk.mutation.mutateAsync({
            $body: values['collections'].removed.map(
              (c) => ({
                collectionId: c.collectionId,
                [key]: constants.services.periods.never
              })
            )
          }))
        }
      }

      return doFinalSubmit(Promise.all(promises.length > 0 ? promises : [Promise.resolve()])
        .then(() => {
          // client last to trigger the refresh of the collection list
          return updateClientEvent?.(client, {}, {[key]: value});
        }));
    } else {
      return doFinalSubmit();
    }
  }

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, dirty, errors);
  }

  useEffect(() => {
    if (isDialog && !isExtended && error) {
      snackbar.show('(De)activating service failed', null,
        {color: 'error', autoHideDuration: constants.delay.error});

      if (innerRef.current) {
        const p = dom.getParent(innerRef.current, null, 'MuiDialog-paper');
        p.style.border = error ? `2px solid ${innerProps.theme.property('palette.error.main')}` : null;
      }
    }
  }, [isDialog, isExtended, error, snackbar, innerProps.theme]);

  const renderReadOnly = () => {
    return <Box className="CollectionServiceProfileCardContent-readOnly">
      <Box className="CollectionServiceProfileCardContent-readOnly-header">
        <Chip className="CollectionServiceProfileCardContent-readOnly-chip"
              showTooltip={false} isLoading={isLoading}
              variant="filled" size="small"
              color={isActive ? 'success' : 'default'}
              label={service?.always ? 'Always active' : (
                isActive ? 'Active' : 'Not active'
              )}/>
        <Box>
          {service?.images?.map((image, idx) => {
            return <React.Fragment key={idx} >
              {idx > 0 ? <Icon icon={ArrowForward}
                               size="tiny"
                               color="primaryBackground"/> : null}
              <Box className="CollectionServiceProfileCardContent-readOnly-image">
                {image.icon ? (utils.isReactElement(image.icon) ? image.icon : <Icon icon={image.icon}
                                                                                     isLoading={isLoading}
                                                                                     color={image.color}/>) : (
                  image.img ? (utils.isReactElement(image.img) ? image.img : <Img src={image.img}
                                                                                  isLoading={isLoading}
                                                                                  alt={image.alt}/>) : null
                )}
              </Box>
            </React.Fragment>
          })}
        </Box>
      </Box>
      <Box className="CollectionServiceProfileCardContent-readOnly-content">
        <Typography isLoading={isLoading} variant="subtitle2">{card.card.title}</Typography>
        <Markdown isLoading={isLoading}>{service?.oneliner}</Markdown>
        <Box className="CollectionServiceProfileCardContent-readOnly-price">
          <H6 isLoading={isLoading}>{service?.price}</H6>
          <Typography isLoading={isLoading} variant="caption" color="text.secondary">cc per company</Typography>
        </Box>
      </Box>
    </Box>
  }

  const activateAction = useMemo(() => ({
    label: service?.plan ? (isActive ? 'Deactivate via plan' : 'Activate via plan') : (
      isOverride ? (isActive ? 'Deactivate via settings' : 'Activate via settings') : (
        service?.always ? 'Always active' :
        (isActive ? 'Deactivate service' : 'Activate service')
      )
    ),
    color: 'success',
    ButtonProps: {
      disabled: service?.always || service?.plan || isOverride || submitting
    },
    onClick: !(service?.plan || service?.always) ? () => {
      formRef.current?.submit();
    } : null
  }), [isActive, service?.always, service?.plan, isOverride, submitting]);

  const closeEvent = useEffectEvent(dialog?.close);
  const closeAction = useMemo(() => ({
    label: 'Close',
    tooltip: 'Close',
    icon: Close,
    onClick: (e) => {
      closeEvent?.(e, 'closeButtonClick');
    }
  }), [closeEvent]);

  const renderFormCard = () => {
    const renderContent = () => {
      return <Box className="CollectionServiceProfileCardContent-form-card-content">
        <Markdown>{(isExtended ? service?.shortDescription : service?.description) + ((!isActive && service?.activation) ? ` ${service?.activation}` : '')}</Markdown>
        {!isExtended ? <Box className="CollectionServiceProfileCardContent-form-card-price">
          <Typography variant="body2">Credits per company</Typography>
          <H3>{service?.price}<Typography variant="body2">cc</Typography></H3>
        </Box> : null}
      </Box>
    }

    return <Box className="CollectionServiceProfileCardContent-form-card">
      <Box className="CollectionServiceProfileCardContent-form-card-header">
        {!isExtended ? <ActionIconButton size="smaller"
                                         density="sparse"
                                         variant="outlined"
                                         className="CollectionServiceProfileCardContent-form-card-close"
                                         IconProps={{
                                           size: 'smaller'
                                         }}
                                         action={closeAction} /> : null}
        <Box>
          {service?.images?.map((image, idx) => {
            return <React.Fragment key={idx} >
              {idx > 0 ? <Icon icon={ArrowForward}
                               size="smaller"
                               color="primaryBackground"/> : null}
              <Box className="CollectionServiceProfileCardContent-form-card-image">
                {image.icon ? (utils.isReactElement(image.icon) ? image.icon : <Icon icon={image.icon}
                                                                                     size={isExtended ? 'normal' : 'large'}
                                                                                     color={image.color}/>) : (
                  image.img ? (utils.isReactElement(image.img) ? image.img : <Img src={image.img} alt={image.alt}/>) : null
                )}
              </Box>
            </React.Fragment>
          })}
        </Box>
        <Typography variant={isExtended ? 'h6' : 'h5'}>{card.card.title}</Typography>
        {isExtended ? renderContent() : null}
      </Box>
      {!isExtended ? renderContent() : null}
    </Box>
  }

  const renderForm = () => {
    if (!isExtended) {
      return <React.Fragment>
        {renderFormCard()}
        <InlineForm ref={formRef}
                    className="CollectionServiceProfileCardContent-form"
                    fields={fields}
                    fieldData={fieldData}
                    onChangeDirect={handleChangeDirect}
                    onSubmit={handleSubmit}
                    onValidating={handleValidating}/>
        <Box className="CollectionServiceProfileCardContent-footer">
          <ActionButton action={activateAction}
                        fullWidth={true}
                        variant="contained"/>
        </Box>
      </React.Fragment>
    } else {
      const renderContext = (renderedFields) => {
        const creditsUsed = Math.min(+client?.props?.monthlyCreditLimit,
          Math.ceil(creditDistribution?.reduce((t, cd) => t + +cd.count, 0)));
        const creditsLeft = +client?.props?.monthlyCreditLimit - creditsUsed;
        const creditsMax = +client?.props?.monthlyCreditLimit ?? 0;
        const creditsScore = +client?.props?.monthlyCreditLimit > 0 ? Math.round(((creditsUsed / +client?.props?.monthlyCreditLimit) * 100)) : 0;

        return <Box className="CollectionServiceProfileCardContent-form-context">
          <Box className="CollectionServiceProfileCardContent-form-context-header">
            {renderFormCard()}
            <Box className="Form-fields">
              {renderedFields}
            </Box>
          </Box>
          <Box className="CollectionServiceProfileCardContent-form-credits">
            <Box className="CollectionServiceProfileCardContent-form-credits-header">
              <Typography variant="subtitle2">Credits left</Typography>
              <Typography isLoading={isLoadingCredits} variant="subtitle2">
                {utils.formatNumber(creditsLeft)} of {utils.formatNumber(creditsMax)}
              </Typography>
            </Box>
            <ScoreLinearProgress reverse={true}
                                 showEmpty={true}
                                 score={creditsScore}
                                 showPercentage={creditsScore >= 20}
                                 isLoading={isLoadingCredits}/>
          </Box>
        </Box>
      }

      const renderFormContent = (groups) => {
        const context = groups.find((g) => g.name === 'context');
        const content = groups.find((g) => g.name === 'content');

        return <ContextWrapper context={renderContext(context.rendered)}
                               open={true}
                               size="smallish">
          <Box className="Form-fields">
            {content.rendered}
          </Box>
        </ContextWrapper>
      }

      return <InlineForm ref={formRef}
                         className="CollectionServiceProfileCardContent-form"
                         fields={fields}
                         fieldData={fieldData}
                         renderContent={renderFormContent}
                         onChangeDirect={handleChangeDirect}
                         onSubmit={handleSubmit}
                         onValidating={handleValidating}/>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing,
    isExtended: isExtended
  });

  return <StyledCollectionServiceProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="CollectionServiceProfileCardContent-content">
      {!isEditing ? renderReadOnly() : renderForm()}
    </Box>
  </StyledCollectionServiceProfileCardContent>
});

CollectionServiceProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  service: PropTypes.object,
  collection: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func,
};

CollectionServiceProfileCardContent.defaultProps = {
};

export default CollectionServiceProfileCardContent;


