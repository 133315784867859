import utils from 'helpers/utils';
import logger from 'helpers/logger';

function getEnvironmentVariable(variable, app=true) {
  if (app && !variable.startsWith('REACT_APP_')) {
    variable = 'REACT_APP_' + variable;
  }

  return process.env[variable];
}

function getEnvironment () {
  return getEnvironmentVariable('ENVIRONMENT')
}

function isDevelopment () {
  return !getEnvironment() || getEnvironment() === 'development';
}

function isStaging () {
  return getEnvironment() === 'staging';
}

function isProduction () {
  return getEnvironment() === 'production';
}

function appName () {
  return getEnvironmentVariable('NAME');
}

function appShortName () {
  return utils.upperFirst(appName().split('.')[0]);
}

function appVersion () {
  return getEnvironmentVariable('VERSION');
}

function appBuild () {
  return getEnvironmentVariable('NODE_ENV', false);
}

function apiHost () {
  return getEnvironmentVariable('API_HOST');
}

function webHost () {
  return getEnvironmentVariable('WEB_HOST');
}

function appSettings (settings) {
  try {
    const current = JSON.parse(sessionStorage.getItem('app_settings')) ?? {};

    if (settings) {
      const changed = {
        ...current,
        ...settings
      };

      sessionStorage.setItem('app_settings', JSON.stringify(changed));
      return changed;
    } else {
      return current;
    }
  } catch (err) {
    logger.trace('Session storage failed', err);
  }
}

function appVerbosity (value) {
  if (utils.isDefined(value)) {
    return +appSettings({verbosity: value})?.verbosity;
  } else {
    return +appSettings()?.verbosity;
  }
}

function gtmConfig () {
  const id = getEnvironmentVariable('GTM_ID');
  const auth = getEnvironmentVariable('GTM_AUTH');
  const preview = getEnvironmentVariable('GTM_PREVIEW');
  const dataLayerName = getEnvironmentVariable('GTM_DATA_LAYER_NAME');
  const customDomain = getEnvironmentVariable('GTM_CUSTOM_DOMAIN');
  const nonce = getEnvironmentVariable('GTM_NONCE');

  const gtm = {};
  if (id) {
    gtm.id = id;
    if (auth || preview) {
      gtm.environment = {
        gtm_auth: auth,
        gtm_preview: preview
      }
    }
    if (dataLayerName) {
      gtm.dataLayerName = dataLayerName;
    }
    if (customDomain) {
      gtm.customDomain = customDomain;
    }
    if (nonce) {
      gtm.nonce = nonce;
    }
  }

  return gtm;
}

function tokenRefreshTime () {
  return +(getEnvironmentVariable('TOKEN_REFRESH_TIME') ?? (5 * 60 * 1000));
}

function tokenRefreshInterval () {
  return +(getEnvironmentVariable('TOKEN_REFRESH_INTERVAL') ?? (4 * 60 * 60 * 1000));
}

function queryCacheTime () {
  return +(getEnvironmentVariable('QUERY_CACHE_TIME') ?? (8 * 60 * 1000));
}

function queryStaleTime () {
  return +(getEnvironmentVariable('QUERY_STALE_TIME') ?? (4 * 60 * 1000));
}

function storeRefreshTime () {
  return +(getEnvironmentVariable('STORE_REFRESH_TIME') ?? (queryCacheTime() + (60 * 1000)));
}

function storeRefreshResetTime () {
  return +(getEnvironmentVariable('STORE_REFRESH_RESET_TIME') ?? (60 * 1000));
}

function queryNetworkMode () {
  return getEnvironmentVariable('QUERY_NETWORK_MODE') ||
    (isDevelopment() ? 'always' : 'online');
}

function synchronisationInterval () {
  return {
    foreground: +(getEnvironmentVariable('SYNCHRONISATION_INTERVAL_FOREGROUND') ?? (5 * 1000)),
    background: +(getEnvironmentVariable('SYNCHRONISATION_INTERVAL_BACKGROUND') ?? (30 * 1000))
  };
}

function maxStoreSize () {
  return +(getEnvironmentVariable('MAX_STORE_SIZE') ?? 10000);
}

function maxResurrectDepth () {
  return +(getEnvironmentVariable('MAX_RESURRECT_DEPTH') ?? 3);
}

function storeRefreshEntityLimit () {
  return +(getEnvironmentVariable('STORE_REFRESH_ENTITY_LIMIT') ?? 3);
}

function storeRefreshListBatchSize () {
  return +(getEnvironmentVariable('STORE_REFRESH_LIST_BATCH_SIZE') ?? 25);
}

function storeRefreshEntityBatchSize () {
  return +(getEnvironmentVariable('STORE_REFRESH_ENTITY_BATCH_SIZE') ?? storeRefreshEntityLimit());
}

function storePreloadEntityLimit () {
  return +(getEnvironmentVariable('STORE_PRELOAD_ENTITY_LIMIT') ?? (storeRefreshEntityBatchSize() * 3));
}

function queryStatusTimeout () {
  return +(getEnvironmentVariable('QUERY_STATUS_TIMEOUT') ?? (10 * 1000));
}

const system = {
  getEnvironment,
  isDevelopment,
  isStaging,
  isProduction,

  appName,
  appShortName,
  appVersion,
  appBuild,

  apiHost,
  webHost,

  appSettings,
  appVerbosity,

  gtmConfig,

  tokenRefreshTime,
  tokenRefreshInterval,

  queryStaleTime,
  queryCacheTime,
  queryNetworkMode,

  synchronisationInterval,

  maxStoreSize,
  storeRefreshTime,
  storeRefreshResetTime,
  maxResurrectDepth,
  storeRefreshEntityLimit,
  storeRefreshListBatchSize,
  storeRefreshEntityBatchSize,
  storePreloadEntityLimit,

  queryStatusTimeout,

  getEnvironmentVariable
}

export default system;
