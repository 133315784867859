import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledMappingsForm = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.layout('1sp')};
  
  &.MappingsForm {
    > .List {
      padding: 0;
      gap: 0;

      > .ListItem {
        padding: 0;
        
        &:not(:first-child) {
          border-top: 1px solid ${props => props.theme.property('palette.divider')};
          padding-top: ${props => props.theme.layout('2sp')};
          margin-top: ${props => props.theme.layout('2sp')};
        }
      }

      .Button {

      }
    }
  }
  
  ${ComponentStyles}
`;

StyledMappingsForm.propTypes = {
  theme: PropTypes.object
};

StyledMappingsForm.defaultProps = {}

export default StyledMappingsForm;
