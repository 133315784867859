import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import List from 'components/atoms/Lists/List/List';
import ListItem from 'components/atoms/Lists/ListItem/ListItem';
import utils from 'helpers/utils';
import Button from 'components/atoms/Buttons/Button/Button';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import constants from 'helpers/constants';
import FieldMappingForm from 'components/organisms/Forms/FieldMappingForm/FieldMappingForm';
import StyledFieldMappingsForm from 'components/organisms/Forms/FieldMappingsForm/FieldMappingsForm.styles';

const FieldMappingsForm = React.forwardRef((props, ref) => {
  const {
    fields,
    list,
    mappedFields,
    connection,
    fieldData,
    required,
    readOnly,
    disabled,
    error,
    errorFrom,
    errorTo,
    multiple,
    onChange,
    onError,
    ...innerProps
  } = useComponentProps(props, 'FieldMappingsForm');

  const innerRef = useRef(null);
  const fieldRefs = useRef({});

  const [internalState, setInternalState] = useState({
    fields: []
  });

  const fieldMappingsForm = useMemo(() => ({
    refs: {
      ref: innerRef
    },
    submit: (touch = true) => {
      return Promise.all(Object.keys(fieldRefs.current).map((fieldId) => {
        return fieldRefs.current[fieldId]?.ref?.submit?.(touch);
      }));
    },
    validate: (touch = true) => {
      return Promise.all(Object.keys(fieldRefs.current).map((fieldId) => {
        return fieldRefs.current[fieldId]?.ref?.validate?.(touch);
      }))
        .then((errors) => {
          return errors.reduce((o, f) => ({...o, ...utils.cleanObject(f)}), {});
        });
    },
    reset: (state) => {
      return Promise.all(Object.keys(fieldRefs.current).map((fieldId) => {
        return fieldRefs.current[fieldId]?.ref?.reset?.(state);
      }));
    }
  }), []);

  useImperativeHandle(ref, () => fieldMappingsForm);

  useLayoutEffect(() => {
    let minId = 0;

    const flds = (fields ?? [])
      .map((s) => {
        if (!utils.isDefined(s.id)) {
          minId = minId - 1;
        } else if (+s.id < 0) {
          minId = Math.min(minId, +s.id);
        }

        return {
          ...s,
          id: !utils.isDefined(s.id) ? minId : +s.id
        }
      });

    if (!readOnly && flds.length === 0) {
      let direction = constants.data.mappingDirections.filter((md) => md.connections.find((c) => c === connection?.type))[0];
      flds.push({id: minId - 1, direction: direction?.value});
    }

    setInternalState(utils.updater({
      fields: flds
    }, true));
  }, [fields, readOnly, connection?.type]);

  const handleAddField = () => {
    setInternalState((current) => {
      const direction = constants.data.mappingDirections.filter((md) => md.connections.find((c) => c === connection?.type))[0];
      const minId = current.fields.reduce((i, f) => +f.id < 0 ? Math.min(i, +f.id) : i, 0);

      return {
        ...current,
        fields: current.fields.concat([{id: minId - 1, direction: direction.value}])
      }
    });
  }

  const doErrors = () => {
    const errorIds = Object.keys(fieldRefs.current).reduce((o, k) => fieldRefs.current[k].error ? {...o, [k]: true} : o, {});

    const error = !utils.isEmpty(errorIds);

    onError?.(error);
    setInternalState(utils.updater({ error, errorIds }, true));
  }

  const handleChange = (field) => (values) => {
    const idx = internalState.fields.findIndex((f) => +f.id === +field.id);
    
    if (idx !== -1) {
      const newValue = [
        ...internalState.fields.slice(0, idx),
        {...field, ...values},
        ...internalState.fields.slice(idx + 1),
      ];

      setInternalState(utils.updater({fields: newValue}, true));
      onChange?.(newValue);
    }
  }

  const handleDelete = (field) => () => {
    const reset = fieldRefs.current[field.id]?.ref?.reset;
    delete fieldRefs.current[field.id];
    utils.retry(() => {
      reset?.({touched: {}});
    }, 3);

    const newValue = internalState.fields
      .filter((f) => +f.id !== +field.id);

    setInternalState(utils.updater({fields: newValue}, true));
    onChange?.(newValue);
    doErrors();
  }

  const handleRef = (field) => (ref) => {
    fieldRefs.current[field.id] = {
      ...fieldRefs.current[field.id],
      ref
    };
  }

  const handleError = (field) => (error) => {
    fieldRefs.current[field.id] = {
      ...fieldRefs.current[field.id],
      error
    };

    doErrors();
  }

  const renderField = (field, only) => {
    const hasErrorFrom = errorFrom || internalState.fields
      .filter((f) => !internalState.errorIds?.[f.id])
      .filter((mf) => !internalState.errorIds?.[field.id] && mf.from?.id === field.from?.id).length > 1 ||
      mappedFields.find((mf) => {
        return !internalState.errorIds?.[field.id] &&
          (mf.from?.id === field.from?.id && (!mf.from?.teamOnly || (!mf.from?.listOnly && +mf.teamId === internalState.team?.teamId)))
      });
    const hasErrorTo = errorTo || internalState.fields
      .filter((f) => !internalState.errorIds?.[f.id])
      .filter((mf) => !internalState.errorIds?.[field.id] && mf.to?.id === field.to?.id).length > 1 ||
      mappedFields.find((mf) => {
        return !internalState.errorIds?.[field.id] &&
          (mf.to?.id === field.to?.id && (!mf.to?.teamOnly || (!mf.to?.listOnly && +mf.teamId === internalState.team?.teamId)))
      });

    return <FieldMappingForm ref={handleRef(field)}
                             key={field.id}
                             className="FieldMappingsForm-field"
                             field={field}
                             only={only}
                             fieldData={fieldData}
                             list={list}
                             connection={connection}
                             error={error}
                             errorFrom={Boolean(hasErrorFrom)}
                             errorTo={Boolean(hasErrorTo)}
                             required={required || !only}
                             readOnly={readOnly}
                             disabled={disabled}
                             removable={true}
                             hideActions={!multiple}
                             onChange={handleChange(field)}
                             onDelete={handleDelete(field)}
                             onError={handleError(field)}/>
  }

  return <StyledFieldMappingsForm ref={innerRef} {...innerProps}>
    <List gap={8} catchFocus={false}>
      {internalState.fields?.map((field) => {
        return <ListItem key={field.id} data-key={field.id} density="densest">
          {renderField(field, internalState.fields.length === 1)}
        </ListItem>
      })}
    </List>
    {(multiple && !(readOnly || disabled)) ?
      <Button variant="text"
              disabled={Boolean(error || errorFrom || errorTo || internalState.error || (internalState.fields.find((f) => utils.isEmpty(f.from?.id) || utils.isEmpty(f.to?.id))))}
              startIcon={<Icon icon={Add} />}
              onClick={handleAddField}>
        Add field
      </Button> : null}
  </StyledFieldMappingsForm>
});

FieldMappingsForm.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  list: PropTypes.object,
  mappedFields: PropTypes.array,
  connection: PropTypes.object,
  fieldData: PropTypes.object,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorFrom: PropTypes.bool,
  errorTo: PropTypes.bool,
  success: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

FieldMappingsForm.defaultProps = {
  multiple: true
};

export default FieldMappingsForm;
