import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import MenuDrawer from 'components/organisms/Drawers/MenuDrawer/MenuDrawer';
import {useLogo, useMenu, useSwitch} from 'components/organisms/Providers/MenuProvider/MenuProvider';
import StyledSideMenuWrapper from 'components/templates/Wrappers/Menus/SideMenuWrapper/SideMenuWrapper.styles';
import Wrapper from 'components/templates/Wrappers/Basic/Wrapper/Wrapper';
import useMediaQuery from '@mui/material/useMediaQuery';
import utils from 'helpers/utils';
import SnackbarProvider from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import ActionbarProvider from 'components/organisms/Providers/ActionbarProvider/ActionbarProvider';

export const SideMenuWrapperContext = React.createContext(null)

export function useSideMenuWrapper () {
  return React.useContext(SideMenuWrapperContext);
}

const SideMenuWrapper = React.forwardRef((props, ref) => {
  const {
    offset,
    ...innerProps
  } = useComponentProps(props, 'SideMenuWrapper', {
    static: ['open', 'animate'],
    children: ['drawer', 'content']
  });

  const innerRef = useRef(null);
  const drawerRef = useRef(null);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(!smDown);

  const sideMenuWrapper = useMemo(() => ({
    refs: {
      ref: innerRef,
      drawerRef
    }
  }), []);

  useImperativeHandle(ref, () => sideMenuWrapper);

  const logoAction = useLogo();
  const switchAction = useSwitch();
  const menuItems = useMenu();

  let width = innerProps.theme.layout(72 + (offset ?? 0));
  let margin = !smDown ? width : '0rem';

  const wrapper = useMemo(() => {
    return {
      bounds: {left: margin},
      scrollBounds: {left: margin}
    };
  }, [margin]);

  const handleToggle = (e, open) => {
    setOpen(open);
  }

  useLayoutEffect(() => {
    setOpen(!smDown);
  }, [smDown]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    open
  });

  return <SideMenuWrapperContext.Provider value={sideMenuWrapper}>
    <StyledSideMenuWrapper ref={sideMenuWrapper.refs.ref} {...innerProps} $width={margin}>
      <MenuDrawer ref={sideMenuWrapper.refs.drawerRef}
                  open={open}
                  width={width}
                  variant={smDown ? 'temporary' : 'permanent'}
                  onToggle={handleToggle}
                  logoAction={logoAction}
                  ActionLogoProps={{
                    TooltipProps: {variant: 'light'}
                  }}
                  switchAction={switchAction}
                  ActionAvatarProps={{
                    color: 'primary',
                    TooltipProps: {variant: 'light'}
                  }}
                  menuItems={menuItems}
                  ActionIconButtonProps={{
                    variant: 'menu',
                    color: 'primary',
                    TooltipProps: {variant: 'light'}
                  }}
                  className="SideMenuWrapper-drawer"
                  color="secondary" />
      <Wrapper className="SideMenuWrapper-content"
               wrapper={wrapper}>
        <SnackbarProvider SnackbarProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}>
          <ActionbarProvider>
            {innerProps.children}
          </ActionbarProvider>
        </SnackbarProvider>
      </Wrapper>
    </StyledSideMenuWrapper>
  </SideMenuWrapperContext.Provider>
});

SideMenuWrapper.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

SideMenuWrapper.defaultProps = {
  children: 'SideMenu Wrapper text'
};

export default SideMenuWrapper;
