import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledDealflowEntitiesTableBar
  from 'components/organisms/Bars/DealflowEntitiesTableBar/DealflowEntitiesTableBar.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import KanbanColumnsDialog from 'components/organisms/Dialogs/KanbanColumnsDialog/KanbanColumnsDialog';
import {useSplitPath} from 'helpers/hooks/links';

const DealflowEntitiesTableBar = React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'DealflowEntitiesTableBar');

  const tableProvider = useTable();
  const [, pathPostfix] = useSplitPath('dealflow');

  const chatAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'dealflow.entity.chat' }),
  }), []);

  const filterAction = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'dealflow.entity.filters' }),
  }), []);

  const renderKanbanColumnsDialog = () => {
    if (!pathPostfix) {
      return <KanbanColumnsDialog open={tableProvider.state?.showColumns}/>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledDealflowEntitiesTableBar ref={ref} {...innerProps}
                                         hideColumn={Boolean(pathPostfix) && pathPostfix !== 'table'}
                                         chatAction={chatAction}
                                         filterAction={filterAction}>
    {tableProvider.state?.showColumns ? renderKanbanColumnsDialog() : null}
  </StyledDealflowEntitiesTableBar>
});

DealflowEntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

DealflowEntitiesTableBar.defaultProps = {
};

export default DealflowEntitiesTableBar;
