import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCollectionSourcesSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionSourcesSectionPanelContent/CollectionSourcesSectionPanelContent.styles';
import {useSourceList} from 'services/source/source.hooks';
import CollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import constants from 'helpers/constants';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import utils from 'helpers/utils';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import FilterSelectionDialog from 'components/organisms/Dialogs/FilterSelectionDialog/FilterSelectionDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import searchUtils from 'helpers/search';
import FilterList from '@mui/icons-material/FilterList';
import Badge from 'components/atoms/Badges/Badge/Badge';
import ScoreLinearProgress from 'components/organisms/Progress/ScoreLinearProgress/ScoreLinearProgress';
import Box from 'components/atoms/Layout/Box/Box';
import Typography from 'components/atoms/Text/Typography/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useCollectionSuggestionList} from 'services/collection/entity/entity.hooks';

const info = 'Add sources to receive company suggestions that are relevant to your collection';

const CollectionSourcesSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    section,
    collection,
    isLoading,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'CollectionSourcesSectionPanelContent');

  const innerRef = useRef(null);
  const updateRef = useRef(null);
  const refreshRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const snackbar = useSnackbar();
  const authorize = useAuthorize();
  const dialogControl = useDialogControl();

  const profileProvider = useProfile();
  const onboarding = profileProvider?.state?.settings?.onboarding;

  const handleShowCard = (card) => {
    return card.name.startsWith('source');
  }

  const sourceIds = (collection?.projectSources ?? [])
    .filter((s) => ![constants.sources.types.suggestions, constants.sources.types.database].includes(s.type))
    .map((s) => s.sourceId);

  const sourceList = useSourceList({
    page: 0,
    pageSize: collection?.projectSources?.length ?? 0,
    filter: [
      {
        id: 'sourceId', value: sourceIds
      },
    ]
  }, {
    enabled: sourceIds.length > 0
  });

  const suggestionList = useCollectionSuggestionList({
    collectionId: collection?.collectionId,
    feedUpdatedAt: collection?.feedUpdatedAt,
    pageSize: 0
  }, {
    enabled: collection?.collectionId > 0
  });
  const suggestionCount = suggestionList.meta?.resultsCount ?? 0;

  const fieldData = useMemo(() => ({
    sources: sourceList.data
  }), [sourceList.data]);

  const setSettingsEvent = useEffectEvent(profileProvider.setSettings);
  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const updateEvent = useEffectEvent(profileProvider.updaters?.updateData);
  const updateQueryEvent = useEffectEvent(profileProvider.updaters?.updateQuery);

  const updatingFeed = profileProvider.state?.settings?.updatingFeed === +collection?.collectionId;
  useEffect(() => {
    const updatedTime = collection?.feedUpdatedAt ? (new Date(collection?.feedUpdatedAt)).getTime() : 0;
    const canUpdate = (onCanUpdateEvent ? onCanUpdateEvent?.(collection) : true) && authorize({
      attribute: 'collection.update', meta: {collection}
    });

    if (Boolean(collection?.shouldUpdateFeed) && canUpdate && collection?.collectionId && !updatingFeed &&
        (+refreshRef.current !== +collection?.collectionId || updateRef.current !== updatedTime)) {
      refreshRef.current = +collection?.collectionId;
      updateRef.current = updatedTime;
      setSettingsEvent?.({updatingFeed: +collection?.collectionId});

      const updatingSnackbar = snackbar.show('Updating source data...', null, {
        color: 'info',
        autoHideDuration: null
      });

      updateQueryEvent?.()
        .then(() => {
          refreshRef.current = null;
        })
        .catch(() => {
          snackbar.show('Updating source data failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          setSettingsEvent?.({updatingFeed: null});
          snackbar.hide(updatingSnackbar);
        });
    }
  }, [collection, snackbar, updateQueryEvent, updatingFeed, authorize, onCanUpdateEvent, setSettingsEvent]);

  const filterAction = useMemo(() => {
    const canUpdate = onCanUpdateEvent?.(collection) ?? false;
    const filterCount = searchUtils.queryFilterCount(collection.projectSourcesFilter);

    return {
      label: 'Source filters',
      icon: FilterList,
      auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) : null,
      onClick: (e) => {
        const handleSubmit = (filter) => {
          filter = searchUtils.queryFilterCount(filter) > 0 ? filter : null;
          return updateEvent?.(collection, {projectSourcesFilter: filter})
            .then(() => {
              dialogControl.hide();
            });
        };

        dialogControl.show(<FilterSelectionDialog title='Source filters'
                                                  info="Only the companies matching these filters below will be added to your suggestions queue"
                                                  filter={collection.projectSourcesFilter}
                                                  onSubmit={handleSubmit}/>, true);

        e.preventDefault();
      },
      ActionButtonProps: {
        IconProps: {
          size: 'smaller'
        }
      },
      ButtonProps: {
        color: filterCount > 0 ? 'primary' : 'secondary',
        variant: filterCount > 0 ? 'contained' : 'outlined',
        flatBackground: true,
        plainBorder: true,
        radius: 'round'
      },
      badge: <Badge badgeContent={filterCount || '0'}
                    color={filterCount > 0 ? 'contrast' : 'light'} />
    }
  }, [collection, dialogControl, onCanUpdateEvent, updateEvent]);

  const renderFooter = () => {
    return <Box className="CollectionSourcesSectionPanelContent-footer">
      <Box className="CollectionSourcesSectionPanelContent-footer-header">
        <Typography variant="subtitle2">Suggestions queue status</Typography>
        <Typography isLoading={isLoading} variant="subtitle2">
          Currently {utils.formatNumber(suggestionCount)} of {utils.formatNumber(constants.collection.suggestion.max)} suggestions
        </Typography>
      </Box>
      <ScoreLinearProgress showEmpty={true}
                           showPercentage={suggestionCount >= 100}
                           score={(suggestionCount ?? 0) / constants.collection.suggestion.max * 100}
                           color="success"
                           isLoading={isLoading}/>
    </Box>
  }

  const renderButtons = () => {
    return <React.Fragment>
      <ActionButton showInactive={true}
                    action={filterAction} />
    </React.Fragment>
  }

  const handleCardClose = () => {
    if (onboarding) {
      setSettingsEvent?.({onboarding: false});
    }
  }

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return <StyledCollectionSourcesSectionPanelContent ref={innerRef} {...innerProps}
                                                     footer={renderFooter()}>
    <CollectionSectionPanelHeader title={section.title} info={!smDown ? info : null} buttons={renderButtons()}/>
    <CollectionProfile columns={smDown ? 1 : (lgDown ? 2 : 3)}
                       variant="grid"
                       fieldData={fieldData}
                       forceEditNew={onboarding}
                       onShowCard={handleShowCard}
                       onCloseCard={handleCardClose}
                       onCanUpdate={onCanUpdate} />
  </StyledCollectionSourcesSectionPanelContent>

});

CollectionSourcesSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  section: PropTypes.object,
  collection: PropTypes.object,
  isLoading: PropTypes.bool,
  onCanUpdate: PropTypes.func
};

CollectionSourcesSectionPanelContent.defaultProps = {};

export default CollectionSourcesSectionPanelContent;
