import utils from 'helpers/utils';
import logger from 'helpers/logger';
import ServiceStore from 'stores/service.store';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export class ExportService extends ServiceStore {
  constructor (app, callbacks) {
    logger.trace(`Starting module at: services/${path}`);

    super(path, app, {
      key: 'exportId',
      refreshEnabled: false,
      process: (data, meta) => {
        const processExport = (data) => {
          const raw = utils.camelcaseEx(data);

          raw.exportId = raw.exportId ?? raw.taskId;

          return raw;
        }

        if (meta?.context?.$store?.dataType !== constants.dataTypes.other) {
          if (utils.isArray(data)) {
            data = data.map(processExport);
          } else {
            data = processExport(data);
          }
        } else {
          return data;
        }

        return data;
      }
    }, callbacks);
  }
}
