import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import StyledChooseCollectionCategoriesWizardContent
  from 'components/organisms/WizardContent/ChooseCollectionCategoriesWizardContent/ChooseCollectionCategoriesWizardContent.styles';
import {useCollectionGenerateTagGroups} from 'services/collection/collection.hooks';

const ChooseCollectionCategoriesWizardContent = React.forwardRef((props, ref) => {
  const {
    collection,
    wizard,
    step,
    ...innerProps
  } = useComponentProps(props, 'ChooseCollectionCategoriesWizardContent');

  const setDataEvent = useEffectEvent(wizard.setData);

  const generateTagGroups = useCollectionGenerateTagGroups();

  const fields = useMemo(() => ([{
    name: 'tagGroups',
    label: 'Categories',
    placeholder: 'Type a category name',
    description: 'Add 1 or more descriptive category names, short sentences give the best results',
    type: constants.formFieldTypes.autocomplete,
    validation: constants.formFieldValidationTypes.list,
    conversion: constants.formFieldConversionTypes.label,
    required: true,
    validate: (value, testContext) => {
      const existing = utils.toArray(value).find((v) => {
        return collection.tagGroups?.find((tg) => tg.name.toLowerCase() === (v?.label ?? v)?.toLowerCase());
      });

      if (existing) {
        return testContext.createError({message: `Category '${existing.label}' already exists`});
      } else {
        return true;
      }
    },
    FormFieldProps: {
      multiple: true,
      createOption: true,
      openDirect: false,
      openOnFocus: false,
      hiddenLabel: false,
      TagProps: {
        variant: 'filled',
        color: 'primary'
      },
      ChipProps: {
        variant: 'filled',
        color: 'primary'
      },
      ChipListProps: {
        variant: 'standard'
      }
    }
  }]), [collection]);

  const handleSubmit = (values) => {
    const inputTagGroups = utils.toArray(values['tagGroups']).map((n) => ({
      name: n
    }));

    return generateTagGroups.mutation.mutateAsync({
      collectionId: collection.collectionId,
      tagGroups: inputTagGroups
    })
      .then((res) => {
        const outputTagGroups = res.response.data.data?.map((tg) => ({
          ...tg,
          color: tg.color ?? innerProps.theme.pickAColor('tagGroup')
        }));

        setDataEvent?.((current) => ({
          ...current,
          [wizard.dataKey]: {
            ...current[wizard.dataKey],
            inputTagGroups,
            outputTagGroups
          }
        }));
      })
      .catch(() => {
        throw new Error('Retrieving category information failed');
      });
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledChooseCollectionCategoriesWizardContent ref={ref} {...innerProps}
                                                        wizard={wizard}
                                                        step={step}
                                                        fields={fields}
                                                        onSubmit={handleSubmit}/>
});

ChooseCollectionCategoriesWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  collection: PropTypes.object
};

ChooseCollectionCategoriesWizardContent.defaultProps = {};

export default ChooseCollectionCategoriesWizardContent;
