import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledDateField from 'components/atoms/Inputs/DateField/DateField.styles';
import {withMemo} from 'helpers/wrapper';
import utils from 'helpers/utils';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Clear from '@mui/icons-material/Clear';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import InputAdornment from 'components/atoms/Inputs/InputAdornment/InputAdornment';

const DateField = withMemo(React.forwardRef((props, ref) => {
  const {
    onClear,
    ...innerProps
  } = useComponentProps(props, 'DateField');

  const position = Boolean(innerProps.InputProps?.endAdornment) ? 'end' : (
    Boolean(innerProps.InputProps?.startAdornment) ? 'start' : 'end'
  );

  const onClearEvent = useEffectEvent(onClear);
  const clearAdornment = useMemo(() => {
    if (Boolean(onClearEvent) && !utils.isEmpty(innerProps.value) && !innerProps.disabled) {
      return <InputAdornment key="clear" className="DateField-clear" position={position}>
        <ActionIconButton action={{
          icon: <Icon icon={Clear}/>,
          onClick: (e) => {
            onClearEvent?.(e);
          }
        }}/>
      </InputAdornment>
    } else {
      return null;
    }
  }, [innerProps.value, innerProps.disabled, onClearEvent, position]);

  const renderAdornment = () => {
    const pickerAdornment = innerProps.InputProps?.[`${position}Adornment`];

    return <React.Fragment>
      {clearAdornment}
      {pickerAdornment}
    </React.Fragment>
  }

  return <StyledDateField ref={ref} {...innerProps}
                          InputProps={{
                            ...innerProps.InputProps,
                            [`${position}Adornment`]: renderAdornment()
                          }}/>
}));

DateField.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  clearable: PropTypes.bool,
  onClear: PropTypes.func
};

DateField.defaultProps = {
};

export default DateField;
