import React, {useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useEffectItem, useOptimistic, useUpdatedRef} from 'helpers/hooks/utils';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import {H6, P, Span} from 'components/atoms/Text/Typography/Typography';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import StyledProfileCard from 'components/organisms/Cards/ProfileCard/ProfileCard.styles';
import ProfileCardContent from 'components/organisms/Cards/ProfileCardContent/ProfileCardContent';
import utils from 'helpers/utils';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Edit from '@mui/icons-material/Edit';
import ProfileCardDialog from 'components/organisms/Dialogs/ProfileCardDialog/ProfileCardDialog';
import Button from 'components/atoms/Buttons/Button/Button';
import Close from '@mui/icons-material/Close';
import CardActions from 'components/atoms/Cards/CardActions/CardActions';
import Component from 'components/organisms/Utils/Component/Component';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Save from '@mui/icons-material/Save';
import dom from 'helpers/dom';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import constants from 'helpers/constants';

const ProfileCard = React.forwardRef((props, ref) => {
  const {
    profile,
    card,
    title,
    contents,
    fieldData,
    isLoading,
    showDialog,
    editInline,
    emptyText,
    toggle,
    actionButtons,
    onEdit,
    onClose,
    onPatch,
    onSubmit,
    onShowCard,
    onValidating,
    onBeforeSubmit,
    onAfterSubmit,
    Card,
    DialogComponent,
    DialogProps,
    ActionIconButtonProps,
    ActionButtonProps,
    CardProps,
    CardContentProps,
    ...innerProps
  } = useComponentProps({...props, ...utils.filterObject(props.card, [
      'title', 'contents', 'Card', 'DialogComponent', 'actionButtons', 'emptyText', 'toggle',
      'onPatch', 'onSubmit', 'onValidating', 'onBeforeSubmit', 'onAfterSubmit'
    ], false), ...props.card?.ProfileCardProps}, 'ProfileCard', {
    static: ['emptyText', 'Card'],
    variable: ['anchor'],
    children: ['header', 'content', 'actions']
  });

  const innerRef = useRef(null);
  const dialogRef = useRef(null);
  const cardRef = useRef(false);
  const submittingRef = useRef(false);
  const waitingForSubmitRef = useRef(false);
  const contentRefs = useRef({dialog: {}, card: {}});
  const [internalState, setInternalState] = useState({
    card: {
      isSubmitting: false,
      submitError: null,
      submitSuccess: null
    },
    dialog: {
      isSubmitting: false,
      submitError: null,
      submitSuccess: null
    },
    showDialog: false,
    dirty: false
  });

  const onEditEvent = useEffectEvent(onEdit);
  const onCloseEvent = useEffectEvent(onClose);
  const onValidatingEvent = useEffectEvent(onValidating);

  const dialogControl = useDialogControl();

  const [data, onSubmitOptimistic] = useOptimistic(card.data ?? profile.data, onSubmit, true, true);

  const doResetForm = useCallback((state) => {
    const isDialog = state.showDialog;
    Object.keys(contentRefs.current[isDialog ? 'dialog' : 'card']).forEach((k) => {
      const c = contentRefs.current[isDialog ? 'dialog' : 'card'][k];
      return c.ref?.resetForm?.();
    });
    onValidatingEvent?.(false, false);
  }, [onValidatingEvent]);

  const doSetSubmitting = useCallback((state, card, value, success = null, error = null) => {
    const isDialog = state.showDialog;
    const editing = Boolean(error) || value;

    submittingRef.current = false;
    Object.keys(contentRefs.current[isDialog ? 'dialog' : 'card']).forEach((k) => {
      const c = contentRefs.current[isDialog ? 'dialog' : 'card'][k];
      return c.ref?.setSubmitting?.(value);
    });

    if (!editing) {
      onCloseEvent?.(null, 'saveButtonClick', card);
    }

    setInternalState((current) => {
      return utils.updater({
        ...current,
        isSubmitting: value,
        showDialog: !editing ? false : current.showDialog,
        [isDialog ? 'dialog' : 'card']: {
          ...current[isDialog ? 'dialog' : 'card'],
          isEditing: !editing ? false : current[isDialog ? 'dialog' : 'card'].isEditing,
          isSubmitting: value,
          submitError: error,
          submitSuccess: success
        }
      })(current);
    });
  }, [onCloseEvent]);

  const doToggle = useCallback((state, card, edit = null, dialog = null) => {
    const isDialog = (dialog ?? !card.editInline);
    const editing = card.isEditing || Boolean(state.dialog.isEditing || state.card.isEditing);

    edit = (utils.isDefined(edit) ? edit : !editing);

    if (!edit) {
      doResetForm(state);
      onCloseEvent?.(null, 'toggleButtonClick', card);
    } else {
      onEditEvent?.(card);
    }

    setInternalState((current) => {
      return utils.updater({
        ...current,
        showDialog: isDialog && edit,
        [isDialog ? 'dialog' : 'card']: {
          ...current[isDialog ? 'dialog' : 'card'],
          isEditing: edit,
          submitSuccess: null,
          submitError: null
        },
        [isDialog ? 'card' : 'dialog']: {
          ...current[isDialog ? 'card' : 'dialog'],
          isEditing: false,
          submitSuccess: null,
          submitError: null
        }
      })(current);
    });

  }, [doResetForm, onEditEvent, onCloseEvent]);

  const doSubmit = useCallback((state, card, test = false) => {
    const isDialog = state.showDialog;

    return Promise.all(Object.keys(contentRefs.current[isDialog ? 'dialog' : 'card']).map((k) => {
      const c = contentRefs.current[isDialog ? 'dialog' : 'card'][k];
      return c.ref?.validate?.(true);
    })).then((results) => {
      if (!results.some((r) => r && Object.keys(r).length > 0)) {
        let submitting = false;
        Object.keys(contentRefs.current[isDialog ? 'dialog' : 'card']).forEach((k) => {
          const c = contentRefs.current[isDialog ? 'dialog' : 'card'][k];
          if (c.validation?.isDirty) {
            submitting = true;
          }
        });

        if (!test) {
          if (submitting) {
            submittingRef.current = true;
            setInternalState((current) => ({
              ...current,
              [isDialog ? 'dialog' : 'card']: {
                ...current[isDialog ? 'dialog' : 'card'],
                isSubmitting: true,
                validation: null,
                submitError: null,
                submitSuccess: null
              }
            }));

            Object.keys(contentRefs.current[isDialog ? 'dialog' : 'card']).forEach((k) => {
              const c = contentRefs.current[isDialog ? 'dialog' : 'card'][k];
              if (c.validation?.isDirty) {
                c.submitting = true;
                c.submission = null;
                c.ref?.submit?.();
              }
            });
          } else {
            if (isDialog) {
              doToggle(state, card, null, true);
            }
          }
        }

        return { submitting };
      } else {
        return { submitting: false, error: results };
      }
    });
  }, [doToggle]);

  const profileCard = useMemo(() => {
    const cardState = {
      ...card,
      data,
      editable: utils.isFunction(card.editable) ? Boolean(card.editable({profile, card, data})) : card.editable,
      editInline: Boolean(card.editInline ?? editInline),
      isSubmitting: Boolean(card.isSubmitting || profile.state.isSubmitting),
      isEditing: Boolean(
        (utils.isFunction(card.isEditing) ? card.isEditing({profile, card, data}) : card.isEditing) ||
        profile.state.isEditing
      ) ? true : null,
    };

    const state = {
      ...internalState,
      ...utils.cleanObject({
        isEditing: cardState.isEditing,
        isSubmitting: cardState.isSubmitting
      })
    };

    const isDialog = Boolean(state.showDialog);
    const isEditing = Boolean(state.dialog?.isEditing || state.card?.isEditing);
    const hidden = utils.isFunction(card.hidden) ?
      Boolean(card.hidden({card, state: {...state, isDialog, isEditing}, data, fieldData})) : card.hidden;
    state.visible = !hidden && !(state.hideCard);

    return {
      refs: {
        ref: innerRef,
        dialogRef,
        cardRef
      },
      state,
      card: cardState,
      toggle: (dialog) => {
        doToggle(state, cardState, null, dialog);
      },
      edit: (dialog) => {
        doToggle(state, cardState, true, dialog);
      },
      close: (dialog) => {
        doToggle(state, cardState, false, dialog);
      },
      resetForm: () => {
        doResetForm(state);
      },
      setSubmitting: (value, success, error) => {
        doSetSubmitting(state, cardState, value, success, error);
      },
      submit: (test) => {
        return doSubmit(state, cardState, test);
      },
      dirty: (isDirty) => {
        setInternalState((current) => ({...current, dirty: isDirty}));
      },
      visible: () => {
        const isDialog = state.showDialog;
        return state.visible && Object.keys(contentRefs.current[isDialog ? 'dialog' : 'card']).some((k) => {
          const c = contentRefs.current[isDialog ? 'dialog' : 'card'][k];
          return c.ref?.state?.visible;
        });
      }
    };
  }, [internalState, profile, card, data, editInline, fieldData,
    doSetSubmitting, doSubmit, doResetForm, doToggle]);

  const profileCardRef = useUpdatedRef(profileCard);

  useImperativeHandle(ref, () => profileCard);

  useLayoutEffect(() => {
    setInternalState(utils.updater({
      showDialog: false,
      dialog: {
        isSubmitting: false,
        submitError: null,
        submitSuccess: null
      },
      card: {
        isSubmitting: false,
        submitError: null,
        submitSuccess: null
      }
    }, true));
  }, [profile.state.isEditing]);

  useLayoutEffect(() => {
    if (!profile.state.isEditing && !isLoading) {
      const isEditing = Boolean(profileCardRef.current.state.dialog.isEditing || profileCardRef.current.state.card.isEditing);
      if (Boolean(profileCard.state.isEditing) !== isEditing) {
        if (profileCard.state.isEditing) {
          profileCardRef.current.edit();
        } else {
          profileCardRef.current.close();
        }
      }
    }
  }, [profileCardRef, profileCard.state.isEditing, profile.state.isEditing, isLoading]);

  useLayoutEffect(() => {
    if (profileCard.state.dialog.isWaitingForSubmit && waitingForSubmitRef.current) {
      return utils.observeTimeout(() => {
        waitingForSubmitRef.current = false;
        profileCard.submit();
        setInternalState((current) => ({
          ...current,
          dialog: {
            ...current.dialog,
            isWaitingForSubmit: false
          }
        }));
      }, constants.debounce.shortest);
    }
  }, [profileCard]);

  const onBeforeSubmitEvent = useEffectEvent(onBeforeSubmit);
  const onAfterSubmitEvent = useEffectEvent(onAfterSubmit);
  const handleSubmit = useCallback((isDialog, id) => (values, actions, fields, optimistic, passThrough = false, onSuccess = null, onError = null) => {
    const processSubmit = () => {
      if (!passThrough && !submittingRef.current) {
        doSubmit(profileCardRef.current.state, profileCardRef.current.card);
      } else {
        const contents = isDialog ? contentRefs.current.dialog : contentRefs.current.card;

        contents[id] = {
          ...contents[id],
          submission: {
            optimistic,
            values,
            fields,
            actions
          }
        }

        const allSubmitted = !Object.keys(contents).some((k) => {
          const c = contents[k];
          return c.submitting && !c.submission;
        });

        if (allSubmitted) {
          const optimistic = Object.keys(contents).reduce((o, k) => {
            const c = contents[k];
            return {...o, ...c.submission?.optimistic};
          }, {});

          const values = Object.keys(contents).reduce((o, k) => {
            const c = contents[k];
            return {...o, ...c.submission?.values};
          }, {});

          const fields = Object.keys(contents).reduce((o, k) => {
            const c = contents[k];
            return [...o, ...(c.submission?.fields ?? [])];
          }, []);

          // reset submission
          Object.keys(contents).forEach((k) => {
            const c = contents[k];
            c.submission = null;
            c.submitting = null;
          }, {});

          setInternalState((current) => ({
            ...current,
            [isDialog ? 'dialog' : 'card']: {
              ...current[isDialog ? 'dialog' : 'card'],
              submitSuccess: null,
              submitError: null
            }
          }));

          return onSubmitOptimistic?.(
            optimistic,
            values,
            fields,
            {
              setSubmitting: profileCardRef.current.setSubmitting,
              resetForm: profileCardRef.current.resetForm
            },
            (msg) => {
              profileCardRef.current.setSubmitting(false, msg ?? 'Saved', null);
              onAfterSubmitEvent?.(msg ?? 'Saved', null);
              onSuccess?.(msg ?? 'Saved');
            },
            (err) => {
              profileCardRef.current.setSubmitting(false, null, err ?? 'Saving failed');
              onAfterSubmitEvent?.(null, err ?? 'Saving failed');
              onError?.(err ?? 'Saving failed');
            }
          );
        }
      }
    }

    if (!passThrough && onBeforeSubmitEvent) {
      onBeforeSubmitEvent(processSubmit);
    } else {
      processSubmit();
    }
  }, [onSubmitOptimistic, onBeforeSubmitEvent, onAfterSubmitEvent, doSubmit, profileCardRef]);

  const onPatchEvent = useEffectEvent(onPatch);
  const handlePatch = useCallback((field, value, onSuccess, onError) => {
    onPatchEvent?.(field, value, onSuccess, onError);
  }, [onPatchEvent]);

  const handleValidating = useCallback((isDialog, id) => (validating, isDirty, hasError) => {
    const contents = isDialog ? contentRefs.current.dialog : contentRefs.current.card;

    contents[id] = {
      ...contents[id],
      validation: {
        isDirty: isDirty,
        hasError: hasError
      }
    }

    const hasSomeErrors = Object.keys(contents).some((k) => {
      const c = contents[k];
      return c.validation?.hasError;
    });
    const isSomeDirty = Object.keys(contents).some((k) => {
      const c = contents[k];
      return c.validation?.isDirty
    });

    onValidatingEvent?.(isSomeDirty, hasSomeErrors);
    if (hasSomeErrors) {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          [isDialog ? 'dialog' : 'card']: {
            ...current[isDialog ? 'dialog' : 'card'],
            isDirty: isSomeDirty,
            validation: 'Please check if all fields have the correct values',
            submitError: null,
            submitSuccess: null
          }
        })(current);
      });
    } else {
      setInternalState((current) => {
        return utils.updater({
          ...current,
          [isDialog ? 'dialog' : 'card']: {
            ...current[isDialog ? 'dialog' : 'card'],
            isDirty: isSomeDirty,
            validation: null
          }
        })(current);
      });
    }
  }, [onValidatingEvent]);

  const getContents = useCallback((isDialog) => {
    return contents?.sort((a, b) => a.position - b.position)
      .map((content, idx) => {
        const id = `${card.id}_${isDialog ? 'dialog' : 'inline'}_${idx}`;

        content = {
          ...content,
          id: id,
          position: idx,
          onPatch: handlePatch,
          onSubmit: handleSubmit(isDialog, id),
          onValidating: handleValidating(isDialog, id)
        };

        return content;
      })
  }, [card.id, contents, handlePatch, handleSubmit, handleValidating]);

  const contentsMemo = useMemo(() => {
    return getContents(false);
  }, [getContents]);

  const dialogContentsMemo = useMemo(() => {
    return getContents(true);
  }, [getContents]);

  const isEditing = profile.state.isEditing || (profileCard.state.showDialog ? profileCard.state.dialog.isEditing : profileCard.state.card.isEditing);
  useEffect(() => {
    setInternalState((current) => {
      return utils.updater({
        ...current,
        hideCard: false
      })(current);
    });
  }, [profile.data, isEditing]);

  useEffect(() => {
    if (profileCard.state.card.isEditing && !profile.state.isEditing) {
      const focus = () => {
        if (!dom.isPartOfParent(document.activeElement, innerRef.current)) {
          return dom.focusElement(innerRef.current);
        }
      }

      utils.retry(focus, 3);
    }
  }, [profileCard.state.card.isEditing, profile.state.isEditing]);

  const titleMemo = useEffectItem(title);
  const renderTitle = useCallback((isDialog, isTooltip) => {
    if (titleMemo) {
      if (utils.isFunction(titleMemo)) {
        return titleMemo({data, isDialog, isTooltip});
      } else {
        return titleMemo;
      }
    }
  }, [titleMemo, data]);

  const readOnly = !profileCard.card.editable || contentsMemo?.length === 0 ||
    !Object.keys(contentRefs.current['card']).some((k) => {
      const c = contentRefs.current['card'][k];
      return c.ref?.state?.readOnly !== true;
    });

  const action = useMemo(() => {
    if (!profileCard.card.isEditing && !readOnly && !isLoading) {
      const editing = Boolean(profileCard.state.card.isEditing);
      if (!editing || !profileCard.state.card.isSubmitting) {
        return {
          label: 'Edit',
          tooltip: `Edit ${renderTitle(false, true)}`,
          icon: editing ? Close : Edit,
          onClick: () => {
            doToggle(profileCard.state, profileCard.card);
          }
        };
      }
    }
  }, [renderTitle, profileCard.state, profileCard.card, doToggle, readOnly, isLoading]);

  const renderContents = (contents, isDialog) => {
    const handleRef = (id) => (ref) => {
      contentRefs.current[isDialog ? 'dialog' : 'card'][id] = {
        ...contentRefs.current[isDialog ? 'dialog' : 'card'][id],
        ref
      };
    }

    if (contents) {
      return contents
        .map((content) => {
          const ContentComponent = content.ContentComponent ?? ProfileCardContent;
          return <ContentComponent key={content.id}
                                   ref={handleRef(content.id)}
                                   className={`ProfileCard-content ProfileCard-content-${card.name}`}
                                   profile={profile}
                                   card={profileCard}
                                   data={data}
                                   content={content}
                                   fieldData={fieldData}
                                   isDialog={isDialog}
                                   isLoading={isLoading}
                                   {...CardContentProps}/>
        });
    }
  }

  const renderDialog = () => {
    if (profileCard.state.showDialog) {
      const handleSubmit = () => {
        profileCard.submit();
        // waitingForSubmitRef.current = true;
        // setInternalState((current) => ({
        //   ...current,
        //   dialog: {
        //     ...current.dialog,
        //     isWaitingForSubmit: true
        //   }
        // }));
      }

      const handleCancel = (e, reason) => {
        onClose?.(e, reason, profileCard.card);
        profileCard.resetForm();
        setInternalState((current) => ({
          ...current,
          showDialog: false,
          dialog: {
            ...current.dialog,
            isSubmitting: false,
            isEditing: false
          }
        }));
      }

      const handleClose = (e, reason) => {
        if (!profileCard.state.dialog.isSubmitting &&
            !profileCard.state.dialog.isDirty &&
            !profileCard.state.dialog.submitError &&
            !profileCard.state.dialog.validation) {
          handleCancel(e, reason);
        } else if (['escapeKeyDown', 'closeButtonClick', 'cancelButtonClick'].includes(reason)) {
          const handleConfirm = () => {
            handleCancel(e, reason);
          }

          if (profileCard.card.confirmClose) {
            dialogControl.show(<ConfirmDialog question="Are you sure you want to exit this dailog?"
                                              explanation="The dialog will close and your data will be lost"
                                              onConfirm={handleConfirm}/>, true);
          } else {
            handleCancel(e, reason);
          }
        }
      }

      const contents = dialogContentsMemo;
      const renderedContents = renderContents(contents, true);

      let dialogContent = renderedContents;
      if (Card) {
        dialogContent = <Component Original={Card}
                                   profile={profile}
                                   card={profileCard}
                                   data={data}
                                   contents={contents}
                                   isDialog={true}
                                   fieldData={fieldData}
                                   renderedContent={renderedContents}/>
      }

      const info = (profileCard.state.dialog.submitError ?? profileCard.state.dialog.validation) ?
          <Span color="error">{profileCard.state.dialog.submitError ?? profileCard.state.dialog.validation}</Span> :
          (profileCard.state.dialog.submitSuccess ?
              <Span color="success">{profileCard.state.dialog.submitSuccess}</Span> : null);

      const buttons = <React.Fragment>
        <Button children={'Cancel'}
                variant="text"
                startIcon={<Icon icon={Close}/>}
                onClick={handleCancel}
                {...profileCard.card.CancelButtonProps}/>
        <Button disabled={profileCard.state.dialog.isSubmitting}
                type="submit"
                variant="contained"
                children="Save"
                startIcon={<Icon icon={Save}/>}
                onClick={handleSubmit}
                {...profileCard.card.SaveButtonProps}/>
      </React.Fragment>

      return <DialogComponent ref={dialogRef}
                              open={true}
                              title={<H6>{renderTitle(true, false)}</H6>}
                              content={dialogContent}
                              info={info}
                              buttons={buttons}
                              onClose={handleClose}
                              {...card.DialogProps}
                              {...DialogProps} />
    }
  }

  const renderCard = () => {
    const handleHide = (hidden) => {
      setInternalState(utils.updater({
        hideCard: hidden
      }, true));
    }

    const renderedContents = renderContents(contentsMemo, false);

    const renderedHeader = <React.Fragment>
      {(title || action) ? <CardHeader className="ProfileCard-header"
                                       title={<H6 className="ProfileCard-title">{renderTitle(false, false)}</H6>}
                                       action={action ? <ActionIconButton className="ProfileCard-action"
                                                                          tabIndex={-1}
                                                                          action={action} density="dense"
                                                                          size="smaller" variant="outlined"
                                                                          IconProps={{size: 'tiny'}}
                                                                          {...ActionIconButtonProps} /> : null}/> : null}
    </React.Fragment>

    const renderedContent = <React.Fragment>
      {(emptyText) ? <Box className="ProfileCard-empty">
        <P>{emptyText}</P>
      </Box> : null}
      {renderedContents}
      {actionButtons ? <CardActions className="ProfileCard-actions">
        {actionButtons.map((action) => {
          return <ActionButton action={action} size="small" {...ActionButtonProps}/>
        })}
      </CardActions> : null}
    </React.Fragment>;

    if (Card) {
      return <Component ref={cardRef}
                        Original={Card}
                        profile={profile}
                        card={profileCard}
                        data={data}
                        contents={contents}
                        action={action}
                        onHide={handleHide}
                        isDialog={false}
                        isLoading={isLoading}
                        fieldData={fieldData}
                        renderedHeader={renderedHeader}
                        renderedContent={renderedContent}
                        {...CardProps}/>
    } else {
      return <React.Fragment>
        {renderedHeader}
        {renderedContent}
      </React.Fragment>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  if (profileCard.state.visible) {
    if (toggle && profileCard.state.showDialog) {
      return renderDialog();
    } else {
      return <StyledProfileCard ref={innerRef} {...innerProps} {...(!Card ? CardProps : null)}>
        {renderCard()}
        {renderDialog()}
      </StyledProfileCard>
    }
  }
});

ProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  profile: PropTypes.object,
  card: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.any,
  action: PropTypes.any,
  contents: PropTypes.array,
  fieldData: PropTypes.object,
  emptyText: PropTypes.any,
  actionButtons: PropTypes.array,
  Card: PropTypes.any,
  Dialog: PropTypes.any,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  toggle: PropTypes.bool,
  showDialog: PropTypes.bool,
  editInline: PropTypes.bool,
  onPatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onValidating: PropTypes.func,
  onBeforeSubmit: PropTypes.func,
  onAfterSubmit: PropTypes.func,
  DialogComponent: PropTypes.any,
  DialogProps: PropTypes.object,
  ActionIconButtonProps: PropTypes.object,
  ActionButtonProps: PropTypes.object,
  CardProps: PropTypes.object,
  CardContentProps: PropTypes.object
};

ProfileCard.defaultProps = {
  elevation: 1,
  radius: 'round',
  DialogComponent: ProfileCardDialog
};

export default ProfileCard;
