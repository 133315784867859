import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Page from 'components/pages/Base/Page/Page';

const StyledDealflowSettingPage = styled(Page)`
  background-color: unset;
  
  .DealflowSettingPage {
    &-content {
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};

      .ClientProfile {
        background-color: unset;
        padding: 0;
        max-width: 100%;

        .ProfileCard {
          &-title {
            ${props => props.theme.font2Css('h6')};
          }

          &-content {
            padding: 0;
          }
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .DealflowSettingPage {
      &-content {
        padding: 0 ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledDealflowSettingPage.propTypes = {
  theme: PropTypes.object
};

StyledDealflowSettingPage.defaultProps = {}

export default StyledDealflowSettingPage;
