import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Cards from 'components/atoms/Cards/Cards/Cards';

const StyledProfile = styled(Cards)`
  min-height: 100%;
  width: 100%;

  background-color: ${props => props.theme.property(`palette.${props.$color}.states.hover`)};
  &.Profile {
    padding: ${props => props.theme.spacing(3)};
    
    .CardItem {
      &:not(:has(.ProfileCard-emptyText, .ProfileCard-content)) {
        display: none;
      }
    }
  }

  &.Profile {
    ${props => props.theme.breakpoints.down('lg')} {
      padding: ${props => props.theme.spacing(2)};
    }
  }
  
  ${ComponentStyles}
`;

StyledProfile.propTypes = {
  theme: PropTypes.object
};

StyledProfile.defaultProps = {
};

export default StyledProfile;
