import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  useCollectionEntityAssign,
  useCollectionEntityList,
  useCollectionEntityUnAssign,
  useCollectionEntityUpdate
} from 'services/collection/entity/entity.hooks';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import {
  processEntity,
  useEntitySettings
} from 'services/entity/entity.utils';
import {useSourceList} from 'services/source/source.hooks';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {useCollectionCustomFieldCache} from 'services/collection/customField/customField.hooks';
import {useCollectionGet} from 'services/collection/collection.hooks';
import {useMergeFieldData, useMergeFilterGroupDefinitions} from 'helpers/hooks/utils';

export function processCollectionEntity (entity) {
  return processEntity(entity);
}

export function useCollectionEntityCallbacks (collection) {
  const [collectionSourcesListSearch, setCollectionSourcesListSearch] = useState({});
  const collectionSourcesListEnabled = Boolean(collectionSourcesListSearch.callback);
  const collectionSourcesList = useSourceList({
    search: collectionSourcesListSearch.search,
    filter: utils.addFilter(((utils.toArray(collectionSourcesListSearch.ids, true).length > 0) ? [{
      id: 'sourceIds',
      value: utils.toArray(collectionSourcesListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => utils.toInt(v))
    }] : [])
      .concat([{id: 'usedIn', value: collection?.collectionId}]),
      collectionSourcesListSearch.filter),
    pageSize: 100
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: collectionSourcesListEnabled && collection?.collectionId > 0
  });

  useEffect(() => {
    if (!collectionSourcesListEnabled || collectionSourcesList.data || collectionSourcesList.status.hasError) {
      collectionSourcesListSearch.callback?.((collectionSourcesList.data ?? []).map((s) => ({
        source: s,
        label: s.name,
        value: +s.sourceId
      })));
      if (collectionSourcesListEnabled) {
        setCollectionSourcesListSearch({});
      }
    }
  }, [collectionSourcesListSearch, collectionSourcesListEnabled, collectionSourcesList.data, collectionSourcesList.status.hasError]);

  return useMemo(() => {
    return {
      collectionSources: ({search, ids, filter, callback}) => {
        setCollectionSourcesListSearch({search, ids, filter, callback});
      }
    }
  }, []);
}

export function useCollectionEntityPatch () {
  const collectionEntityUpdate = useCollectionEntityUpdate();

  return useCallback((collection, entity, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(entity, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return collectionEntityUpdate.mutation.mutateAsync({
        collectionId: collection.collectionId,
        entityId: entity.entityId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [collectionEntityUpdate.mutation]);
}

export function useCollectionEntityToggle (mutationOptions = {}) {
  const entityAssign = useCollectionEntityAssign(mutationOptions);
  const entityUnAssign = useCollectionEntityUnAssign(mutationOptions);

  return useCallback((collectionId, entityId, remove) => {
    return (!remove ? entityAssign.mutation.mutateAsync : entityUnAssign.mutation.mutateAsync)(
      {collectionId: collectionId, entityId}
    );
  }, [entityAssign.mutation, entityUnAssign.mutation]);
}

export function useCollectionEntitySelected (collectionId) {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selected = useCollectionEntityList({
    collectionId,
    search: state.listState?.search,
    filter: state.listState?.filter,
    sort: state.listState?.sort,
    page: 0,
    pageSize: state.count,
    minimal: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && collectionId > 0
  });

  useEffect(() => {
    if (!selectedEnabled || selected.data || selected.status.hasError) {
      if (!selected.status.hasError) {
        state.success?.(selected.data);
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selected.data, selected.status.hasError]);

  return useCallback((listState, count, success, error) => {
    setState({listState, count, success, error});
  }, []);
}

export function useCollectionEntityGroups (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    if (tagGroups && customFields) {
      const groups = [];

      Object.keys(constants.collection.entity.groupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.collection.entity.groupDefinition[k])) {
          if (!groups.find((g) => g.name === k)) {
            const groupDef = constants.groupDefinition.lookup('collection.entity', view, k, false, utils.mergeObjects);

            groups.push({
              ...groupDef,
              name: k,
              position: groupDef.position ??
                groups.reduce((p, g) => Math.max(p, g.position), 0),
              fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
                ...f,
                entity: 'entity',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute:`collection.entity.field.${f.name}.read`}),
                  update: utils.createAuth({attribute:`collection.entity.field.${f.name}.update`})
                },
                required: f.required ?? false
              })) ?? []
            });
          }
        }
      });

      let group = groups.find((g) => g.name === 'collectionTags');
      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      group.fields = group.fields.filter((f) => hasPoints || !f.has?.includes('points'));

      const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position), 0);
      tagGroups?.forEach((tg) => {
        group.fields.push({
          name: `tagGroup${tg.groupId}`,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          variant: 'tagGroups',
          entity: 'entity',
          position: lastPos + +(tg.pos || 1),
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.field.collectionTags.read', meta: {tagGroup: tg}}),
            update: utils.createAuth({attribute: 'collection.entity.field.collectionTags.update', meta: {tagGroup: tg}}),
          }
        });
      });

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })
        .forEach((cf) => {
          const groupDef = constants.groupDefinition.lookup('collection.entity', view, cf.name, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {}).find((k) => k === utils.camelcase(cf.name))];

          if (utils.isDefined(groupDef?.name ?? cf.groupName)) {
            let group = groups.find((g) => g.name === (groupDef?.name ?? utils.camelcase(cf.groupName)));
            if (!group || group.locked) {
              const gd = constants.groupDefinition.lookup('collection.entity', view, groupDef?.name ?? cf.groupName, false, utils.mergeObjects);
              const lastPos = groups.reduce((p, g) => Math.max(p, g.position ?? 0), constants.numbers.position.maxPosition);

              group = {
                ...gd,
                ...groupDef,
                locked: false,
                name: gd?.name ?? utils.camelcase(cf.groupName),
                title: gd?.title ?? utils.upperFirst(cf.groupName),
                position: +(cf.groupPosition ?? gd?.position ?? (lastPos + 1)),
                fields: []
              };
              groups.push(group);
            } else {
              group.position = +(cf.groupPosition ?? group.position);
            }

            const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position ?? 0), constants.numbers.position.maxPosition);
            const fieldIndex = group.fields.findIndex((f) => f.name === utils.camelcase(cf.name));
            const isLink = cf.dataType === constants.fieldDataTypes.url;
            const field = {
              ...fieldDef,
              customField: cf,
              name: cf.name,
              entity: 'entity',
              link: isLink ? constants.data.lookup('links', cf.name) : null,
              context: isLink ? {
                ...fieldDef?.context,
                link: constants.data.lookup('links', cf.name)?.value
              } : fieldDef?.context,
              variant: fieldDef?.variant ?? (isLink ? 'links' : 'detail'),
              options: fieldDef?.options ?? (isLink ? 'linkSuggestions' : null),
              auth: {
                read: utils.createAuth({attribute:`collection.entity.field.${utils.camelcase(cf.name)}.read`}),
                update: utils.createAuth({attribute:`collection.entity.field.${utils.camelcase(cf.name)}.update`})
              },
              position: +(cf.position ?? fieldDef?.position ?? (lastPos + 1)),
              required: fieldDef?.required ?? false
            };

            if (fieldIndex !== -1) {
              group.fields[fieldIndex] = {
                ...group.fields[fieldIndex],
                ...field
              }
            } else {
              group.fields.push(field);
            }
          }
        });

      return groups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view]);
}

export function useCollectionEntityColumns (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;
  const activeQuestions = collection?.activeQuestions;

  return useMemo(() => {
    if (customFields) {
      let columns = [];

      const presetDefs = constants.presetDefinition.lookup('collection.entity', view);

      Object.keys(constants.collection.entity.columnDefinition).forEach((k) => {
        if (!utils.isFunction(constants.collection.entity.columnDefinition[k]) && (k !== 'collectionTags')) {
          const groupDef = constants.groupDefinition.lookup('collection.entity', view, k, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
            .find((k1) => k1 === k)];
          const columnDef = constants.columnDefinition.lookup('collection.entity', view, k, utils.mergeObjects);

          const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
          const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
          const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (+p + 1) : p, groupDef?.name ? 0 : null);
          const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
            ((firstPosInGroup ?? 0) === 0 && lastPos > 0) ? (lastPos + constants.numbers.position.groupGap) :
              ((firstPosInGroup > 0) ? (firstPosInGroup - 1) : constants.numbers.position.maxPosition)
          )) * constants.numbers.position.groupGap;
          const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) : (lastPos + 1));
          if (!columns.find((c) => c.name === k)) {
            columns.push({
              ...fieldDef,
              ...columnDef,
              name: k,
              id: columnDef.sortingKey ?? k,
              entity: 'entity',
              hidden: columnDef?.hidden,
              header: columnDef?.header ?? fieldDef?.label,
              group: columnDef.group ?? groupDef,
              presets: columnDef.presets ?? presetDefs,
              position: columnDef.position ? columnDef.position : fieldPos,
              fieldPosition: fieldDef?.position ?? lastPosInGroup,
              auth: {
                read: utils.createAuth({attribute:`collection.entity.column.${k}.read`}),
                update: utils.createAuth({attribute:`collection.entity.column.${k}.update`})
              }
            });
          }
        }
      });

      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })
        .forEach((cf) => {
          let groupDef = constants.groupDefinition.lookup('collection.entity', view, cf.name, true, utils.mergeObjects) ??
            constants.groupDefinition.lookup('collection.entity', view, cf.groupName, false, utils.mergeObjects);
          // custom fields always have groups
          groupDef = {
            ...groupDef,
            name: groupDef?.name ?? utils.camelcase(cf.groupName),
            title: groupDef?.title ?? utils.upperFirst(cf.groupName)
          };

          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
            .find((k) => k === utils.camelcase(cf.name))];
          const columnDef = constants.columnDefinition.lookup('collection.entity', view, cf.name, utils.mergeObjects);

          if (!['markdown', 'textarea'].includes(cf.renderer)) {
            const lastPos = columns.reduce((p, c) => Math.max(p, c.position), constants.numbers.position.maxPosition);
            const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
              (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
            const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
              (+p + 1) : p, groupDef?.name ? constants.numbers.position.maxPosition : null);
            const groupPos = +(cf.groupPosition ?? groupDef?.tablePosition ?? groupDef?.position ?? (
              ((firstPosInGroup ?? 0) === 0 && lastPos > 0) ? (lastPos + constants.numbers.position.groupGap) :
                ((firstPosInGroup > 0) ? (firstPosInGroup - 1) : constants.numbers.position.maxPosition)
            )) * constants.numbers.position.groupGap;
            let columnIndex = columns.findIndex((c) => c.name === utils.camelcase(cf.name));
            if (columnIndex === -1) {
              columns.push({
                ...fieldDef,
                ...columnDef,
                entity: 'entity'
              });
              columnIndex = columns.length - 1;
            }
            const columnPos = (
              utils.isDefined(cf.position ?? fieldDef?.position ?? lastPosInGroup) ?
                (groupPos + (cf.position ?? fieldDef?.position ?? (lastPosInGroup + 1))) :
                (columns[columnIndex].position ?? (lastPos + 1))
            )

            const isLink = cf.dataType === constants.fieldDataTypes.url;
            columns[columnIndex] = {
              ...columns[columnIndex],
              id: columns[columnIndex].sortingKey ?? cf.name,
              name: cf.name,
              group: columns[columnIndex].group ?? groupDef,
              presets: columns[columnIndex].presets ?? presetDefs,
              header: columns[columnIndex].header ?? cf.label,
              customField: cf,
              link: isLink ? constants.data.lookup('links', cf.name) : null,
              context: isLink ? {
                ...fieldDef?.context,
                link: constants.data.lookup('links', cf.name)?.value
              } : fieldDef?.context,
              options: columns[columnIndex].options ?? (isLink ? 'linkSuggestions' : null),
              auth: {
                read: utils.createAuth({attribute: `collection.entity.column.${utils.camelcase(cf.name)}.read`}),
                update: utils.createAuth({attribute: `collection.entity.column.${utils.camelcase(cf.name)}.update`})
              },
              position: columnDef?.position ? columnDef.position : columnPos,
              fieldPosition: columns[columnIndex].fieldPosition ?? fieldDef?.position ?? lastPosInGroup,
              visible: columns[columnIndex].visible ?? false,
              sortable: columnDef?.sortable ?? true,
              required: columnDef?.required ?? false
            };
          }
        });

      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      columns = columns.filter((c) => hasPoints || !c.has?.includes('points'));

      tagGroups?.forEach((tg) => {
        const columnDef = constants.columnDefinition.lookup('collection.entity', view, 'collectionTags', utils.mergeObjects);
        const groupDef = constants.groupDefinition.lookup('collection.entity', view, 'collectionTags', false, utils.mergeObjects);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? constants.numbers.position.maxPosition) * constants.numbers.position.groupGap;
        const lastPos = Object.keys(groupDef.fields || {}).reduce((p, k) => Math.max(p, groupDef.fields[k].tablePosition ?? groupDef.fields[k].position), 0);

        columns.push({
          ...columnDef,
          id: `dropdown_${tg.groupId}`, // sortingKey
          name: `tagGroup${tg.groupId}`,
          entity: 'entity',
          group: groupDef ? {
            name: 'collectionTags',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `dropdown_${tg.groupId}`,
          header: tg.name,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: lastPos + groupPos + (tg.pos || 1),
          fieldPosition: tg.pos,
          required: false,
          visible: columnDef?.visible ?? false,
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.column.collectionTags.read', meta: {tagGroup: tg}}),
            update: utils.createAuth({attribute: 'collection.entity.column.collectionTags.update', meta: {tagGroup: tg}}),
          },
          FormFieldProps: {
            ChipListProps: {
              variant: 'compact'
            }
          }
        });
      });

      activeQuestions?.forEach((question) => {
        const groupDef = constants.groupDefinition.lookup('collection.entity', view, 'questionnaireAnswers', false, utils.mergeObjects);

        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? constants.numbers.position.maxPosition) * constants.numbers.position.groupGap;
        columns.push({
          id: `answer_${question.questionId}`, // sortingKey
          name: `answer${question.questionId}`,
          entity: 'entity',
          type: constants.formFieldTypes.text,
          group: groupDef ? {
            name: 'questionnaireAnswers',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `answer_${question.questionId}`,
          header: question.question,
          questionId: question.questionId,
          question: question,
          position: (groupPos + question.pos),
          fieldPosition: question.pos,
          visible: false,
          required: false,
          readOnly: true,
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.column.questionnaireAnswer.read', meta: {question}}),
            update: utils.createAuth({attribute: 'collection.entity.column.questionnaireAnswer.update', meta: {question}}),
          }
        });
      });

      return columns;
    } else {
      return null;
    }
  }, [tagGroups, customFields, activeQuestions, view]);
}

export function useCollectionEntityFilterGroups (view, collection, customFields, clientId) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    const filterGroups = [];
    if (tagGroups && customFields) {
      Object.keys(constants.collection.entity.filterGroupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.collection.entity.filterGroupDefinition[k])) {
          const groupDef = constants.filterGroupDefinition.lookup('collection.entity', view, k, false, utils.mergeObjects);
          const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
          filterGroups.push({
            ...groupDef,
            name: k,
            position: (groupDef.position ?? (lastPos + 1)),
            filters: Object.keys(groupDef.filters ?? {}).map((k, idx) => {
              const f = groupDef.filters[k];
              return {
                ...f,
                id: f.filterKey ?? k,
                name: k,
                entity: 'entity',
                position: f.position ?? (idx + 1),
                auth: {
                  read: utils.createAuth({attribute: `collection.entity.filter.${k}.read`}),
                  update: utils.createAuth({attribute: `collection.entity.filter.${k}.update`}),
                }
              }
            })
          });
        }
      });

      let groupDef = constants.filterGroupDefinition.lookup('collection.entity', view, 'customFields', true, utils.mergeObjects);
      let group = filterGroups.find((g) => g.name === groupDef.name);
      let filter = group.filters.find((f) => f.name === 'customFields');
      group.filters = group.filters.filter((f) => f.name !== 'customFields');

      let filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      [...customFields].sort((a, b) => {
        const pos = +(a.pos ?? -1) - +(b.pos ?? -1);;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })?.forEach((cf) => {
        const typeToFilterProps = () => {
          if (cf.renderer === constants.fieldRenderers.monetary) {
            return {
              monetary: true,
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'moneyRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.numeric) {
            return {
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'mediumRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else {
            return {};
          }
        }

        if (+cf.clientId > 0 && +cf.clientId === +clientId) {
          if ([constants.fieldRenderers.text,
            constants.fieldRenderers.numeric,
            constants.fieldRenderers.monetary].includes(cf.renderer)) {
            filterPos += 1;
            group.filters.push({
              ...filter,
              id: cf.name, // filterKey
              name: cf.name,
              label: cf.label,
              filterKey: cf.name,
              customField: cf,
              description: '',
              position: +(cf.position ?? filterPos),
              auth: {
                read: utils.createAuth({attribute: 'collection.entity.filter.customFields.read'}),
                update: utils.createAuth({attribute: 'collection.entity.filter.customFields.update'}),
              },
              ...(typeToFilterProps())
            });
          }
        }
      });

      // lookup group for name
      groupDef = constants.filterGroupDefinition.lookup('collection.entity', view, 'collectionTags', true, utils.mergeObjects);
      group = filterGroups.find((g) => g.name === groupDef.name);
      filter = group.filters.find((f) => f.name === 'collectionTags');
      group.filters = group.filters.filter((f) => f.name !== 'collectionTags');

      filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      tagGroups?.forEach((tg) => {
        group.filters.push({
          ...filter,
          id: `group_${tg.groupId}`, // filterKey
          name: `tagGroup${tg.groupId}`,
          label: tg.name,
          validation: `${constants.formFieldValidationTypes.list}(${constants.formFieldValidationTypes.int})`,
          filterKey: `group_${tg.groupId}`,
          type: constants.formFieldTypes.list,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: (filterPos + tg.pos),
          auth: {
            read: utils.createAuth({attribute: 'collection.entity.filter.collectionTags.read'}),
            update: utils.createAuth({attribute: 'collection.entity.filter.collectionTags.update'}),
          },
          FormFieldProps: {
            multiple: true
          }
        });
      });

      return filterGroups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view, clientId]);
}

export function useCollectionEntitySections (view, collection) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.collection.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('collection.entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `collection.entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `collection.entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            auth: {
              read: utils.createAuth({attribute: `collection.entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `collection.entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useCollectionEntityGraphs (view) {
  return useMemo(() => {
    const graphs = [];
    Object.keys(constants.collection.entity.graphDefinition).forEach((k) => {
      if (!utils.isFunction(constants.collection.entity.graphDefinition[k])) {
        const graphDef = constants.graphDefinition.lookup('collection.entity', view, k, utils.mergeObjects);
        const lastPos = graphs.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        graphs.push({
          ...graphDef,
          name: k,
          position: (graphDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `collection.entity.graph.${graphDef.name}.read`}),
            update: utils.createAuth({attribute: `collection.entity.graph.${graphDef.name}.update`})
          }
        });
      }
    });

    return graphs;
  }, [view]);
}

export function useCollectionEntitySettings (collectionId = null) {
  const client = useAuthClient();
  const authorize = useAuthorize();

  const [defaultFieldData, defaultFilterGroups] = useEntitySettings();

  const collectionEntityProfileCallbacks = useCollectionEntityCallbacks();

  const collectionCustomFields = useCollectionCustomFieldCache({clientId: client?.clientId, collectionId: collectionId, addLinks: false},
    {enabled: client?.clientId >= 0 && collectionId > 0});

  const collection = useCollectionGet({collectionId: collectionId},
    {enabled: Boolean(collectionId)});

  const collectionFieldData = useMemo(() => ({
    customFields: ((collectionCustomFields.data?.data ?? collectionCustomFields.data) ?? []),
    tagGroups: collection?.data?.tagGroups,
    callbacks: collectionEntityProfileCallbacks
  }), [collectionEntityProfileCallbacks, collectionCustomFields.data, collection?.data?.tagGroups]);

  const fieldData = useMergeFieldData(defaultFieldData, collectionFieldData);

  const collectionEntityFilterGroups = useCollectionEntityFilterGroups(null, collection?.data, fieldData.customFields, client?.clientId);
  const filterGroupsMerged = useMergeFilterGroupDefinitions(defaultFilterGroups, collectionEntityFilterGroups);

  const filterGroups = useMemo(() => {
    if (filterGroupsMerged?.length > 0) {
      return filterGroupsMerged
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, filterGroupsMerged]);

  return [fieldData, filterGroups, collection?.data, fieldData.customFields, client];
}

export function useCollectionEntityScore (collection, entity) {
  const isUniverse = utils.isDefined(collection?.universeClientId);
  const prefix = isUniverse ? 'client' : 'collection';
  const positivePotential = collection?.scoringPotential?.positivePotentialAdvanced ?? 0;

  const totalPoints = useMemo(() => {
    if (utils.isDefined(entity?.[prefix + 'AnalysisPointsAdvanced'])) {
      return +entity?.[prefix + 'AnalysisPointsAdvanced'];
    } else {
      return 0;
    }
  }, [prefix, entity]);

  const score = (positivePotential > 0 && totalPoints >= 0) ? (totalPoints / positivePotential) * 100 : 0;

  return score ?? 0;
}

export function useCollectionEntityScoreBreakDown (collection, entity) {
  const isUniverse = utils.isDefined(collection?.universeClientId);
  const positivePotential = collection?.scoringPotential?.positivePotentialAdvanced ?? 0;
  const tagGroups = collection?.tagGroups;
  const questions = isUniverse ? entity?.clientQuestions : entity?.collectionQuestions;

  const breakdown = useMemo(() => {
    if (questions && tagGroups) {
      return tagGroups.map((tg) => {
        let tgQs = questions.filter((q) => q.questionType === constants.collection.questionTypes.advanced && +q.tagGroup === +tg.groupId);

        return {
          questions: tgQs,
          tagGroup: tg
        }
      });
    } else {
      return [];
    }
  }, [questions, tagGroups]);

  return [breakdown, positivePotential];
}
