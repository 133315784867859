import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Banner from 'assets/svgs/WelcomeBanner.svg';
import Box from 'components/atoms/Layout/Box/Box';

const StyledWelcomeBanner = styled(Box)`
  width: ${props => props.theme.layout(440)};
  
  .WelcomeBanner {
    &-brand {
      display: flex;
      flex-direction: row;
      align-items: center;
      top: ${props => props.theme.layout(62)};
      left: ${props => props.theme.spacing(7)};
      position: fixed;
      
      &-logo {
        width: ${props => props.theme.layout(16)};
        height: ${props => props.theme.layout(30)};
      }

      &-logo-text {
        margin-top: ${props => props.theme.layout(1)};
        margin-left: ${props => props.theme.layout('1.5sp')};
        font-size: ${props => props.theme.fontSize(24)} !important;
        ${props => props.theme.font2Css('brand')};
      }
    }

    &-background {
      position: fixed;
      width: ${props => props.theme.layout(440)};
      height: 100%;
      top: 0;
      left: 0;

      padding: 0 ${props => props.theme.spacing(7)};

      background-color: #EFF5FA;
      background-image: url('${Banner}');
      background-repeat: no-repeat;
      background-size: 110% auto;
      background-position-x: 0%;
      background-position-y: max(54vh, 100%);
      box-shadow: inset -28px 0 32px -28px rgba(0, 0, 0, 0.2);
    }
    
    &-slogan {
      margin-top: ${props => props.theme.layout(170)};
      font-size: ${props => props.theme.fontSize(48)} !important;
      ${props => props.theme.font2Css('slogan')};

      span {
        color: ${props => props.theme.property('palette.primary.main')};
      }
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: unset;
    min-width: unset;
    
    .WelcomeBanner {
      &-brand {
        position: absolute;
      }
      &-background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        box-shadow: unset;
        background-color: unset;
        overflow: visible;
        background-position-y: max(64vh, 100%);

        > * {
          :nth-child(3) {
            display: none;
          }
        }
      }

      &-slogan {
        display: none;
      }
    }
  }
  
  ${ComponentStyles}
`;

export default StyledWelcomeBanner;
