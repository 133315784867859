import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Dialog from 'components/organisms/Dialogs/Dialog/Dialog';
import FormStyles from 'styles/mixins/Form.styles';

const StyledEntitiesChatDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: clamp(0px, ${props => props.theme.layout(800)}, 86vw);
    max-width: clamp(0px, ${props => props.theme.layout(800)}, 86vw);
    height: clamp(0px, ${props => props.theme.layout(800)}, 86vh);
    max-height: clamp(0px, ${props => props.theme.layout(800)}, 86vh);
  }
  
  .EntitiesChatDialog {
    &-content {
      display: flex;
      flex-direction: column;
      padding: 0 ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)} ${props => props.theme.spacing(3)};
    }
    
    &-conversation {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      gap: ${props => props.theme.layout('1sp')};
      padding: ${props => props.theme.spacing(3)} 0;

      .P {
        margin: 0;
      }
    }
    
    &-message {
      position: relative;
      padding: ${props => props.theme.spacing(2)};
      border-radius: ${props => props.theme.radius(2)};
      border: 1px solid ${props => props.theme.property('palette.divider')};
      background-color: ${props => props.theme.property(`palette.primary.states.hover`)};
      
      .Span {
        &.title {
          display: inline-block;
          font-weight: 500;
          padding-bottom: ${props => props.theme.spacing(1)};
        }
      }
      
      img {
        max-width: 100%;
      }
      
      > .Span:has(.EntitiesChatDialog-message-chip), > .EntitiesChatDialog-message-chip {
        position: absolute;
        top: ${props => props.theme.spacing(2)};
        right: ${props => props.theme.spacing(2)};
      }
    }
    
    &-loader {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${props => props.theme.layout('0.5sp')};
      margin: ${props => props.theme.spacing(3)} 0;
      
      .CircularProgress {
        .MuiCircularProgress-root {
          color: ${props => props.theme.property('palette.divider')};
        }
      }
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: right;
      gap: ${props => props.theme.layout('1sp')};
      padding-bottom: ${props => props.theme.spacing(0.25)};
      padding-right: ${props => props.theme.spacing(1)};
    }
    
    &-form {
      display: flex;
      align-items: center;
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(1)} 0 ${props => props.theme.spacing(1)};
      gap: ${props => props.theme.layout('2sp')};

      border-top: 1px solid ${props => props.theme.property('palette.divider')};
    }
  }
  
  ${FormStyles}
  ${ComponentStyles}
`;

StyledEntitiesChatDialog.propTypes = {
  theme: PropTypes.object
};

StyledEntitiesChatDialog.defaultProps = {}

export default StyledEntitiesChatDialog;
