import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledProfileBar from 'components/organisms/Bars/ProfileBar/ProfileBar.styles';
import ContentNavigation from 'components/molecules/Navigations/ContentNavigation/ContentNavigation';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import dom from 'helpers/dom';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';

const ProfileBar = React.forwardRef((props, ref) => {
  const {
    ContentNavigationProps,
    ...innerProps
  } = useComponentProps(props, 'ProfileBar', {
    children: ['navigation']
  });

  const snackbar = useSnackbar();
  const profileProvider = useProfile();
  const profile = profileProvider.refs.current?.profile;

  const innerRef = useRef(null);
  const [actions, setActions] = useState(null);

  useImperativeHandle(ref, () => innerRef.current);

  useEffect(() => {
    if (profileProvider.state.isEditing) {
      const getActions = () => {
        if (profile?.refs?.ref?.current?.refs?.ref?.current) {
          let cards = profile.refs.ref.current.refs.ref.current.querySelectorAll('.CardItem');
          cards = Array.from(cards)
            .map((el) => ({
              id: el.dataset['key'],
              title: el.dataset['title'],
              visible: profile.refs.cardRefs.current[el.dataset['key']]?.ref?.visible()
            })).filter((c) => c.visible);

          if (cards.length > 0) {
            setActions((current) => {
              const ids = cards.map((card) => card.id);
              if (!utils.compare(current?.ids, ids)) {
                return {
                  ids,
                  actions: cards
                    .map((card, idx) => {
                      return {
                        label: card.title,
                        onClick: () => {
                          const scroll = () => {
                            const toolbarEl = innerRef.current?.refs?.ref?.current;
                            const profileEl = profile?.refs?.ref?.current?.refs?.ref?.current;
                            const el = profileEl?.querySelector(`[data-key="${card.id}"]`);
                            const scrollElement = dom.getScrollElement(profileEl, false, true);

                            if (el && scrollElement) {
                              const profileStyle = dom.getComputedStyle(profileEl);
                              const cardsStyle = dom.getComputedStyle(profileEl?.querySelector?.('.Cards-cards'));
                              const padding = profileStyle?.paddingTop ? utils.toNumber(profileStyle.paddingTop) : 0;
                              const gap = cardsStyle?.rowGap ? utils.toNumber(cardsStyle.rowGap) : 0;

                              const toolbarBox = dom.getBbox(toolbarEl);
                              const elementBox = dom.getBbox(el);

                              const scrollLimits = dom.getScrollLimits(scrollElement);
                              const offset = (scrollElement === window ? scrollElement.scrollY : scrollElement.scrollTop);
                              const top = elementBox.top + offset - toolbarBox.bottom;
                              const target = Math.min(scrollLimits.Y, top <= padding ? 0 : (top - (gap / 2 * (idx === 0 ? 3 : 1))));
                              scrollElement.scrollTo({
                                top: target,
                                left: 0,
                                behavior: 'smooth'
                              });
                              utils.retry(() => {
                                if (Math.abs((scrollElement === window ? scrollElement.scrollY : scrollElement.scrollTop) - target) < 10) {
                                  dom.focusElement(el, {preventScroll: true});
                                  return true;
                                } else {
                                  return false;
                                }
                              });

                              return true;
                            } else {
                              return false;
                            }
                          };

                          utils.retry(scroll, 5);
                        }
                      }
                    })
                }
              } else {
                return current;
              }
            });
          }
        }
      }

      return utils.observeRetry(getActions, 10);
    } else {
      setActions(null);
    }
  }, [profileProvider.state.isEditing, profile]);

  useEffect(() => {
    return utils.observeTimeout(() => {
      const contentEl = innerRef.current?.refs?.ref?.current?.parentElement?.children?.[1]?.children?.[0];
      dom.scrollIntoView(contentEl, {behavior: 'instant'});
    }, constants.debounce.minimal);
  }, [profileProvider.state.isEditing]);

  useEffect(() => {
    if (profileProvider.state.isEditing && profileProvider.state.submitSuccess) {
      profileProvider.close();
    }
  }, [profileProvider]);

  useEffect(() => {
    if (profileProvider.state.isEditing && profileProvider.state.submitError) {
      snackbar.show(profileProvider.state.submitError, null,
        {color: 'error', autoHideDuration: 2000000 ?? constants.delay.error});
    }
  }, [snackbar, profileProvider.state.isEditing, profileProvider.state.submitError]);

  const renderNavigation = () => {
    if (profileProvider.state.isEditing && actions?.actions?.length > 0) {
      return <Toolbar className="ProfileBar-navigation">
        <ContentNavigation title="Quick to:"
                           actions={actions?.actions}
                           {...ContentNavigationProps}/>
      </Toolbar>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledProfileBar ref={innerRef} {...innerProps}>
    {renderNavigation()}
  </StyledProfileBar>
});

ProfileBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  navigationActions: PropTypes.array,
  ContentNavigationProps: PropTypes.object
};

ProfileBar.defaultProps = {
  title: 'ProfileBar text'
};

export default ProfileBar;
