import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';
import constants from 'helpers/constants';

const StyledActionLogo = styled(Box)`
  display: flex;
  justify-content: center;

  ${props => props.theme.color2Css('ActionLogo', null, 'default', {type: constants.colorTypes.button})}
  
  &.ActionLogo {
    ${props => props.theme.state2Selector('ActionLogo', null, ['disabled'])} {
      background-color: unset !important;
    }
  }
  
  &.ActionLogo-clickable {
    cursor: pointer;
  }
  
  ${ComponentStyles}
`;

StyledActionLogo.propTypes = {
  theme: PropTypes.object
};

StyledActionLogo.defaultProps = {
}

export default StyledActionLogo;
