import React, {useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Delete from '@mui/icons-material/Delete';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import StyledCollectionFieldProfileCard
  from 'components/organisms/Cards/CollectionFieldProfileCard/CollectionFieldProfileCard.styles';

const CollectionFieldProfileCard = React.forwardRef((props, ref) => {
  const {
    card,
    action,
    onHide,
    field,
    collection,
    fieldData,
    isDialog,
    renderedContent,
    ...innerProps
  } = useComponentProps(props, 'CollectionFieldProfileCard', {
    static: ['isAddNew', 'isReadOnly', 'isEditing', 'isDeleted', 'isDialog'],
    children: ['content']
  });

  const innerRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useImperativeHandle(ref, () => innerRef.current);

  const isAddNew = !utils.isDefined(field?.fieldId);
  const isReadOnly = !card.card.editable;
  const isNewCollection = !(collection.collectionId > 0);
  const isLoading = !utils.isDefined(field?.name) || !utils.isDefined(field?.type);

  const dialogControl = useDialogControl();

  useLayoutEffect(() => {
    const editing = card.state.isEditing ?? card.state.card.isEditing ?? card.state.dialog.isEditing;
    if (!isAddNew) {
      setIsEditing(editing);
    } else {
      if (!card.state.card.submitSuccess) { // wait for new card
        setIsEditing(editing);
      }
    }
  }, [card.state.isEditing, card.state.card.isEditing, card.state.dialog.isEditing, card.state.card.submitSuccess, isAddNew]);

  const handleDeleteClick = (e) => {
    const handleConfirm = () => {
      const patchData = {
        name: `customField-${field.fieldId}`
      };

      return utils.asPromiseCallback(card.card.onPatch)(patchData, null)
        .then(() => {
          onHide?.(true);
          setIsDeleted(true);
        });
    }

    if (!isNewCollection) {
      dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this field?"
                                        explanation="The field will be removed including data related to this field"
                                        challenge={field.label}
                                        challengeLabel="field name"
                                        onConfirm={handleConfirm}
                                        ConfirmButtonProps={{
                                          children: 'Remove field',
                                          startIcon: <Icon icon={Delete} />,
                                          color: 'error'
                                        }}/>, true);
    } else {
      handleConfirm();
    }

    e.preventDefault();
  };

  const renderActions = () => {
    if (action && !isLoading) {
      return <React.Fragment>
        {!(isEditing || isAddNew || isReadOnly) ? <ActionIconButton className="ProfileCard-action"
                                                      variant="contained"
                                                      size="smaller"
                                                      color="error"
                                                      density="dense"
                                                      tabIndex={-1}
                                                      action={{
                                                        icon: <Icon icon={Delete} size="smaller" />,
                                                        tooltip: 'Remove source'
                                                      }}
                                                      IconProps={{size: 'tiny'}}
                                                      onClick={handleDeleteClick} /> : null}
        <ActionIconButton className="ProfileCard-action"
                          tabIndex={-1}
                          action={action} density="dense"
                          size="smaller" variant="outlined"
                          IconProps={{size: 'tiny'}}/>
      </React.Fragment>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isReadOnly: isReadOnly,
    isEditing: isEditing,
    isDeleted: isDeleted
  });

  return <StyledCollectionFieldProfileCard ref={innerRef} {...innerProps}>
    {!(isAddNew || isDialog) ? <CardHeader className="ProfileCard-header"
                                           title=""
                                           action={renderActions()}/> : null}
    {renderedContent}
  </StyledCollectionFieldProfileCard>
});

CollectionFieldProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  action: PropTypes.object,
  onHide: PropTypes.func,
  field: PropTypes.object,
  collection: PropTypes.object,
  fieldData: PropTypes.object,
  isDialog: PropTypes.bool,
  renderedContent: PropTypes.any
};

CollectionFieldProfileCard.defaultProps = {
};

export default CollectionFieldProfileCard;
