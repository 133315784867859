import {useComponentProps} from 'helpers/hooks/utils';
import React from 'react';
import Box from 'components/atoms/Layout/Box/Box';
import {Logo} from 'assets/icons';
import StyledWelcomeBanner from 'components/organisms/Banners/WelcomeBanner/WelcomeBanner.styles';
import system from 'helpers/system';
import {H3, Span} from 'components/atoms/Text/Typography/Typography';

const WelcomeBanner = (props) => {
  const innerProps = useComponentProps(props, 'WelcomeBanner', {
    children: ['brand', 'background', 'slogan']
  });
  return <StyledWelcomeBanner {...innerProps}>
    <Box className={"WelcomeBanner-background"}>
      <H3 className={"WelcomeBanner-slogan"}>
        Find
        <Span> better</Span>
        <br/>companies,<br/>
        <Span>faster</Span>
      </H3>
    </Box>
    <Box className="WelcomeBanner-brand">
      <Logo className="WelcomeBanner-brand-logo"/>
      <Span className="WelcomeBanner-brand-logo-text">{system.appShortName()}</Span>
    </Box>
  </StyledWelcomeBanner>
};

export default WelcomeBanner;
