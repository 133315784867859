import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledProgressTooltip = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('1sp')};
  padding: ${props => props.theme.spacing(1.5)};

  .ProgressTooltip {
    &-item {
      display: flex;
      flex-direction: row;
      gap: ${props => props.theme.layout('2sp')};
      
      > .Box {
        display: flex;
        flex-direction: column;
        gap: ${props => props.theme.layout('0.5sp')};
        justify-content: center;
        
        &:first-child {
          min-width: ${props => props.theme.layout(60)};
          max-width: ${props => props.theme.layout(60)};
          
          .Typography:first-child {
            font-size: ${props => props.theme.fontSize(10)};
            line-height: ${props => props.theme.lineHeight('1f')};
          }
          .Typography:last-child {
            margin-top: ${props => props.theme.layout(-3)};
            font-size: ${props => props.theme.fontSize(8)};
          }
        }
        
        &:last-child {
          flex-grow: 1;
          min-width: ${props => props.theme.layout(120)};
          
          .LinearProgress {
            width: 100%;
          }
          .Typography {
            font-size: ${props => props.theme.fontSize(8)};
          } 
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledProgressTooltip.propTypes = {
  theme: PropTypes.object
};

StyledProgressTooltip.defaultProps = {
}

export default StyledProgressTooltip;
