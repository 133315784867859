import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import ReactMarkdown from 'react-markdown';
import {lighten} from 'polished';

const StyledMarkdown = styled(ReactMarkdown)`
  h1, h2, h3 {
    &:not(:first-child) {
      margin-top: ${props => props.theme.spacing(1)}; 
    }
    
    ~ ul, ~ ol {
      margin-top: ${props => props.theme.spacing(1)};
    }
  }
  
  > ul, > ol {
    margin-top: ${props => props.theme.spacing(2)};
    margin-left: ${props => props.theme.spacing(2)};

    > li {
      margin-bottom: ${props => props.theme.spacing(1)};
    }
    
    ul, ol {
      margin-top: ${props => props.theme.spacing(0.5)};
      margin-left: ${props => props.theme.spacing(1)};
      margin-bottom: ${props => props.theme.spacing(0.5)};
    }
  }
  
  p:not(:first-child) {
    padding-top: ${props => props.theme.spacing(2)};
  }
  a {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: ${props => props.theme.property('palette.primary.main')};
    
    &:focus {
      text-decoration: underline;
    }
  }
  
  table {
    font-size: 75%;
    border-spacing: 0;
    margin-top: ${props => props.theme.spacing(2)};
    border: 1px solid ${props => lighten(0.25, props.theme.property('palette.greyScale.main'))};
    
    display: inline-block;
    overflow: auto;
    max-width: 100%;
    
    thead {
      tr {
        th {
          padding: ${props => props.theme.spacing(1)};
          background-color: ${props => lighten(0.2, props.theme.property('palette.greyScale.light'))};
          text-align: left;

          border-bottom: 1px solid ${props => lighten(0.25, props.theme.property('palette.greyScale.main'))};
          
          &:not(:first-child) {
            border-left: 1px solid ${props => lighten(0.25, props.theme.property('palette.greyScale.main'))};
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: ${props => props.theme.spacing(1)};
          text-align: left;

          border-bottom: 1px solid ${props => props.theme.property('palette.divider')};

          &:not(:first-child) {
            border-left: 1px solid ${props => props.theme.property('palette.divider')};
          }
        }
        
        &:last-child {
          td {
            border-bottom: unset;
          }
        }
      }
    }
  }
  ${ComponentStyles}
`;

StyledMarkdown.propTypes = {
  theme: PropTypes.object
};

StyledMarkdown.defaultProps = {
}

export default StyledMarkdown;
