import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useColumnState, useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import constants from 'helpers/constants';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import Delete from '@mui/icons-material/Delete';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import IconButtonTableCell from 'components/molecules/TableCells/IconButtonTableCell/IconButtonTableCell';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import Table from 'components/organisms/Tables/Table/Table';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import SettingsHeader from 'components/molecules/Headers/SettingsHeader/SettingsHeader';
import utils from 'helpers/utils';
import Edit from '@mui/icons-material/Edit';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import {useClientAdd, useClientDelete, useClientList} from 'services/client/client.hooks';
import {useClientPatch} from 'services/client/client.utils';
import ClientAddDialog from 'components/organisms/Dialogs/ClientAddDialog/ClientAddDialog';
import Chip from 'components/atoms/Chips/Chip/Chip';
import {AccountCircle} from '@mui/icons-material';
import {useLinkNavigate} from 'helpers/hooks/links';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';
import StyledClientsSettingPage from 'components/pages/Setting/ClientsSettingPage/ClientsSettingPage.styles';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import Download from '@mui/icons-material/Download';
import {useExportUsageAdd} from 'services/export/export.hooks';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

const info = 'Add and manage clients';

const ClientsSettingPage = (props) => {
  const innerProps = useComponentProps(props, 'ClientsSettingPage');

  const [showClientAddDialog, setShowClientAddDialog] = useState(false);

  const profileProvider = useProfile();
  const dialogControl = useDialogControl();

  const snackbar = useSnackbar();
  const authorize = useAuthorize();
  const navigate = useLinkNavigate();

  const clientAdd = useClientAdd();
  const patchClient = useClientPatch();
  const deleteClient = useClientDelete();
  const usageClient = useExportUsageAdd();

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const options = useMemo(() => ({
    listState: {
      initial: {
        pageSize: 25,
        filter: []
      },
      options: {
        name: 'clientsSettings'
      }
    },
    listSelection: {
      options: {
        max: constants.selection.max.client
      }
    },
    columnState: {
      initial: {
        columnPinning: {
          left: !smDown ? ['name'] : []
        },
        columnVisibility: !smDown ? null : {
          plan: false,
          currency: false,
          description: false
        },
        columnSizing: {
          name: 170
        }
      },
      options: {
        name: 'clientsSettings'
      }
    }
  }), [smDown]);

  const listState = useListState(options.listState?.initial, options.listState?.options);
  const columnState = useColumnState(options?.columnState?.initial, options?.columnState?.options);

  const clientList = useClientList({
    search: listState.search,
    filter: listState.filter,
    sort: listState.sort,
    page: listState.pagination.pageIndex,
    pageSize: listState.pagination.pageSize
  }, {
    ...constants.queryOptions.infinite,
  });

  const clearEvent = useEffectEvent(columnState.clear);
  useEffect(() => {
    clearEvent?.();
  }, [clearEvent, smDown]);

  const handleChange = useCallback((row, cell) => (field, value, onSuccess, onError) => {
    onSuccess?.(); // optimistic
    cell.column.columnDef.optimistic.set(cell, value);
    if (['name', 'description'].includes(field.name)) {
      patchClient(row.original, {[field.name]: value}, {}).catch(() => {
        onError?.();
        cell.column.columnDef.optimistic.reset(cell);
      });
    } else {
      patchClient(row.original, {}, {[field.name]: value}).catch(() => {
        onError?.();
        cell.column.columnDef.optimistic.reset(cell);
      });
    }
  }, [patchClient]);

  const dirtyEvent = useEffectEvent(profileProvider.dirty);
  const handleDirty = useCallback((dirty) => {
    dirtyEvent?.(dirty);
  }, [dirtyEvent]);

  const columnsMemo = useMemo(() => {
    const canEditColumn = (client) => {
      return authorize({attribute: 'settings.clients.update'}) &&
        authorize({attribute: 'client.update', meta: {client}});
    }
    const columns = [];

    columns.push({
      accessorKey: 'name',
      id: 'name',
      header: 'Name',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'client.column.name.update', meta: {client: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          entity: 'client',
          type: constants.formFieldTypes.text,
          validation: constants.formFieldValidationTypes.text,
          required: true,
          initial: name
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<Icon icon={AccountCircle} size="large" color="warning"/>}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({cell, row, column, table}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());

        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={name}
                              icon={<Icon icon={AccountCircle} size="large" color="warning"/>}
                              action={action}/>
      }
    });

    columns.push({
      accessorKey: 'plan',
      id: 'plan',
      header: 'Plan',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'client.column.plan.update', meta: {client: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const plan = cell.column.columnDef.optimistic.get(cell, cell.row.original.props.plan);
        const planDef = constants.data.lookup('plans', plan) ?? constants.data.lookup('plans', 'custom');

        const field = {
          name: column.id,
          label: column.columnDef.header,
          entity: 'client',
          type: constants.formFieldTypes.autocomplete,
          validation: constants.formFieldValidationTypes.text,
          conversion: constants.formFieldConversionTypes.value,
          options: 'plans',
          required: true,
          initial: plan
        };

        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={planDef ? <Chip label={planDef.label}/> : ''}/>}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const plan = cell.column.columnDef.optimistic.get(cell, cell.row.original.props.plan);
        const planDef = constants.data.lookup('plans', plan) ?? constants.data.lookup('plans', 'custom');

        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={planDef ? <Chip label={planDef.label}/> : ''}
                              action={action} />
      }
    });

    columns.push({
      accessorKey: 'currency',
      id: 'currency',
      header: 'Currency',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'client.column.currency.update', meta: {client: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const currency = cell.column.columnDef.optimistic.get(cell, cell.row.original.props.currency);
        const currencyOption = constants.data.lookup('currencies', currency);
        const field = {
          name: column.id,
          label: column.columnDef.header,
          required: false,
          entity: 'client',
          initial: currency
        };
        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={currencyOption?.label} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const currency = cell.column.columnDef.optimistic.get(cell, cell.row.original.props.currency);
        const currencyOption = constants.data.lookup('currencies', currency);
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={currencyOption?.label}
                              action={action} />
      }
    });

    columns.push({
      accessorKey: 'description',
      id: 'description',
      header: 'Description',
      enableEditing: (row) => {
        return canEditColumn(row.original) &&
          authorize({attribute: 'client.column.description.update', meta: {client: row.original}});
      },
      Edit: ({row, column, cell, table}) => {
        const description = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const field = {
          name: column.id,
          label: column.columnDef.header,
          entity: 'client',
          type: constants.formFieldTypes.markdown,
          validation: constants.formFieldValidationTypes.text,
          required: false,
          initial: description
        };
        return <FieldTableCellEdit cell={cell}
                                   table={table}
                                   fields={[field]}
                                   Anchor={<TextTableCell title={description} />}
                                   onDirty={handleDirty}
                                   onChange={handleChange(row, cell)}/>
      },
      Cell: ({table, row, column, cell}) => {
        const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
          column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
        const description = cell.column.columnDef.optimistic.get(cell, cell.getValue());
        const action = {
          label: 'Edit',
          tooltip: 'Edit',
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
          icon: Edit,
          onClick: (e) => {
            table.setEditingCell(cell);
            e.preventDefault();
          }
        };

        return <TextTableCell title={description}
                              action={action} />
      }
    });

    columns.push({
      id: 'delete',
      size: 65,
      minSize: 65,
      muiTableHeadCellProps: {
        align: 'right',
      },
      muiTableBodyCellProps: {
        align: 'right',
      },
      enableSorting: false,
      Cell: ({cell, row}) => {
        const client = cell.row.original;

        const enableEditing = canEditColumn(row.original);

        const action = {
          label: 'Remove client',
          tooltip: 'Remove client',
          icon: <Icon icon={Delete} size="smaller" />,
          auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) :
            utils.createAuth({attribute: 'client.delete', meta: {client}}),
          onClick: (e) => {
            const handleConfirm = () => {
              deleteClient.mutation.mutateAsync({
                clientId: client.clientId
              }).catch(() => {
                snackbar.show('Remove client failed', null,
                  {color: 'error', autoHideDuration: constants.delay.error});
              });
            };

            dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this client?"
                                              explanation="The client will be removed including data related to this client"
                                              challenge={client.name}
                                              challengeLabel="client name"
                                              onConfirm={handleConfirm}
                                              ConfirmButtonProps={{
                                                children: 'Remove client',
                                                startIcon: <Icon icon={Delete} />,
                                                color: 'error'
                                              }} />, true);
            e.preventDefault();
          }
        };

        return <IconButtonTableCell ActionIconButtonProps={{
                                      variant: 'outlined',
                                      size: 'smaller',
                                      density: 'sparse',
                                      showInactive: true,
                                      action: action }} />
      }
    });
    return columns;
  }, [
    dialogControl, snackbar, authorize,
    handleDirty, handleChange, deleteClient.mutation
  ]);

  const addAction = useMemo(() => ({
    label: !mdDown ? 'Add client' : null,
    tooltip: mdDown ? 'Add client' : null,
    icon: <Icon icon={Add} />,
    iconPosition: 'start',
    auth: !authorize({attribute: 'settings.clients.update'}) ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'client.create'}),
    onClick: (e) => {
      setShowClientAddDialog(true);
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      color: 'success'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'success',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [authorize, mdDown]);

  const downloadAction = useMemo(() => ({
    label: !mdDown ? 'Usage' : null,
    tooltip: mdDown ? 'Usage' : null,
    icon: Download,
    auth: utils.createAuth({attribute: 'client.download.usage'}),
    iconPosition: 'start',
    onClick: () => {
      const filename = 'Client usage.xlsx';
      const downloadingSnackbar = snackbar.show('Downloading client usage...', null, {
        color: 'info',
        autoHideDuration: null
      });

      usageClient.mutation.mutateAsync({
        filename: filename
      })
        .then((res) => {
          utils.bytesDownload(res.response.data, filename, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        })
        .catch(() => {
          snackbar.show(`Downloading client usage failed`, null,
            {color: 'error', autoHideDuration: constants.delay.error});
        })
        .finally(() => {
          snackbar.hide(downloadingSnackbar);
        });
    },
    ButtonProps: !mdDown ? {
      color: 'primary'
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'primary',
      variant: 'contained',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [snackbar, usageClient.mutation, mdDown]);

  const renderClientAddDialog = () => {
    if (showClientAddDialog) {
      const handleSubmit = (client) => {
        return clientAdd.mutation.mutateAsync(utils.underscore(client))
          .then(() => {
            setShowClientAddDialog(false);
          });
      };

      const handleClose = () => {
        setShowClientAddDialog(false);
      };

      return <ClientAddDialog open={true}
                              onSubmit={handleSubmit}
                              onClose={handleClose}/>
    }
  };

  const searchField = useMemo(() => ({
    placeholder: 'Search for a client'
  }), []);

  const renderSettingsHeader = () => {
    const buttons = <React.Fragment>
      {!mdDown ? <ActionButton action={downloadAction} /> : <ActionIconButton action={downloadAction} />}
      {!mdDown ? <ActionButton action={addAction} /> : <ActionIconButton action={addAction} />}
    </React.Fragment>

    return <SettingsHeader className="ClientsSettingPage-header"
                           title={!smDown ? 'Clients' : null}
                           info={!smDown ? info : null}
                           search={listState.search}
                           searchField={searchField}
                           onSearch={listState.setSearch}
                           buttons={buttons} />
  }

  const handleCanRowClick = (client) => {
    return utils.isDefined(client.clientId) && authorize({attribute: 'settings.client.read', meta: {client}});
  }

  const handleRowClick = (e, client) => {
    navigate({
      event: e,
      to: './' + client.clientId,
      keepSearchParams: true
    });
  }

  return <StyledClientsSettingPage as={TableWrapper} {...innerProps}
                                   header={renderSettingsHeader()}
                                   dividers={true}
                                   borders={{left: !smDown ? '3sp' : '2sp', right: !smDown ? '3sp' : '2sp', bottom: !smDown ? '3sp' : '2sp'}}>
    <Table className="ClientsSettingPage-table"
           dataKey="clientId"
           enableParentScroll={true}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableSorting={false}
           enableEditing={true}
           enableColumnDragging={false}
           enableColumnActions={false}
           enableColumnOrdering={false}
           enableColumnResizing={false}
           enablePinning={true}
           listState={listState}
           columnState={columnState}
           onCanRowClick={handleCanRowClick}
           onRowClick={handleRowClick}
           onFetchMore={clientList.query.fetchNextPage}
           onRefetch={clientList.query.refetch}
           columns={columnsMemo}
           data={clientList.data}
           rowCount={clientList.meta?.resultsCount}
           state={{
             isLoading: clientList.status.isLoading,
             showProgressBars: clientList.status.isLoadingNext
           }}
           muiTableHeadCellProps={{align: 'left'}}
           muiTableBodyCellProps={{align: 'left'}} />
    {renderClientAddDialog()}
  </StyledClientsSettingPage>
};

ClientsSettingPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

ClientsSettingPage.defaultProps = {};

export default ClientsSettingPage;
