import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledBadge from 'components/atoms/Badges/Badge/Badge.styles';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {Span} from 'components/atoms/Text/Typography/Typography';
import utils from 'helpers/utils';
import {withMemo} from 'helpers/wrapper';

const Badge = withMemo(React.forwardRef((props, ref) => {
  const {
    onClick,
    clickable,
    hover,
    variant,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'Badge', {
    static: ['clickable', 'disabled', 'hover']
  });

  if (isLoading) {
    innerProps.slots = {
      badge: ({children, className}) => <Skeleton className={className}><Span>{children}</Span></Skeleton>
    }
  }

  innerProps.variant = (variant === 'inlineDot') ? 'dot' : variant;

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledBadge ref={ref} {...innerProps}>
    {innerProps.children}
  </StyledBadge>
}));

Badge.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  color: PropTypes.string,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['dot', 'standard', 'inline', 'inlineDot']), PropTypes.string]),
};

Badge.defaultProps = {
  badgeContent: 'bc',
  variant: 'standard',
  color: 'primary'
};

export default Badge;
