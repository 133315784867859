import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledProfileFoldout = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .ProfileFoldout {
    &-toolbar {
      gap: ${props => props.theme.layout('1sp')};
      padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(3)};
      border-bottom: 1px solid ${props => props.theme.property('palette.divider')};
      &-title {
        flex-grow: 1;
        overflow: hidden;
        
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  &.ProfileFoldout {
    &:has(.SectionPanel-tabs) {
      .ProfileFoldout {
        &-toolbar {
          padding-bottom: ${props => props.theme.spacing(0.5)};
          border-bottom: unset;
        }
      }
    }

    .SectionPanel {
      padding-top: 0;

      &-tabs {
        padding: 0 ${props => props.theme.spacing(3)} 0 ${props => props.theme.spacing(3)};
      }

      &-panel {
        overflow-y: auto;
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .ProfileFoldout {
      &-toolbar {
        padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2)};
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledProfileFoldout.propTypes = {
  theme: PropTypes.object
};

StyledProfileFoldout.defaultProps = {}

export default StyledProfileFoldout;
