import logger from 'helpers/logger';
import utils from 'helpers/utils';
import system from 'helpers/system';

const applyDefaultOptions = (options = {}) => {
  return {
    maxResurrectDepth: system.maxResurrectDepth(),
    ...options
  };
}

export default class BaseStore {
  constructor(options) {
    this.options = applyDefaultOptions(options);
  }

  retrieveObjectsFromCache (cache, cacheIds, invalid = false) {
    return utils.uniqueArray(cacheIds).map((cacheId) => {
      if (!cache[cacheId]) {
        if (!invalid) {
          logger.trace('Object not found in cache', this.path, cacheId, cache);
        }
        return null;
      } else {
        return cache[cacheId];
      }
    }).filter((_) => (_));
  }

  retrieveObjectsFromCacheById (cache, entity, ids, meta) {
    return Object.keys(cache)
      .filter((k) => ids.find((id) => {
        return (this.entity === entity && cache[k].id.toString() === id.toString()) ||
           Boolean(this.options.match?.(cache[k], entity, id, meta)) ||
            (cache[k].matchCallbacks ?? []).some((matchCallback) => matchCallback(cache[k], entity, id, meta));
      }))
      .map((k) => cache[k]);
  }

  retrieveObjectsFromCacheByContextId (cache, ids, ignoreParent) {
    return Object.keys(cache)
      .filter((k) => (!this.parent || ignoreParent) || ids.find((id) => {
        return utils.toArray(cache[k].context?.$store[this.parent?.listKey]).find((cId) => {
          return cId.toString() === id.toString();
        })
      }))
      .map((k) => cache[k]);
  }

  resurrectItemFromCache (parent, key, get, used = [], depth = 0) {
    const maxDepth = this.options.maxResurrectDepth;

    const resurrectObject = (source, key) => {
      const itm = source[key];
      if (itm.cacheId && itm.store) {
        const objs = this.retrieveObjectsFromCache(get(itm.store.atoms.cache), [itm.cacheId]);
        const obj = objs?.length > 0 ? objs[0]: null;

        if (obj) {
          if (obj.dummy || obj.preload) {
            if (utils.isArray(source)) {
              source.splice(key, 1);
            } else {
              delete source[key];
            }
          } else if (obj.deleted) {
            if (itm.store.options.keepDeletedData === true) {
              source[key] = obj.data ? utils.clone(obj.data, true) : {[itm.store.key]: obj.id};
              source[key].$store = {
                deleted: true,
                keep: true
              };
            } else {
              source[key] = {
                $store: {
                  deleted: true,
                  keep: false
                }
              };
            }
          } else {
            used.push(itm);
            source[key] = utils.clone(obj.data, true);
          }
        } else {
          source[key] = null;
          logger.trace('Resurrection failed', itm);

          throw new Error('Resurrection failed');
        }
      }
    };

    if (depth <= maxDepth) {
      if (depth === 0) {
        parent = {root: utils.clone(parent, true)};
        key = 'root';
      }

      if (utils.isArray(parent[key])) {
        parent[key].forEach((itm, idx) => {
          this.resurrectItemFromCache(parent[key], idx, get, used, depth + 1);
        });
        parent[key] = parent[key].filter((itm) => !utils.isObject(itm) || !itm.$store?.deleted || itm.$store?.keep);
      } else if (utils.isObject(parent[key])) {
        resurrectObject(parent, key);
        if (!utils.isObject(parent[key]) || !parent[key].$store?.deleted || parent[key].$store?.keep) {
          Object.keys(parent[key] || {}).forEach((k) => {
            this.resurrectItemFromCache(parent[key], k, get, used, depth + 1);
          });
        }
      }
    }

    const data = !key ? parent : parent[key];
    if (!utils.isObject(data) || !data.$store?.deleted || data.$store?.keep) {
      return {data, used};
    } else {
      return {data: null, used}; // removed
    }
  }

  resurrectItem (data, get) {
    try {
      return this.resurrectItemFromCache(data, null, get);
    } catch (err) {
      logger.trace('Resurrection failed', err, data, this);
      return {data: null, used: []};
    }
  }
}
