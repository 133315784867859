import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import CardHeader from 'components/atoms/Cards/CardHeader/CardHeader';
import ActionButton from 'components/molecules/Buttons/ActionButton/ActionButton';
import StyledClientCollaboratorsProfileCard
  from 'components/organisms/Cards/ClientCollaboratorsProfileCard/ClientCollaboratorsProfileCard.styles';
import Add from '@mui/icons-material/Add';
import {H6} from 'components/atoms/Text/Typography/Typography';
import {useClientUserList} from 'services/client/user/user.hooks';
import CollaboratorAddDialog from 'components/organisms/Dialogs/CollaboratorAddDialog/CollaboratorAddDialog';
import constants from 'helpers/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

const ClientCollaboratorsProfileCard = React.forwardRef((props, ref) => {
  const {
    card,
    action,
    client,
    isDialog,
    isLoading,
    renderedContent,
    ...innerProps
  } = useComponentProps(props, 'ClientPassesProfileCard', {
    static: ['isReadOnly', 'isDialog'],
    children: ['content']
  });

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const isReadOnly = !card.card.editable;

  const dialogControl = useDialogControl();

  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const userList = useClientUserList({
    clientId: client?.clientId,
    filter: [
      {id: 'userType', value: constants.user.types.proxy},
      {id: 'activePasses', value: true}
    ]
  }, {
    enabled: !isLoading && utils.isDefined(client?.clientId)
  });

  const addAction = useMemo(() => ({
    label: !smDown ? 'Add collaborator' : 'Add',
    icon: Add,
    auth: utils.createAuth({ attribute: 'collaborator.create' }),
  }), [smDown]);

  const handleAddClick = (e) => {
    const handleSubmit = (userIds) => {
      const field = {
        name: 'collaborator-create'
      };

      return utils.asPromiseCallback(card.card.onPatch)(field, userIds);
    }

    const existingUserIds = userList.data?.map((user) => user.holder.userId);

    dialogControl.show(<CollaboratorAddDialog title="Add collaborators"
                                              existingUserIds={existingUserIds}
                                              onSubmit={handleSubmit} />, true);

    e.preventDefault();
  };

  const renderActions = () => {
    if (!isReadOnly && !isLoading) {
      return <React.Fragment>
        <ActionButton className="ProfileCard-action"
                      action={addAction}
                      variant="text"
                      onClick={handleAddClick}/>
      </React.Fragment>
    }
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    isReadOnly: isReadOnly
  });

  return <StyledClientCollaboratorsProfileCard ref={innerRef} {...innerProps}>
    {!isDialog ? <CardHeader className="ProfileCard-header"
                             title={<H6 className="ProfileCard-title">{card?.card?.title}</H6>}
                             action={renderActions()}/> : null}
    {renderedContent}
  </StyledClientCollaboratorsProfileCard>
});

ClientCollaboratorsProfileCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  action: PropTypes.object,
  client: PropTypes.object,
  isDialog: PropTypes.bool,
  isLoading: PropTypes.bool,
  renderedContent: PropTypes.any
};

ClientCollaboratorsProfileCard.defaultProps = {
};

export default ClientCollaboratorsProfileCard;
