import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useImageLoaded} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Avatar from 'components/atoms/Avatars/Avatar/Avatar';
import StyledLogo from 'components/atoms/Logos/Logo/Logo.styles';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Skeleton from 'components/atoms/Skeletons/Skeleton/Skeleton';
import {withMemo} from 'helpers/wrapper';
import Component from 'components/organisms/Utils/Component/Component';

const Logo = withMemo(React.forwardRef((props, ref) => {
  const {
    logo,
    altLogo,
    fallbackIcon,
    variant,
    outlined,
    density,
    badge,
    isLoading,
    BadgeProps,
    ...innerProps
  } = useComponentProps(props, 'Logo', {
    static: ['outlined', 'clickable']
  });

  const logoLoaded = useImageLoaded(logo);
  const clickable = Boolean(innerProps.onClick);

  innerProps.as = isLoading ? Skeleton : innerProps.as;
  innerProps.variant = isLoading ? 'circular' : innerProps.variant;

  const renderChildren = () => {
    const children = <Avatar variant={variant} src={(logoLoaded === 'error' && altLogo) ? altLogo : logo}>
      {fallbackIcon ? <Icon className="fallback" icon={fallbackIcon} /> : null}
    </Avatar>;

    if (badge) {
      return <Component Original={badge}
                        className="Logo-badge"
                        size="small"
                        disabled={innerProps.disabled}
                        isLoading={isLoading}
                        {...BadgeProps}>
        {children}
      </Component>;
    } else {
      return children;
    }
  }

  const renderLogo = () => {
    return <StyledLogo ref={ref} {...innerProps}>
      {renderChildren()}
    </StyledLogo>
  }

  innerProps.className = utils.flattenClassName(innerProps.className, {
    clickable: clickable
  });

  if (isLoading) {
    return <Skeleton variant="circular">
      {renderLogo()}
    </Skeleton>
  } else {
    return renderLogo();
  }
}));

Logo.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  logo: PropTypes.string,
  altLogo: PropTypes.string,
  fallbackIcon: PropTypes.any,
  density: PropTypes.string,
  outlined: PropTypes.bool,
  badge: PropTypes.any,
  isLoading: PropTypes.bool,
  BadgeProps: PropTypes.object
};

Logo.defaultProps = {
};

export default Logo;
