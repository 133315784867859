import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFormHelperText from 'components/atoms/Helpers/FormHelperText/FormHelperText.styles';
import {withMemo} from 'helpers/wrapper';

const FormHelperText = withMemo(React.forwardRef((props, ref) => {
  const innerProps = useComponentProps(props, 'FormHelperText');

  const handleUserInput = (e) => {
    // keep helper mouse in the helper for text selection
    e.stopPropagation();

    return true;
  }

  return <StyledFormHelperText ref={ref} {...innerProps}
                               onFocus={handleUserInput}
                               onMouseDown={handleUserInput}
                               onMouseUp={handleUserInput}
                               onClick={handleUserInput}>
    {innerProps.children}
  </StyledFormHelperText>
}));

FormHelperText.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

FormHelperText.defaultProps = {
};

export default FormHelperText;
