import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import CardContent from 'components/atoms/Cards/CardContent/CardContent';
import TeamMembersTable from 'components/organisms/Tables/TeamMembersTable/TeamMembersTable';
import TeamManagerDialog from 'components/organisms/Dialogs/TeamManagerDialog/TeamManagerDialog';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Add from '@mui/icons-material/Add';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import {useAuthClientId, useAuthTeamId} from 'services/auth/auth.utils';
import {useAuthorize, useAuthTeams} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import StyledTeamsSettingsCard from 'components/organisms/Cards/TeamsSettingsCard/TeamsSettingsCard.styles';
import Delete from '@mui/icons-material/Delete';
import TeamsSettingsCardHeader from 'components/molecules/Cards/TeamsSettingsCardHeader/TeamsSettingsCardHeader';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useClientTeamDelete} from 'services/client/team/team.hooks';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import {useClientTeamPatch} from 'services/client/team/team.utils';
import utils from 'helpers/utils';
import useMediaQuery from '@mui/material/useMediaQuery';

const TeamsSettingsCard = React.forwardRef((props, ref) => {
  const {
    team,
    canUpdate,
    ...innerProps
  } = useComponentProps(props, 'TeamsSettingsCard');

  const dialogControl = useDialogControl();
  const snackbar = useSnackbar();
  const authorize = useAuthorize();

  const teams = useAuthTeams();
  const teamId = useAuthTeamId();
  const clientId = useAuthClientId();

  const patchTeam = useClientTeamPatch();
  const deleteTeam = useClientTeamDelete({enabled: clientId && team.teamId});

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const addUsersButtonAction = useMemo(() => ({
    label: !mdDown ? 'Add users' : null,
    tooltip: mdDown ? 'Add users' : null,
    icon: <Icon icon={Add} />,
    auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) :
      utils.createAuth({attribute: 'team.member.create', meta: {team}}),
    onClick: (e) => {
      dialogControl.show(<TeamManagerDialog variant="standard"
                                            team={team}/>, true);
      e.preventDefault();
    },
    ButtonProps: !mdDown ? {
      variant: 'text',
      showInactive: true
    } : null,
    ActionIconButtonProps: mdDown ? {
      color: 'primary',
      variant: 'outlined',
      size: 'smaller',
      density: 'sparse',
      showInactive: true,
      IconProps: {
        size: 'smaller'
      }
    } : null
  }), [canUpdate, dialogControl, team, mdDown]);

  const deleteTeamButtonAction = useMemo(() => {
    const currentTeam = +team.teamId === +teamId;
    const lastOpenTeam = !team?.props?.isPrivate && teams.filter((t) => !t.props.isPrivate).length === 1;

    return {
      icon: <Icon icon={Delete} size="smaller"/>,
      tooltip: currentTeam ? 'Active team can not be removed' : (
        lastOpenTeam ? 'Last open team can not be removed' : 'Remove team'
      ),
      auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) :
        utils.createAuth({attribute: 'team.delete', meta: {team}}),
      onClick: (e) => {
        const handleConfirm = () => {
          deleteTeam.mutation.mutateAsync({clientId, teamId: team.teamId})
            .catch(() => {
              snackbar.show('Removing team failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        };
        dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this team?"
                                          explanation="This will remove the team including all team related data"
                                          challenge={team.name}
                                          challengeLabel="team name"
                                          onConfirm={handleConfirm}
                                          size="medium"
                                          ConfirmButtonProps={{
                                            children: 'Remove team',
                                            startIcon: <Icon icon={Delete} />,
                                            color: 'error'
                                          }}/>, true);
        e.preventDefault();
      },
      ActionIconButtonProps: {
        showInactive: true
      },
      IconButtonProps: {
        variant: 'contained',
        color: 'error',
        size: 'smaller',
        density: 'sparse'
      }
    }
  }, [canUpdate, dialogControl, snackbar, teams, team, clientId, teamId, deleteTeam.mutation]);

  const handleChange = (field, value, onSuccess, onError) => {
    patchTeam(team, {[field.name]: value})
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onError?.();
      });
  };

  return <StyledTeamsSettingsCard ref={ref} {...innerProps}
                                  elevation={1}>
    <TeamsSettingsCardHeader title={team.name}
                             subtitle={`${team.members.length ?? 0} users`}
                             isPrivate={team.props?.isPrivate}
                             canUpdate={canUpdate && authorize({attribute: 'team.update', meta: {team}})}
                             addUsersButtonAction={addUsersButtonAction}
                             deleteTeamButtonAction={deleteTeamButtonAction}
                             onChange={handleChange}/>
    <CardContent className="TeamsSettingsCard-content">
      <TeamMembersTable team={team}
                        variant="extended"
                        canUpdate={canUpdate} />
    </CardContent>
  </StyledTeamsSettingsCard>
});

TeamsSettingsCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  team: PropTypes.object,
  canUpdate: PropTypes.bool
};

TeamsSettingsCard.defaultProps = {};

export default TeamsSettingsCard;
