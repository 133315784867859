import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Fab from '@mui/material/Fab';
import constants from 'helpers/constants';

const StyledFab = styled(Fab)`
  ${props => props.theme.state2Selector('Fab', null, 'selected')} {
    background-color: ${props => props.theme.state2Color(props.color, 'selected', constants.colorTypes.button, constants.colorTargets.background)};
  }
  
  &.Fab {
    .Badge {
      .MuiBadge-badge {
        top: -${props => props.theme.layout('0.9sp')};
        right: -${props => props.theme.layout('0.9sp')};
      }
    }
  }
  
  // sizes
  &.Fab-size-larger {
    width: ${props => props.theme.layout(96)};
    height: ${props => props.theme.layout(96)};
  }
  
  ${ComponentStyles}
`;

StyledFab.propTypes = {
  theme: PropTypes.object
};

StyledFab.defaultProps = {}

export default StyledFab;
